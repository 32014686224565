import React from "react";
import { useState } from "react";
import Logo from "../images/logo.png";
import { collection, doc, Firestore, setDoc } from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import swal from "sweetalert";
import { db } from "../firebase.config";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import NumericFormat from "react-number-format";
import config from "../config";
const Signup = () => {
  const navigate = useNavigate();
  const [signIn, setSignIn] = useState(false);
  const [values, setValues] = useState({
    fname: "",
    lname: "",
    company: "",
    weburl: "",
    contact: "",
    email: "",
    password: "",
    zipCode: "",
    cpassword: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    if (
      values.cpassword &&
      values.email &&
      values.fname &&
      values.lname &&
      values.password
    ) {
      if (values.password === values.cpassword) {
        debugger;
        try {
          createUserWithEmailAndPassword(auth, values.email, values.password)
            .then(async (res) => {
              // const user = res.user;

              sendEmailVerification(res.user, {
                url:
                  window.location.hostname === "localhost" ||
                  window.location.hostname === "127.0.0.1"
                    ? `http://localhost:3000/`
                    : `https://member.postaira.com/`,
              })
                .then(() => {
                  // Your code is here
                  swal({
                    title: "Please check your email to verify your account",
                    icon: "success",
                  });
                })
                .catch((error) => {
                  console.log("Email verification error", error);
                });
              values.password = CryptoJS.AES.encrypt(
                values.password,
                "sdfsfdUnj5n8xdj9IEBasdfsdfvFsdf_#@#JRrc"
              ).toString();
              values.cpassword = CryptoJS.AES.encrypt(
                values.cpassword,
                "sdfsfdUnj5n8xdj9IEBasdfsdfvFsdf_#@#JRrc"
              ).toString();
              values.plan = "Free Plan";
              setDoc(doc(db, "users", res.user.uid), values);
              const PasswordDe = CryptoJS.AES.decrypt(
                values.password,
                "sdfsfdUnj5n8xdj9IEBasdfsdfvFsdf_#@#JRrc"
              );
              values.password = PasswordDe.toString(CryptoJS.enc.Utf8);
              axios
                .post(`${config.base_api_url}/auth/signUp`, values)
                .then((response) => {
                  setSignIn(true);
                  navigate("/");
                })
                .catch((error) => {
                  console.error("There was an error!", error);
                });
            })
            .catch((error) =>
              swal({
                title: error.message,
                icon: "error",
                dangerMode: true,
              })
            );
        } catch (error) {
          console.error("Error:================================ ", error);
        }
      } else {
        swal({
          title: "Password and confirm password are not match",
          icon: "error",
          dangerMode: true,
        });
      }
    } else {
      swal({
        title: "Please fill required fields",
        icon: "error",
        dangerMode: true,
      });
    }
  };
  return (
    <>
      <div className="main_wrap">
        <div className="left_form">
          <div className="inner_form">
            <h1>Sign Up</h1>
            <p>Enter your Details!</p>
            <form onSubmit={handleSubmit}>
              <div className="group">
                <div className="form_group">
                  <label>
                    First Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    name="fname"
                    value={values.fname}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter First name"
                  />
                </div>
                <div className="form_group">
                  <label>
                    Last Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    name="lname"
                    value={values.lname}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Last name"
                  />
                </div>
              </div>
              <div className="group">
                <div className="form_group">
                  <label>Company</label>
                  <input
                    type="text"
                    defaultValue=""
                    name="company"
                    value={values.company}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Company name"
                  />
                </div>
                <div className="form_group">
                  <label>
                    Email<span>*</span>
                  </label>
                  <input
                    type="text"
                    defaultValue=""
                    name="email"
                    value={values.email}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter email"
                  />
                </div>
              </div>
              <div className="group">
                <div className="form_group">
                  <label>Website URL</label>
                  <input
                    type="text"
                    defaultValue=""
                    name="weburl"
                    value={values.weburl}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter Website URL"
                  />
                </div>
                <div className="form_group">
                  <label>Phone Number</label>
                  {/* <input
                    type='number'
                    defaultValue=''
                    name='contact'
                    value={values.contact}
                    onChange={(e) => handleChange(e)}
                    placeholder='Enter contact number'
                  /> */}
                  <NumericFormat
                    format="+1 (###) ###-####"
                    placeholder="Enter contact number"
                    mask="_"
                    className="w-100 numFormat"
                    name="contact"
                    value={values.contact}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className="group">
                <div className="form_group">
                  <label>
                    Password<span>*</span>
                  </label>
                  <input
                    type="password"
                    defaultValue=""
                    name="password"
                    value={values.password}
                    onChange={(e) => handleChange(e)}
                    id="password-field"
                    placeholder="Enter password"
                  />
                  {/* <div className='show_password'>
                  <i
                    toggle='#password-field'
                    className='far fa-eye'
                    aria-hidden='true'
                  ></i>
                </div> */}
                </div>
                <div className="form_group">
                  <label>
                    Confirm Password<span>*</span>
                  </label>
                  <input
                    type="password"
                    defaultValue=""
                    name="cpassword"
                    value={values.cpassword}
                    onChange={(e) => handleChange(e)}
                    placeholder="Enter user name"
                  />
                </div>
              </div>
              <div className="form_group form_check">
                <div className="remember">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Keep me logged in
                  </label>
                </div>
              </div>
              <div className="form_group">
                <input type="submit" value="Submit" />
              </div>
            </form>
            {signIn == true ? null : (
              <div className="sign_text">
                <p>
                  Not registered yet? <Link to="/">Sign in User</Link>
                </p>
              </div>
            )}
          </div>
          {/* <div className='copyright'>
            <p>
              © 2023 Postaira. All Rights Reserved. 
            </p>
          </div> */}
        </div>

        <div className="right_logo">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div className="footer_link">
            <ul style={{ listStyle: "none" }}>
              <li>
                <a href="/">Terms of Use</a>
              </li>
              <li>
                <a href="/">Blog</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
