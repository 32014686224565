import { useEffect, useState } from "react";
import moment from "moment";

const useRecorder = () => {
  const [audioURLs, setAudioURLs] = useState([]);
  const [audioURL, setAudioURL] = useState("");
  const [title, setTitle] = useState("");
  const [editName, setEditName] = useState([]);

  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [blobData, setBlobData] = useState(null);

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.

    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    let blobArr = [];
    const handleData = async (e, file1) => {
      blobArr.push(e.data);
      if (e?.name != undefined) {
        let blob = await fetch(URL.createObjectURL(blobArr[0])).then((r) =>
          r.blob()
        );

        function returnFileSize(number) {
          if (number < 1024) {
            return number + "bytes";
          } else if (number >= 1024 && number < 1048576) {
            return (number / 1024).toFixed(2) + "KB";
          } else if (number >= 1048576) {
            return (number / 1048576).toFixed(2) + "MB";
          }
        }
        returnFileSize(blob.size);
        let arr = [];
        arr.push({
          title: `Untitled_${moment().format("L_LTS")}`,
          audio: URL.createObjectURL(blobArr[0]),
          createdTime: Date(),
          size: returnFileSize(blob?.size),
        });

        if (title != "") {
          setAudioURL({
            audio: arr[0].audio,
            title: title,
            createdTime: arr[0].createdTime,
            size: arr[0].size,
            file: e,
          });
          setAudioURLs(
            (prev) =>
              (prev = [
                ...prev,
                {
                  audio: arr[0].audio,
                  title: title,
                  createdTime: arr[0].createdTime,
                  size: arr[0].size,
                  file: e,
                },
              ])
          );
          // setTitle(`Untitled_${moment().format("L_LTS")}`);
        } else {
          setAudioURL({
            audio: arr[0].audio,
            title: arr[0].title,
            createdTime: arr[0].createdTime,
            size: arr[0].size,
            file: e,
          });
          setAudioURLs(
            (prev) =>
              (prev = [
                ...prev,
                {
                  audio: arr[0].audio,
                  title: arr[0].title,
                  createdTime: arr[0].createdTime,
                  size: arr[0].size,
                  file: e,
                },
              ])
          );
        }
        setBlobData(blobArr[0]);
      }
    };
    const stop = async (e) => {
      const mimeType = "audio/mpeg";
      if (blobArr.length != 0) {
        const audioBlob = new Blob(blobArr, {
          type: mimeType,
        });
        var blob_url = URL.createObjectURL(audioBlob);
        setBlobData(audioBlob);
        let file1 = new File([audioBlob], "audio.mp3", { type: mimeType });
        //  file.push(file1);
        handleData(file1);
      }
    };
    recorder.addEventListener("dataavailable", handleData);
    recorder.addEventListener("stop", stop);

    return () => {
      recorder.removeEventListener("dataavailable", handleData);
      recorder.removeEventListener("stop", stop);
    };
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    // const blob = new Blob(blobData, {
    //   type: "audio/mp3",
    // });
    setIsRecording(false);
  };
  const Save = () => {
    //  setIsRecording(false);
    if (title != "") {
      let arr = [];
      const checkData = audioURLs.filter((data) => {
        if (data.audio == audioURL.audio) {
          return (data.title = title);
        }
      });
      // checkData.title = title;
      arr.push(checkData);

      const checkData1 = audioURLs.filter((data) => {
        return data.audio != audioURL.audio;
      });
      arr.push(checkData1);
      setAudioURLs(arr.flat());
    }
    setAudioURL("");
  };
  const Delete = () => {
    const checkData = audioURLs.filter((data) => {
      return data.audio != audioURL.audio;
    });
    setAudioURLs(checkData);

    setAudioURL("");

    //  setIsRecording(false);
  };
  const EditOne = () => {
    // let arr = [];
    // const checkData = audioURLs.filter((data) => {
    //   if (data.audio == editName?.audio) {
    //     return (data.title = editName?.title);
    //   }
    // });
    // // checkData.title = title;
    // arr.push(checkData);
    // const checkData1 = audioURLs.filter((data) => {
    //   return data.audio != editName?.audio;
    // });
    // arr.push(checkData1);
    // setAudioURLs(arr.flat());
  };
  const CancelOne = (url) => {
    const checkData = audioURLs.filter((data) => {
      return data.audio != url.audio;
    });
    setAudioURLs(checkData);
  };
  return [
    audioURLs,
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    blobData,
    setAudioURLs,
    title,
    setTitle,
    CancelOne,
    Delete,
    Save,
  ];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  // const blob = new Blob({ type: audioType });

  return new MediaRecorder(stream);
}
export default useRecorder;

//Ref: https://codesandbox.io/s/81zkxw8qnl
