import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./fonts/dmsans-regular-webfont.ttf";
import "./fonts/dmsans-bold-webfont.ttf";
import "./fonts/dmsans-medium-webfont.ttf";
import "bootstrap/dist/css/bootstrap.css";
const stripePromise = loadStripe(
  "pk_test_51Lfy2uE8JwEGNJFzZIXSxNYq2OTkSht3iBBfV2yaZvEEkBiX6el6kFd8CFl5qeM9a4KjA1GXFnjCwDU50rXKYnpP00dhtAOfNZ"
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
