import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  setDoc,
  query,
  getDocs,
  updateDoc,
  where,
  onSnapshot,
  addDoc,
  orderBy,
  limit,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase.config";
import config from "../../config";
import { fetchExtreamApi } from "../../utils/fetchExtreamApi";
import CircularProgress from "@mui/material/CircularProgress";
import { Divider, Modal } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import moment from "moment";
import swal from "sweetalert";
import axios from "axios";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";

const Billing = ({ setValue }) => {
  const [data, setData] = useState();
  const [isBillingInfoFetched, setIsBillingInfoFetched] = useState(true);
  const [billingInfo, setBillingInfo] = useState(null);
  const [userInvoices, setUserInvoices] = useState([]);
  const stripe = useStripe();
  const elements = useElements();
  const [switchPaymentCard, setSwitchPaymentCard] = useState(false);
  const handleCancleSubscription = () => {
    swal({
      title: "Are you sure?",
      text: "Your subscription will be cancelled and you will not be charged further",
      icon: "warning",
      dangerMode: true,
      button: {
        text: "Proceed",
        closeModal: false,
      },
    }).then((willSearch) => {
      if (willSearch && billingInfo) {
        axios
          .post(`${config.cloud_functions_url}/cancelSubscription`, {
            subscriptionId:
              billingInfo && billingInfo.invoice
                ? billingInfo.invoice.subscription
                : "",
          })
          .then(async (res) => {
            console.log("response from cancel subcription", res);
            await userInfo();
            return swal(
              "Cancelled!",
              "Your active subscription is been cancelled",
              "success"
            );
          });
      } else {
        console.log(billingInfo + " has some issues");
      }
    });
  };

  const userInfo = async () => {
    try {
      setIsBillingInfoFetched(false);
      let response = {};
      let url1_ = `${config.base_api_url}/auth/UserInfo`;
      let method1_ = "GET";
      response = await fetchExtreamApi(url1_, method1_);
      console.log("response ---->", response);
      setData(response?.message);
      if (response.message) {
        const citiesRef = collection(db, "users");
        const q = query(
          citiesRef,
          where("email", "==", response.message.email)
        );
        const userInfoFromFirestore = await getDocs(q);
        if (!userInfoFromFirestore.empty) {
          userInfoFromFirestore.forEach(async (info) => {
            let id = info.id;
            setData({ id, ...response.message, ...info.data() });
            let invoicesRef = collection(db, "users", id, "invoices");
            let invoicesSnapshot = await getDocs(invoicesRef);
            let invoices = [];
            invoicesSnapshot.forEach((invoice) => {
              let inv = invoice.data();
              inv.plan =
                inv.amount == "24.99"
                  ? "Premium Plan"
                  : inv.amount == "9.99"
                  ? "Pro Plan"
                  : inv.amount == "3.9"
                  ? "Basic Plan"
                  : "Free Plan";
              invoices.push(inv);
            });
            invoices.length > 0 &&
              invoices.sort((a, b) => a.invoice.created - b.invoice.created);
            setUserInvoices(invoices);
            const latestInvoice =
              invoices.length > 0 &&
              invoices.reduce((prev, current) => {
                return prev.invoice.created > current.invoice.created
                  ? prev
                  : current;
              });
            setSwitchPaymentCard(false);
            setBillingInfo(latestInvoice);
            setIsBillingInfoFetched(true);
            console.log(latestInvoice, invoices);
          });
        }
      }
    } catch (err) {}
  };
  useEffect(() => {
    (async () => await userInfo())();
  }, []);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: "16px",
    transform: "translate(-50%, -50%)",
    width: 468,
    bgcolor: "background.paper",
    backgroundColor: "white",
    boxShadow: 24,
    p: 4,
    zIndex: "0",
  };
  const [paymentCardModal, showPaymentCardModal] = useState(false);

  const handleAddPaymentMethod = async (e) => {
    e.preventDefault();
    console.log(data);
    if (data.planStatus == "Cancelled") {
      return swal("Cancelled!", "Please buy a subscription first", "error");
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: data.billing_address,
    });
    if (paymentMethod) {
      let url1_ = `${config.cloud_functions_url}/changePaymentMethod`;
      let method1_ = "POST";
      let body = {
        stripe_subscription_id: data.stripe_subscription_id,
        stripe_customer_id: data.stripe_customer_id,
        paymentMethod,
      };
      const response = await axios.post(
        `${config.cloud_functions_url}/changePaymentMethod`,
        body
      );
      console.log("response", response);
      if (response.status == "200") {
        let exisiting_cards =
          data.cards && data.cards.length > 0
            ? data.cards.map((c) => {
                c.active = false;
                return c;
              })
            : [];
        let activeCard = {
          active: true,
          payment_method_id: paymentMethod.id,
          subscription_id: data.stripe_subscription_id,
          expire_month: paymentMethod.card.exp_month,
          expire_year: paymentMethod.card.exp_year,
          type: paymentMethod.type,
          last_4_digit: paymentMethod.card.last4,
          brand: paymentMethod.card.brand,
        };
        const docRef = doc(db, "users", data.id);
        await updateDoc(docRef, {
          cards: [...exisiting_cards, activeCard],
        });
        await userInfo();
        showPaymentCardModal(false);
      }
    }
  };

  const handleSwitchPaymentMethod = async (c) => {
    console.log(c);

    setSwitchPaymentCard(true);
    let body = {
      paymentMethod_id: c.payment_method_id,
      stripe_subscription_id: data.stripe_subscription_id,
      stripe_customer_id: data.stripe_customer_id,
    };
    const response = await axios.post(
      `${config.cloud_functions_url}/switchPaymentMethod`,
      body
    );
    console.log("response", response);
    if (response.status == "200") {
      let updateCards =
        data.cards.length > 0 &&
        data.cards.map((cd) => {
          if (cd.payment_method_id == c.payment_method_id) {
            cd.active = true;
          } else {
            cd.active = false;
          }
          return cd;
        });
      const docRef = doc(db, "users", data.id);
      await updateDoc(docRef, {
        cards: [...updateCards],
      });
      await userInfo();
    }
  };
  return (
    <div>
      {!isBillingInfoFetched || switchPaymentCard ? (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <h4 style={{ marginTop: "20px" }}>Subscription Details</h4>
          <Divider />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="col-7">
              {console.log(data)}
              <h5 style={{ marginTop: "5px" }}>
                Active:{" "}
                <span style={{ paddingLeft: "125px" }}>
                  {billingInfo && billingInfo.plan
                    ? billingInfo.plan
                    : "Not defined"}
                </span>
              </h5>
              <h5 style={{ marginTop: "5px" }}>
                Expires On:{" "}
                <span style={{ paddingLeft: "100px" }}>
                  {data && data.planExpiresAt
                    ? moment(Number(data.planExpiresAt)).format("MMMM Do YYYY")
                    : "Not defined"}
                </span>
              </h5>
              <h5 style={{ marginTop: "5px" }}>
                Next Billing Status:{" "}
                <span style={{ paddingLeft: "55px" }}>
                  {data && data.planStatus ? data.planStatus : "Not defined"}
                </span>
              </h5>
            </div>
            <div className="col-5">
              <Button
                variant="contained"
                style={{ margin: "5px" }}
                onClick={() => setValue("3")}
              >
                Get More Storage
              </Button>
              <Button
                variant="outlined"
                style={{ margin: "5px" }}
                onClick={handleCancleSubscription}
                disabled={
                  data && data.planStatus
                    ? data.planStatus == "Cancelled"
                    : false
                }
              >
                Cancel Subscription
              </Button>
            </div>
          </div>
          <h4 style={{ marginTop: "20px" }}> Billing Information</h4>
          <Divider />
          <h5 style={{ paddingTop: "10px" }}>
            Name:{" "}
            <span style={{ paddingLeft: "50px" }}>
              {billingInfo && billingInfo.invoice
                ? billingInfo.invoice.customer_name
                : ""}
            </span>
          </h5>
          <h5>
            Email:{" "}
            <span style={{ paddingLeft: "50px" }}>
              {billingInfo && billingInfo.invoice
                ? billingInfo.invoice.customer_email
                : ""}
            </span>
          </h5>
          <h4 style={{ marginTop: "20px" }}> Payment Method</h4>
          <Divider />
          <div
            style={{
              display: "flex",
              gap: "1",
              margin: "10px",
              boxSizing: "border-box",
              // flexFlow: "row wrap",
              columnGap: "10px",
            }}
          >
            {data &&
              data.cards &&
              data.cards.length > 0 &&
              data.cards.map((c) => {
                return (
                  <div
                    className="col-3"
                    style={{ border: "0.5px solid black", padding: "10px" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h5 style={{ display: "inline", paddingRight: "50px" }}>
                        Card:{" "}
                        <span style={{ paddingLeft: "50px" }}>
                          {c && c.brand ? c.brand : ""}
                        </span>
                      </h5>
                      <input
                        type="radio"
                        value={c.active}
                        defaultChecked={c.active}
                        onChange={() => handleSwitchPaymentMethod(c)}
                      />
                    </div>

                    <h5>
                      Number:{" "}
                      <span style={{ paddingLeft: "30px" }}>
                        {c && c.last_4_digit
                          ? `.... .... .... ${c.last_4_digit}`
                          : ""}
                      </span>
                    </h5>
                    <h5>
                      Expires:{" "}
                      <span style={{ paddingLeft: "35px" }}>
                        {c && c.expire_month
                          ? `0${c.expire_month}/${c.expire_year}`
                          : ""}
                      </span>
                    </h5>
                  </div>
                );
              })}
          </div>

          <Button
            onClick={() => {
              if (data.planStatus == "Cancelled") {
                return swal(
                  "Cancelled!",
                  "Please buy a subscription first",
                  "error"
                );
              }
              return showPaymentCardModal(true);
            }}
            style={{ cursor: "pointer", fontSize: "14px" }}
          >
            {" "}
            + Add payment method
          </Button>
          <h4 style={{ marginTop: "20px" }}>Invoice History</h4>
          <Divider />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontSize: "15px" }}>Plan</TableCell>
                <TableCell align="center" sx={{ fontSize: "15px" }}>
                  Status
                </TableCell>
                <TableCell align="center" sx={{ fontSize: "15px" }}>
                  Purchased On
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userInvoices.map((row, i) => {
                let date = moment(Number(row.invoice.created + "000")).format(
                  "MMMM Do YYYY, h:mm:ss a"
                );
                return (
                  <TableRow
                    key={i + Math.random(10)}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ fontSize: "12px" }}
                    >
                      {row.plan}
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      Paid
                    </TableCell>
                    <TableCell align="center" sx={{ fontSize: "12px" }}>
                      {date}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </>
      )}
      <Modal
        open={paymentCardModal}
        onClose={() => showPaymentCardModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <form id="payment-form" onSubmit={handleAddPaymentMethod}>
            <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
              Enter card details
            </h3>
            <CardElement style={{ margin: "10px 0px" }} />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{ margin: "5px" }}
                type="submit"
              >
                Add
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default Billing;
