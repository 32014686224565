import React, { useEffect } from "react";
import { useState } from "react";
import Dropdown from "react-dropdown";
import Switch from "react-input-switch";
import "react-dropdown/style.css";
import CryptoJS from "crypto-js";
import Modal from "@material-ui/core/Modal";
import swal from "sweetalert";
import { fetchExtreamApi } from "../../utils/fetchExtreamApi";

import {
  getAuth,
  updateEmail,
  onAuthStateChanged,
  reauthenticateWithCredential,
  updatePassword,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import CircularProgress from "@mui/material/CircularProgress";

import {
  collection,
  doc,
  setDoc,
  query,
  getDocs,
  updateDoc,
  where,
  onSnapshot,
  addDoc,
  orderBy,
  limit,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase.config";
import axios from "axios";
import { async } from "@firebase/util";
import config from "../../config";
const options1 = ["View", "Edit", "Admin"];
const defaultOption = options1[0];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "16px",
  transform: "translate(-50%, -50%)",
  border: "none",
  // width: 468, // remove this workkkkkk
  bgcolor: "background.paper",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: "0",
};
const General = ({ setValue, valueG }) => {
  const [accessValue, setAccessValue] = useState("View");
  const [data, setData] = useState();
  const [insert, setInsert] = useState(false);
  const [open, setOpen] = React.useState(false);
  // const [value, setValue] = useState(valueG);
  const [storage, setStorage] = useState({ limit: "", used: "" });
  const [storageDataFetched, setStorageDataFetched] = useState(true);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [fields, setFields] = useState({
    email: "",
    fname: "",

    lname: "",
    company: "",
    contact: "",
    cpassword: "",
    password: "",
    upassword: "",
    weburl: "",
    zipCode: "",
  });
  const handleCloseNoteVer = () => setOpen(false);
  const auth = getAuth();

  useEffect(() => {
    (async () => {
      setStorageDataFetched(false);
      let response = {};
      let url1_ = `${config.base_api_url}/auth/UserInfo`;
      let method1_ = "GET";
      response = await fetchExtreamApi(url1_, method1_);
      console.log("response ---->", response);
      setData(response?.message);
      let url1 = `${config.base_api_url}/api/imagesSizeCountStorage`;
      let method1 = "GET";
      await fetchExtreamApi(url1, method1)
        .then(async (res) => {
          console.log("res---->", res);
          const citiesRef = collection(db, "users");
          const q = query(
            citiesRef,
            where("email", "==", response.message.email)
          );
          const userInfoFromFirestore = await getDocs(q);
          if (!userInfoFromFirestore.empty) {
            console.log(userInfoFromFirestore);
            userInfoFromFirestore.forEach((info) => {
              let { plan } = info.data();
              console.log(info.data());
              let limit = "100";
              if (plan == "Free Plan") limit = "100";
              else if (plan == "Basic Plan") limit = "1024";
              else if (plan == "Pro Plan") limit = "10240";
              else if (plan == "Premium Plan") limit = "102400";
              console.log({ limit, used: res.used, plan: plan });
              setStorage({ limit, used: res.used, plan: plan });
              setStorageDataFetched(true);
            });
          } else {
            console.log("user is not available in firestore");
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    })();
  }, []);
  useEffect(() => {
    if (insert == true) {
      const check = async () => {
        const examcollref = doc(db, "users", auth?.lastNotifiedUid);
        await updateDoc(examcollref, {
          fname: fields.fname != "" ? fields.fname : data.fname,
          lname: fields.lname != "" ? fields.lname : data.lname,
          company: fields?.company != "" ? fields?.company : data?.company,
          contact: fields?.contact != "" ? fields?.contact : data?.contact,
          weburl: fields?.weburl != "" ? fields?.weburl : data?.weburl,
          zipCode:
            fields?.zipCode != ""
              ? fields?.zipCode
              : data?.zipCode != undefined
              ? data?.zipCode
              : null,
        })
          .then((response) => {
            const formData = new FormData();

            fields?.fname != "" && formData.append("fname", fields?.fname);

            image?.raw != undefined && formData.append("image", image?.raw);

            fields?.lname != "" && formData.append("lname", fields?.lname);

            fields?.company != "" &&
              formData.append("company", fields?.company);
            fields?.email != "" &&
              formData.append("emailAddress", fields?.email);
            fields?.contact != "" && formData.append("phone", fields?.contact);

            fields?.weburl != "" && formData.append("weburl", fields?.weburl);

            fields?.zipCode != "" &&
              formData.append("zipCode", fields?.zipCode);

            const fetchNote = async () => {
              try {
                let url1 = `${config.base_api_url}/auth/editUser`;
                let method1 = "POST";
                let response = await fetchExtreamApi(url1, method1, formData);
                setData(response?.message);
                if (response?.location != undefined) {
                  const checkl = async () => {
                    await updateDoc(examcollref, {
                      image: response?.location,
                    })
                      .then((response) => {
                        swal({
                          title: "Successfully Updated",
                          icon: "success",
                        });
                        // window.location.reload();
                      })
                      .catch((error) => {
                        console.log(error.message);
                      });
                  };
                  checkl();
                } else {
                  swal({
                    title: "Successfully Updated",
                    icon: "success",
                  });
                  // window.location.reload();
                }
              } catch (error) {
                // console.error(error.message);
              }
            };
            fetchNote();
          })
          .catch((error) => {
            console.log(error.message);
          });
      };
      check();
      setInsert(false);
    }
  }, [insert]);
  const handlePreImg = (e) => {
    setImage({
      preview: URL.createObjectURL(e.target.files[0]),
      raw: e.target.files[0],
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({
      ...fields,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fields.email != "") {
      if (fields.password == "") {
        setOpen(true);
      } else {
        setOpen(false);
        console.log("herereererwrewrwr");
        const user = auth.currentUser;
        const newEmail = fields.email; // Replace with the new email address

        // Check if the new email is already registered

        fetchSignInMethodsForEmail(auth, newEmail)
          .then((signInMethods) => {
            if (signInMethods.length === 0) {
              if (user) {
                // Prompt the user to re-authenticate before changing the email
                const credential = EmailAuthProvider.credential(
                  user.email,
                  fields.password
                );

                reauthenticateWithCredential(user, credential)
                  .then(() => {
                    // Re-authentication successful, proceed with email update
                    updateEmail(user, newEmail)
                      .then(() => {
                        // Email changed successfully
                        const check = async () => {
                          const examcollref = doc(
                            db,
                            "users",
                            auth?.lastNotifiedUid
                          );
                          await updateDoc(examcollref, {
                            fname:
                              fields.fname != "" ? fields.fname : data.fname,
                            lname:
                              fields.lname != "" ? fields.lname : data.lname,
                            company:
                              fields?.company != ""
                                ? fields?.company
                                : data?.company,
                            contact:
                              fields?.contact != ""
                                ? fields?.contact
                                : data?.contact,

                            weburl:
                              fields?.weburl != ""
                                ? fields?.weburl
                                : data?.weburl,
                            zipCode:
                              fields?.zipCode != ""
                                ? fields?.zipCode
                                : data?.zipCode != undefined
                                ? data?.zipCode
                                : null,
                            email:
                              fields.email != "" ? fields.email : data.email,
                          })
                            .then((res) => {
                              const formData = new FormData();

                              fields?.fname != "" &&
                                formData.append("fname", fields?.fname);

                              image?.raw != undefined &&
                                formData.append("image", image?.raw);

                              fields?.lname != "" &&
                                formData.append("lname", fields?.lname);
                              fields?.email != "" &&
                                formData.append("emailAddress", fields?.email);

                              fields?.company != "" &&
                                formData.append("company", fields?.company);

                              fields?.contact != "" &&
                                formData.append("phone", fields?.contact);

                              fields?.weburl != "" &&
                                formData.append("weburl", fields?.weburl);

                              fields?.zipCode != "" &&
                                formData.append("zipCode", fields?.zipCode);
                              const fetchNote = async () => {
                                try {
                                  let url1 = `${config.base_api_url}/auth/editUser`;
                                  let method1 = "POST";
                                  let response = await fetchExtreamApi(
                                    url1,
                                    method1,
                                    formData
                                  );
                                  console.log(response, "response");
                                  setData(response?.message);

                                  if (response?.location != undefined) {
                                    const check = async () => {
                                      const examcollref = doc(
                                        db,
                                        "users",
                                        auth?.lastNotifiedUid
                                      );
                                      await updateDoc(examcollref, {
                                        image: response?.location,
                                      })
                                        .then((response) => {
                                          swal({
                                            title: "Successfully Updated",
                                            icon: "success",
                                          });
                                          // window.location.reload();
                                        })
                                        .catch((error) => {
                                          console.log(error.message);
                                        });
                                    };
                                    check();
                                  } else {
                                    swal({
                                      title: "Successfully Updated",
                                      icon: "success",
                                    });
                                    // window.location.reload();
                                  }
                                } catch (error) {
                                  console.error(error.message);
                                  swal({
                                    title: error.message,
                                    icon: "error",
                                  });
                                  setFields({ ...fields, password: "" });
                                }
                              };
                              fetchNote();
                            })
                            .catch((error) => {
                              swal({
                                title: error.message,
                                icon: "error",
                              });
                              setFields({ ...fields, password: "" });
                              console.log(error.message, "jkhkjjhgjhfghfhgfh");
                            });
                        };
                        check();
                      })
                      .catch((error) => {
                        swal({
                          title: error.message,
                          icon: "error",
                        });
                        setFields({ ...fields, password: "" });
                        // An error occurred while changing email
                        console.error("Error updating email:", error);
                      });
                  })
                  .catch((error) => {
                    swal({
                      title: error.message,
                      icon: "error",
                    });
                    setFields({ ...fields, password: "" });
                    // An error occurred during re-authentication
                    console.error("Error re-authenticating user:", error);
                  });
              }
            } else {
              // The new email is already registered
              console.log("Email already exists");
            }
          })
          .catch((error) => {
            swal({
              title: error.message,
              icon: "error",
            });
            setFields({ ...fields, password: "" });
            // An error occurred while checking the new email
            console.error("Error checking new email:", error);
          });
      }
    }
    if (fields.cpassword != "" && fields.upassword != "") {
      if (fields.cpassword == fields.upassword) {
        if (fields.password == "") {
          setOpen(true);
          // console.log("please enter your current password");
        } else {
          setOpen(false);
          const user = auth.currentUser;
          const newPassword = fields.upassword;
          if (user) {
            // Prompt the user to re-authenticate before changing the email
            const credential = EmailAuthProvider.credential(
              user.email,
              fields.password
            );

            reauthenticateWithCredential(user, credential)
              .then(() => {
                // Re-authentication successful, proceed with email update

                updatePassword(user, newPassword)
                  .then(() => {
                    // Email changed successfully
                    const check = async () => {
                      const examcollref = doc(
                        db,
                        "users",
                        auth?.lastNotifiedUid
                      );
                      await updateDoc(examcollref, {
                        fname: fields.fname != "" ? fields.fname : data.fname,
                        lname: fields.lname != "" ? fields.lname : data.lname,
                        company:
                          fields?.company != ""
                            ? fields?.company
                            : data?.company,
                        contact:
                          fields?.contact != ""
                            ? fields?.contact
                            : data?.contact,

                        weburl:
                          fields?.weburl != "" ? fields?.weburl : data?.weburl,
                        zipCode:
                          fields?.zipCode != ""
                            ? fields?.zipCode
                            : data?.zipCode != undefined
                            ? data?.zipCode
                            : null,
                        cpassword: CryptoJS.AES.encrypt(
                          fields.cpassword,
                          "sdfsfdUnj5n8xdj9IEBasdfsdfvFsdf_#@#JRrc"
                        ).toString(),
                        password: CryptoJS.AES.encrypt(
                          fields.cpassword,
                          "sdfsfdUnj5n8xdj9IEBasdfsdfvFsdf_#@#JRrc"
                        ).toString(),
                      })
                        .then((response) => {
                          const formData = new FormData();

                          fields?.fname != "" &&
                            formData.append("fname", fields?.fname);

                          image?.raw != undefined &&
                            formData.append("image", image?.raw);
                          fields?.email != "" &&
                            formData.append("emailAddress", fields?.email);
                          fields?.lname != "" &&
                            formData.append("lname", fields?.lname);

                          fields?.company != "" &&
                            formData.append("company", fields?.company);

                          fields?.contact != "" &&
                            formData.append("phone", fields?.contact);

                          fields?.weburl != "" &&
                            formData.append("weburl", fields?.weburl);

                          fields?.zipCode != "" &&
                            formData.append("zipCode", fields?.zipCode);
                          const fetchNote = async () => {
                            try {
                              let url1 = `${config.base_api_url}/auth/editUser`;
                              let method1 = "POST";
                              let response = await fetchExtreamApi(
                                url1,
                                method1,
                                formData
                              );
                              setData(response?.message);

                              if (response?.location != undefined) {
                                const check = async () => {
                                  const examcollref = doc(
                                    db,
                                    "users",
                                    auth?.lastNotifiedUid
                                  );
                                  await updateDoc(examcollref, {
                                    image: response?.location,
                                  })
                                    .then((response) => {
                                      swal({
                                        title: "Successfully Updated",
                                        icon: "success",
                                      });
                                      // window.location.reload();
                                    })
                                    .catch((error) => {
                                      console.log(error.message);
                                    });
                                };
                                check();
                              } else {
                                swal({
                                  title: "Successfully Updated",
                                  icon: "success",
                                });
                                // window.location.reload();
                              }
                            } catch (error) {
                              // console.error(error.message);
                              swal({
                                title: error.message,
                                icon: "error",
                              });
                              setFields({ ...fields, password: "" });
                            }
                          };
                          fetchNote();
                        })
                        .catch((error) => {
                          console.log(error.message);
                          swal({
                            title: error.message,
                            icon: "error",
                          });
                          setFields({ ...fields, password: "" });
                        });
                    };
                    check();
                  })
                  .catch((error) => {
                    // An error occurred while changing email
                    swal({
                      title: error.message,
                      icon: "error",
                    });
                    setFields({ ...fields, password: "" });
                    console.error("Error updating email:", error);
                  });
              })
              .catch((error) => {
                swal({
                  title: error.message,
                  icon: "error",
                });
                setFields({ ...fields, password: "" });
                // An error occurred during re-authentication
                console.error("Error re-authenticating user:", error);
              });
          }
        }
      } else {
        swal({
          title:
            "Confirm password & Update password are not match, please check",
          icon: "error",
        });
      }
    }
    if (
      fields.email == "" &&
      fields.cpassword == "" &&
      fields.upassword == ""
    ) {
      setInsert(true);
    }
  };
  return (
    <div>
      <div className="col-lg-12">
        <div className="form-left h-100 ">
          <form className="row g-4" onSubmit={(e) => handleSubmit(e)}>
            <div
              // className="col-6"
              className="col-12 row py-5 "
            >
              <div
                className="col-6"
                style={{ display: "flex", alignItems: "center", gap: "5%" }}
              >
                <img
                  // src={image?.preview != "" ? image?.preview : "/user.png"}

                  src={
                    image?.preview != ""
                      ? image?.preview
                      : data?.image != undefined
                      ? data?.image
                      : "/user.png"
                  }
                  className="btnimg"
                />
                <div>
                  <input
                    type="file"
                    className="btnimgInput"
                    onChange={(e) => handlePreImg(e)}
                    style={{ width: "100px", height: "100px" }}
                  />
                  <h4 style={{ fontWeight: "600" }}>
                    {data?.fname} {data?.lname}
                  </h4>
                  Admin
                </div>
              </div>
              {!storageDataFetched ? (
                <div
                  className="col-6"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                storage.limit != "" && (
                  <div className="col-6">
                    <h4>Storage</h4>
                    <p>
                      {storage.used} MB used out of {storage.limit} MB
                    </p>
                    <div
                      className="top_input_box_half button"
                      style={{ padding: "0px" }}
                      onClick={() => setValue("3")}
                    >
                      <button>Get more storage</button>
                    </div>
                  </div>
                )
              )}
            </div>
            <div className="col-12 row py-5 ">
              <div className="col-6">
                <label>Firstname</label>
                <input
                  type="text"
                  name="fname"
                  defaultValue={data?.fname}
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  className="form-control bgchgd"
                  // placeholder="Enter Name"
                  autoComplete="off"
                />
              </div>
              <div className="col-6">
                <label>Lastname</label>
                <input
                  type="text"
                  name="lname"
                  defaultValue={data?.lname}
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  className="form-control bgchgd"
                  // placeholder="Enter Name"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="col-12 row ">
              <div className="col-6">
                <label>Phone</label>
                <input
                  type="text"
                  name="contact"
                  defaultValue={data?.contact}
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  className="form-control bgchgd"
                  // placeholder="Enter Name"
                  autoComplete="off"
                />
              </div>
              <div className="col-6">
                <label>Zip Code</label>
                <input
                  type="text"
                  name="zipCode"
                  defaultValue={data?.zipCode}
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  className="form-control bgchgd"
                  // placeholder="Enter Name"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="col-12 row py-5">
              <div className="col-6">
                <label>Email address</label>
                <input
                  type="text"
                  name="email"
                  defaultValue={data?.email}
                  onChange={(e) => handleChange(e)}
                  className="form-control bgchgd"
                  // placeholder="Enter Name"
                  autoComplete="off"
                />
              </div>
              {/* <div className="col-6">
                <label>Username</label>
                <input
                  type="text"
                  name="Username"
                  defaultValue={data?.username}
                  onChange={(e) =>
                    setFields({ ...fields, [e.target.name]: e.target.value })
                  }
                  className="form-control bgchgd"
                  // placeholder="Enter Name"
                  autoComplete="off"
                />
              </div> */}
            </div>
            <div
              className="col-12 row"
              style={{ padding: "0px 27px 0px 17px" }}
            >
              {" "}
              <hr />
            </div>

            <div className="col-12 row">
              <div className="col-6">
                <label>Update Password</label>
                <input
                  type="text"
                  name="upassword"
                  onChange={(e) =>
                    setFields({ ...fields, upassword: e.target.value })
                  }
                  className="form-control bgchgd"
                  // placeholder="Enter Name"
                  autoComplete="off"
                />
              </div>
              <div className="col-6">
                <label>Confirm Password</label>
                <input
                  type="text"
                  name="cpassword"
                  onChange={(e) =>
                    setFields({ ...fields, cpassword: e.target.value })
                  }
                  className="form-control bgchgd"
                  // placeholder="Enter Name"
                  autoComplete="off"
                />
              </div>
            </div>
            <div
              className="col-12 row py-5"
              style={{ padding: "0px 27px 0px 17px", height: "0px" }}
            >
              {" "}
              <hr />
            </div>
            <div className="col-12 row">
              <div className="top_input_box button" style={{ padding: "0px" }}>
                <button type="submit">Save</button>
              </div>
            </div>

            {/* <div className="col-12 mx-auto">
                <button type="submit" className="btn btn-primary px-4 mt-4">
                  Submit
                </button>
              </div> */}
            <Modal
              open={open}
              onClose={handleCloseNoteVer}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div style={style}>
                {" "}
                <div className="col-12">
                  <label>Current Password</label>
                  <input
                    type="password"
                    name="CurrentPassword"
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        password: e.target.value,
                      })
                    }
                    className="form-control bgchgd"
                    // placeholder="Current Password"
                    autoComplete="off"
                  />
                  <div
                    className="top_input_box button"
                    style={{ padding: "0px", marginTop: "10px" }}
                  >
                    <button onClick={(e) => handleSubmit(e)}>Submit</button>
                  </div>
                </div>
              </div>
            </Modal>
          </form>
        </div>
      </div>
    </div>
  );
};

export default General;
