import React, { Component, useState } from "react";

import EditNoteBox from "../../components/EditNoteBox";
import Leftside from "../../components/Leftside";
import Container from "../../components/NotesBox";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const EditNotes = () => {
  const [drawer, setDrawer] = useState(false);
  const [redirectPage, setRedirectPage] = useState(false);
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };
  return (
    <div className="mainwrap">
        <Drawer open={drawer} onClose={toggleDrawer} style={{ width: 300 }}>
          <div className="left_bar">
            {redirectPage != undefined && (
              <>
                {" "}
                {console.log(redirectPage, "redirectPage")}
                <Leftside redirectCheck={redirectPage} />
              </>
            )}
          </div>
        </Drawer>
        {/* <Leftside /> */}
      {/* </div> */}
      <div className="left_bar norView">
        <Leftside redirectCheck={redirectPage} />
      </div>
      <div className="right_side">
        <EditNoteBox
          setRedirectPage={setRedirectPage}
          drawer={drawer}
          setDrawer={setDrawer}
        />
      </div>
    </div>
  );
};

export default EditNotes;
