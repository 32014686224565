import { Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { db } from "../../firebase.config";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Modal from "@material-ui/core/Modal";
import SimpleBackdrop from "../../utils/spinner";
import config from "../../config";
import swal from "sweetalert";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { countries } from "./CountryCode";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "16px",
  transform: "translate(-50%, -50%)",
  width: 468,
  bgcolor: "background.paper",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: "0",
};
const Plan = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [checkedList, setcheckedList] = useState([]);
  const [checkedPlan, setcheckedPlan] = useState("");
  const [toggleButtonAction, setToggleButtonAction] = useState(false);
  const [data, setdata] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [payment, setpayment] = useState({
    fname: "",
    lname: "",
    email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    cardName: "",
    cardNum: "",
    expireDate: "",
    cvv: "",
  });
  const [planDataFetched, setPlanDataFetched] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setPlanDataFetched(false);
        const collectionRef = collection(db, "STRIPE_PRODUCTS");
        const querySnapshot = await getDocs(collectionRef);
        const fetchedData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("fetched data", fetchedData);
        setdata(fetchedData);
        setPlanDataFetched(true);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);
  const handleChange = (e) => {
    e.preventDefault();
    setpayment({ ...payment, [e.target.name]: e.target.value });
  };

  let requiredKeys = {
    fname: "First name",
    lname: "Last name",
    email: "Email",
    address: "Address",
    country: "Country",
    state: "State",
    city: "City",
    zip: "Zip",
  };
  const validateFields = (data) => {
    for (let key in data) {
      if (
        data.hasOwnProperty(key) &&
        data[key] === "" &&
        requiredKeys.hasOwnProperty(key)
      ) {
        return {
          key: key,
          message: `${requiredKeys[key]} is required`,
        };
      }
    }
    return {
      key: "",
      message: "success",
    };
  };
  const handleSubmit = async (e) => {
    setToggleButtonAction(true);
    e.preventDefault();
    console.log(payment);
    const { key, message } = validateFields(payment);
    if (message !== "success" && key !== "") {
      setToggleButtonAction(false);
      return alert(message);
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: payment.fname + " " + payment.lname,
        email: payment.email,
        address: {
          state: payment.state,
          city: payment.city,
          postal_code: payment.zip,
          line1: payment.address,
          country: payment.country,
        },
      },
    });

    if (error) {
      setToggleButtonAction(false);
      return alert(error.message);
    }

    let customerObj = {
      firstName: payment.fname,
      lastName: payment.lname,
      email: payment.email,
      state: payment.state,
      address: payment.address,
      zip: payment.zip,
      city: payment.city,
      planType: checkedList[0],
    };
    let subscriptionObj = {
      customerObj: customerObj,
      paymentMethod: paymentMethod,
    };

    if (!error) {
      try {
        //  const { id } = paymentMethod;
        const response = await axios.post(
          `${config.cloud_functions_url}/createSubscription`,
          subscriptionObj
        );
        console.log("Payment response", response);
        if (response && response.status == 200) {
          const email = payment.email;
          try {
            // Find the document ID by email
            const q = query(
              collection(db, "users"),
              where("email", "==", email)
            );
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (snapshot) => {
              // Get the document ID
              const documentId = snapshot.id;
              console.log(snapshot.data(), documentId);
              // Update the specific field in the document
              const docRef = doc(db, "users", documentId);
              const userData = snapshot.data();
              let exisiting_cards =
                userData.cards && userData.cards.length > 0
                  ? userData.cards.map((c) => {
                      c.active = false;
                      return c;
                    })
                  : [];
              await updateDoc(docRef, {
                isPaid: true,
                plan: checkedPlan,
                stripe_customer_id: response.data.customer,
                stripe_subscription_id: response.data.id,
                planStartsAt: Number(response.data.start_date + "000"),
                planExpiresAt: Number(
                  moment(
                    moment(Number(response.data.start_date + "000")).add(
                      1,
                      "month"
                    )
                  ).unix() + "000"
                ),
                planStatus: "Active",
                billing_address: {
                  name: payment.fname + " " + payment.lname,
                  email: payment.email,
                  address: {
                    state: payment.state,
                    city: payment.city,
                    postal_code: payment.zip,
                    line1: payment.address,
                    country: payment.country,
                  },
                },
                cards: [
                  // ...exisiting_cards,
                  {
                    active: true,
                    payment_method_id: paymentMethod.id,
                    subscription_id: response.data.id,
                    expire_month: paymentMethod.card.exp_month,
                    expire_year: paymentMethod.card.exp_year,
                    type: paymentMethod.type,
                    last_4_digit: paymentMethod.card.last4,
                    brand: paymentMethod.card.brand,
                  },
                ],
              });
              const subcollectionDocRef = doc(
                collection(db, "users", documentId, "plans")
              );
              await setDoc(subcollectionDocRef, {
                createdAt: response.data.created,
                productId: response.data.items.data[0].plan.product,
                plan: response.data.items.data[0].plan,
              });
              const subcollectionDocRefinvoices = doc(
                collection(db, "users", documentId, "invoices")
              );
              await setDoc(subcollectionDocRefinvoices, {
                amount: response.data.items.data[0].plan.amount / 100,
                invoiceId: response.data.latest_invoice.id,
                invoice: response.data.latest_invoice,
              });
              const subcollectionDocRefreceipts = doc(
                collection(db, "users", documentId, "receipts")
              );
              await setDoc(subcollectionDocRefreceipts, {
                receipt_url:
                  response.data.latest_invoice.payment_intent.charges.data[0]
                    .receipt_url,
              });
            });

            console.log("Document field updated successfully!");
          } catch (error) {
            console.error("Error updating document field:", error);
            throw error;
          }
          handleClose();
          swal({
            title:
              "Congratulations! Your subscription for selected plan is successful",
            icon: "success",
          });
          window.open(
            response.data.latest_invoice.hosted_invoice_url,
            "_blank"
          );
          return setToggleButtonAction(false);
        }
      } catch (error) {
        console.log("Error", error);
      }
    } else {
      console.log(error.message);
    }
  };
  const onCheckboxChange = (option, e) => {
    // if (e.target.checked) {
    setcheckedList([option.id + "#" + option.price.id]);
    setcheckedPlan(option.name);
    handleOpen();
    // } else {
    //   for (var i = 0; i < data.length; i++) {
    //     if (checkedList[i] == option.id + "#" + option.price.id) {
    //       checkedList.splice(i, 1);
    //     }
    //   }
    // }
  };
  return (
    <div className="container">
      <div
        className={`${
          planDataFetched && data.length > 0 && "card-deck"
        } mb-3 text-center`}
      >
        {!planDataFetched ? (
          <div
            // className="col-6"
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          data.length > 0 &&
          data.map((item) => {
            if (item.active)
              return (
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">{item.name}</h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">
                      ${item?.price?.unit_amount / 100}
                      <small className="text-muted">/ month</small>
                    </h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>included</li>
                      <li>{item.description}</li>
                      {/* <li>Email support</li>
                  <li>Help center access</li> */}
                    </ul>
                    <button
                      type="button"
                      className={
                        item?.price?.unit_amount / 100 == 0
                          ? "btn btn-lg btn-block btn-outline-primary"
                          : "btn btn-lg btn-block btn-primary"
                      }
                      onClick={
                        // item?.price?.unit_amount / 100 == 0
                        //   ? null
                        //   :
                        (e) => onCheckboxChange(item, e)
                      }
                    >
                      {item?.price?.unit_amount / 100 == 0 ? (
                        <>Sign up for free</>
                      ) : (
                        <>Get started</>
                      )}
                    </button>
                  </div>
                </div>
              );
          })
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div style={style}>
            {" "}
            <div className="card mb-4">
              <div className="card-header py-3">
                <h5 className="mb-0">Payment details</h5>
              </div>
              <div className="card-body">
                <form id="payment-form">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label for="firstName">
                        First name <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        style={{ fontSize: "12px" }}
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        id="firstName"
                        placeholder=""
                        name="fname"
                        // value=""
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label for="lastName">
                        Last name <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        style={{ fontSize: "12px" }}
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lname"
                        placeholder=""
                        onChange={(e) => handleChange(e)}
                        // value=""
                        required
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="email">
                      Email <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      style={{ fontSize: "12px" }}
                      type="email"
                      className="form-control"
                      required
                      disabled={payment.email}
                      name="email"
                      onChange={(e) => handleChange(e)}
                      id="email"
                      value={payment.email}
                      placeholder="you@example.com"
                    />
                  </div>
                  <div className="mb-3">
                    <label for="address">
                      Address <span style={{ color: "red" }}> *</span>
                    </label>
                    <input
                      style={{ fontSize: "12px" }}
                      type="text"
                      className="form-control"
                      id="address"
                      name="address"
                      onChange={(e) => handleChange(e)}
                      placeholder="1234 Main St"
                      required
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-5 mb-3">
                      <label for="country">
                        Country <span style={{ color: "red" }}> *</span>
                      </label>
                      <select
                        style={{ fontSize: "12px" }}
                        value={payment.country}
                        onChange={(e) => handleChange(e)}
                        placeholder=""
                        id="country"
                        className="form-control"
                        name="country"
                        required
                      >
                        {countries.length > 0 &&
                          countries.map((c) => (
                            <option style={{ fontSize: "12px" }} value={c.code}>
                              {c.country}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-md-4 mb-3">
                      <label for="state">
                        State <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        style={{ fontSize: "12px" }}
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        id="state"
                        name="state"
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label for="city">
                        City <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        style={{ fontSize: "12px" }}
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        id="city"
                        name="city"
                        placeholder=""
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label for="zip">
                        Zip <span style={{ color: "red" }}> *</span>
                      </label>
                      <input
                        style={{ fontSize: "12px" }}
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        id="zip"
                        name="zip"
                        placeholder=""
                        required
                      />
                    </div>
                  </div>{" "}
                  <CardElement />
                  {/* <div className="row">
                    <div className="col-md-6 mb-3">
                      <label for="cc-name">Name on card</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        id="cc-name"
                        name="cardName"
                        placeholder=""
                        required=""
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label for="cc-number">Credit card number</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        id="cc-number"
                        placeholder=""
                        name="cardNum"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label for="cc-expiration">Expiration</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChange(e)}
                        id="cc-expiration"
                        name="expireDate"
                        placeholder=""
                        required=""
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label for="cc-cvv">CVV</label>
                      <input
                        type="text"
                        onChange={(e) => handleChange(e)}
                        name="cvv"
                        className="form-control"
                        id="cc-cvv"
                        placeholder=""
                        required=""
                      />
                    </div>
                  </div> */}
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-primary  mt-4"
                    onClick={handleSubmit}
                    disabled={toggleButtonAction}
                  >
                    {toggleButtonAction ? (
                      <SimpleBackdrop size={16} />
                    ) : (
                      "Subscribe Now"
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default Plan;
