export const fetchExtreamApi = async (url, methodType, bodyValue) => {
  try {
    if (bodyValue == undefined) {
      let res = await fetch(url, {
        method: methodType,
        // signal,
        //   body: { bodyParams },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
          Accept: "text/event-stream",
          "Content-type": "application/json",
        },
      });

      // eslint-disable-next-line no-undef
      let decoder = new TextDecoderStream();

      const reader = res.body.pipeThrough(decoder).getReader();

      var data = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        // callback({ success: true, value });
        data = data + value;
        console.log("data---->", data);
        // const dataWithoutPrefix = data.replace("data: ", "");
      }
      const parsedData = JSON.parse(data);
      return parsedData;
    } else {
      if (bodyValue instanceof FormData) {
        console.log("i am here");
        // The object is an instance of FormData
        let res = await fetch(url, {
          method: methodType,
          // signal,
          body: bodyValue,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            Accept: "text/event-stream",
            // "Content-type": "application/json",
          },
        });

        // eslint-disable-next-line no-undef
        let decoder = new TextDecoderStream();

        const reader = res.body.pipeThrough(decoder).getReader();

        var data = "";

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          // callback({ success: true, value });
          data = data + value;
          const dataWithoutPrefix = data.replace("data: ", "");
          const parsedData = JSON.parse(dataWithoutPrefix);
          return parsedData;
        }
      } else {
        // The object is not a FormData instance
        console.log("no i am here", bodyValue);

        let res = await fetch(url, {
          method: methodType,
          // signal,
          body: JSON.stringify(bodyValue),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            Accept: "text/event-stream",
            "Content-type": "application/json",
          },
        });

        // eslint-disable-next-line no-undef
        let decoder = new TextDecoderStream();

        const reader = res.body.pipeThrough(decoder).getReader();

        var data = "";

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          // callback({ success: true, value });
          data = data + value;
          const dataWithoutPrefix = data.replace("data: ", "");
          const parsedData = JSON.parse(dataWithoutPrefix);
          return parsedData;
        }
      }
    }
  } catch (error) {
    console.log("Error while extream ", error);
  }
};
