import React, { Component } from "react";
import Leftside from "../../components/Leftside";
import Notification from "../../components/frontend/Notification";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { fetchExtreamApi } from "../../utils/fetchExtreamApi";
import config from "../../config";

const NotificationMain = () => {
  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };
  const [dataReqList, setDataReqList] = useState([]);
  const [data, setData] = useState(false);

  useEffect(() => {
    let url1 = `${config.base_api_url}/api/ReqShellListPartUser`;
    let method1 = "GET";
    (async () => {
      await fetchExtreamApi(url1, method1)
        .then((response) => {
          setDataReqList(response?.data);
          for (let arr of response?.data) {
            if (arr.count == true) {
              axios
                .post(
                  `${config.base_api_url}/api/UpdateCountNotification`,
                  {
                    main_id: arr._id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "firebasetoken"
                      )}`,
                    },
                  }
                )
                .then((res) => {
                  setData(true);
                })
                .catch((error) => {
                  console.error("There was an error!", error);
                });
            }
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    })();
  }, []);
  return (
    <div className="mainwrapNoti">
      <div className=" MobView">
        <AppBar position="static">
          {/* <Toolbar> */}
          <IconButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          {/* </Toolbar> */}
        </AppBar>
        <Drawer open={drawer} onClose={toggleDrawer} style={{ width: 300 }}>
          <div className="left_bar">
            <Leftside data={data} />
          </div>
        </Drawer>
        {/* <Leftside /> */}
      </div>
      <div className="left_bar norView">
        <Leftside data={data} />
      </div>
      <div className="right_side">
        <>
          {" "}
          <Notification
            dataReqList={dataReqList}
            setDataReqList={setDataReqList}
          />
        </>
      </div>
    </div>
  );
};

export default NotificationMain;
