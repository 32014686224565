import axios from "axios";
import React, { useMemo, useState, useCallback, useRef } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Parser from "html-react-parser";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import Moment from "react-moment";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import config from "../config";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { images } from "./frontend/images";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BlotFormatter from "quill-blot-formatter";

import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

import Modal from "@material-ui/core/Modal";
import Select from "react-select";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import Avatar from "@material-ui/core/Avatar";
import "react-select-search/style.css";
import useRecorder from "./useRecorder";
import CommentQuill from "./frontend/CommentQuill";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import CommentEdit from "./frontend/CommentEdit";
import { fetchExtreamApi } from "../utils/fetchExtreamApi";
const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });
  return keyPressed;
};

// import "sweetalert2/src/sweetalert2.scss";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "16px",
  transform: "translate(-50%, -50%)",
  border: "none",
  // width: 468, // remove this workkkkkk
  bgcolor: "background.paper",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: "0",
};
const EditNoteBox = ({ setRedirectPage, setDrawer, drawer }) => {
  let imagesArr = [];
  const [rangedReload, setRangedReload] = useState(false);
  const [rangedReloadHash, setRangedReloadHash] = useState(false);

  const [preview, setPreview] = useState([]);

  const [timer, setTimer] = useState(0);
  const [start, setStart] = useState(false);
  const [ranged, setRanged] = useState();
  const [imageWidth, setImageWidth] = useState(null);
  const [openQuillEdit, setOpenQuillEdit] = useState(false);
  const [idQuillEdit, setIdQuillEdit] = useState(null);

  const [rangedATR, setRangedATR] = useState();
  const firstStart = useRef(true);
  const tick = useRef();
  const dispSecondsAsMins = (seconds) => {
    // 25:00
    const mins = Math.floor(seconds / 60);
    const seconds_ = seconds % 60;
    return (
      mins.toString() +
      ":" +
      (seconds_ == 0
        ? "00"
        : seconds_.toString().length == 2
        ? seconds_.toString()
        : ` 0${seconds_.toString()}`)
    );
  };
  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }

    if (start) {
      tick.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(tick.current);
    }

    return () => clearInterval(tick.current);
  }, [start]);
  function handleImageLoad() {
    window.dispatchEvent(new Event("resize"));
    // const image = document.querySelector(".ril-image-current");
    // if (image) {
    //   if (image.naturalWidth == undefined) {
    //     setTimeout(() => {
    //       console.log(
    //         document.querySelector(".ril-image-current").naturalWidth,
    //         document.querySelector(".ril-image-current").naturalHeight,
    //         "imagenatura)"
    //       );
    //       if (image.naturalWidth > 2000) {
    //         setImageWidth(1400);
    //         setImageHeight("auto");
    //       } else if (image.naturalHeight > 2000) {
    //         setImageWidth("auto");
    //         setImageHeight(1400);
    //       }
    //     }, 500);
    //   } else {
    //     console.log(
    //       document.querySelector(".ril-image-current").naturalWidth,
    //       document.querySelector(".ril-image-current").naturalHeight,
    //       "imagenatura)"
    //     );
    //     if (image.naturalWidth > 2000) {
    //       setImageWidth(1400);
    //       setImageHeight("auto");
    //     } else if (image.naturalHeight > 2000) {
    //       setImageWidth("auto");
    //       setImageHeight(1400);
    //     } else {
    //       setImageWidth(undefined);
    //       setImageHeight(undefined);
    //     }
    //   }

    //   //  setImageWidth(image.naturalWidth);
    //   //  setImageHeight(image.naturalHeight);
    // }
  }
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };
  const toggleStart = () => {
    setStart(!start);
    let tip;
    let audioContext;
    let context;
    let audioInput;

    var microphone = document.getElementById("microphone");
    function startRecord(e) {
      var record = navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      record.then(function onSuccess(stream) {
        audioContext = window.AudioContext;

        try {
          context = new audioContext();
        } catch (e) {
          console.log("not support AudioContext");
        }

        audioInput = context.createMediaStreamSource(stream);

        var binaryData = [];
        binaryData.push(stream);
        microphone.src = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/zip" })
        );
        microphone.onloadedmetadata = function (e) {};
        var analyser = context.createAnalyser();
        audioInput.connect(analyser);

        drawSpectrum(analyser);
      });
      record.catch(function (e) {
        tip.innerHTML = e.name;
      });
      var drawSpectrum = function (analyser) {
        var canvas = document.getElementById("canvas"),
          cwidth = canvas.width,
          cheight = canvas.height,
          meterWidth = 8,
          gap = 2,
          meterNum = cwidth / (meterWidth + gap),
          ctx = canvas.getContext("2d"),
          gradient = ctx.createLinearGradient(0, 0, 0, cheight);
        gradient.addColorStop(1, "#a467af");
        gradient.addColorStop(0.3, "#ff0");
        gradient.addColorStop(0, "#f00");
        ctx.fillStyle = gradient;
        var drawMeter = function () {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);

          var step = Math.round(array.length / meterNum);
          ctx.clearRect(0, 0, cwidth, cheight);
          for (var i = 0; i < meterNum; i++) {
            var value = array[i * step];

            ctx.fillRect(
              i * (meterWidth + gap),
              cheight - value,
              meterWidth,
              cheight
            );
          }
          requestAnimationFrame(drawMeter);
        };
        requestAnimationFrame(drawMeter);
      };
    }

    startRecord();
  };

  let [
    audioURLs,
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    blobData,
    setAudioURLs,
    title,
    setTitle,
    CancelOne,
    Delete,
    Save,
  ] = useRecorder();
  useEffect(() => {
    // Perform your desired action here, such as displaying an alert
    const handler = (event) => {
      // window.location.replace = "http://localhost:3000/admin";

      event.preventDefault();
      event.returnValue = "";
    };
    if (audioURLs.length != 0 || rangedReload != 0 || preview.length != 0) {
      window.addEventListener("beforeunload", handler);
      // const unblock = () => navigate(location.pathname);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
        // unblock();
      };
    }

    return () => {};
    // Rest of your logic
  }, [rangedReload, audioURLs, preview]);
  useEffect(() => {
    // Perform your desired action here, such as displaying an alert
    if (audioURLs.length != 0) {
      setRedirectPage(true);
    } else {
      setRedirectPage(false);
    }
    // Rest of your logic
  }, [audioURLs]);
  // var arrow = document.getElementById("myBtn_save");
  // window.onscroll = function (e) {
  //   var arrowBound = arrow.getBoundingClientRect();
  //   const originalTop = arrowBound.top + window.pageYOffset;
  //   const originalBottom = arrowBound.bottom + window.pageYOffset;

  //   // var color = item.getAttribute("arrowColor");
  //   // if (
  //   //   arrowBound.bottom > divBound.top &&
  //   //   arrowBound.top < divBound.bottom
  //   // ) {
  //   //   arrow.style.borderTopColor = color;
  //   // }
  // };
  // useEffect(() => {}, [arrow]);
  useEffect(() => {
    // Perform your desired action here, such as displaying an alert
    if (audioURLs.length != 0) {
      setRedirectPage(true);
    } else {
      setRedirectPage(false);
    }
    // Rest of your logic
  }, [audioURLs]);
  useEffect(() => {
    if (audioURL) {
      fetch(`http://localhost/fileuploader/fileuploader.php`, {
        method: "POST",
        mode: "cors",
        body: blobData,
      })
        .then((response) => {
          if (response.ok) return response;
          else
            throw Error(
              `Server returned ${response.status}: ${response.statusText}`
            );
        })
        .then((response) => console.log(response.text()))
        .catch((err) => {
          // alert(err);
        });
      //let audio = new Audio(audioURL);
      document.querySelector("audio").play();
    }
  }, [audioURL, blobData]);
  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "size",
    "list",
    "indent",
    "header",
    "height",
    "width",
    "color",
    "background",
    "font",
    "align",
    "image",
    "link",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link"],
      [
        // { size: ["small", false, "large", "huge"] },
        { header: [1, 2, 3, 4, 5, 6, false] },
      ],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [
        {
          font: [],
        },
      ],
      [{ align: [] }],
      ["image"],
    ],
    blotFormatter: {},
  };

  // var quill1 = new Quill("#editor-container", {
  //   theme: "snow",
  //   readOnly: true,
  // });
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [notes, setNotes] = useState();
  const [notesVersion, setNotesVersion] = useState();
  const [mentionInfo, setMentionInfo] = useState([]);

  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [uploadImage, setUploadImage] = useState([]);
  const [saveId, setSaveId] = useState("");
  const [titleChange, setTitleChange] = useState("");

  const [notesTitle, setNotesTitle] = useState();
  const [notesTitle1, setNotesTitle1] = useState();
  const [valueOp, setValueOp] = useState("0");
  const [comments, setComments] = useState();
  const [accessValue, setAccessValue] = useState("View");
  const [atTag, setAtTag] = useState([]);
  const [atTagB, setAtTagB] = useState(false);
  const [accessValueEdit, setAccessValueEdit] = useState("");
  const [accessValueEdit1, setAccessValueEdit1] = useState("");
  const [accessValueEdit2, setAccessValueEdit2] = useState("");

  const [editId, setEditId] = useState("");
  const [editId1, setEditId1] = useState("");
  const [editId2, setEditId2] = useState("");

  const [index, setIndex] = useState(-1);
  const [index2, setIndex2] = useState(-1);

  const [allDataType, setAllDataType] = useState([]);
  const [defaultLi, setDefaultLi] = useState(false);

  const [shareWith, setShareWith] = useState("");
  const [typedValue, setTypedValue] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);
  const [allComments, setAllComments] = useState([]);
  const [allData, setAllData] = useState([]);
  const [imagesArr1, setImagesArr1] = useState([]);
  const [imagesArr2, setImagesArr2] = useState([]);
  const [imagesArr3, setImagesArr3] = useState([]);

  const [titleInput, setTitleInput] = useState(false);
  const [allTItle, setAllTitle] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [allTItle1, setAllTitle1] = useState([]);
  const [noteSp, setNoteSp] = useState(false);
  const [sharedNoteUser, setSharedNoteUser] = useState([]);
  const [hashAdd, setHashAdd] = React.useState(false);

  const [searchValueHash, setsearchValueHash] = useState("");
  const [searchValueATR, setsearchValueATR] = useState("");
  const [openG, setOpenG] = React.useState(false);
  const [openA, setOpenA] = React.useState(false);
  const [openRec, setOpenRec] = React.useState(false);
  const [openNoteVersion, setOpenNoteVersion] = React.useState(false);
  const [noteVersionUser, setNoteVersionUser] = React.useState("");

  const [open2, setOpen2] = React.useState(false);
  let hashTitle = [];

  const [open3, setOpen3] = React.useState(false);
  const handleOpen = () => {
    const fetchNote = async () => {
      let url = `${config.base_api_url}/api/sharedNoteUsers`;
      let method = "GET";
      fetchExtreamApi(url, method).then((result) => {
        const uniqueObjects = [];
        const idSet = new Set();
        let arr = result?.sharedNote;
        for (const obj of arr) {
          if (!idSet.has(obj._id)) {
            uniqueObjects.push(obj);
            idSet.add(obj._id);
          }
        }

        setSharedNoteUser(uniqueObjects);
      });
    };
    fetchNote();
    setOpen2(true);
  };
  const handleOpenGallery = (e) => {
    e.preventDefault();
    setOpenG(!openG);
  };
  const handleCloseGallery = () => setOpenG(false);
  const handleOpenAudio = (e) => {
    e.preventDefault();
    setOpenA(!openA);
  };
  const handleCloseAudio = () => setOpenA(false);
  const handleOpenRec = () => setOpenRec(true);
  const handleOpenHash = () => {
    setHashAdd(true);
  };
  const handleCloseRec = () => setOpenRec(false);
  const handleCloseNoteVer = () => setOpenNoteVersion(false);

  const handleClose = () => setOpen2(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const options1 = ["View", "Edit", "Admin"];
  const defaultOption = options1[0];
  const Item = ({ entity: { text } }) => {
    return titleInput && <div>{`${text}`}</div>;
  };
  const Loading = () => <div>Loading</div>;

  useEffect(() => {
    const fetchNote = async () => {
      let url = `${config.base_api_url}/api/getnoteMain`;
      let method = "GET";
      fetchExtreamApi(url, method).then((result) => {
        setAllDataType(result);
      });
      // try {
      //   const { data: response } = await axios.get(
      //     `${
      //       window.location.hostname === "localhost" ||
      //       window.location.hostname === "127.0.0.1"
      //         ? process.env.REACT_APP_BASE_API_LOCAL
      //         : process.env.REACT_APP_BASE_API_LIVE
      //     }/api/getnoteMain`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
      //       },
      //     }
      //   );
      //   setAllDataType(response);
      // } catch (error) {
      //   // console.error(error.message);
      // }
    };
    fetchNote();
  }, []);
  useEffect(() => {
    (async () => {
      let url1 = `${config.base_api_url}/api/getCommentWithType/${params.id}`;
      let method1 = "GET";
      let response = await fetchExtreamApi(url1, method1);

      if (response?.description == undefined) {
        const stage = [response?.note_reference];

        if (imagesArr.length == 0) {
          for (let arr of stage) {
            for (let arr1 of arr?.noteimage) {
              imagesArr.push({
                src: arr1,
                width: 100,
                height: 100,
                // isSelected: true,
                _id: arr?._id,
                original: arr1,
                caption: "random",
              });
            }
          }
        }
        setImagesArr1(imagesArr.flat());
        setImagesArr2(response?.note_reference?.noteImages);
      } else {
        const stage = [response];
        if (imagesArr.length == 0) {
          for (let arr of stage) {
            for (let arr1 of arr?.noteimage) {
              imagesArr.push({
                src: arr1,
                width: 100,
                height: 100,
                // isSelected: true,
                _id: arr?._id,
                original: arr1,
                caption: "random",
              });
            }
          }
        }
        setImagesArr1(imagesArr.flat());
        setImagesArr2(response?.noteImages);
      }

      setAllData(response);

      if (response?.description == undefined) {
        setNotesVersion(response?.note_reference?.description);
        setNotes(response?.note_reference?.description);
        // setAllData(response?.data);
        setAllComments(response?.note_reference?.comments);
      } else {
        setNotesVersion(response?.description);
        setNotes(response?.description);
        // setAllData(response?.data);
        setAllComments(response?.comments);
      }
    })();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url1 = `${config.base_api_url}/api/getnoteMain`;
        let method1 = "GET";
        let response = await fetchExtreamApi(url1, method1);
        let arr = [];
        response.map(
          (data) => !arr.includes(data.title) && arr.push(data.title)
        );
        setAllTitle(arr);
        setAllTitle1(arr);
      } catch (error) {
        console.error(error.message);
      }
      try {
      } catch (error) {
        console.error(error.message, "eeroooooooo");
      }
    };
    fetchData();
  }, []);
  const readOnly =
    allData?.access == undefined
      ? allData?.permission == undefined
        ? false
        : allData?.permission == "View"
        ? true
        : false
      : allData?.access == "View"
      ? true
      : false;

  const { quill, quillRef, Quill } = useQuill({
    modules,
    formats,
    readOnly,
  });
  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register("modules/blotFormatter", BlotFormatter);
  }
  var toolbarDiv = document.querySelector("div.ql-toolbar.ql-snow");

  React.useEffect(() => {
    if (toolbarDiv != null) {
      var spanElement = document.createElement("span");
      var iElement = document.createElement("i");
      iElement.className = "fa-solid fa-print";

      spanElement.appendChild(iElement);

      spanElement.onclick = handlePrint; // Assuming handlePrint is the function to be executed on click
      spanElement.className = "spanPrint";
      // Append the span element to the toolbar div
      toolbarDiv.appendChild(spanElement);
    }
  }, [quill]);
  React.useEffect(() => {
    if (notes) {
      if (quill) {
        let newRange = [];
        let newRange1 = [];
        quill.on("editor-change", (eventName, ...args) => {
          if (eventName === "selection-change") {
            const range = args[0];
            if (range) {
              newRange.push(range.index);
              setRangedReloadHash(range.index);
              // if (range.index == 0) {
              //   setRedirectPage(false);
              // } else {
              //   setRedirectPage(true);
              // }
            }
          }
        });
        quill.clipboard.dangerouslyPasteHTML(notes);

        quill.on("text-change", (delta, oldDelta, source) => {
          quill.on("editor-change", (eventName, ...args) => {
            if (eventName === "selection-change") {
              const range = args[0];

              if (range) {
                setRangedReload(range.index);
                if (range.index == 0) {
                  setRedirectPage(false);
                } else {
                  setRedirectPage(true);
                }
              }
            }
          });

          const getUser = (s) =>
            s.includes("#") && s.substr(s.lastIndexOf("#") + 1).split(" ")[0];

          if (quill.getText().match("#")) {
            var range = quill.getSelection();

            const fetchData = async () => {
              try {
                let url1 = `${config.base_api_url}/api/getnoteMain`;
                let method1 = "GET";
                let response = await fetchExtreamApi(url1, method1);
                // setAllNotes(response);

                let arr = [];
                let arr1 = [];
                response.map((data) => {
                  if (data.type == "TagShell") {
                    data?.title &&
                      !arr1.includes(data.title) &&
                      arr1.push({
                        title: data.title,
                        shareBy: data?.sharedBy?.fname + data?.sharedBy?.lname,
                      });
                  } else {
                    data?.title &&
                      !arr1.includes(data.title) &&
                      arr1.push({ title: data.title, shareBy: "No" });
                  }
                });
                const titleMain = arr1.length != 0 && arr1.flat();
                let removesubHash = [];
                hashTitle = [];
                for (let data of titleMain) {
                  if (data?.title[0] != undefined) {
                    if (data?.shareBy != "No") {
                      removesubHash.push(
                        "@" +
                          data?.shareBy +
                          data?.title[0].replace(/\b\#\w+/g, "")
                      );
                    } else {
                      removesubHash.push(
                        data?.title[0].replace(/\b\#\w+/g, "")
                      );
                    }
                  }
                }
                let uniqueChars = [...new Set(removesubHash)];
                let allHeads = [uniqueChars];

                const subHead = arr1.flat().filter((data) => {
                  if (data?.title[0] != undefined) {
                    // return data?.title[0].match(/#/g).length >= 2;
                    if (data?.shareBy != "No") {
                      if (data?.title[0].match(/#/g).length >= 2) {
                        allHeads.push("@" + data?.shareBy + data?.title[0]);
                      }
                    } else {
                      if (data?.title[0].match(/#/g).length >= 2) {
                        allHeads.push(data?.title[0]);
                      }
                    }
                  }
                });
                allHeads.push(subHead);

                const data = [];
                //  ==================================================================================
                var str = quill.getText();
                var stringPosWord = [];
                // let postText = text.replace(regexp, "");

                let test = str.substring(str.lastIndexOf("#") + 1, str.length);
                function getWordAt(str, pos) {
                  // Perform type conversions.
                  str = String(str);
                  pos = Number(pos) >>> 0;

                  // Search for the word's beginning and end.
                  var left = str.slice(0, pos + 1).search(/\S+$/),
                    right = str.slice(pos).search(/\s/);

                  // The last word in the string is a special case.
                  if (right < 0) {
                    stringPosWord.push(str.slice(left));
                    return str.slice(left);
                  }

                  // Return the word, using the located bounds to extract it from the string.
                  stringPosWord.push(str.slice(left, right + pos));

                  return str.slice(left, right + pos);
                }
                let num = quill.getSelection()?.index;
                if (num != undefined) {
                  getWordAt(quill.getText().replace(/\n/g, " "), num - 1);
                }
                if (stringPosWord.length != 0) {
                  const data1 = allHeads.flat().filter((text) => {
                    if (text.includes("@")) {
                      let atAdd = text.split(/(?=#)/g);

                      return text
                        ?.toLowerCase()

                        .startsWith(
                          `${atAdd[0]}${stringPosWord[0].trim()}`.toLowerCase()
                        );
                    } else {
                      return text
                        ?.toLowerCase()

                        .startsWith(`${stringPosWord[0].trim()}`.toLowerCase());
                    }
                  });
                  data.push(data1);
                }

                if (data[0] != undefined) {
                  if (data[0].length != 0 && stringPosWord[0].includes("#")) {
                    setNoteSp(true);
                    setsearchValueHash(stringPosWord[0]);
                    setTimeout(() => {
                      // const element = document.getElementById("unicorn");
                      const cursorPosition = 33;
                      const editor1 = document.getElementById("unicorn");
                      let arry = newRange;
                      let lastElement = arry[arry.length - 1];
                      setRanged(lastElement);
                      let index = lastElement - stringPosWord[0].length;
                      quill.setSelection(lastElement);

                      // Get the bounds of the selection object
                      const bounds = quill.getBounds(index);
                      // Calculate the offsetLeft and offsetTop properties of the cursor position
                      const offsetLeftk =
                        bounds.left + window.pageXOffset - editor1.offsetLeft;
                      const offsetTopk =
                        bounds.top + window.pageYOffset - editor1.offsetTop;
                      const offsetBottom = bounds.top + bounds.height;

                      var float_elm =
                        document.getElementsByClassName("Active_Float")[0];

                      let x = offsetTopk;
                      let positive_x = x < 0 ? -x : x;
                      if (offsetTopk <= -50) {
                        float_elm.style.top = `${positive_x + 10}px`;
                      } else {
                        float_elm.style.top = `${positive_x + 60}px`;
                      }

                      float_elm.style.left = `${offsetLeftk + 5}px`;
                    }, 1000);
                  } else {
                    setNoteSp(false);
                    if (stringPosWord[0].includes("#")) {
                      setTimeout(() => {
                        let arry = newRange;
                        let lastElement = arry[arry.length - 1];

                        quill.setSelection(lastElement);
                      }, 500);
                    }
                  }
                }

                hashTitle.push(data[0]);

                return hashTitle;
              } catch (error) {
                console.error(error.message);
              }
            };
            fetchData();
            fetchData().then((data) => {
              if (data == undefined) {
                setAllTitle([]);
              } else {
                let uniqueChars = [...new Set(data.flat())];
                let allHeads = [uniqueChars];
                setAllTitle(allHeads.flat().sort());
              }
            });
          } else {
            setNoteSp(false);
          }
          if (quill.getText().match("@")) {
            const fetchData = async () => {
              try {
                let url = `${config.base_api_url}/auth/Userslist`;
                let method = "GET";
                let response = await fetchExtreamApi(url, method);
                try {
                  // setTimeout(async () => {

                  let url1 = `${config.base_api_url}/api/sharedNoteUsers`;
                  let method1 = "GET";
                  let res = await fetchExtreamApi(url1, method1);
                  const emailSet = new Set();
                  const filteredData = [];
                  res?.sharedNote.forEach((obj) => {
                    if (!emailSet.has(obj.email)) {
                      emailSet.add(obj.email);
                      filteredData.push(obj);
                    }
                  });

                  let sharedNoteUser = filteredData;

                  var stringPosWord = [];
                  // let postText = text.replace(regexp, "");

                  function getWordAt(str, pos) {
                    // Perform type conversions.
                    str = String(str);
                    pos = Number(pos) >>> 0;

                    // Search for the word's beginning and end.
                    var left = str.slice(0, pos + 1).search(/\S+$/),
                      right = str.slice(pos).search(/\s/);

                    // The last word in the string is a special case.
                    if (right < 0) {
                      stringPosWord.push(str.slice(left));
                      return str.slice(left);
                    }

                    // Return the word, using the located bounds to extract it from the string.
                    stringPosWord.push(str.slice(left, right + pos));

                    return str.slice(left, right + pos);
                  }
                  let num = quill.getSelection()?.index;
                  if (num != undefined) {
                    getWordAt(quill.getText().replace(/\n/g, " "), num - 1);
                  }
                  if (
                    sharedNoteUser &&
                    stringPosWord.length != 0 &&
                    stringPosWord[0].includes("@")
                  ) {
                    if (sharedNoteUser.length != 0) {
                      const tagFilter = sharedNoteUser.filter((data) => {
                        return `${data.fname + data.lname}`
                          .toLowerCase()
                          .trim()
                          .startsWith(
                            stringPosWord[0]
                              .replace(/^@/, "")
                              .trim()
                              .toLowerCase()
                          );
                      });

                      if (tagFilter.length == 0) {
                        const tagFilter1 = response?.list.filter((data) => {
                          return `${data.fname + data.lname}`
                            .toLowerCase()
                            .trim()
                            .startsWith(
                              stringPosWord[0]
                                .replace(/^@/, "")
                                .trim()
                                .toLowerCase()
                            );
                        });

                        if (tagFilter1.length == 0) {
                          setAtTagB(false);
                        } else if (
                          stringPosWord[0].includes("@") &&
                          tagFilter1.length != 0
                        ) {
                          setAtTagB(true);
                          setsearchValueATR(stringPosWord[0]);
                          setTimeout(() => {
                            // const element = document.getElementById("unicorn");
                            const cursorPosition = 33;
                            const editor1 = document.getElementById("unicorn");
                            let arry = newRange;
                            let lastElement = arry[arry.length - 1];
                            setRangedATR(lastElement);
                            let index = lastElement - stringPosWord[0].length;
                            quill.setSelection(lastElement);

                            // Get the bounds of the selection object
                            const bounds = quill.getBounds(index);
                            // Calculate the offsetLeft and offsetTop properties of the cursor position
                            const offsetLeftk =
                              bounds.left +
                              window.pageXOffset -
                              editor1.offsetLeft;
                            const offsetTopk =
                              bounds.top +
                              window.pageYOffset -
                              editor1.offsetTop;
                            const offsetBottom = bounds.top + bounds.height;

                            var float_elm =
                              document.getElementsByClassName(
                                "Active_Float"
                              )[0];

                            let x = offsetTopk;
                            let positive_x = x < 0 ? -x : x;

                            float_elm.style.top = `${positive_x + 60}px`;
                            float_elm.style.left = `${offsetLeftk + 5}px`;
                          }, 1000);
                        }

                        return tagFilter1;
                      } else if (stringPosWord[0].includes("@")) {
                        setAtTagB(true);
                        setsearchValueATR(stringPosWord[0]);

                        setTimeout(() => {
                          // const element = document.getElementById("unicorn");
                          const cursorPosition = 33;
                          const editor1 = document.getElementById("unicorn");
                          let arry = newRange;
                          let lastElement = arry[arry.length - 1];
                          setRangedATR(lastElement);
                          let index = lastElement - stringPosWord[0].length;
                          quill.setSelection(lastElement);

                          // Get the bounds of the selection object
                          const bounds = quill.getBounds(index);
                          // Calculate the offsetLeft and offsetTop properties of the cursor position
                          const offsetLeftk =
                            bounds.left +
                            window.pageXOffset -
                            editor1.offsetLeft;
                          const offsetTopk =
                            bounds.top + window.pageYOffset - editor1.offsetTop;
                          const offsetBottom = bounds.top + bounds.height;

                          var float_elm =
                            document.getElementsByClassName("Active_Float")[0];

                          let x = offsetTopk;
                          let positive_x = x < 0 ? -x : x;

                          float_elm.style.top = `${positive_x + 60}px`;
                          float_elm.style.left = `${offsetLeftk + 5}px`;
                        }, 1000);

                        return tagFilter;
                      }
                    } else {
                      const tagFilter = response?.list.filter((data) => {
                        return `${data.fname + data.lname}`
                          .toLowerCase()
                          .trim()
                          .startsWith(
                            stringPosWord[0]
                              .replace(/^@/, "")
                              .trim()
                              .toLowerCase()
                          );
                      });
                      if (tagFilter.length == 0) {
                        setAtTagB(false);
                      } else if (stringPosWord[0].includes("@")) {
                        setAtTagB(true);
                        setsearchValueATR(stringPosWord[0]);

                        setTimeout(() => {
                          // const element = document.getElementById("unicorn");
                          const cursorPosition = 33;
                          const editor1 = document.getElementById("unicorn");
                          let arry = newRange;
                          let lastElement = arry[arry.length - 1];
                          setRangedATR(lastElement);
                          let index = lastElement - stringPosWord[0].length;
                          quill.setSelection(lastElement);

                          // Get the bounds of the selection object
                          const bounds = quill.getBounds(index);
                          // Calculate the offsetLeft and offsetTop properties of the cursor position
                          const offsetLeftk =
                            bounds.left +
                            window.pageXOffset -
                            editor1.offsetLeft;
                          const offsetTopk =
                            bounds.top + window.pageYOffset - editor1.offsetTop;
                          const offsetBottom = bounds.top + bounds.height;

                          var float_elm =
                            document.getElementsByClassName("Active_Float")[0];

                          let x = offsetTopk;
                          let positive_x = x < 0 ? -x : x;

                          float_elm.style.top = `${positive_x + 60}px`;
                          float_elm.style.left = `${offsetLeftk + 5}px`;
                        }, 1000);
                      }
                      return tagFilter;
                    }
                  } else if (stringPosWord[0].includes("@") == false) {
                    setAtTagB(false);
                  }
                  // }, 5000);
                } catch (error) {
                  // console.error(error.message);
                }

                //  let arr = [];
              } catch (error) {
                console.error(error.message);
              }
            };
            fetchData();
            fetchData().then((data) => {
              setAtTag(data);
              if (data == undefined) {
                setAtTag([]);
              }

              //  setAllTitle(data.flat());
            });
          } else {
            setAtTagB(false);
          }

          setNotesTitle(quill.getText());
          setNotesTitle1(quill.getContents()); // Get delta contents
          setNotes(quill.root.innerHTML);
        });
      }
    }
  }, [quill]);
  const handlePrint = () => {
    const content = quill.root.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write("<div>" + content + "</div>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };
  const handleSubmitShare = ({ e, allData }) => {
    e.preventDefault();
    // === here
    if (shareWith?.value == 0 && accessValue != "") {
      if (allData?._id != undefined) {
        axios
          .post(
            `${config.base_api_url}/api/SendMailToSignup`,
            {
              email: shareWith?.label,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            swal({
              title: response.data.message,
              icon: "success",
            });

            window.location.reload();
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: error?.response?.data?.message,
              icon: "error",
            });
          });
        if (allData?.type == "note") {
          axios
            .post(
              `${config.base_api_url}/api/AddPreRequestNote/${allData._id}`,
              {
                access: accessValue,
                User_main_id: allData?.user,
                shareWith_email: shareWith?.label,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              swal({
                title: response.data.message,
                icon: "success",
              });
              setOpen2(false);
              setShareWith("");
              window.location.reload();
            })
            .catch((error) => {
              console.error("There was an error!", error);
              swal({
                title: error?.response?.data?.message,
                icon: "error",
              });
              setOpen2(false);
              setShareWith("");
              // window.location.reload();
            });
        } else {
          axios
            .post(
              `${config.base_api_url}/api/AddPreRequestNote/${allData?.note_reference?._id}`,
              {
                access: accessValue,
                User_main_id: allData?.controlBy?._id,
                shareWith_email: shareWith?.label,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              swal({
                title: response.data.message,
                icon: "success",
              });
              setOpen2(false);
              setShareWith("");
              window.location.reload();
            })
            .catch((error) => {
              console.error("There was an error!", error);
              swal({
                title: error?.response?.data?.message,
                icon: "error",
              });
              setOpen2(false);
              setShareWith("");
            });
        }
      } else {
        swal({
          title: "Please first fill all the fields",
          icon: "error",
        });
      }
    } else {
      if (allData?._id != undefined && accessValue != "" && shareWith != "") {
        if (allData?.type == "note") {
          axios
            .post(
              `${config.base_api_url}/api/AddRequestNote/${allData._id}`,
              {
                shareWith: shareWith.value,
                access: accessValue,
                User_main_id: allData?.user,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              swal({
                title: response.data.message,
                icon: "success",
              });
              setOpen2(false);
              setShareWith("");
              window.location.reload();
            })
            .catch((error) => {
              console.error("There was an error!", error);
              swal({
                title: error?.response?.data?.message,
                icon: "error",
              });
              setOpen2(false);
              setShareWith("");
              // window.location.reload();
            });
        } else {
          axios
            .post(
              `${config.base_api_url}/api/AddRequestNote/${allData?.note_reference?._id}`,
              {
                shareWith: shareWith.value,
                access: accessValue,
                User_main_id: allData?.controlBy?._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              swal({
                title: response.data.message,
                icon: "success",
              });
              setOpen2(false);
              setShareWith("");
              window.location.reload();
            })
            .catch((error) => {
              console.error("There was an error!", error);
              swal({
                title: error?.response?.data?.message,
                icon: "error",
              });
              setOpen2(false);
              setShareWith("");
            });
        }
      } else {
        swal({
          title: "Please first fill all the fields",
          icon: "error",
        });
      }
    }
  };
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        setUploadImage((prev) => (prev = [...prev, file]));
        const objectUrl = URL.createObjectURL(file);
        setPreview((prev) => (prev = [...prev, objectUrl]));
        setRedirectPage(true);
        // return () => URL.revokeObjectURL(objectUrl);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const handleCancelImage = ({ data, index }) => {
    const removePreviewIndex = preview.filter((data, i) => {
      return i != index;
    });
    setPreview(removePreviewIndex);
    const removeuploadImageIndex = uploadImage.filter((data, i) => {
      return i != index;
    });

    setUploadImage(removeuploadImageIndex);
    if (removePreviewIndex.length == 0) {
      setRedirectPage(false);
    }
  };
  const addNote = async (e) => {
    e.preventDefault();
    if (allData.type == "note" || allData.type == "tagShell") {
      // =====================
      let url1 = `${config.base_api_url}/api/CheckUpdatedNoteVersion/${allData._id}`;
      let method1 = "POST";
      let bodyValue = { description: notesVersion };
      let response = await fetchExtreamApi(url1, method1, bodyValue);
      if (response?.value == true) {
        axios
          .post(
            `${config.base_api_url}/api/AddUpdateNoteNotification/${allData._id}`,
            {
              User_main_id: allData?.user,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            console.log("Successfully shared note", response.data.message);
          })
          .catch((error) => {
            console.error("There was an error!", error);

            // window.location.reload();
          });
        if (notesTitle1 != undefined && notesTitle != undefined) {
          // edit note

          const stringText = quill.root.innerHTML;
          const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
          const imgSrcMatches = stringText.matchAll(imgSrcRegex);

          const srcArray = [];

          for (const match of imgSrcMatches) {
            const srcAttribute = match[1];
            if (srcAttribute.startsWith("data:image/")) {
              srcArray.push(srcAttribute);
            }
          }
          let imgId = 0; // Counter for assigning image ids

          // Regular expression pattern to match <img> tags
          let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

          // Removing <img> tags and adding id attribute
          let newString = stringText.replace(imgPattern1, function (match) {
            let idAttribute = ' src="id:' + imgId++ + '"';
            return match.replace(/src="[^"]*"/, idAttribute);
          });
          let imagesBase = [];
          if (srcArray.length != 0) {
            for (let imagesCov of srcArray) {
              const file = base64ToFile(imagesCov, "image");
              imagesBase.push(file);
            }
          }
          function base64ToFile(base64, filename) {
            const arr = base64.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);

            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const fileExtension = mime.split("/")[1];

            // Append the file extension to the file name
            const fullFileName = `${filename}.${fileExtension}`;
            const file = new File([u8arr], fullFileName, { type: mime });

            return file;
          }
          // newString;
          const hashTag = notesTitle
            .replace(/\n/g, " ")
            .split(" ")
            .filter((v) => v.startsWith("#"));
          // const check = hashTag[0].split(" ").filter((v) => v.replace(/\n/g, " "));
          const data = new FormData();

          // data.append("title", `${hashTag}`);
          const arr = [];
          if (hashTag[0] != undefined && hashTag.length != 1) {
            for (let tag of hashTag) {
              const checktag = tag.replace(/\<(?!li).*?\>/g, "");
              if (checktag?.includes("\n") == true) {
                const checkhashBracket = (s) =>
                  s.includes("#") && s.substr(s.startsWith("#")).split("\n")[0];
                const result = checkhashBracket(checktag);
                arr.push(`#${result.replace(/[^\w\s#]/gi, "")}`);
              } else {
                arr.push(checktag.replace(/[^\w\s#]/gi, ""));
              }
            }
          } else if (hashTag.length == 1) {
            const checktag = notesTitle.match(/[^\s]+/g);
            const hashTag = checktag.filter((data) => {
              return data.startsWith("#");
            });
            for (let tag of hashTag) {
              arr.push(tag);
            }
          } else {
            const string = notesTitle1.ops[0];
            if (notesTitle1.ops.length == 1) {
              const stage = notesTitle1.ops[0].insert.replace(/\n/g, " ");
              const result = stage.split(" ").filter((v) => v.startsWith("#"));
              for (let data of result) {
                arr.push(data);
              }
            } else if (notesTitle1.ops.length > 1) {
              for (let data of notesTitle1.ops) {
                if (
                  data.insert?.image != undefined ||
                  data.insert?.image != null
                ) {
                } else {
                  const result = data.insert
                    .split(" ")
                    .filter((v) => v.startsWith("#"));
                  if (result != []) {
                    for (let test of result) {
                      result != [] && arr.push(test);
                    }
                  }
                  // result != [] && arr.push(result);
                }
              }
            }
          }
          let arrFilter = [];
          for (let check of arr) {
            if (check.length >= 2) {
              arrFilter.push(check);
            }
          }
          arrFilter.length != 0 && data.append("title", arrFilter);
          data.append("description", newString);
          data.append("id", params.id);
          data.append("notes", notesVersion);
          const blob = audioURLs;
          if (uploadImage.length != 0) {
            for (let uploadImageArr of uploadImage)
              data.append("fileAttachment", uploadImageArr);
          }
          if (blob.length != 0) {
            for (let blobArr of blob) data.append("audio", blobArr.file);
          }
          audioURLs.forEach((item) => {
            data.append(`audioInfo`, JSON.stringify(item));
          });
          if (imagesBase.length != 0) {
            for (let uploadImageConArr of imagesBase)
              data.append("imagesBase", uploadImageConArr);
          }
          let url1 = `${config.base_api_url}/api/editnote`;
          let method1 = "PUT";
          let bodyValue = { data };
          await fetchExtreamApi(url1, method1, data)
            .then((response) => {
              if (response?.mergedArrayAllInfo.length != 0) {
                for (let imagesInfo of response?.mergedArrayAllInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfImageInfo`,

                      {
                        id_note: allData?._id,
                        s3location: imagesInfo?.imag_loc,
                        size: imagesInfo?.image_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                          // "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Successfully added", res);
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                      console.error("There was an error!", error);
                      console.log(notes?.length, "Ffff");
                    });
                }
              }
              if (response?.AudioAllFilesInfo.length != 0) {
                for (let audioInfo of response?.AudioAllFilesInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                      {
                        id_note: allData?._id,
                        s3location: audioInfo?.Audio_loc,
                        size: audioInfo?.Audio_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Successfully added", res);
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                      console.error("There was an error!", error);
                      console.log(notes?.length, "Ffff");
                    });
                }
              }
              swal({
                title: "Edit notes sucessfully!!",
                icon: "success",
              });
              setAudioURLs([]);
              setPreview([]);
              setRangedReload(0);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              console.error("There was an error!", error);
              swal({
                title: "Something went wrong!",
                icon: "error",
                dangerMode: true,
              });
            });
          // arr.length != 0 ? data.append("title", arr) : data.append("title", null);
        } else {
          const stringText = quill.root.innerHTML;
          const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
          const imgSrcMatches = stringText.matchAll(imgSrcRegex);

          const srcArray = [];

          for (const match of imgSrcMatches) {
            const srcAttribute = match[1];
            if (srcAttribute.startsWith("data:image/")) {
              srcArray.push(srcAttribute);
            }
          }
          let imgId = 0; // Counter for assigning image ids

          // Regular expression pattern to match <img> tags
          let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

          // Removing <img> tags and adding id attribute
          let newString = stringText.replace(imgPattern1, function (match) {
            let idAttribute = ' src="id:' + imgId++ + '"';
            return match.replace(/src="[^"]*"/, idAttribute);
          });
          let imagesBase = [];
          if (srcArray.length != 0) {
            for (let imagesCov of srcArray) {
              const file = base64ToFile(imagesCov, "image");
              imagesBase.push(file);
            }
          }
          function base64ToFile(base64, filename) {
            const arr = base64.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);

            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const fileExtension = mime.split("/")[1];

            // Append the file extension to the file name
            const fullFileName = `${filename}.${fileExtension}`;
            const file = new File([u8arr], fullFileName, { type: mime });

            return file;
          }

          const data = new FormData();
          data.append("title", undefined);
          data.append("description", newString);
          data.append("id", params.id);
          data.append("notes", notesVersion);

          const blob = audioURLs;
          if (blob.length != 0) {
            for (let blobArr of blob) data.append("audio", blobArr.file);
          }
          audioURLs.forEach((item) => {
            data.append(`audioInfo`, JSON.stringify(item));
          });
          if (uploadImage.length != 0) {
            for (let uploadImageArr of uploadImage)
              data.append("fileAttachment", uploadImageArr);
          }
          if (imagesBase.length != 0) {
            for (let uploadImageConArr of imagesBase)
              data.append("imagesBase", uploadImageConArr);
          }

          let url1 = `${config.base_api_url}/api/editnote`;
          let method1 = "PUT";
          // let bodyValue = { data };
          await fetchExtreamApi(url1, method1, data)
            .then((response) => {
              if (response?.mergedArrayAllInfo.length != 0) {
                for (let imagesInfo of response?.mergedArrayAllInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfImageInfo`,

                      {
                        id_note: allData?._id,
                        s3location: imagesInfo?.imag_loc,
                        size: imagesInfo?.image_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                          // "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Successfully added", res);
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                      console.error("There was an error!", error);
                    });
                }
              }
              if (response?.AudioAllFilesInfo.length != 0) {
                for (let audioInfo of response?.AudioAllFilesInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                      {
                        id_note: allData?._id,
                        s3location: audioInfo?.Audio_loc,
                        size: audioInfo?.Audio_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Successfully added", res);
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                      console.error("There was an error!", error);
                    });
                }
              }
              swal({
                title: "Edit notes sucessfully!!",
                icon: "success",
              });
              setAudioURLs([]);
              setPreview([]);
              setRangedReload(0);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              console.error("There was an error!", error);
              swal({
                title: "Something went wrong!",
                icon: "error",
                dangerMode: true,
              });
            });
        }
      } else {
        setOpenNoteVersion(true);

        if (response?.updated != undefined) {
          const name = response?.data?.updated?.current_userId;
          setNoteVersionUser(`${name.fname + " " + name.lname}`);
        }
      }
    } else {
      let url1 = `${config.base_api_url}/api/CheckUpdatedNoteVersion/${allData?.note_reference?._id}`;
      let method1 = "POST";
      let bodyValue = { description: notesVersion };
      let response = await fetchExtreamApi(url1, method1, bodyValue);
      if (response?.value == true) {
        axios
          .post(
            `${config.base_api_url}/api/AddUpdateNoteNotification/${allData?.note_reference?._id}`,
            {
              User_main_id: allData?.controlBy?._id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            console.log("Successfully shared note", response.data.message);
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
        if (notesTitle1 != undefined && notesTitle != undefined) {
          const stringText = quill.root.innerHTML;
          const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
          const imgSrcMatches = stringText.matchAll(imgSrcRegex);

          const srcArray = [];

          for (const match of imgSrcMatches) {
            const srcAttribute = match[1];
            if (srcAttribute.startsWith("data:image/")) {
              srcArray.push(srcAttribute);
            }
          }
          let imgId = 0; // Counter for assigning image ids

          // Regular expression pattern to match <img> tags
          let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

          // Removing <img> tags and adding id attribute
          let newString = stringText.replace(imgPattern1, function (match) {
            let idAttribute = ' src="id:' + imgId++ + '"';
            return match.replace(/src="[^"]*"/, idAttribute);
          });
          let imagesBase = [];
          if (srcArray.length != 0) {
            for (let imagesCov of srcArray) {
              const file = base64ToFile(imagesCov, "image");
              imagesBase.push(file);
            }
          }
          function base64ToFile(base64, filename) {
            const arr = base64.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);

            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const fileExtension = mime.split("/")[1];

            // Append the file extension to the file name
            const fullFileName = `${filename}.${fileExtension}`;
            const file = new File([u8arr], fullFileName, { type: mime });

            return file;
          }
          const hashTag = notesTitle
            .replace(/\n/g, " ")
            .split(" ")
            .filter((v) => v.startsWith("#"));
          // const check = hashTag[0].split(" ").filter((v) => v.replace(/\n/g, " "));

          // data.append("title", `${hashTag}`);
          const arr = [];
          if (hashTag[0] != undefined && hashTag.length != 1) {
            for (let tag of hashTag) {
              const checktag = tag.replace(/\<(?!li).*?\>/g, "");
              if (checktag?.includes("\n") == true) {
                const checkhashBracket = (s) =>
                  s.includes("#") && s.substr(s.startsWith("#")).split("\n")[0];
                const result = checkhashBracket(checktag);
                arr.push(`#${result.replace(/[^\w\s#]/gi, "")}`);
              } else {
                arr.push(checktag.replace(/[^\w\s#]/gi, ""));
              }
            }
          } else if (hashTag.length == 1) {
            const checktag = notesTitle.match(/[^\s]+/g);
            const hashTag = checktag.filter((data) => {
              return data.startsWith("#");
            });
            for (let tag of hashTag) {
              arr.push(tag);
            }
          } else {
            const string = notesTitle1.ops[0];
            if (notesTitle1.ops.length == 1) {
              const stage = notesTitle1.ops[0].insert.replace(/\n/g, " ");
              const result = stage.split(" ").filter((v) => v.startsWith("#"));
              for (let data of result) {
                arr.push(data);
              }
            } else if (notesTitle1.ops.length > 1) {
              for (let data of notesTitle1.ops) {
                if (
                  data.insert?.image != undefined ||
                  data.insert?.image != null
                ) {
                } else {
                  const result = data.insert
                    .split(" ")
                    .filter((v) => v.startsWith("#"));
                  if (result != []) {
                    for (let test of result) {
                      result != [] && arr.push(test);
                    }
                  }
                  // result != [] && arr.push(result);
                }
              }
            }
          }
          const data = new FormData();
          let arrFilter = [];
          for (let check of arr) {
            if (check.length >= 2) {
              arrFilter.push(check);
            }
          }
          arrFilter.length != 0 && data.append("title", arrFilter);
          data.append("description", newString);
          data.append("notes", notesVersion);

          data.append("id", allData?.note_reference?._id);
          const blob = audioURLs;
          if (blob.length != 0) {
            for (let blobArr of blob) data.append("audio", blobArr.file);
          }
          if (uploadImage.length != 0) {
            for (let uploadImageArr of uploadImage)
              data.append("fileAttachment", uploadImageArr);
          }
          audioURLs.forEach((item) => {
            data.append(`audioInfo`, JSON.stringify(item));
          });
          if (imagesBase.length != 0) {
            for (let uploadImageConArr of imagesBase)
              data.append("imagesBase", uploadImageConArr);
          }
          let url1 = `${config.base_api_url}/api/editnote`;
          let method1 = "PUT";
          let bodyValue = { data };
          await fetchExtreamApi(url1, method1, data)
            .then((response) => {
              if (response?.mergedArrayAllInfo.length != 0) {
                for (let imagesInfo of response?.mergedArrayAllInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfImageInfo`,

                      {
                        id_note: allData?.note_reference?._id,
                        s3location: imagesInfo?.imag_loc,
                        size: imagesInfo?.image_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                          // "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Successfully added", res);
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                      console.error("There was an error!", error);
                    });
                }
              }
              if (response?.AudioAllFilesInfo.length != 0) {
                for (let audioInfo of response?.AudioAllFilesInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                      {
                        id_note: allData?.note_reference?._id,
                        s3location: audioInfo?.Audio_loc,
                        size: audioInfo?.Audio_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Successfully added", res);
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                      console.error("There was an error!", error);
                    });
                }
              }
              swal({
                title: "Edit notes sucessfully!!",
                icon: "success",
              });
              setAudioURLs([]);
              setPreview([]);
              setRangedReload(0);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              console.error("There was an error!", error);
              swal({
                title: "Something went wrong!",
                icon: "error",
                dangerMode: true,
              });
            });
          // arr.length != 0 ? data.append("title", arr) : data.append("title", null);
        } else {
          const stringText = quill.root.innerHTML;
          const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
          const imgSrcMatches = stringText.matchAll(imgSrcRegex);

          const srcArray = [];

          for (const match of imgSrcMatches) {
            const srcAttribute = match[1];
            if (srcAttribute.startsWith("data:image/")) {
              srcArray.push(srcAttribute);
            }
          }
          let imgId = 0; // Counter for assigning image ids

          // Regular expression pattern to match <img> tags
          let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

          // Removing <img> tags and adding id attribute
          let newString = stringText.replace(imgPattern1, function (match) {
            let idAttribute = ' src="id:' + imgId++ + '"';
            return match.replace(/src="[^"]*"/, idAttribute);
          });
          let imagesBase = [];
          if (srcArray.length != 0) {
            for (let imagesCov of srcArray) {
              const file = base64ToFile(imagesCov, "image");
              imagesBase.push(file);
            }
          }
          function base64ToFile(base64, filename) {
            const arr = base64.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);

            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            const fileExtension = mime.split("/")[1];

            // Append the file extension to the file name
            const fullFileName = `${filename}.${fileExtension}`;
            const file = new File([u8arr], fullFileName, { type: mime });

            return file;
          }
          const data = new FormData();

          data.append("title", undefined);
          data.append("description", newString);
          data.append("id", allData?.note_reference?._id);
          data.append("notes", notesVersion);

          const blob = audioURLs;
          if (blob.length != 0) {
            for (let blobArr of blob) data.append("audio", blobArr.file);
          }
          audioURLs.forEach((item) => {
            data.append(`audioInfo`, JSON.stringify(item));
          });
          if (uploadImage.length != 0) {
            for (let uploadImageArr of uploadImage)
              data.append("fileAttachment", uploadImageArr);
          }
          if (imagesBase.length != 0) {
            for (let uploadImageConArr of imagesBase)
              data.append("imagesBase", uploadImageConArr);
          }
          let url1 = `${config.base_api_url}/api/editnote`;
          let method1 = "PUT";
          let bodyValue = { data };
          await fetchExtreamApi(url1, method1, data)
            .then((response) => {
              if (response?.mergedArrayAllInfo.length != 0) {
                for (let imagesInfo of response?.mergedArrayAllInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfImageInfo`,

                      {
                        id_note: allData?.note_reference?._id,
                        s3location: imagesInfo?.imag_loc,
                        size: imagesInfo?.image_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                          // "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Successfully added", res);
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                      console.error("There was an error!", error);
                    });
                }
              }
              if (response?.AudioAllFilesInfo.length != 0) {
                for (let audioInfo of response?.AudioAllFilesInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                      {
                        id_note: allData?.note_reference?._id,
                        s3location: audioInfo?.Audio_loc,
                        size: audioInfo?.Audio_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Successfully added", res);
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                      console.error("There was an error!", error);
                    });
                }
              }
              swal({
                title: "Edit notes sucessfully!!",
                icon: "success",
              });
              setAudioURLs([]);
              setPreview([]);
              setRangedReload(0);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              console.error("There was an error!", error);
              swal({
                title: "Something went wrong!",
                icon: "error",
                dangerMode: true,
              });
            });
        }
      } else {
        setOpenNoteVersion(true);
      }
    }
  };
  const addNoteAfterVerify = async (e) => {
    e.preventDefault();
    if (allData.type == "note") {
      axios
        .post(
          `${config.base_api_url}/api/AddUpdateNoteNotification/${allData._id}`,
          {
            User_main_id: allData?.user,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          console.log("Successfully shared note", response.data.message);
        })
        .catch((error) => {
          console.error("There was an error!", error);

          // window.location.reload();
        });
      if (notesTitle1 != undefined && notesTitle != undefined) {
        const hashTag = notesTitle
          .replace(/\n/g, " ")
          .split(" ")
          .filter((v) => v.startsWith("#"));
        // const check = hashTag[0].split(" ").filter((v) => v.replace(/\n/g, " "));
        const data = new FormData();

        // data.append("title", `${hashTag}`);
        const arr = [];
        if (hashTag[0] != undefined && hashTag.length != 1) {
          for (let tag of hashTag) {
            const checktag = tag.replace(/\<(?!li).*?\>/g, "");
            if (checktag?.includes("\n") == true) {
              const checkhashBracket = (s) =>
                s.includes("#") && s.substr(s.startsWith("#")).split("\n")[0];
              const result = checkhashBracket(checktag);
              arr.push(`#${result.replace(/[^\w\s#]/gi, "")}`);
            } else {
              arr.push(checktag.replace(/[^\w\s#]/gi, ""));
            }
          }
        } else if (hashTag.length == 1) {
          const checktag = notesTitle.match(/[^\s]+/g);
          const hashTag = checktag.filter((data) => {
            return data.startsWith("#");
          });
          for (let tag of hashTag) {
            arr.push(tag);
          }
        } else {
          const string = notesTitle1.ops[0];
          if (notesTitle1.ops.length == 1) {
            const stage = notesTitle1.ops[0].insert.replace(/\n/g, " ");
            const result = stage.split(" ").filter((v) => v.startsWith("#"));
            for (let data of result) {
              arr.push(data);
            }
          } else if (notesTitle1.ops.length > 1) {
            for (let data of notesTitle1.ops) {
              if (
                data.insert?.image != undefined ||
                data.insert?.image != null
              ) {
              } else {
                const result = data.insert
                  .split(" ")
                  .filter((v) => v.startsWith("#"));
                if (result != []) {
                  for (let test of result) {
                    result != [] && arr.push(test);
                  }
                }
                // result != [] && arr.push(result);
              }
            }
          }
        }
        let arrFilter = [];
        for (let check of arr) {
          if (check.length >= 2) {
            arrFilter.push(check);
          }
        }
        arrFilter.length != 0 && data.append("title", arrFilter);
        const stringText = quill.root.innerHTML;
        const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
        const imgSrcMatches = stringText.matchAll(imgSrcRegex);

        const srcArray = [];

        for (const match of imgSrcMatches) {
          const srcAttribute = match[1];
          if (srcAttribute.startsWith("data:image/")) {
            srcArray.push(srcAttribute);
          }
        }
        let imgId = 0; // Counter for assigning image ids

        // Regular expression pattern to match <img> tags
        let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

        // Removing <img> tags and adding id attribute
        let newString = stringText.replace(imgPattern1, function (match) {
          let idAttribute = ' src="id:' + imgId++ + '"';
          return match.replace(/src="[^"]*"/, idAttribute);
        });
        let imagesBase = [];
        if (srcArray.length != 0) {
          for (let imagesCov of srcArray) {
            const file = base64ToFile(imagesCov, "image");
            imagesBase.push(file);
          }
        }
        function base64ToFile(base64, filename) {
          const arr = base64.split(",");
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          const fileExtension = mime.split("/")[1];

          // Append the file extension to the file name
          const fullFileName = `${filename}.${fileExtension}`;
          const file = new File([u8arr], fullFileName, { type: mime });

          return file;
        }
        data.append("description", newString);
        data.append("id", params.id);
        data.append("notes", notesVersion);

        const blob = audioURLs;
        if (uploadImage.length != 0) {
          for (let uploadImageArr of uploadImage)
            data.append("fileAttachment", uploadImageArr);
        }
        if (blob.length != 0) {
          for (let blobArr of blob) data.append("audio", blobArr.file);
        }
        audioURLs.forEach((item) => {
          data.append(`audioInfo`, JSON.stringify(item));
        });
        if (imagesBase.length != 0) {
          for (let uploadImageConArr of imagesBase)
            data.append("imagesBase", uploadImageConArr);
        }
        let url1 = `${config.base_api_url}/api/REACT_APP_BASE_API_LIVEeditnote`;
        let method1 = "PUT";
        let bodyValue = { data };
        await fetchExtreamApi(url1, method1, data)
          .then((response) => {
            if (response?.mergedArrayAllInfo.length != 0) {
              for (let imagesInfo of response?.mergedArrayAllInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfImageInfo`,

                    {
                      id_note: allData?._id,
                      s3location: imagesInfo?.imag_loc,
                      size: imagesInfo?.image_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                        // "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            if (response?.AudioAllFilesInfo.length != 0) {
              for (let audioInfo of response?.AudioAllFilesInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                    {
                      id_note: allData?._id,
                      s3location: audioInfo?.Audio_loc,
                      size: audioInfo?.Audio_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            swal({
              title: "Edit notes sucessfully!!",
              icon: "success",
            });
            setAudioURLs([]);
            setPreview([]);
            setRangedReload(0);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: "Something went wrong!",
              icon: "error",
              dangerMode: true,
            });
          });
        // arr.length != 0 ? data.append("title", arr) : data.append("title", null);
      } else {
        const stringText = quill.root.innerHTML;
        const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
        const imgSrcMatches = stringText.matchAll(imgSrcRegex);

        const srcArray = [];

        for (const match of imgSrcMatches) {
          const srcAttribute = match[1];
          if (srcAttribute.startsWith("data:image/")) {
            srcArray.push(srcAttribute);
          }
        }
        let imgId = 0; // Counter for assigning image ids

        // Regular expression pattern to match <img> tags
        let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

        // Removing <img> tags and adding id attribute
        let newString = stringText.replace(imgPattern1, function (match) {
          let idAttribute = ' src="id:' + imgId++ + '"';
          return match.replace(/src="[^"]*"/, idAttribute);
        });
        let imagesBase = [];
        if (srcArray.length != 0) {
          for (let imagesCov of srcArray) {
            const file = base64ToFile(imagesCov, "image");
            imagesBase.push(file);
          }
        }
        function base64ToFile(base64, filename) {
          const arr = base64.split(",");
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          const fileExtension = mime.split("/")[1];

          // Append the file extension to the file name
          const fullFileName = `${filename}.${fileExtension}`;
          const file = new File([u8arr], fullFileName, { type: mime });

          return file;
        }
        const data = new FormData();
        data.append("title", undefined);
        data.append("description", newString);
        data.append("id", params.id);
        data.append("notes", notesVersion);

        const blob = audioURLs;
        if (blob.length != 0) {
          for (let blobArr of blob) data.append("audio", blobArr.file);
        }
        audioURLs.forEach((item) => {
          data.append(`audioInfo`, JSON.stringify(item));
        });
        if (uploadImage.length != 0) {
          for (let uploadImageArr of uploadImage)
            data.append("fileAttachment", uploadImageArr);
        }
        if (imagesBase.length != 0) {
          for (let uploadImageConArr of imagesBase)
            data.append("imagesBase", uploadImageConArr);
        }
        let url1 = `${config.base_api_url}/api/editnote`;
        let method1 = "PUT";
        let bodyValue = { data };
        await fetchExtreamApi(url1, method1, data)
          .then((response) => {
            if (response?.mergedArrayAllInfo.length != 0) {
              for (let imagesInfo of response?.mergedArrayAllInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfImageInfo`,

                    {
                      id_note: allData?._id,
                      s3location: imagesInfo?.imag_loc,
                      size: imagesInfo?.image_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                        // "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            if (response?.AudioAllFilesInfo.length != 0) {
              for (let audioInfo of response?.AudioAllFilesInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                    {
                      id_note: allData?._id,
                      s3location: audioInfo?.Audio_loc,
                      size: audioInfo?.Audio_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            swal({
              title: "Edit notes sucessfully!!",
              icon: "success",
            });
            setAudioURLs([]);
            setPreview([]);
            setRangedReload(0);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: "Something went wrong!",
              icon: "error",
              dangerMode: true,
            });
          });
      }
    } else {
      axios
        .post(
          `${config.base_api_url}/api/AddUpdateNoteNotification/${allData?.note_reference?._id}`,
          {
            User_main_id: allData?.controlBy?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          console.log("Successfully shared note", response.data.message);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      if (notesTitle1 != undefined && notesTitle != undefined) {
        const hashTag = notesTitle
          .replace(/\n/g, " ")
          .split(" ")
          .filter((v) => v.startsWith("#"));
        // const check = hashTag[0].split(" ").filter((v) => v.replace(/\n/g, " "));

        // data.append("title", `${hashTag}`);
        const arr = [];
        if (hashTag[0] != undefined && hashTag.length != 1) {
          for (let tag of hashTag) {
            const checktag = tag.replace(/\<(?!li).*?\>/g, "");
            if (checktag?.includes("\n") == true) {
              const checkhashBracket = (s) =>
                s.includes("#") && s.substr(s.startsWith("#")).split("\n")[0];
              const result = checkhashBracket(checktag);
              arr.push(`#${result.replace(/[^\w\s#]/gi, "")}`);
            } else {
              arr.push(checktag.replace(/[^\w\s#]/gi, ""));
            }
          }
        } else if (hashTag.length == 1) {
          const checktag = notesTitle.match(/[^\s]+/g);
          const hashTag = checktag.filter((data) => {
            return data.startsWith("#");
          });
          for (let tag of hashTag) {
            arr.push(tag);
          }
        } else {
          const string = notesTitle1.ops[0];
          if (notesTitle1.ops.length == 1) {
            const stage = notesTitle1.ops[0].insert.replace(/\n/g, " ");
            const result = stage.split(" ").filter((v) => v.startsWith("#"));
            for (let data of result) {
              arr.push(data);
            }
          } else if (notesTitle1.ops.length > 1) {
            for (let data of notesTitle1.ops) {
              if (
                data.insert?.image != undefined ||
                data.insert?.image != null
              ) {
              } else {
                const result = data.insert
                  .split(" ")
                  .filter((v) => v.startsWith("#"));
                if (result != []) {
                  for (let test of result) {
                    result != [] && arr.push(test);
                  }
                }
                // result != [] && arr.push(result);
              }
            }
          }
        }
        const stringText = quill.root.innerHTML;
        const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
        const imgSrcMatches = stringText.matchAll(imgSrcRegex);

        const srcArray = [];

        for (const match of imgSrcMatches) {
          const srcAttribute = match[1];
          if (srcAttribute.startsWith("data:image/")) {
            srcArray.push(srcAttribute);
          }
        }
        let imgId = 0; // Counter for assigning image ids

        // Regular expression pattern to match <img> tags
        let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

        // Removing <img> tags and adding id attribute
        let newString = stringText.replace(imgPattern1, function (match) {
          let idAttribute = ' src="id:' + imgId++ + '"';
          return match.replace(/src="[^"]*"/, idAttribute);
        });
        let imagesBase = [];
        if (srcArray.length != 0) {
          for (let imagesCov of srcArray) {
            const file = base64ToFile(imagesCov, "image");
            imagesBase.push(file);
          }
        }
        function base64ToFile(base64, filename) {
          const arr = base64.split(",");
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          const fileExtension = mime.split("/")[1];

          // Append the file extension to the file name
          const fullFileName = `${filename}.${fileExtension}`;
          const file = new File([u8arr], fullFileName, { type: mime });

          return file;
        }
        const data = new FormData();
        let arrFilter = [];
        for (let check of arr) {
          if (check.length >= 2) {
            arrFilter.push(check);
          }
        }
        arrFilter.length != 0 && data.append("title", arrFilter);
        data.append("description", newString);
        data.append("id", allData?.note_reference?._id);
        data.append("notes", notesVersion);

        const blob = audioURLs;
        if (blob.length != 0) {
          for (let blobArr of blob) data.append("audio", blobArr.file);
        }
        if (uploadImage.length != 0) {
          for (let uploadImageArr of uploadImage)
            data.append("fileAttachment", uploadImageArr);
        }
        audioURLs.forEach((item) => {
          data.append(`audioInfo`, JSON.stringify(item));
        });
        if (imagesBase.length != 0) {
          for (let uploadImageConArr of imagesBase)
            data.append("imagesBase", uploadImageConArr);
        }
        let url1 = `${config.base_api_url}/api/editnote`;
        let method1 = "PUT";
        let bodyValue = { data };
        await fetchExtreamApi(url1, method1, data)
          .then((response) => {
            if (response?.mergedArrayAllInfo.length != 0) {
              for (let imagesInfo of response?.mergedArrayAllInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfImageInfo`,

                    {
                      id_note: allData?.note_reference?._id,
                      s3location: imagesInfo?.imag_loc,
                      size: imagesInfo?.image_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                        // "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            if (response?.AudioAllFilesInfo.length != 0) {
              for (let audioInfo of response?.AudioAllFilesInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                    {
                      id_note: allData?.note_reference?._id,
                      s3location: audioInfo?.Audio_loc,
                      size: audioInfo?.Audio_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            swal({
              title: "Edit notes sucessfully!!",
              icon: "success",
            });
            setAudioURLs([]);
            setPreview([]);
            setRangedReload(0);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: "Something went wrong!",
              icon: "error",
              dangerMode: true,
            });
          });
        // arr.length != 0 ? data.append("title", arr) : data.append("title", null);
      } else {
        const stringText = quill.root.innerHTML;
        const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
        const imgSrcMatches = stringText.matchAll(imgSrcRegex);

        const srcArray = [];

        for (const match of imgSrcMatches) {
          const srcAttribute = match[1];
          if (srcAttribute.startsWith("data:image/")) {
            srcArray.push(srcAttribute);
          }
        }
        let imgId = 0; // Counter for assigning image ids

        // Regular expression pattern to match <img> tags
        let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

        // Removing <img> tags and adding id attribute
        let newString = stringText.replace(imgPattern1, function (match) {
          let idAttribute = ' src="id:' + imgId++ + '"';
          return match.replace(/src="[^"]*"/, idAttribute);
        });
        let imagesBase = [];
        if (srcArray.length != 0) {
          for (let imagesCov of srcArray) {
            const file = base64ToFile(imagesCov, "image");
            imagesBase.push(file);
          }
        }
        function base64ToFile(base64, filename) {
          const arr = base64.split(",");
          const mime = arr[0].match(/:(.*?);/)[1];
          const bstr = atob(arr[1]);
          let n = bstr.length;
          const u8arr = new Uint8Array(n);

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          const fileExtension = mime.split("/")[1];

          // Append the file extension to the file name
          const fullFileName = `${filename}.${fileExtension}`;
          const file = new File([u8arr], fullFileName, { type: mime });

          return file;
        }
        const data = new FormData();
        data.append("title", undefined);
        data.append("description", newString);
        data.append("id", allData?.note_reference?._id);
        data.append("notes", notesVersion);

        const blob = audioURLs;
        if (blob.length != 0) {
          for (let blobArr of blob) data.append("audio", blobArr.file);
        }
        audioURLs.forEach((item) => {
          data.append(`audioInfo`, JSON.stringify(item));
        });
        if (uploadImage.length != 0) {
          for (let uploadImageArr of uploadImage)
            data.append("fileAttachment", uploadImageArr);
        }
        if (imagesBase.length != 0) {
          for (let uploadImageConArr of imagesBase)
            data.append("imagesBase", uploadImageConArr);
        }
        let url1 = `${config.base_api_url}/api/editnote`;
        let method1 = "PUT";
        let bodyValue = { data };
        await fetchExtreamApi(url1, method1, data)
          .then((response) => {
            if (response?.mergedArrayAllInfo.length != 0) {
              for (let imagesInfo of response?.mergedArrayAllInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfImageInfo`,

                    {
                      id_note: allData?.note_reference?._id,
                      s3location: imagesInfo?.imag_loc,
                      size: imagesInfo?.image_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                        // "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            if (response?.AudioAllFilesInfo.length != 0) {
              for (let audioInfo of response?.AudioAllFilesInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                    {
                      id_note: allData?.note_reference?._id,
                      s3location: audioInfo?.Audio_loc,
                      size: audioInfo?.Audio_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            swal({
              title: "Edit notes sucessfully!!",
              icon: "success",
            });
            setAudioURLs([]);
            setPreview([]);
            setRangedReload(0);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: "Something went wrong!",
              icon: "error",
              dangerMode: true,
            });
          });
      }
    }
  };
  const deleteNote = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");
        if (allData.type == "note") {
          axios
            .delete(`${config.base_api_url}/api/DeleteNote/${params.id}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            })
            .then((response) => {
              navigate("/admin");
            });
        } else {
          axios
            .delete(
              `${config.base_api_url}/api/DeleteNote/${allData?.note_reference?._id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              navigate("/admin");
            });
        }
      }
    });
  };
  const handleDelete_shareWithUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Remove your access from this shared note",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove Me!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Remove!", "This note has been removed.", "success");

        axios
          .post(
            `${config.base_api_url}/api/DeleteNoteShellByUser/${allData?._id}`,
            {
              main_id: allData?.note_reference?._id,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            navigate("/admin");
          });
      }
    });
  };
  const handleDelete_shareWith = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");
        if (allData.type == "note") {
          axios
            .post(
              `${config.base_api_url}/api/DeleteNoteShell/${data?.note_id_user}`,
              {
                shareWith_userId: data?._id,
                main_id: allData._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              window.location.reload();
            });
        } else {
          axios
            .post(
              `${config.base_api_url}/api/DeleteNoteShell/${data?.note_id_user}`,
              {
                shareWith_userId: data?._id,
                main_id: allData?.note_reference?._id,
              },

              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              window.location.reload();
            });
        }
      }
    });
  };
  const handleDelete_ReqWith = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");
        if (allData.type == "note") {
          axios
            .post(
              `${config.base_api_url}/api/DeleteReqShell/${data?.req_id}`,
              {
                shareWith_userId: data?._id,
                main_id: allData._id,
              },

              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              window.location.reload();
            });
        } else {
          axios
            .post(
              `${config.base_api_url}/api/DeleteReqShell/${data?.req_id}`,
              {
                shareWith_userId: data?._id,
                main_id: allData?.note_reference?._id,
              },

              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              window.location.reload();
            });
        }
      }
    });
  };
  const handleDelete_NonExistReqWith = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");
        if (allData.type == "note") {
          axios
            .post(
              `${config.base_api_url}/api/DeleteNonExistReqShell/${data?.req_id}`,
              {
                shareWith_userId: data?._id,
                main_id: allData._id,
              },

              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              window.location.reload();
            });
        } else {
          axios
            .post(
              `${config.base_api_url}/api/DeleteNonExistReqShell/${data?.req_id}`,
              {
                shareWith_userId: data?._id,
                main_id: allData?.note_reference?._id,
              },

              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              window.location.reload();
            });
        }
      }
    });
  };
  const handleSubmitSaveUpdate = (data) => {
    if (allData.type == "note") {
      if (accessValueEdit != "") {
        axios
          .post(
            `${config.base_api_url}/api/UpdateNoteShell/${data?.note_id_user}`,
            {
              shareWith_userId: data?._id,
              main_id: allData._id,
              accessRight: accessValueEdit,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
            swal({
              title: "Successfully Updated",
              icon: "success",
              // dangerMode: true,
            }).catch((err) => console.log(err));
            // navigate("/admin");
          });
      } else {
        swal({
          title: "Please first change access",
          icon: "error",
          // dangerMode: true,
        });
      }
    } else {
      if (accessValueEdit != "") {
        axios
          .post(
            `${config.base_api_url}/api/UpdateNoteShell/${data?.note_id_user}`,
            {
              shareWith_userId: data?._id,
              main_id: allData?.note_reference?._id,
              accessRight: accessValueEdit,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
            swal({
              title: "Successfully Updated",
              icon: "success",
              // dangerMode: true,
            }).catch((err) => console.log(err));
            // navigate("/admin");
          });
      } else {
        swal({
          title: "Please first change access",
          icon: "error",
          // dangerMode: true,
        });
      }
    }
  };
  const handleSubmitSaveUpdateRequestUser = (data) => {
    if (allData.type == "note") {
      if (accessValueEdit1 != "") {
        axios
          .post(
            `${config.base_api_url}/api/UpdateReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: allData._id,
              accessRight: accessValueEdit1,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
            swal({
              title: "Successfully Updated",
              icon: "success",
              // dangerMode: true,
            }).catch((err) => console.log(err));
            // navigate("/admin");
          });
      } else {
        swal({
          title: "Please first change access",
          icon: "error",
          // dangerMode: true,
        });
      }
    } else {
      if (accessValueEdit1 != "") {
        axios
          .post(
            `${config.base_api_url}/api/UpdateReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: allData?.note_reference?._id,
              accessRight: accessValueEdit1,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
            swal({
              title: "Successfully Updated",
              icon: "success",
              // dangerMode: true,
            }).catch((err) => console.log(err));
            // navigate("/admin");
          });
      } else {
        swal({
          title: "Please first change access",
          icon: "error",
          // dangerMode: true,
        });
      }
    }
  };
  const handleSubmitSaveUpdateNonExistRequestUser = (data) => {
    if (allData.type == "note") {
      if (accessValueEdit2 != "") {
        axios
          .post(
            `${config.base_api_url}/api/UpdateNonExistReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: allData._id,
              accessRight: accessValueEdit2,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
            swal({
              title: "Successfully Updated",
              icon: "success",
              // dangerMode: true,
            }).catch((err) => console.log(err));
            // navigate("/admin");
          });
      } else {
        swal({
          title: "Please first change access",
          icon: "error",
          // dangerMode: true,
        });
      }
    } else {
      if (accessValueEdit1 != "") {
        axios
          .post(
            `${config.base_api_url}/api/UpdateNonExistReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: allData?.note_reference?._id,
              accessRight: accessValueEdit2,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
            swal({
              title: "Successfully Updated",
              icon: "success",
              // dangerMode: true,
            }).catch((err) => console.log(err));
            // navigate("/admin");
          });
      } else {
        swal({
          title: "Please first change access",
          icon: "error",
          // dangerMode: true,
        });
      }
    }
  };
  const addComment = async (e) => {
    if (allData.type == "note") {
      e.preventDefault();
      let bodyValue = {
        id: params.id,
        comments: comments,
        parentId: undefined,
      };
      let url1 = `${config.base_api_url}/api/addcomment`;
      let method1 = "PUT";
      await fetchExtreamApi(url1, method1, bodyValue)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      e.preventDefault();
      let bodyValue = {
        id: allData?.note_reference?._id,
        comments: comments,
        parentId: undefined,
      };
      let url1 = `${config.base_api_url}/api/addcomment`;
      let method1 = "PUT";
      await fetchExtreamApi(url1, method1, bodyValue)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  const customFilter = (option, searchText) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,}$/;
    if (option.data.label == searchText) {
      return true;
    } else {
      if (
        option.data.label.toLowerCase().startsWith(searchText.toLowerCase())
      ) {
        return true;
      } else {
        if (re.test(searchText.toLowerCase())) {
          let bodyValue = { email: searchText };
          let url1 = `${config.base_api_url}/auth/userDetailBySearch`;
          let method1 = "POST";
          (async () => {
            let response = await fetchExtreamApi(url1, method1, bodyValue);
            let len = sharedNoteUser.length - 1;
            if (
              response?.length == 0 &&
              sharedNoteUser[len]?.email.toLowerCase() !=
                searchText.toLowerCase()
            ) {
              let arr = sharedNoteUser;
              arr.push({
                email: searchText,
                _id: 0,
              });
              for (let data1 of arr) {
                if (data1?.email.toLowerCase() == searchText.toLowerCase()) {
                  option.data.label = searchText;
                  option.data.value = 0;
                  setShareWith(option.data);
                  return true;
                }
              }
            } else if (
              response?.length != 0 &&
              sharedNoteUser[len]?.email.toLowerCase() !=
                searchText.toLowerCase()
            ) {
              let arr = sharedNoteUser;
              arr.push({
                email: response[0]?.email,
                _id: response[0]?._id,
              });
              for (let data1 of arr) {
                if (data1?.email.toLowerCase() == searchText.toLowerCase()) {
                  option.data.label = response[0]?.email;
                  option.data.value = response[0]?._id;
                  setShareWith(response[0]);
                  return true;
                }
              }
            } else {
              return false;
            }
          })();
        } else {
          return false;
        }
      }
    }
  };
  const handleOnChange = (selected, { inputValue }) => {
    setSelectedOption(selected.label);
    setShareWith(selected);
  };
  const getOptionLabel = (option) => {
    if (!option) return "";
    if (option.value === typedValue) return typedValue; // use typed value if it matches an option value
    return typedValue;
  };
  const ListItem = ({ item, active, setSelected, setHovered }) => (
    <div
      style={{ padding: "10px", fontSize: "16px" }}
      className={`item ${active ? "active" : ""}`}
      onClick={() => {
        if (item.includes("@")) {
          let atAdd = item.indexOf("#");
          var msg = item.slice(atAdd);
          setSelected(msg);
        } else {
          setSelected(item);
        }
        // handledropSelect(item);
      }}
      onMouseEnter={() => {
        setHovered(item);
      }}
      onMouseLeave={() => setHovered(undefined)}
    >
      {item}
    </div>
  );
  const ListItem1 = ({ item, active, setSelected, setHovered }) => (
    <div
      style={{ padding: "10px", fontSize: "16px" }}
      className={`item ${active ? "active" : ""}`}
      onClick={() => {
        setSelected("@" + item?.fname + item?.lname);
        setMentionInfo((prev) => [
          ...prev,
          {
            name: item?.fname + item?.lname,
            uni: item?._id,
          },
        ]);
      }}
      onMouseEnter={() => setHovered(item?.email)}
      onMouseLeave={() => setHovered(undefined)}
    >
      {item?.fname} {item?.lname}({item?.email})
    </div>
  );
  const handleDeleteImage = (data, elm) => {
    const Comment_id = elm != undefined ? elm : undefined;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this image!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your image has been deleted.", "success");
        axios
          .post(
            `${config.base_api_url}/api/deleteImage`,
            {
              img_url: data?.original,
              Comment_id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const DeleteAudio = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this audio!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your audio has been deleted.", "success");
        if (allData.type == "note") {
          axios
            .post(
              `${config.base_api_url}/api/deleteAudio`,
              {
                Audio: data,
                note_id: allData?._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              window.location.reload();
            });
        } else {
          axios
            .delete(
              `${config.base_api_url}/api/deleteAudio`,
              { Audio_id: data?._id, note_id: allData?._id },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((response) => {
              navigate("/admin");
            });
        }
      }
    });
  };
  const handleDeleteAudioComment = (data, elm) => {
    const Comment_id = elm != undefined ? elm : undefined;
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this audio!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your audio has been deleted.", "success");
        axios
          .post(
            `${config.base_api_url}/api/deleteAudioComment`,
            {
              Aud_url: data?.url,
              Comment_id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const handleTouchMove = useMemo(() => {
    return (e) => {
      if (e.keyCode >= 37 && e.keyCode <= 40) {
        e.preventDefault();
        return (e.returnValue = "");
      }
    };
  }, []);
  // const handleTouchMove = useMemo(() => {
  //   return (e) => {
  //     e.preventDefault();
  //     return (e.returnValue = "");
  //   };
  // }, []);
  useEffect(() => {
    if (
      (noteSp == true && downPress) ||
      (noteSp == true && upPress) ||
      noteSp == true
    ) {
      window.addEventListener("keydown", handleTouchMove, false);
      window.addEventListener("keyup", handleTouchMove, false);
      return () => {
        window.removeEventListener("keyup", handleTouchMove, false);
        window.removeEventListener("keydown", handleTouchMove, false);
      };
      // document.getElementById("mybtn").click();
    }
  }, [noteSp]);
  useEffect(() => {
    if (
      (atTagB == true && downPress) ||
      (atTagB == true && upPress) ||
      atTagB == true
    ) {
      window.addEventListener("keydown", handleTouchMove, false);
      window.addEventListener("keyup", handleTouchMove, false);
      return () => {
        window.removeEventListener("keyup", handleTouchMove, false);
        window.removeEventListener("keydown", handleTouchMove, false);
      };
      // document.getElementById("mybtn").click();
    }
  }, [atTagB]);
  useEffect(() => {
    if (allTItle.length && downPress && noteSp == true) {
      // window.addEventListener("keydown", listener);
      // window.addEventListener("keydown", handleTouchMove, false);
      setCursor((prevState) =>
        prevState < allTItle.length - 1 ? prevState + 1 : prevState
      );
    } else {
      // setTimeout(() => {
      //   window.removeEventListener("keyup", handleTouchMove);
      // }, 1000);
      // return () => {
      //   window.removeEventListener("keydown", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
    if (atTag.length && downPress && atTagB == true) {
      // window.addEventListener("keydown", listener);
      // window.addEventListener("keydown", handleTouchMove, false);
      setCursor((prevState) =>
        prevState < atTag.length - 1 ? prevState + 1 : prevState
      );
    } else {
      // setTimeout(() => {
      //   window.removeEventListener("keyup", handleTouchMove);
      // }, 1000);
      // return () => {
      //   window.removeEventListener("keydown", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
  }, [downPress]);
  useEffect(() => {
    // const handleTouchMove = function (e) {
    //   if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //   }
    // };

    if (allTItle.length && upPress && (noteSp == true || atTagB == true)) {
      // window.addEventListener("keydown", listener);
      // window.addEventListener("keyup", handleTouchMove, false);

      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    } else {
      // return () => {
      //   window.removeEventListener("keyup", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
    if (atTag.length && upPress && atTagB == true) {
      // window.addEventListener("keydown", listener);
      // window.addEventListener("keyup", handleTouchMove, false);

      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    } else {
      // return () => {
      //   window.removeEventListener("keyup", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
  }, [upPress]);

  useEffect(() => {
    if (allTItle.length && enterPress && noteSp) {
      // setSelected(allTItle[cursor]);
      if (allTItle[cursor]?.includes("@") && allTItle[cursor] != undefined) {
        let atAdd = allTItle[cursor].indexOf("#");
        var msg = allTItle[cursor].slice(atAdd);
        setSelected(msg);
      } else {
        setSelected(allTItle[cursor]);
        setDefaultLi(true);
      }
      // handledropSelect(allTItle[cursor]);
    }
    if (atTag.length && enterPress && atTagB) {
      // item?.fname + item?.lname;
      setSelected("@" + atTag[cursor]?.fname + atTag[cursor]?.lname);
      setMentionInfo((prev) => [
        ...prev,
        {
          name: atTag[cursor]?.fname + atTag[cursor]?.lname,
          uni: atTag[cursor]?._id,
        },
      ]);
      setDefaultLi(true);
      // handledropSelect(allTItle[cursor]);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (allTItle.length && hovered) {
      setCursor(allTItle.indexOf(hovered));
    }
    if (atTag.length && hovered) {
      setCursor(atTag.indexOf(hovered));
    }
  }, [hovered]);
  useEffect(() => {
    if (
      selected != undefined &&
      searchValueHash.includes("#") &&
      noteSp == true
    ) {
      let index = searchValueHash.length;
      if (defaultLi == true) {
        quill.deleteText(ranged - index, index + 1);
        setDefaultLi(false);
      } else {
        quill.deleteText(ranged - index, index);
      }

      // let index = searchValueHash.length;
      let add = ranged - index;
      quill.insertText(add, `${selected}`);
      // ${searchValueHash}${selected}
      if (quill) {
        quill.focus();
        let index1 = ranged - index + selected.length;
        setTimeout(() => {
          quill.setSelection(index1);
        }, 0);
      }
      setAllTitle(allTItle1);
      setNoteSp(false);

      setSelected(undefined);
    }
    if (
      selected != undefined &&
      searchValueATR.includes("@") &&
      atTagB == true
    ) {
      let index = searchValueATR.length;
      if (defaultLi == true) {
        quill.deleteText(rangedATR - index, index + 1);
        setDefaultLi(false);
      } else {
        quill.deleteText(rangedATR - index, index);
      }

      // let index = searchValueHash.length;
      let add = rangedATR - index;
      quill.insertText(add, `${selected}`, {
        background: "#d3e1eb",
      });
      if (quill) {
        quill.focus();
        let index1 = rangedATR - index + selected.length;
        setTimeout(() => {
          quill.setSelection(index1 + 1);
        }, 0);
      }
      quill.insertText(add + selected.length, ` `, {
        background: "white",
      });
      setAllTitle(allTItle1);

      setSelected(undefined);
      setAtTag([]);

      setAtTagB(false);
    }
  }, [selected && quill]);
  useEffect(() => {
    if (hashAdd == true) {
      let index = 1;
      if (rangedReloadHash == 0) {
        let add = 0;
        quill.insertText(add, "#");
        if (quill) {
          quill.focus();
          let index1 = add + 1;
          setTimeout(() => {
            quill.setSelection(index1);
          }, 0);
        }
      } else {
        let add = rangedReloadHash;
        quill.insertText(add, "#");
        if (quill) {
          quill.focus();
          let index1 = add + 1;
          setTimeout(() => {
            quill.setSelection(index1);
          }, 0);
        }
      }

      setHashAdd(false);
    }
  }, [hashAdd && quill]);
  // imagesArr2 = undefined;
  const currentImage = imagesArr2[index];

  const nextIndex = (index + 1) % imagesArr2.length;
  const nextImage = imagesArr2[nextIndex] || currentImage;
  const prevIndex = (index + imagesArr2.length - 1) % imagesArr2.length;
  const prevImage = imagesArr2[prevIndex] || currentImage;

  const currentImage2 = imagesArr3[index2];
  const nextIndex2 = (index2 + 1) % imagesArr3.length;
  const nextImage2 = imagesArr3[nextIndex2] || currentImage2;
  const prevIndex2 = (index2 + imagesArr3.length - 1) % imagesArr3.length;
  const prevImage2 = imagesArr3[prevIndex2] || currentImage2;

  const handleClickImages = (i) => {
    setIndex(i);

    // setTimeout(() => {
    //   handleMovePrev();
    // }, 1000);
  };
  const handleClickImages2 = (i, elm) => {
    elm?.images.length != 0 ? setImagesArr3(elm?.images) : setImagesArr3([]);

    setIndex2(i);

    // setTimeout(() => {
    //   handleMovePrev();
    // }, 1000);
  };
  const handleCloseImages = () => {
    setIndex(-1);
  };
  const handleCloseImages2 = () => {
    setIndex2(-1);
  };
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);
  const handleMovePrev2 = () => setIndex2(prevIndex2);
  const handleMoveNext2 = () => setIndex2(nextIndex2);
  useEffect(() => {
    // Adding an event listener to the document to capture keydown events
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // Do something when Escape key is pressed
        console.log("Escape key was pressed", rangedReload);
        let add = rangedReload;
        quill.insertText(add, " ");
        if (quill) {
          quill.focus();
          let index1 = add + 1;
          setTimeout(() => {
            quill.setSelection(index1);
          }, 0);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      // Removing the event listener when the component is unmounted
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [quill && rangedReload]);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <>
      {notes && (
        <>
          <div className="button_main_div_kk">
            <div class="editnotetop">
              {allData?.type == "note" && (
                <>
                  <div className="headerNoteDate">
                    {" "}
                    <div className=" MobView">
                      <AppBar position="static">
                        <IconButton color="inherit" onClick={toggleDrawer}>
                          <MenuIcon />
                        </IconButton>
                      </AppBar>
                    </div>
                    <div className="button_main_div">
                      {" "}
                      {allData?.permission != "View" &&
                        allData?.access != "View" && (
                          <button
                            className="top_input_box_diff_Save"
                            onClick={(e) => addNote(e)}
                          >
                            Save
                          </button>
                        )}
                      {allData?.permission != "View" &&
                        allData?.permission != "Edit" && (
                          <>
                            <button
                              className="top_input_box_diff"
                              onClick={handleOpen}
                            >
                              Share
                            </button>
                            <button
                              className="top_input_box_diff"
                              onClick={deleteNote}
                            >
                              Delete
                            </button>
                          </>
                        )}
                      {allData?.type == "noteShell" && (
                        <>
                          <button
                            className="top_input_box_diff"
                            onClick={handleDelete_shareWithUser}
                          >
                            Remove
                          </button>
                        </>
                      )}
                    </div>
                    <div class="metadata">
                      <Moment
                        format="dddd D MMM YYYY hh:mm"
                        withTitle
                        toNow
                        diff
                      >
                        {allData?.updatedAt}
                      </Moment>
                      @{allData?.user?.fname}
                    </div>
                  </div>
                  {allData?.sharedWith.length +
                    allData?.Request_sharedWith.length +
                    allData?.Request_sharedWithUserNotExist.length >
                    0 && (
                    <div>
                      &nbsp;&nbsp;&nbsp;
                      <i
                        class="fa-solid fa-users"
                        style={{ color: "#49b3cb" }}
                      ></i>
                      &nbsp;Shared with:{" "}
                      {allData?.sharedWith.length +
                        allData?.Request_sharedWith.length +
                        allData?.Request_sharedWithUserNotExist.length}{" "}
                      {allData?.sharedWith.length +
                        allData?.Request_sharedWith.length +
                        allData?.Request_sharedWithUserNotExist.length >
                      1
                        ? "persons"
                        : "person"}
                    </div>
                  )}
                </>
              )}
            </div>

            <div>
              {(allData?.type == "noteShell" ||
                allData?.type == "tagShell") && (
                <>
                  <div className="headerNoteDate">
                    {" "}
                    &nbsp;&nbsp;&nbsp;
                    <div className=" MobView">
                      <AppBar position="static">
                        <IconButton color="inherit" onClick={toggleDrawer}>
                          <MenuIcon />
                        </IconButton>
                      </AppBar>
                    </div>
                    <Moment format="dddd D MMM YYYY hh:mm" withTitle toNow diff>
                      {allData?.note_reference?.updatedAt}
                    </Moment>
                    @{allData?.controlBy?.fname}
                  </div>

                  <div>
                    &nbsp;&nbsp;&nbsp;
                    <i
                      class="fa-solid fa-users"
                      style={{ color: "rgb(124 187 75)" }}
                    ></i>
                    &nbsp;Shared By: @{allData?.sharedBy?.fname}{" "}
                  </div>
                </>
              )}
            </div>
          </div>
          <form method="post">
            {allData?.permission == "View" ||
              (allData?.permission == "Edit" && (
                <div className="space_mobRes"></div>
              ))}
            <div className="top_input_box_edit">
              <div className="border_box">
                <div className="left_inputF">
                  <div ref={quillRef} id="unicorn">
                    {noteSp == true && (
                      <div className="Active_Float">
                        <div className="MuiAutocomplete-listbox">
                          {allTItle.map((item, i) => {
                            return (
                              <ListItem
                                key={i}
                                active={i === cursor}
                                item={item}
                                setSelected={setSelected}
                                setHovered={setHovered}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {atTagB == true && (
                      <div className="Active_Float">
                        <div className="MuiAutocomplete-listbox">
                          {atTag.map((item, i) => {
                            return (
                              <ListItem1
                                key={i}
                                active={i === cursor}
                                item={item}
                                setSelected={setSelected}
                                setHovered={setHovered}
                              />
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className="right_text"

                  // onClick={()  }
                >
                  {allData?.permission != "View" && (
                    <>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />

                        <i className="fa-solid fa-paperclip"></i>
                        <h2>Attach</h2>
                      </div>
                      <div onClick={handleOpenRec}>
                        <i class="fa-solid fa-microphone"></i>
                        <h2>Record</h2>
                      </div>
                      <div onClick={handleOpenHash}>
                        <i class="fa-sharp fa-solid fa-hashtag"></i>
                        <h2>Hash</h2>
                      </div>
                    </>
                  )}

                  {/* <ul style={{ listStyle: "none" }}>
                    <li>
                      {" "}
                      <span>$</span> - Track finance
                    </li>
                    <li>
                      {" "}
                      <span>*</span> - Track Time
                    </li>
                    <li>
                      <span>@</span> - Tag user
                    </li>
                  </ul> */}
                </div>
              </div>
              <div>
                {imagesArr2?.length != 0 && (
                  <>
                    {" "}
                    <div
                      className="displayImages WhiteBox py15 "
                      style={{ borderRadius: "10px" }}
                    >
                      <div style={{ textAlign: "center", marginTop: "0px" }}>
                        <h2>Gallery</h2>
                      </div>

                      <div className="gridImages extraGrid">
                        {imagesArr2.map((data, i) => (
                          <>
                            {" "}
                            <div className="image_div">
                              <img
                                src={data?.imageThumb}
                                onClick={() => handleClickImages(i)}
                                style={{
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                {!!currentImage && (
                  <>
                    <Lightbox
                      reactModalStyle={{
                        overlay: {
                          width: "red",
                        },
                      }}
                      mainSrc={currentImage?.imageComp}
                      imageTitle={
                        <span>
                          <a href={currentImage?.original} download>
                            <button style={{ color: "black" }}>
                              <span>
                                <i class="fa-solid fa-circle-arrow-down"></i>
                              </span>{" "}
                              Download
                            </button>
                          </a>
                          {(allData.permission == "Admin" ||
                            allData.type == "note") && (
                            <button
                              style={{ color: "black" }}
                              onClick={() => handleDeleteImage(currentImage)}
                            >
                              <span>
                                <i class="fa-sharp fa-solid fa-trash"></i>
                              </span>{" "}
                              Delete
                            </button>
                          )}
                        </span>
                      }
                      // onImageLoad={() => {
                      //   window.dispatchEvent(new Event("resize"));
                      // }}
                      mainSrcThumbnail={currentImage?.src}
                      nextSrc={nextImage?.original}
                      nextSrcThumbnail={nextImage?.src}
                      // discourageDownloads={true}
                      prevSrc={prevImage?.original}
                      prevSrcThumbnail={prevImage?.src}
                      onCloseRequest={handleCloseImages}
                      onMovePrevRequest={handleMovePrev}
                      onMoveNextRequest={handleMoveNext}
                      onImageLoad={handleImageLoad}
                    />
                    {imageWidth && (
                      <style>
                        {`.ril-image-current {
                          width: 1400px;
                          height:auto;
    position: absolute;
    left: 60%;
           
          }`}
                      </style>
                    )}
                    {/* )} */}
                  </>
                )}
              </div>
              {uploadImage.length != 0 && (
                <div className="WhiteBox gridImages">
                  {preview.map((data, index) => (
                    <div className="displayImages">
                      <span className="ImageAdjust">
                        <span
                          className="CancelImage"
                          onClick={() => handleCancelImage({ data, index })}
                        >
                          {" "}
                          <i class="fa-solid fa-circle-xmark"></i>
                        </span>
                        <img
                          src={data}
                          style={{
                            height: 70,
                            width: 150,
                            objectFit: "contain",
                          }}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              )}
              {audioURLs.length != "" && (
                <div className="WhiteBox" style={{ borderRadius: "10px" }}>
                  {audioURLs &&
                    audioURLs.map((url) => {
                      return (
                        <>
                          <div className="left_align">
                            <span>
                              <span className="font_dark">File Size:</span>
                              {url?.size}
                            </span>
                            <span>
                              <span className="font_dark">Recorded:</span>
                              {moment(url?.createdTime).format("L LT")}
                            </span>
                            <span
                              className="font_Red"
                              onClick={() => {
                                CancelOne(url);
                              }}
                            >
                              Delete file
                            </span>
                          </div>

                          <input
                            type="text"
                            className="input_RecoredVoice"
                            // value={url?.title}
                            value={url?.title}
                            onChange={(e) => {
                              let arr = [];
                              const checkData = audioURLs.filter((data) => {
                                if (data.audio == url?.audio) {
                                  if (data.title.length == 1) {
                                    if (e.target.value.length > 0) {
                                      return (data.title = e.target.value);
                                    } else {
                                      return (data.title = " ");
                                    }
                                  } else {
                                    if (e.target.value.length > 0) {
                                      return (data.title = e.target.value);
                                    } else {
                                      return (data.title = " ");
                                    }
                                  }
                                } else {
                                  return data.title;
                                }
                              });

                              arr.push(checkData);

                              setAudioURLs(arr.flat());
                            }}
                          />
                          <audio
                            className="input_AudioVoice"
                            src={url?.audio}
                            controls
                          />
                        </>
                      );
                    })}
                </div>
              )}
              {/* //here */}
              {(allData?.audioUrl.length != 0 ||
                allData?.note_reference?.audioUrl.length != 0) && (
                <>
                  {" "}
                  {allData?.description == undefined
                    ? allData?.note_reference?.audioUrl.map((data) => {
                        return (
                          <>
                            <div
                              className="WhiteBox"
                              style={{
                                justifyContent: "center",
                                position: "relative",
                              }}
                            >
                              <div className="left_align">
                                <span>
                                  <span className="font_dark">File Size:</span>
                                  {data?.size}
                                </span>
                                <span>
                                  <span className="font_dark">Recorded:</span>
                                  {moment(data?.createdTime).format("L LT")}
                                </span>
                                <span
                                  className="font_Red"
                                  onClick={() => {
                                    DeleteAudio(data);
                                  }}
                                >
                                  Delete file
                                </span>
                              </div>

                              <input
                                type="text"
                                className="input_RecoredVoice"
                                // value={url?.title}
                                defaultValue={data?.title}
                                onChange={(e) => {
                                  setTitleChange(e.target.value);
                                  setSaveId(data?.url);
                                }}
                              />
                              <audio
                                className="input_AudioVoice"
                                src={data?.url}
                                controls
                              />

                              {saveId != "" && saveId == data?.url && (
                                <div style={{ display: "flex", gap: "2%" }}>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      // EditOne();
                                      axios
                                        .post(
                                          `${
                                            window.location.hostname ===
                                              "localhost" ||
                                            window.location.hostname ===
                                              "127.0.0.1"
                                              ? process.env
                                                  .REACT_APP_BASE_API_LOCAL
                                              : process.env
                                                  .REACT_APP_BASE_API_LIVE
                                          }/api/editAudio`,
                                          {
                                            Audio_id: data?._id,
                                            note_id: allData?._id,
                                            ChangedTitle: titleChange,
                                          },
                                          {
                                            headers: {
                                              Authorization: `Bearer ${localStorage.getItem(
                                                "firebasetoken"
                                              )}`,
                                            },
                                          }
                                        )
                                        .then((response) => {
                                          window.location.reload();
                                        });
                                      setSaveId("");
                                    }}
                                  >
                                    SAVE
                                  </button>
                                  <button onClick={() => setSaveId("")}>
                                    cancel
                                  </button>
                                </div>
                              )}
                              <br />
                            </div>
                          </>
                        );
                      })
                    : allData?.audioUrl.map((data) => {
                        return (
                          <>
                            <div
                              className="WhiteBox"
                              style={{
                                justifyContent: "center",
                                position: "relative",
                              }}
                            >
                              <div className="left_align">
                                <span>
                                  <span className="font_dark">File Size:</span>
                                  {data?.size}
                                </span>
                                <span>
                                  <span className="font_dark">Recorded:</span>
                                  {moment(data?.createdTime).format("L LT")}
                                </span>
                                <span
                                  className="font_Red"
                                  onClick={() => {
                                    DeleteAudio(data);
                                  }}
                                >
                                  Delete file
                                </span>
                              </div>

                              <input
                                type="text"
                                className="input_RecoredVoice"
                                // value={url?.title}
                                defaultValue={data?.title}
                                onChange={(e) => {
                                  setTitleChange(e.target.value);
                                  setSaveId(data?.url);
                                }}
                              />
                              <audio
                                className="input_AudioVoice"
                                src={data?.url}
                                controls
                              />

                              {saveId != "" && saveId == data?.url && (
                                <div style={{ display: "flex", gap: "2%" }}>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      // EditOne();
                                      axios
                                        .post(
                                          `${
                                            window.location.hostname ===
                                              "localhost" ||
                                            window.location.hostname ===
                                              "127.0.0.1"
                                              ? process.env
                                                  .REACT_APP_BASE_API_LOCAL
                                              : process.env
                                                  .REACT_APP_BASE_API_LIVE
                                          }/api/editAudio`,
                                          {
                                            Audio_id: data?._id,
                                            note_id: allData?._id,
                                            ChangedTitle: titleChange,
                                          },
                                          {
                                            headers: {
                                              Authorization: `Bearer ${localStorage.getItem(
                                                "firebasetoken"
                                              )}`,
                                            },
                                          }
                                        )
                                        .then((response) => {
                                          window.location.reload();
                                        });
                                      setSaveId("");
                                    }}
                                  >
                                    SAVE
                                  </button>
                                  <button onClick={() => setSaveId("")}>
                                    cancel
                                  </button>
                                </div>
                              )}
                              <br />
                            </div>
                          </>
                        );
                      })}
                </>
              )}
              {allData?.permission != "View" && allData?.access != "View" && (
                <button
                  className="sticky_btn_save"
                  // id="myBtn_save"
                  onClick={(e) => addNote(e)}
                >
                  Save
                </button>
              )}
              {/* {
               allData.type == "noteShell"
                  ?  allData?.note_reference?.commentNote.length == 0
              } */}
              <div className="mainBlockComment">
                {allComments && allData && allData.type == "noteShell"
                  ? allData?.note_reference?.commentNote.map((elm) => {
                      return (
                        <>
                          <div className="commentNoteMain">
                            {" "}
                            <Avatar sx={{ bgcolor: deepOrange[500] }}>
                              {" "}
                              {elm?.user_id?.fname[0].toUpperCase()}
                            </Avatar>
                            <div
                              className={
                                allData?.note_reference?.user ==
                                elm?.user_id?._id
                                  ? "commentMainCgUser"
                                  : "commentMain"
                              }
                            >
                              {allData?.user?._id == elm?.user_id?._id && (
                                <div className="editCom">
                                  <EditIcon
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setOpenQuillEdit(true);
                                      setIdQuillEdit(elm?._id);
                                    }}
                                  />
                                </div>
                              )}
                              <div>
                                @{elm?.user_id?.fname}
                                {allData?.user?._id == elm?.user_id?._id
                                  ? " (you)"
                                  : ""}{" "}
                                &nbsp;
                                <span
                                  className={
                                    allData?.note_reference?.user ==
                                    elm?.user_id?._id
                                      ? "TimeCommentUser"
                                      : "TimeComment"
                                  }
                                >
                                  <Moment format="l , LT" withTitle diff>
                                    {elm?.createdAt}
                                  </Moment>
                                </span>
                              </div>
                              {openQuillEdit == true &&
                              idQuillEdit == elm?._id ? (
                                <CommentEdit
                                  description={elm.description}
                                  elm={elm}
                                  setOpenQuillEdit={setOpenQuillEdit}
                                  allData={allData}
                                  params={params.id}
                                />
                              ) : (
                                <div> {Parser(elm.description)}</div>
                              )}
                              {elm?.images.length != 0 && (
                                <>
                                  <div className="WhiteBoxNoPadding">
                                    <div className="gridImages extraGrid">
                                      {elm?.images.map((data, i) => (
                                        <>
                                          {" "}
                                          <div className="image_div">
                                            <img
                                              src={data?.imageThumb}
                                              onClick={() =>
                                                handleClickImages2(i, elm)
                                              }
                                              style={{
                                                objectFit: "cover",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                  {!!currentImage2 && (
                                    <>
                                      {/* {imagesArr1?.length != 0 && ( */}
                                      <Lightbox
                                        reactModalStyle={{
                                          overlay: {
                                            width: "red",
                                          },
                                        }}
                                        mainSrc={currentImage2?.imageComp}
                                        imageTitle={
                                          <span>
                                            <a
                                              href={currentImage2?.original}
                                              download
                                            >
                                              <button
                                                style={{ color: "black" }}
                                              >
                                                <span>
                                                  <i class="fa-solid fa-circle-arrow-down"></i>
                                                </span>{" "}
                                                Download
                                              </button>
                                            </a>
                                            <button
                                              style={{ color: "black" }}
                                              onClick={() =>
                                                handleDeleteImage(
                                                  currentImage2,
                                                  elm
                                                )
                                              }
                                            >
                                              <span>
                                                <i class="fa-sharp fa-solid fa-trash"></i>
                                              </span>{" "}
                                              Delete
                                            </button>
                                          </span>
                                        }
                                        // onImageLoad={() => {
                                        //   window.dispatchEvent(new Event("resize"));
                                        // }}
                                        mainSrcThumbnail={currentImage2?.src}
                                        nextSrc={nextImage2?.original}
                                        nextSrcThumbnail={nextImage2?.src}
                                        // discourageDownloads={true}
                                        prevSrc={prevImage2?.original}
                                        prevSrcThumbnail={prevImage2?.src}
                                        onCloseRequest={handleCloseImages2}
                                        onMovePrevRequest={handleMovePrev2}
                                        onMoveNextRequest={handleMoveNext2}
                                        onImageLoad={handleImageLoad}
                                      />
                                      {imageWidth && (
                                        <style>
                                          {`.ril-image-current {width: 1400px;height:auto;position: absolute;left: 60%; }`}
                                        </style>
                                      )}
                                      {/* )} */}
                                    </>
                                  )}
                                </>
                              )}
                              {elm?.audios.length != 0 &&
                                elm?.audios.map((data) => (
                                  <div
                                    className="WhiteBox"
                                    style={{
                                      justifyContent: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      className="left_align"
                                      style={{ color: "black" }}
                                    >
                                      <span>
                                        <span className="font_dark">
                                          File Size:
                                        </span>
                                        {data?.size}
                                      </span>
                                      <span>
                                        <span className="font_dark">
                                          Recorded:
                                        </span>
                                        {moment(data?.created).format("L LT")}
                                      </span>
                                      <span
                                        className="font_Red"
                                        onClick={() => {
                                          handleDeleteAudioComment(data, elm);
                                        }}
                                      >
                                        Delete file
                                      </span>
                                    </div>

                                    <input
                                      type="text"
                                      className="input_RecoredVoice"
                                      // value={url?.title}
                                      value={data?.title}
                                      // onChange={(e) => {
                                      //   setTitleChange(e.target.value);
                                      //   setSaveId(data?.url);
                                      // }}
                                    />
                                    <audio
                                      className="input_AudioVoice"
                                      src={data?.url}
                                      controls
                                    />

                                    {/* {saveId != "" && saveId == data?.url && (
      <div style={{ display: "flex", gap: "2%" }}>
        <button
          onClick={(e) => {
            e.preventDefault();
            // EditOne();
            axios
              .post(
                `${
                  config.base_api_url
                }/api/editAudio`,
                {
                  Audio_id: data?._id,
                  note_id: allData?._id,
                  ChangedTitle: titleChange,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "firebasetoken"
                    )}`,
                  },
                }
              )
              .then((response) => {
                window.location.reload();
              });
            setSaveId("");
          }}
        >
          SAVE
        </button>
        <button onClick={() => setSaveId("")}>cancel</button>
      </div>
    )} */}
                                    <br />
                                  </div>
                                ))}
                            </div>
                          </div>
                        </>
                      );
                    })
                  : allData?.commentNote.map((elm) => {
                      return (
                        <>
                          <div className="commentNoteMain">
                            {" "}
                            <Avatar sx={{ bgcolor: deepOrange[500] }}>
                              {elm?.user_id?.fname[0].toUpperCase()}
                            </Avatar>
                            <div
                              className={
                                allData?.user?._id == elm?.user_id?._id
                                  ? "commentMainCgUser"
                                  : "commentMain"
                              }
                            >
                              {allData?.user?._id == elm?.user_id?._id && (
                                <div className="editCom">
                                  <EditIcon
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setOpenQuillEdit(true);
                                      setIdQuillEdit(elm?._id);
                                    }}
                                  />
                                </div>
                              )}

                              <div>
                                @{elm?.user_id?.fname}
                                {allData?.user?._id == elm?.user_id?._id
                                  ? " (you)"
                                  : ""}
                                {"  "} &nbsp;
                                <span
                                  className={
                                    allData?.user?._id == elm?.user_id?._id
                                      ? "TimeCommentUser"
                                      : "TimeComment"
                                  }
                                >
                                  {" "}
                                  <Moment format="l , LT" withTitle diff>
                                    {elm?.createdAt}
                                  </Moment>
                                </span>
                              </div>
                              {openQuillEdit == true &&
                              idQuillEdit == elm?._id ? (
                                <CommentEdit
                                  description={elm.description}
                                  elm={elm}
                                  setOpenQuillEdit={setOpenQuillEdit}
                                  allData={allData}
                                  params={params.id}
                                />
                              ) : (
                                <div> {Parser(elm.description)}</div>
                              )}

                              {elm?.images.length != 0 && (
                                <>
                                  <div className="WhiteBoxNoPadding">
                                    <div className="gridImages extraGrid">
                                      {elm?.images.map((data, i) => (
                                        <>
                                          {" "}
                                          <div className="image_div">
                                            <img
                                              src={data?.imageThumb}
                                              onClick={() =>
                                                handleClickImages2(i, elm)
                                              }
                                              style={{
                                                objectFit: "cover",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </div>
                                        </>
                                      ))}
                                    </div>
                                  </div>
                                  {!!currentImage2 && (
                                    <>
                                      {/* {imagesArr1?.length != 0 && ( */}
                                      <Lightbox
                                        reactModalStyle={{
                                          overlay: {
                                            width: "red",
                                          },
                                        }}
                                        mainSrc={currentImage2?.imageComp}
                                        imageTitle={
                                          <span>
                                            <a
                                              href={currentImage2?.original}
                                              download
                                            >
                                              <button
                                                style={{ color: "black" }}
                                              >
                                                <span>
                                                  <i class="fa-solid fa-circle-arrow-down"></i>
                                                </span>{" "}
                                                Download
                                              </button>
                                            </a>
                                            <button
                                              style={{ color: "black" }}
                                              onClick={() =>
                                                handleDeleteImage(
                                                  currentImage2,
                                                  elm
                                                )
                                              }
                                            >
                                              <span>
                                                <i class="fa-sharp fa-solid fa-trash"></i>
                                              </span>{" "}
                                              Delete
                                            </button>
                                          </span>
                                        }
                                        // onImageLoad={() => {
                                        //   window.dispatchEvent(new Event("resize"));
                                        // }}
                                        mainSrcThumbnail={currentImage2?.src}
                                        nextSrc={nextImage2?.original}
                                        nextSrcThumbnail={nextImage2?.src}
                                        // discourageDownloads={true}
                                        prevSrc={prevImage2?.original}
                                        prevSrcThumbnail={prevImage2?.src}
                                        onCloseRequest={handleCloseImages2}
                                        onMovePrevRequest={handleMovePrev2}
                                        onMoveNextRequest={handleMoveNext2}
                                        onImageLoad={handleImageLoad}
                                      />
                                      {imageWidth && (
                                        <style>
                                          {`.ril-image-current {width: 1400px;height:auto;position: absolute;left: 60%; }`}
                                        </style>
                                      )}
                                      {/* )} */}
                                    </>
                                  )}
                                </>
                              )}

                              {elm?.audios.length != 0 &&
                                elm?.audios.map((data) => (
                                  <div
                                    className="WhiteBox"
                                    style={{
                                      justifyContent: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      className="left_align"
                                      style={{ color: "black" }}
                                    >
                                      <span>
                                        <span className="font_dark">
                                          File Size:
                                        </span>
                                        {data?.size}
                                      </span>
                                      <span>
                                        <span className="font_dark">
                                          Recorded:
                                        </span>
                                        {moment(data?.created).format("L LT")}
                                      </span>
                                      <span
                                        className="font_Red"
                                        onClick={() => {
                                          handleDeleteAudioComment(data, elm);
                                        }}
                                      >
                                        Delete file
                                      </span>
                                    </div>

                                    <input
                                      type="text"
                                      className="input_RecoredVoice"
                                      // value={url?.title}
                                      value={data?.title}
                                      // onChange={(e) => {
                                      //   setTitleChange(e.target.value);
                                      //   setSaveId(data?.url);
                                      // }}
                                    />
                                    <audio
                                      className="input_AudioVoice"
                                      src={data?.url}
                                      controls
                                    />

                                    {/* {saveId != "" && saveId == data?.url && (
      <div style={{ display: "flex", gap: "2%" }}>
        <button
          onClick={(e) => {
            e.preventDefault();
            // EditOne();
            axios
              .post(
                `${
                  config.base_api_url
                }/api/editAudio`,
                {
                  Audio_id: data?._id,
                  note_id: allData?._id,
                  ChangedTitle: titleChange,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "firebasetoken"
                    )}`,
                  },
                }
              )
              .then((response) => {
                window.location.reload();
              });
            setSaveId("");
          }}
        >
          SAVE
        </button>
        <button onClick={() => setSaveId("")}>cancel</button>
      </div>
    )} */}
                                    <br />
                                  </div>
                                ))}
                            </div>
                          </div>
                        </>
                      );
                    })}
                {/* <Moment format="D MMM YYYY hh:mm" withTitle diff>
                  {allData.updatedAt}
                </Moment> */}
              </div>
              {open1 == false && (
                <div
                  style={{ marginTop: " 20px" }}
                  className="top_input_box_comment"
                >
                  <button onClick={() => setOpen1(true)}>Add Comment</button>
                </div>
              )}
            </div>
          </form>

          {open1 == true && (
            <div>
              {/* <form method="post" className="commentForm">
                <div className="top_input_box">
                  <div className="border_box">
                    <div className="left_input">
                      <ReactTextareaAutocomplete
                        className="my-textarea"
                        loadingComponent={Loading}
                        onChange={(e) => {
                          setComments(e.target.value);
                          setTitleInput(true);
                        }}
                        value={comments}
                        minChar={0}
                        trigger={{
                          "#": {
                            dataProvider: (token) => {
                              return allTItle
                                .filter((text) =>
                                  text
                                    ?.toLowerCase()
                                    .startsWith(`#${token}`.toLowerCase())
                                )
                                .map((text) => ({ text }));
                            },
                            component: Item,
                            output: (item, trigger) => `${item.text}`,
                          },
                        }}
                      />
                    </div>
                    <div className="right_text">
                      <i className="fa-solid fa-paperclip"></i>
                      <h2>Attach</h2>
                      <ul style={{ listStyle: "none" }}>
                        <li>$ - Track finance</li>
                        <li>* - Track Time</li>
                        <li>@ - Tag user</li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <button onClick={(e) => addComment(e)}>Submit</button>
                  </div>
                </div>
              </form> */}
              <CommentQuill
                enterPress={enterPress}
                allData={allData}
                params={params}
              />
            </div>
          )}
          <Modal
            open={open2}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div style={style} className="share_modal">
              <div style={{ marginLeft: "94%" }}>
                <CloseIcon
                  style={{
                    color: "#6F6F6F",
                    cursor: "pointer",
                    fontSize: "3rem",
                  }}
                  onClick={handleClose}
                />
              </div>
              <div className="share_div">SHARE NOTE</div>
              {shareWith?.value == 0 && (
                <div style={{ color: "red" }}>
                  This email doesn't exist(Invite user with a mail).
                </div>
              )}
              {/* {shareWith?.value == undefined &&
                shareWith?.label != undefined && (
                  <div>This user doesn't exist</div>
                )} */}
              <div className="share_two_dd">
                <Select
                  // defaultValue={selectedOption}
                  // onChange={setSelectedOption}
                  inputValue={selectedOption != null ? null : typedValue}
                  onInputChange={(inputValue, { action }) => {
                    if (inputValue.length != 0) {
                      setTypedValue(inputValue);
                      setSelectedOption(null);
                      setShareWith("");
                    } else if (action === "input-change") {
                      setTypedValue(inputValue);
                      setSelectedOption(null);
                      setShareWith("");
                    }
                  }}
                  placeholder="Search user"
                  options={sharedNoteUser?.map(({ _id, email }) => ({
                    value: _id,
                    label: email.toLowerCase(),
                  }))}
                  onChange={handleOnChange}
                  isSearchable={true}
                  filterOption={customFilter}
                  // getOptionLabel={getOptionLabel}
                />
                {/* {shareWith?.value != 0 && ( */}
                <Dropdown
                  options={options1}
                  className="ddShareNote"
                  // onChange={_onSelect}
                  value={defaultOption}
                  onChange={(value) => setAccessValue(value.value)}
                  // placeholder=""
                />
                {/* )} */}

                {/* )} */}
              </div>
              <div className="top_input_box_green">
                <button onClick={(e) => handleSubmitShare({ e, allData })}>
                  SHARE
                </button>
                <div className="mainDivScrollShare">
                  {allData.sharedWith.length != 0 && (
                    <div className="share_div2">SHARED WITH</div>
                  )}

                  {allData.type == "note"
                    ? allData?.sharedWith.map((data) => {
                        return (
                          <>
                            <div className="share_two_dd">
                              <input
                                type="text"
                                value={data?.user_id?.email}
                                className="input_SharedEmail"
                              />

                              <Dropdown
                                options={options1}
                                // onChange={_onSelect}
                                value={
                                  accessValueEdit != "" && editId == data?._id
                                    ? accessValueEdit
                                    : data?.accessRight
                                }
                                onChange={(value) => {
                                  setAccessValueEdit(value.value);
                                  setEditId(data?._id);
                                }}
                                // placeholder=""
                              />
                              <div
                                style={{
                                  cursor: "pointer",
                                  border: "2px solid #dce0e8",
                                  height: "46px",
                                  width: "50px",
                                  display: "grid",
                                  borderRadius: "5px",
                                  placeItems: "center",
                                }}
                              >
                                <CloseIcon
                                  style={{
                                    color: "#6F6F6F",

                                    height: "70%",
                                    width: "70%",
                                  }}
                                  onClick={() => handleDelete_shareWith(data)}
                                />
                              </div>
                              {/* <CreateIcon
                              style={{
                                color: "#6F6F6F",
                                cursor: "pointer",
                                border: "2px solid #dce0e8",
                                height: "46px",
                                width: "auto",
                                borderRadius: "5px",
                              }}
                              onClick={() => {
                                setEditId(data?._id);
                              }}
                            /> */}
                            </div>

                            {editId == data?._id && (
                              <div style={{ display: "flex", gap: "2%" }}>
                                <button
                                  // style={
                                  //   editId == data?._id
                                  //     ? { diplay: "block" }
                                  //     : { diplay: "none" }
                                  // }
                                  onClick={(e) =>
                                    handleSubmitSaveUpdate(data, e)
                                  }
                                >
                                  SAVE
                                </button>
                                <button
                                  // style={
                                  //   editId == data?._id
                                  //     ? { diplay: "block" }
                                  //     : { diplay: "none" }
                                  // }
                                  onClick={(e) => setEditId("")}
                                >
                                  cancel
                                </button>
                              </div>
                            )}
                          </>
                        );
                      })
                    : allData?.note_reference?.sharedWith.map((data) => {
                        return (
                          <>
                            <div className="share_two_dd">
                              <input
                                type="text"
                                value={data?.user_id?.email}
                                className="input_SharedEmail"
                              />

                              <Dropdown
                                options={options1}
                                // onChange={_onSelect}
                                value={
                                  accessValueEdit != "" && editId == data?._id
                                    ? accessValueEdit
                                    : data?.accessRight
                                }
                                onChange={(value) => {
                                  setAccessValueEdit(value.value);
                                  setEditId(data?._id);
                                }}
                                // placeholder=""
                              />
                              <div
                                style={{
                                  cursor: "pointer",
                                  border: "2px solid #dce0e8",
                                  height: "46px",
                                  width: "50px",
                                  display: "grid",
                                  borderRadius: "5px",
                                  placeItems: "center",
                                }}
                              >
                                <CloseIcon
                                  style={{
                                    color: "#6F6F6F",

                                    height: "70%",
                                    width: "70%",
                                  }}
                                  onClick={() => handleDelete_shareWith(data)}
                                />
                              </div>
                              {/* <CreateIcon
                              style={{
                                color: "#6F6F6F",
                                cursor: "pointer",
                                border: "2px solid #dce0e8",
                                height: "46px",
                                width: "auto",
                                borderRadius: "5px",
                              }}
                              onClick={() => {
                                setEditId(data?._id);
                              }}
                            /> */}
                            </div>

                            {editId == data?._id && (
                              <div style={{ display: "flex", gap: "2%" }}>
                                <button
                                  // style={
                                  //   editId == data?._id
                                  //     ? { diplay: "block" }
                                  //     : { diplay: "none" }
                                  // }
                                  onClick={(e) =>
                                    handleSubmitSaveUpdate(data, e)
                                  }
                                >
                                  SAVE
                                </button>
                                <button
                                  // style={
                                  //   editId == data?._id
                                  //     ? { diplay: "block" }
                                  //     : { diplay: "none" }
                                  // }
                                  onClick={(e) => setEditId("")}
                                >
                                  Cancel
                                </button>
                              </div>
                            )}
                          </>
                        );
                      })}
                  {allData.Request_sharedWith.length != 0 && (
                    <div className="share_div2">REQUESTED WITH</div>
                  )}

                  {allData.type == "note"
                    ? allData?.Request_sharedWith.map((data) => {
                        return (
                          <>
                            <div className="share_two_dd">
                              <input
                                type="text"
                                value={data?.user_id?.email}
                                className="input_SharedEmail"
                              />

                              <Dropdown
                                options={options1}
                                // onChange={_onSelect}
                                value={
                                  accessValueEdit1 != "" && editId1 == data?._id
                                    ? accessValueEdit1
                                    : data?.accessRight
                                }
                                onChange={(value) => {
                                  setAccessValueEdit1(value.value);
                                  setEditId1(data?._id);
                                }}
                                // placeholder=""
                              />
                              <div
                                style={{
                                  cursor: "pointer",
                                  border: "2px solid #dce0e8",
                                  height: "46px",
                                  width: "50px",
                                  display: "grid",
                                  borderRadius: "5px",
                                  placeItems: "center",
                                }}
                              >
                                <CloseIcon
                                  style={{
                                    color: "#6F6F6F",

                                    height: "70%",
                                    width: "70%",
                                  }}
                                  onClick={() => handleDelete_ReqWith(data)}
                                />
                              </div>
                            </div>

                            {editId1 == data?._id && (
                              <div style={{ display: "flex", gap: "2%" }}>
                                <button
                                  style={
                                    editId1 == data?._id
                                      ? { diplay: "block" }
                                      : { diplay: "none" }
                                  }
                                  onClick={(e) =>
                                    handleSubmitSaveUpdateRequestUser(data, e)
                                  }
                                >
                                  SAVE
                                </button>
                                <button
                                  style={
                                    editId1 == data?._id
                                      ? { diplay: "block" }
                                      : { diplay: "none" }
                                  }
                                  onClick={(e) => setEditId1("")}
                                >
                                  cancel
                                </button>
                              </div>
                            )}
                          </>
                        );
                      })
                    : null}
                  {/* ==================== */}
                  {allData.Request_sharedWithUserNotExist.length != 0 && (
                    <div className="share_div2">
                      REQUESTED WITH NON EXIST USER
                    </div>
                  )}

                  {allData.type == "note"
                    ? allData?.Request_sharedWithUserNotExist.map((data) => {
                        return (
                          <>
                            <div className="share_two_dd">
                              <input
                                type="text"
                                value={data?.user_Email}
                                className="input_SharedEmail"
                              />

                              <Dropdown
                                options={options1}
                                // onChange={_onSelect}
                                value={
                                  accessValueEdit2 != "" && editId2 == data?._id
                                    ? accessValueEdit2
                                    : data?.accessRight
                                }
                                onChange={(value) => {
                                  setAccessValueEdit2(value.value);
                                  setEditId2(data?._id);
                                }}
                                // placeholder=""
                              />
                              <div
                                style={{
                                  cursor: "pointer",
                                  border: "2px solid #dce0e8",
                                  height: "46px",
                                  width: "50px",
                                  display: "grid",
                                  borderRadius: "5px",
                                  placeItems: "center",
                                }}
                              >
                                <CloseIcon
                                  style={{
                                    color: "#6F6F6F",

                                    height: "70%",
                                    width: "70%",
                                  }}
                                  onClick={() =>
                                    handleDelete_NonExistReqWith(data)
                                  }
                                />
                              </div>
                            </div>

                            {editId2 == data?._id && (
                              <div style={{ display: "flex", gap: "2%" }}>
                                <button
                                  style={
                                    editId2 == data?._id
                                      ? { diplay: "block" }
                                      : { diplay: "none" }
                                  }
                                  onClick={(e) =>
                                    handleSubmitSaveUpdateNonExistRequestUser(
                                      data,
                                      e
                                    )
                                  }
                                >
                                  SAVE
                                </button>
                                <button
                                  style={
                                    editId2 == data?._id
                                      ? { diplay: "block" }
                                      : { diplay: "none" }
                                  }
                                  onClick={(e) => setEditId2("")}
                                >
                                  cancel
                                </button>
                              </div>
                            )}
                          </>
                        );
                      })
                    : null}
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={openRec}
            onClose={handleCloseRec}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div style={style}>
              <div style={{ marginLeft: "94%" }}>
                <CloseIcon
                  style={{ color: "#6F6F6F", cursor: "pointer" }}
                  onClick={handleCloseRec}
                />
              </div>
              <div className="share_div">RECORD AUDIO</div>

              <div></div>
              <div className="top_input_box_green">
                <h4>Name</h4>
                {audioURL != "" && (
                  <>
                    <input
                      type="text"
                      className="input_RecoredVoice"
                      defaultValue={
                        title != ""
                          ? title
                          : `Untitled_${moment().format("L_LTS")}`
                      }
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <audio
                      className="input_AudioVoice"
                      src={audioURL?.audio}
                      onChange={(e) => {
                        console.log(e.target.files, "files");
                      }}
                      controls
                    />
                  </>
                )}

                <br />
                {audioURL == "" && (
                  <>
                    <input
                      type="text"
                      className="input_RecoredVoice"
                      defaultValue={
                        title != ""
                          ? title
                          : `Untitled_${moment().format("L_LTS")}`
                      }
                      // value={`Untitled_${moment().format("L_LTS")}`}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <h1 style={{ marginTop: "0px", textAlign: "center" }}>
                      {dispSecondsAsMins(timer)}
                    </h1>
                  </>
                )}
                {!isRecording && audioURL == "" && (
                  <>
                    {" "}
                    <button
                      onClick={() => {
                        startRecording();
                        toggleStart();
                      }}
                      disabled={isRecording}
                    >
                      start recording
                    </button>
                    <audio id="microphone" autoplay></audio>
                    {/* <canvas id="canvas" width="300" height="50" ></canvas> */}
                  </>
                )}
                {isRecording && audioURL == "" && (
                  <>
                    <button
                      className="top_input_box_Red"
                      onClick={() => {
                        stopRecording();
                        toggleStart();
                      }}
                      disabled={!isRecording}
                    >
                      stop recording
                    </button>
                    <audio id="microphone" autoplay></audio>
                    <canvas id="canvas" width="300" height="50"></canvas>
                  </>
                )}
                {audioURL != "" && (
                  <div style={{ display: "flex", gap: "2%" }}>
                    <button
                      onClick={() => {
                        Save();
                        setTimer(0);
                        setTitle("");
                        handleCloseRec();
                      }}
                    >
                      SAVE
                    </button>
                    <button
                      onClick={() => {
                        Delete();
                        setTimer(0);
                        setTitle("");
                        handleCloseRec();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              {/* </div> */}
            </div>
          </Modal>
          <Modal
            open={openNoteVersion}
            onClose={handleCloseNoteVer}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div style={style}>
              <p style={{ fontSize: "16px", fontWeight: "600" }}>
                {" "}
                A newer version of this note has been saved by {noteVersionUser}
                , would you like to overwrite it?
              </p>
              <div className="button_main_div">
                <button
                  className="top_input_box_diff_Save"
                  onClick={(e) => addNoteAfterVerify(e)}
                >
                  Yes
                </button>
                <button
                  className="top_input_box_diff_Save"
                  onClick={() => handleCloseNoteVer()}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default EditNoteBox;
