import axios from "axios";
import React, { useMemo, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { createTagwiseArray } from "../../utils/functions/createTagwiseArray";
import { filterByKeywords } from "../../utils/functions/filterByKeywords";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";

import Select from "react-select";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "react-select-search/style.css";
import swal from "sweetalert";
import Swal from "sweetalert2";

import { Async } from "../../utils/Async";
import DynamicSub from "../../utils/DynamicSub";
import DynamicTagSub from "../../utils/functions/DynamicTagSub";
import ShareWithPer from "../../utils/functions/ShareWithPer";
import { fetchExtreamApi } from "../../utils/fetchExtreamApi";
import config from "../../config";

const options1 = ["View", "Edit", "Admin"];
const defaultOption = options1[0];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "16px",
  transform: "translate(-50%, -50%)",
  // width: 468,
  bgcolor: "background.paper",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: "0",
};
const TagsContainer = () => {
  const navigate = useNavigate();
  const [notes, setNotes] = useState(null);
  const [userEmail, setUserEmail] = useState([]);
  const [accessValue, setAccessValue] = useState("View");
  const [allData, setAllData] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState(null);
  const [showSubText, setShowSubText] = useState();
  const [tagShareWith, setTagShareWith] = useState([]);
  const [tagShareWith1, setTagShareWith1] = useState([]);
  const [editId2, setEditId2] = useState("");
  const [accessValueEdit2, setAccessValueEdit2] = useState("");

  const [tagTitle, setTagTitle] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [typedValue, setTypedValue] = useState("");

  const [showSubText1, setShowSubText1] = useState();
  const [shareWith, setShareWith] = useState("");
  const [openAfterData, setOpenAfterData] = React.useState(false);

  const [open, setOpen] = useState([]);
  const [open2, setOpen2] = React.useState(false);
  const [tagArr, setTagArr] = React.useState(false);

  const [data, setData] = useState([]);
  const [accessValueEdit, setAccessValueEdit] = useState("");
  const [accessValueEdit1, setAccessValueEdit1] = useState("");

  const [tagControl_id, setTagControl_id] = useState(undefined);

  const [editId, setEditId] = useState("");
  const [editId1, setEditId1] = useState("");

  const handleOpen = () => setOpen2(true);
  const handleClose = () => setOpen2(false);
  const arr1 = [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url1 = `${config.base_api_url}/api/getnote`;
        let method1 = "GET";
        let response = await fetchExtreamApi(url1, method1);
        setAllData(response);
        setNotes(createTagwiseArray(response));
        // Convert object to array of key-value pairs
        const arr = Object.entries(createTagwiseArray(response));

        // Sort the array based on keys and values
        arr.sort((a, b) =>
          a[0]
            .replace(/@.*?(?=#)/, "")
            .localeCompare(b[0].replace(/@.*?(?=#)/, ""))
        );
        // Create a new object from the sorted array
        const sortedObj = Object.fromEntries(arr);

        setFilteredNotes(sortedObj);
      } catch (error) {
        console.error(error.message);
      }

      try {
        let url1 = `${config.base_api_url}/api/sharedNoteUsers`;
        let method1 = "GET";
        let res = await fetchExtreamApi(url1, method1);

        const uniqueObjects = [];
        const idSet = new Set();
        let arr = res?.sharedNote;
        for (const obj of arr) {
          if (!idSet.has(obj._id)) {
            uniqueObjects.push(obj);
            idSet.add(obj._id);
          }
        }

        setTagShareWith1(uniqueObjects);
      } catch (error) {
        // console.error(error.message);
      }
    };
    fetchData();

    // Get the element with the hide5 class
    // const hide5Element = document.querySelector(".hide5");

    // // Check if the element with the sharedPer class exists
    // const sharedPerElement = document.querySelector(".sharedPer");
    // if (sharedPerElement && hide5Element) {
    //   // Set the desired right position if the sharedPer class exists
    //   hide5Element.style.right = "1000px";
    // } else if (hide5Element) {
    //   // Reset the right position to the default if the sharedPer class does not exist
    //   hide5Element.style.right = "auto";
    // }
  }, []);
  const customFilter = (option, searchText) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,}$/;
    if (option.data.label == searchText) {
      return true;
    } else {
      if (
        option.data.label.toLowerCase().startsWith(searchText.toLowerCase())
      ) {
        return true;
      } else {
        if (re.test(searchText.toLowerCase())) {
          let bodyValue = { email: searchText };
          let url1 = `${config.base_api_url}/auth/userDetailBySearch`;
          let method1 = "POST";
          (async () => {
            let response = await fetchExtreamApi(url1, method1, bodyValue);
            let len = tagShareWith1.length - 1;
            if (
              response?.length == 0 &&
              tagShareWith1[len]?.email.toLowerCase() !=
                searchText.toLowerCase()
            ) {
              let arr = tagShareWith1;
              arr.push({
                email: searchText,
                _id: 0,
              });
              for (let data1 of arr) {
                if (data1?.email.toLowerCase() == searchText.toLowerCase()) {
                  option.data.label = searchText;
                  option.data.value = 0;
                  setShareWith(option.data);
                  return true;
                }
              }
            } else if (
              response?.length != 0 &&
              tagShareWith1[len]?.email.toLowerCase() !=
                searchText.toLowerCase()
            ) {
              let arr = tagShareWith1;
              arr.push({
                email: response[0]?.email,
                _id: response[0]?._id,
              });
              for (let data1 of arr) {
                if (data1?.email.toLowerCase() == searchText.toLowerCase()) {
                  option.data.label = response[0]?.email;
                  option.data.value = response[0]?._id;
                  setShareWith(response[0]);
                  return true;
                }
              }
            } else {
              return false;
            }
          })();
        } else {
          return false;
        }
      }
    }
  };
  const handleOnChange = (selected, { inputValue }) => {
    setSelectedOption(selected.label);
    setShareWith(selected);
    // setTypedValue(inputValue);
  };
  const handleSearch = (e) => {
    setFilteredNotes(filterByKeywords(e.target.value, notes));
  };

  const handleOnWhitePaceClick1 = (id) => {
    setShowSubText(id);

    // setOpen(true);
  };
  const handleRemoveSearchValue = () => {
    const pastDate = new Date(0); // Set to a date in the past
    document.cookie = `SearchValue=; expires=${pastDate.toUTCString()}; path=/`;
  };
  const handleTextClick = (e, title) => {
    e.stopPropagation();

    // if (title.includes("@")) {
    //   let atAdd = title.indexOf("#");
    //   var msg = title.slice(atAdd);
    //   title = msg;
    // }
    // navigate("/admin", { state: { dataTitle: title } });
  };

  // const handleShareTag = (e, title, data) => {
  //   e.stopPropagation();
  //   setTagShareWith([]);
  //   setTagArr(false);
  //   let atAdd = title.flat()[0].indexOf("#");
  //   var msg = title.flat()[0].slice(atAdd);
  //   setTagTitle(msg);
  //   setTagControl_id(data?.controlBy?._id);

  //   if (data?.controlBy?._id != undefined) {
  //     const fetchData = async () => {
  //       try {
  //         let bodyValue = {
  //           tag: msg,
  //           controlBy: data?.controlBy?._id,
  //         };
  //         let url1 = `${
  //           window.location.hostname === "localhost" ||
  //           window.location.hostname === "127.0.0.1"
  //             ? process.env.REACT_APP_BASE_API_LOCAL
  //             : process.env.REACT_APP_BASE_API_LIVE
  //         }/api/GetMasterTagShell`;
  //         let method1 = "POST";
  //         let response = await fetchExtreamApi(url1, method1, bodyValue);
  //         setTagShareWith(response);
  //         handleOpen();
  //       } catch (error) {
  //         console.error(error.message);
  //       }
  //     };
  //     fetchData();
  //   } else {
  //     const fetchData = async () => {
  //       try {
  //         let bodyValue = {
  //           tag: title.flat()[0],
  //           controlBy: undefined,
  //         };
  //         let url1 = `${
  //           window.location.hostname === "localhost" ||
  //           window.location.hostname === "127.0.0.1"
  //             ? process.env.REACT_APP_BASE_API_LOCAL
  //             : process.env.REACT_APP_BASE_API_LIVE
  //         }/api/GetMasterTagShell`;
  //         let method1 = "POST";
  //         let response = await fetchExtreamApi(url1, method1, bodyValue);
  //         setTagShareWith(response);
  //         handleOpen();
  //         //  let arr = [];
  //         //  response.map(
  //         //    (data) => !arr.includes(data.title) && arr.push(data.title)
  //         //  );
  //         //  setAllTitle(arr);
  //         //  setAllTitle1(arr);
  //       } catch (error) {
  //         console.error(error.message);
  //       }
  //     };
  //     fetchData();
  //   }
  // };

  const handleShareTagArr = (e, title, data) => {
    e.stopPropagation();
    setTagShareWith([]);
    let atAdd = title.indexOf("#");
    var msg = title.slice(atAdd);
    setTagTitle(msg);
    setTagArr(true);
    setTagControl_id(data[0]?.controlBy?._id);
    if (data[0]?.controlBy?._id != undefined) {
      const fetchData = async () => {
        try {
          let bodyValue = {
            tag: msg,
            controlBy: data[0]?.controlBy?._id,
          };
          let url1 = `${config.base_api_url}/api/GetMasterTagShell`;
          let method1 = "POST";
          let response = await fetchExtreamApi(url1, method1, bodyValue);

          setTagShareWith(response);
          handleOpen();
          //  let arr = [];
          //  response.map(
          //    (data) => !arr.includes(data.title) && arr.push(data.title)
          //  );
          //  setAllTitle(arr);
          //  setAllTitle1(arr);
        } catch (error) {
          console.error(error.message);
        }
      };
      fetchData();
    } else {
      const fetchData = async () => {
        try {
          let bodyValue = {
            tag: title,
          };
          let url1 = `${config.base_api_url}/api/GetMasterTagShell`;
          let method1 = "POST";
          let response = await fetchExtreamApi(url1, method1, bodyValue);

          setTagShareWith(response);

          handleOpen();
          //  let arr = [];
          //  response.map(
          //    (data) => !arr.includes(data.title) && arr.push(data.title)
          //  );
          //  setAllTitle(arr);
          //  setAllTitle1(arr);
        } catch (error) {
          console.error(error.message);
        }
      };
      fetchData();
    }
  };
  const handleSubmitSaveUpdate = (data) => {
    axios
      .post(
        `${config.base_api_url}/api/UpdateTagShell`,
        {
          shareWith_userId: data?._id,
          note_id_user: data?.note_id_user,
          accessRight: accessValueEdit,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
          },
        }
      )
      .then((response) => {
        window.location.reload();
        swal({
          title: "Successfully Updated",
          icon: "success",
          // dangerMode: true,
        }).catch((err) => console.log(err));
        // navigate("/admin");
      });
  };
  const handleSubmitShare = ({ e, allData }) => {
    e.preventDefault();

    let atAdd = tagTitle.indexOf("#");
    var msg = tagTitle.slice(atAdd).replace(/ *\([^)]*\) */g, "");
    // tagTitle = msg;
    // .replace(/ *\([^)]*\) */g, "");
    if (shareWith?.value == 0 && accessValue != "") {
      axios
        .post(
          `${config.base_api_url}/api/SendMailToSignup`,
          {
            email: shareWith?.label,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          swal({
            title: response.data.message,
            icon: "success",
          });

          window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error!", error);
          swal({
            title: error?.response?.data?.message,
            icon: "error",
          });
        });
      axios
        .post(
          `${config.base_api_url}/api/AddPreTagRequestShellArr`,
          {
            shareWith_email: shareWith.label,
            access: accessValue,
            tag: msg,
            ControlBy: tagControl_id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          swal({
            title: response.data.message,
            icon: "success",
          });
          setOpen2(false);
          setShareWith("");
          window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error!", error);
          swal({
            title: error?.response?.data?.message,
            icon: "error",
          });
          setOpen2(false);
          setShareWith("");
          // window.location.reload();
        });
    } else {
      if (
        msg != undefined &&
        accessValue != "" &&
        shareWith?.value != undefined
      ) {
        if (tagControl_id != undefined) {
          if (tagArr == true) {
            axios
              .post(
                `${config.base_api_url}/api/addTagRequestShellArr`,
                {
                  shareWith: shareWith.value,
                  access: accessValue,
                  tag: msg,
                  ControlBy: tagControl_id,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "firebasetoken"
                    )}`,
                  },
                }
              )
              .then((response) => {
                swal({
                  title: response.data.message,
                  icon: "success",
                });
                setOpen2(false);
                window.location.reload();
              })
              .catch((error) => {
                console.error("There was an error!", error);
                // swal({
                //   title: "This note is already shared with this user",
                //   icon: "error",
                // });
                // setOpen2(false);
                // setShareWith("");
                // window.location.reload();
              });
          }
        } else {
          if (tagArr == true) {
            axios
              .post(
                `${config.base_api_url}/api/addTagRequestShellArr`,
                {
                  shareWith: shareWith.value,
                  access: accessValue,
                  tag: msg,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                      "firebasetoken"
                    )}`,
                  },
                }
              )
              .then((response) => {
                swal({
                  title: response.data.message,
                  icon: "success",
                });
                setOpen2(false);
                window.location.reload();
              })
              .catch((error) => {
                console.error("There was an error!", error);
                // swal({
                //   title: "This note is already shared with this user",
                //   icon: "error",
                // });
                // setOpen2(false);
                // setShareWith("");
                // window.location.reload();
              });
          }
        }
      } else {
        swal({
          title: "Please first fill all the fields",
          icon: "error",
        });
      }
    }
  };
  const handleDelete_shareWith = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");
        axios
          .post(
            `${config.base_api_url}/api/deleteTagShell`,
            {
              shareWith_userId: data?._id,
              note_id_user: data?.note_id_user,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const handleDelete_ReqWith = (data, tagShareWith) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");

        axios
          .post(
            `${config.base_api_url}/api/DeleteReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: tagShareWith._id,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
            // swal({
            //   title: "Successfully deleted",
            //   icon: "success",
            //   // dangerMode: true,
            // }).catch((err) => console.log(err));
            // navigate("/admin");
          });
      }
    });
  };
  const handleDelete_NonExistReqWith = (data, tagShareWith) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");

        axios
          .post(
            `${config.base_api_url}/api/DeleteNonExistUserReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: tagShareWith._id,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
            // swal({
            //   title: "Successfully deleted",
            //   icon: "success",
            //   // dangerMode: true,
            // }).catch((err) => console.log(err));
            // navigate("/admin");
          });
      }
    });
  };
  const handleSubmitSaveUpdateRequestUser = (data, tagShareWith) => {
    if (accessValueEdit1 != "") {
      axios
        .post(
          `${config.base_api_url}/api/UpdateReqShell/${data?.req_id}`,
          {
            shareWith_userId: data?._id,
            main_id: tagShareWith._id,
            accessRight: accessValueEdit1,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          window.location.reload();
          swal({
            title: "Successfully Updated",
            icon: "success",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          // navigate("/admin");
        });
    } else {
      swal({
        title: "Please first change access",
        icon: "error",
        // dangerMode: true,
      });
    }
  };
  const handleSubmitSaveUpdateNonExistRequestUser = (data, tagShareWith) => {
    if (accessValueEdit2 != "") {
      axios
        .post(
          `${config.base_api_url}/api/UpdateTagNonExistUserReqShell/${data?.req_id}`,
          {
            shareWith_userId: data?._id,
            main_id: tagShareWith._id,
            accessRight: accessValueEdit2,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          window.location.reload();
          swal({
            title: "Successfully Updated",
            icon: "success",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          // navigate("/admin");
        });
    } else {
      swal({
        title: "Please first change access",
        icon: "error",
        // dangerMode: true,
      });
    }
  };
  return (
    <div className="tags_box">
      <div className="search_box">
        <div className="search_box_div">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            defaultValue=""
            placeholder="Search"
            onChange={(e) => handleSearch(e)}
          />
        </div>
      </div>
      {/* filteredNotes[`${title}`].values */}
      {filteredNotes != null &&
        Object.keys(filteredNotes).map((title, index) => {
          var arrSub = filteredNotes[`${title}`].values;

          function removeDuplicates(arrSub) {
            return arrSub.filter(
              (item, index) => arrSub.indexOf(item) === index
            );
          }

          let arr = [];
          const check = allData.find((data) => {
            return data?.title.some((titled) => {
              if (title.includes("@")) {
                return (
                  `@${
                    data?.sharedBy?.fname + data?.sharedBy?.lname + titled
                  }`.startsWith(title.replace(/ *\([^)]*\) */g, "")) &&
                  data?.controlBy?._id == filteredNotes[`${title}`]?.id
                );
              } else {
                return title == titled;
              }
            });
          });
          {
          }
          arr.push(check);
          let title1 = title;
          if (title.includes("@")) {
            let atAdd = title.indexOf("#");
            var msg = title.slice(atAdd);
            title1 = msg;
          }
          return (
            <>
              <div
                key={title}
                className="p-3 mb-2 bg-light bg-gradient text-dark border border-secondary rounded posFlex"
                onClick={(e) => handleOnWhitePaceClick1(title)}
              >
                <div className="posFlex1 main_block">
                  <p
                    style={{
                      marginBottom: "0rem",
                      width: "fit-content",
                      display: "flex",
                    }}
                    onClick={(e) => handleTextClick(e, title)}
                  >
                    <div class="tooltip-back">
                      <a
                        href={`/admin?data-custom-title=${encodeURIComponent(
                          title1
                        )}`}
                        onClick={handleRemoveSearchValue}
                      >
                        {title.replace(/ *\([^)]*\) */g, "")}&nbsp;{" "}
                      </a>
                      {filteredNotes[`${title}`].id.length != 0 ? (
                        <p class="tooltiptext-inn">
                          <h4>Name: {filteredNotes[`${title}`]?.name}</h4>
                          <h4>Email: {filteredNotes[`${title}`]?.email}</h4>
                          <h4>Control ID: {filteredNotes[`${title}`]?.id}</h4>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <strong>
                      {" "}
                      (
                      <Async
                        e={title.replace(/ *\([^)]*\) */g, "")}
                        d={allData}
                        t={filteredNotes[`${title}`]?.id}
                      />
                      )
                    </strong>
                    &nbsp;
                    {filteredNotes[`${title}`]?.values.length != 0 ? (
                      <>
                        {" "}
                        <p
                          style={{
                            display: title === showSubText ? "none" : "block",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOnWhitePaceClick1(title)}
                        >
                          +
                        </p>
                        <p
                          style={{
                            display: title === showSubText ? "block" : "none",
                            cursor: "pointer",
                          }}
                          onClick={() => handleOnWhitePaceClick1((title = ""))}
                        >
                          -
                        </p>{" "}
                        &nbsp;
                      </>
                    ) : null}
                  </p>
                  <span>
                    <ShareWithPer
                      title={title.replace(/ *\([^)]*\) */g, "")}
                      data={arr?.flat()}
                    />

                    {/* {allData?.sharedWith.length > 1 ? "persons" : "person"} */}
                  </span>
                  {arr?.flat()[0] != undefined ? (
                    arr?.flat()[0]?.permission != "View" ? (
                      arr?.flat()[0]?.permission != "Edit" ? (
                        <p
                          className="hide5"
                          onClick={(e) =>
                            handleShareTagArr(
                              e,
                              title.replace(/ *\([^)]*\) */g, ""),
                              arr?.flat()
                            )
                          }
                        >
                          Share
                        </p>
                      ) : null
                    ) : null
                  ) : title.includes("@") != true ? (
                    <p
                      className="hide5"
                      onClick={(e) =>
                        handleShareTagArr(
                          e,
                          title.replace(/ *\([^)]*\) */g, ""),
                          arr?.flat()
                        )
                      }
                    >
                      Share
                    </p>
                  ) : null}
                </div>
                {/* ====================================== */}

                <div style={{ marginBottom: "0rem" }}>
                  <div
                    style={{
                      display: title === showSubText ? "block" : "none",
                    }}
                    key={index}
                  >
                    <DynamicTagSub
                      menuData={removeDuplicates(arrSub)}
                      title={title.replace(/ *\([^)]*\) */g, "")}
                      d={allData}
                      t={filteredNotes[`${title}`]?.id}
                    />
                  </div>
                </div>
                {/* ====================================== */}
              </div>
            </>
          );
        })}
      <Modal
        open={open2}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="share_modal">
          <div style={{ marginLeft: "94%" }}>
            <CloseIcon
              style={{ color: "#6F6F6F", cursor: "pointer", fontSize: "3rem" }}
              onClick={handleClose}
            />
          </div>
          <div className="share_div">SHARE TAG</div>
          {shareWith?.value == 0 && (
            <div style={{ color: "red" }}>
              This email doesn't exist(Invite user with a mail).
            </div>
          )}
          <div className="share_tag_cont">
            All notes tagged with this tag will be shared
          </div>
          <div className="share_two_dd">
            <Select
              // defaultValue={selectedOption}
              // onChange={setSelectedOption}
              inputValue={selectedOption != null ? null : typedValue}
              onInputChange={(inputValue, { action }) => {
                if (inputValue.length != 0) {
                  setTypedValue(inputValue);
                  setSelectedOption(null);
                  setShareWith("");
                } else if (action === "input-change") {
                  setTypedValue(inputValue);
                  setSelectedOption(null);
                  setShareWith("");
                }
              }}
              placeholder="Search user"
              options={tagShareWith1.map(({ _id, email }) => ({
                value: _id,
                label: email.toLowerCase(),
              }))}
              onChange={handleOnChange}
              isSearchable={true}
              filterOption={customFilter}
            />

            <Dropdown
              options={options1}
              // onChange={_onSelect}
              value={defaultOption}
              onChange={(value) => setAccessValue(value.value)}
              // placeholder=""
            />
          </div>
          <div className="top_input_box_green">
            <button onClick={(e) => handleSubmitShare({ e, allData })}>
              SHARE
            </button>

            {tagShareWith[0]?.sharedWith != undefined &&
              tagShareWith[0]?.sharedWith.length != 0 && (
                <div className="share_div2">SHARED WITH</div>
              )}

            {tagShareWith[0]?.sharedWith.map((data) => {
              return (
                <>
                  <div className="share_two_dd">
                    <input
                      type="text"
                      value={data?.user_id?.email}
                      className="input_SharedEmail"
                    />

                    <Dropdown
                      options={options1}
                      // onChange={_onSelect}
                      value={
                        accessValueEdit != "" && editId == data?._id
                          ? accessValueEdit
                          : data?.accessRight
                      }
                      onChange={(value) => {
                        setAccessValueEdit(value.value);
                        setEditId(data?._id);
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        border: "2px solid #dce0e8",
                        height: "46px",
                        width: "50px",
                        display: "grid",
                        borderRadius: "5px",
                        placeItems: "center",
                      }}
                    >
                      <CloseIcon
                        style={{
                          color: "#6F6F6F",

                          height: "70%",
                          width: "70%",
                        }}
                        onClick={() => handleDelete_shareWith(data)}
                      />
                    </div>
                  </div>

                  {editId == data?._id && (
                    <div style={{ display: "flex", gap: "2%" }}>
                      <button onClick={(e) => handleSubmitSaveUpdate(data, e)}>
                        SAVE
                      </button>
                      <button onClick={(e) => setEditId("")}>cancel</button>
                    </div>
                  )}
                </>
              );
            })}
            {tagShareWith[0]?.Request_sharedWith != undefined &&
              tagShareWith[0]?.Request_sharedWith.length != 0 && (
                <div className="share_div2">REQUESTED WITH</div>
              )}
            {tagShareWith[0]?.Request_sharedWith.map((data) => {
              return (
                <>
                  <div className="share_two_dd">
                    <input
                      type="text"
                      value={data?.user_id?.email}
                      className="input_SharedEmail"
                    />

                    <Dropdown
                      options={options1}
                      // onChange={_onSelect}
                      value={
                        accessValueEdit1 != "" && editId1 == data?._id
                          ? accessValueEdit1
                          : data?.accessRight
                      }
                      onChange={(value) => {
                        setAccessValueEdit1(value.value);
                        setEditId1(data?._id);
                      }}
                    />
                    <CloseIcon
                      style={{
                        color: "#6F6F6F",
                        cursor: "pointer",
                        border: "2px solid #dce0e8",
                        height: "46px",
                        width: "auto",
                        borderRadius: "5px",
                      }}
                      onClick={() =>
                        handleDelete_ReqWith(data, tagShareWith[0])
                      }
                    />
                  </div>

                  {editId1 == data?._id && (
                    <div style={{ display: "flex", gap: "2%" }}>
                      <button
                        onClick={(e) =>
                          handleSubmitSaveUpdateRequestUser(
                            data,

                            tagShareWith[0]
                          )
                        }
                      >
                        SAVE
                      </button>
                      <button onClick={(e) => setEditId1("")}>cancel</button>
                    </div>
                  )}
                </>
              );
            })}
            {/* ============== */}
            {tagShareWith[0]?.Request_sharedWithUserNotExist != undefined &&
              tagShareWith[0]?.Request_sharedWithUserNotExist.length != 0 && (
                <div className="share_div2">REQUESTED WITH NON EXIST USER</div>
              )}

            {tagShareWith[0]?.Request_sharedWithUserNotExist.map((data) => {
              return (
                <>
                  <div className="share_two_dd">
                    <input
                      type="text"
                      value={data?.user_Email}
                      className="input_SharedEmail"
                    />

                    <Dropdown
                      options={options1}
                      // onChange={_onSelect}
                      value={
                        accessValueEdit2 != "" && editId2 == data?._id
                          ? accessValueEdit2
                          : data?.accessRight
                      }
                      onChange={(value) => {
                        setAccessValueEdit2(value.value);
                        setEditId2(data?._id);
                      }}
                      // placeholder=""
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        border: "2px solid #dce0e8",
                        height: "46px",
                        width: "50px",
                        display: "grid",
                        borderRadius: "5px",
                        placeItems: "center",
                      }}
                    >
                      <CloseIcon
                        style={{
                          color: "#6F6F6F",

                          height: "70%",
                          width: "70%",
                        }}
                        onClick={() =>
                          handleDelete_NonExistReqWith(data, tagShareWith[0])
                        }
                      />
                    </div>
                  </div>

                  {editId2 == data?._id && (
                    <div style={{ display: "flex", gap: "2%" }}>
                      <button
                        style={
                          editId2 == data?._id
                            ? { diplay: "block" }
                            : { diplay: "none" }
                        }
                        onClick={(e) =>
                          handleSubmitSaveUpdateNonExistRequestUser(
                            data,

                            tagShareWith[0]
                          )
                        }
                      >
                        SAVE
                      </button>
                      <button
                        style={
                          editId2 == data?._id
                            ? { diplay: "block" }
                            : { diplay: "none" }
                        }
                        onClick={(e) => setEditId2("")}
                      >
                        cancel
                      </button>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default TagsContainer;
