import React from "react";
import Logo from "../images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { fetchExtreamApi } from "../utils/fetchExtreamApi";
import config from "../config";

const Leftside = ({ data, redirectCheck }) => {
  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    navigate("/");
    localStorage.removeItem("firebasetoken");
    localStorage.clear();
  };
  const [dataReqListAll, setDataReqListAll] = useState([]);
  useEffect(() => {
    let url1 = `${config.base_api_url}/api/ReqShellListPartUserCount`;
    let method1 = "GET";
    (async () => {
      await fetchExtreamApi(url1, method1)
        .then((response) => {
          setDataReqListAll(response);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    })();
  }, [data]);
  const handleRedirect = (e, redirectPage, title) => {
    if (redirectPage) {
      e.preventDefault(); // Prevent the default redirect behavior

      const confirmNavigation = window.confirm(
        "You have unsaved data. Do you want to redirect and lose the data?"
      );
      if (confirmNavigation) {
        navigate(title); // Redirect to the tags page
      }
    }
  };
  return (
    <div className="side_bar">
      <div className="top_logo">
        <img src={Logo} alt="" />
      </div>
      <ul style={{ listStyle: "none" }}>
        <li
          className={window.location.pathname == "/admin" ? "active" : "none"}
        >
          <Link to="/" onClick={(e) => handleRedirect(e, redirectCheck, "/")}>
            <i className="fa-solid fa-house"></i>
          </Link>
        </li>
        <li className={window.location.pathname == "/tags" ? "active" : "none"}>
          <Link
            to="/tags"
            onClick={(e) => handleRedirect(e, redirectCheck, "/tags")}
          >
            <i className="fa-solid fa-tag"></i>
          </Link>
        </li>
        {/* <li
          className={
            window.location.pathname == "/template" ? "active" : "none"
          }
        >
          <Link
            to="/template"
            onClick={(e) => handleRedirect(e, redirectCheck, "/template")}
          >
            <i class="fa-solid fa-file-lines"></i>
          </Link>
        </li> */}
        <li
          className={
            window.location.pathname == "/notification" ? "active" : "none"
          }
          style={{ position: "relative" }}
        >
          <Link
            to="/notification"
            onClick={(e) => handleRedirect(e, redirectCheck, "/notification")}
          >
            <i className="fa-solid fa-message"></i>
            {dataReqListAll.length != 0 && (
              <span className="countLenNoti">{dataReqListAll.length}</span>
            )}
          </Link>
        </li>
        <li
          className={window.location.pathname == "/setting" ? "active" : "none"}
        >
          <Link
            to="/setting"
            onClick={(e) => handleRedirect(e, redirectCheck, "/setting")}
          >
            <i className="fa-solid fa-gear"></i>
          </Link>
        </li>
        <li>
          <button onClick={(e) => handleLogout(e)} className="logoutbtn">
            <img src="/logout.png" className="logoutIcon" />
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Leftside;
