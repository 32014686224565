import React, { Component, useEffect, useState } from "react";
import Leftside from "../../components/Leftside";
import Container from "../../components/NotesBox";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import axios from "axios";
import { fetchExtreamApi } from "../../utils/fetchExtreamApi";
import config from "../../config";

const Home = () => {
  const [drawer, setDrawer] = useState(false);
  const [dataReqList, setDataReqList] = useState(undefined);
  const [redirectPage, setRedirectPage] = useState(false);
  useEffect(() => {
    (async () => {
      let url1 = `${config.base_api_url}/api/ReqShellListPartUser`;
      let method1 = "GET";
      await fetchExtreamApi(url1, method1)
        .then((response) => {
          const filterRequest = response?.data.filter((data) => {
            return data.type == "requestShell";
          });
          setDataReqList(filterRequest);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    })();
  }, []);
  const toggleDrawer = () => {
    setDrawer(!drawer);
  };
  return (
    <div className="mainwrap">
      <div className=" MobView">
        <AppBar position="static">
          {/* <Toolbar> */}
          <IconButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          {/* </Toolbar> */}
        </AppBar>
        <Drawer open={drawer} onClose={toggleDrawer} style={{ width: 300 }}>
          <div className="left_bar">
            {redirectPage != undefined && (
              <>
                {" "}
                <Leftside redirectCheck={redirectPage} />
              </>
            )}
          </div>
        </Drawer>
        {/* <Leftside /> */}
      </div>
      <div className="left_bar norView">
        <Leftside redirectCheck={redirectPage} />
      </div>
      <div className="right_side">
        {dataReqList != undefined && (
          <Container
            dataReqList={dataReqList}
            setRedirectPage={setRedirectPage}
          />
        )}
      </div>
    </div>
  );
};

export default Home;
