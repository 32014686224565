import axios from "axios";
import React, { useMemo, useState, useCallback, useRef } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Parser from "html-react-parser";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import Moment from "react-moment";
import moment from "moment";
import swal from "sweetalert";
import BlotFormatter from "quill-blot-formatter";

import { useDropzone } from "react-dropzone";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// import { images } from "./frontend/images";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Select from "react-select";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import "react-select-search/style.css";
import useRecorder from "../useRecorder";
import { fetchExtreamApi } from "../../utils/fetchExtreamApi";
import config from "../../config";
const useKeyPressD = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });
  return keyPressed;
};

const CommentEdit = ({
  description,
  elm,
  setOpenQuillEdit,
  allData,
  params,
}) => {
  const [notes, setNotes] = useState();
  const [noteSp, setNoteSp] = useState(false);
  const [allTItle, setAllTitle] = useState([]);
  const [atTagB, setAtTagB] = useState(false);
  const [searchValueATR, setsearchValueATR] = useState("");
  const [rangedATR, setRangedATR] = useState();
  const [atTag, setAtTag] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const [openRec, setOpenRec] = React.useState(false);
  const [open1, setOpen1] = useState(false);

  const [uploadImage, setUploadImage] = useState([]);

  const handleOpenRec = () => setOpenRec(true);
  const handleCloseRec = () => setOpenRec(false);

  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "size",
    "list",
    "indent",
    "header",
    "color",
    "background",
    "font",
    "align",
    "height",
    "width",
    "image",
    "link",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      ["link"],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [
        // { size: ["small", false, "large", "huge"] },
        { header: [1, 2, 3, 4, 5, 6, false] },
      ],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [
        {
          font: [],
        },
      ],
      [{ align: [] }],
      ["image"],
    ],
    blotFormatter: {},
  };
  const { quill, quillRef, Quill } = useQuill({
    modules,
    formats,
    // readOnly,
  });
  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register("modules/blotFormatter", BlotFormatter);
  }
  var toolbarDiv = document.querySelector(".left_inputF2 .ql-toolbar.ql-snow");

  React.useEffect(() => {
    if (toolbarDiv != null) {
      var spanElement = document.createElement("span");
      var iElement = document.createElement("i");
      iElement.className = "fa-solid fa-print";

      spanElement.appendChild(iElement);

      spanElement.onclick = handlePrint; // Assuming handlePrint is the function to be executed on click
      spanElement.className = "spanPrint";
      // Append the span element to the toolbar div
      toolbarDiv.appendChild(spanElement);
    }
  }, [quill]);
  React.useEffect(() => {
    if (quill) {
      let newRange = [];
      let newRange1 = [];
      quill.on("editor-change", (eventName, ...args) => {
        if (eventName === "selection-change") {
          const range = args[0];
          if (range) {
            newRange.push(range.index);
          }
        }
      });
      quill.clipboard.dangerouslyPasteHTML(description);

      quill.on("text-change", (delta, oldDelta, source) => {
        setNotes(quill.root.innerHTML);
      });
    }
  }, [quill]);
  const handlePrint = () => {
    const content = quill.root.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write("<div>" + content + "</div>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const handleTouchMove = useMemo(() => {
    return (e) => {
      if (e.keyCode >= 37 && e.keyCode <= 40) {
        e.preventDefault();
        return (e.returnValue = "");
      }
    };
  }, []);

  const addComment = async (e) => {
    e.preventDefault();
    const stringText = quill.root.innerHTML;
    const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
    const imgSrcMatches = stringText.matchAll(imgSrcRegex);

    const srcArray = [];

    for (const match of imgSrcMatches) {
      const srcAttribute = match[1];
      if (srcAttribute.startsWith("data:image/")) {
        srcArray.push(srcAttribute);
      }
    }
    let imgId = 0; // Counter for assigning image ids

    // Regular expression pattern to match <img> tags
    let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

    // Removing <img> tags and adding id attribute
    let newString = stringText.replace(imgPattern1, function (match) {
      let idAttribute = ' src="id:' + imgId++ + '"';
      return match.replace(/src="[^"]*"/, idAttribute);
    });
    let imagesBase = [];
    if (srcArray.length != 0) {
      for (let imagesCov of srcArray) {
        const file = base64ToFile(imagesCov, "image");
        imagesBase.push(file);
      }
    }
    function base64ToFile(base64, filename) {
      const arr = base64.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const fileExtension = mime.split("/")[1];

      // Append the file extension to the file name
      const fullFileName = `${filename}.${fileExtension}`;
      const file = new File([u8arr], fullFileName, { type: mime });

      return file;
    }
    const data = new FormData();
    data.append("description", newString);
    data.append("CommentMainNote", JSON.stringify(elm));

    if (imagesBase.length != 0) {
      for (let uploadImageConArr of imagesBase)
        data.append("imagesBase", uploadImageConArr);
    }
    if (allData.type == "note") {
      data.append(`noteId`, params);

      let url1 = `${config.base_api_url}/api/editComment`;
      let method1 = "PUT";
      await fetchExtreamApi(url1, method1, data)
        .then((response) => {
          if (response?.imagesBaseArrAllInfo.length != 0) {
            for (let imagesInfo of response?.imagesBaseArrAllInfo) {
              axios
                .post(
                  `${config.base_api_url}/api/AddSizeOfImageInfo`,

                  {
                    id_note: allData?._id,
                    s3location: imagesInfo?.imag_loc,
                    size: imagesInfo?.image_size,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "firebasetoken"
                      )}`,
                      // "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((res) => {
                  console.log("Successfully added", res);
                })
                .catch((error) => {
                  swal({
                    title: error?.response?.data?.Error,
                    icon: "error",
                    dangerMode: true,
                  });
                  // setNotes("");
                  // setUploadImage("");
                  console.error("There was an error!", error);
                });
            }
          }

          swal({
            title: "Edit comment sucessfully!!",
            icon: "success",
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.error("There was an error!", error);
          swal({
            title: "Something went wrong!",
            icon: "error",
            dangerMode: true,
          });
        });
    } else {
      data.append(`noteId`, allData?.note_reference?._id);

      let url1 = `${config.base_api_url}/api/editComment`;
      let method1 = "PUT";
      await fetchExtreamApi(url1, method1, data)
        .then((response) => {
          if (response?.imagesBaseArrAllInfo.length != 0) {
            for (let imagesInfo of response?.imagesBaseArrAllInfo) {
              axios
                .post(
                  `${config.base_api_url}/api/AddSizeOfImageInfo`,

                  {
                    id_note: allData?._id,
                    s3location: imagesInfo?.imag_loc,
                    size: imagesInfo?.image_size,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "firebasetoken"
                      )}`,
                      // "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((res) => {
                  console.log("Successfully added", res);
                })
                .catch((error) => {
                  swal({
                    title: error?.response?.data?.Error,
                    icon: "error",
                    dangerMode: true,
                  });
                  // setNotes("");
                  // setUploadImage("");
                  console.error("There was an error!", error);
                  console.log(notes?.length, "Ffff");
                });
            }
          }

          swal({
            title: "Edit comment sucessfully!!",
            icon: "success",
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.error("There was an error!", error);
          swal({
            title: "Something went wrong!",
            icon: "error",
            dangerMode: true,
          });
        });
    }
  };

  return (
    <>
      <div>
        {" "}
        <form method="post">
          <div className="top_input_box2">
            <div className="border_box">
              <div className="left_inputF2">
                <div ref={quillRef} id="unicorn2"></div>
              </div>
            </div>
            <div></div>

            <div className="WhiteBoxAD">
              <button className="buttonEdit" onClick={(e) => addComment(e)}>
                Save
              </button>
              <button
                className="buttonEdit"
                onClick={(e) => setOpenQuillEdit(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CommentEdit;
