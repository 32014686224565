import React, { Component } from "react";

import Leftside from "../../components/Leftside";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { SettingMain } from "../../components/SettingMain";

export default class Setting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawer: false,
    };
  }
  toggleDrawer = () => {
    this.setState({
      drawer: !this.state.drawer,
    });
  };
  render() {
    return (
      <div className="mainwrap">
        <div className=" MobView editMar">
          <AppBar position="static">
            {/* <Toolbar> */}
            <IconButton color="inherit" onClick={this.toggleDrawer}>
              <MenuIcon />
            </IconButton>
            {/* </Toolbar> */}
          </AppBar>
          <Drawer
            open={this.state.drawer}
            onClose={this.toggleDrawer}
            style={{ width: 300 }}
          >
            <div className="left_bar">
              <Leftside />
            </div>
          </Drawer>
          {/* <Leftside /> */}
        </div>
        <div className="left_bar norView">
          <Leftside />
        </div>
        <div className="right_side">
          {/* <EditNoteBox /> */}
          <SettingMain />
        </div>
      </div>
    );
  }
}
