import axios from "axios";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import swal from "sweetalert";
import Parser from "html-react-parser";
import TruncateMarkup from "react-truncate-markup";

import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config";

const Notification = ({ dataReqList, setDataReqList }) => {
  const navigate = useNavigate();

  const handleAcceptNote = (data) => {
    if (data.type == "requestTemplateShell") {
      axios
        .post(
          `${config.base_api_url}/api/AddTemplateShell/${data?._id}`,
          {
            access: data?.access,
            shareWith: data?.shareWith,
            User_main_id: data?.User_main_id,
            id_note: data?.id_note?._id,
            current_userId: data?.current_userId?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          swal({
            title: response?.data?.message,
            icon: "success",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          window.location.reload();
        })
        .catch((error) => {
          swal({
            title: error.response.data.message,
            icon: "error",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          console.error("There was an error!", error);
        });
      axios
        .post(
          `${config.base_api_url}/api/AddAcceptTempRequestNotification`,
          {
            access: data?.access,
            shareWith: data?.shareWith,
            User_main_id: data?.User_main_id,
            id_note: data?.id_note?._id,
            current_userId: data?.current_userId?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((res) => {
          // setDataReqList(response.data?.find_shareWith);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      axios
        .post(
          `${config.base_api_url}/api/AddNoteShell/${data?._id}`,
          {
            access: data?.access,
            shareWith: data?.shareWith,
            User_main_id: data?.User_main_id,
            id_note: data?.id_note?._id,
            current_userId: data?.current_userId?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          swal({
            title: response?.data?.message,
            icon: "success",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          window.location.reload();
        })
        .catch((error) => {
          swal({
            title: error.response.data.message,
            icon: "error",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          console.error("There was an error!", error);
        });
      axios
        .post(
          `${config.base_api_url}/api/AddAcceptRequestNoteNotification`,
          {
            access: data?.access,
            shareWith: data?.shareWith,
            User_main_id: data?.User_main_id,
            id_note: data?.id_note?._id,
            current_userId: data?.current_userId?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((res) => {
          // setDataReqList(response.data?.find_shareWith);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };
  const handleAcceptTag = (data) => {
    axios
      .post(
        `${config.base_api_url}/api/acceptReqTagShellArr`,
        {
          access: data?.access,
          shareWith: data?.shareWith,
          tag: data?.tag,
          ControlBy: data?.controlBy,
          current_userId: data?.current_userId?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
          },
        }
      )
      .then((response) => {
        swal({
          title: response?.data?.message,
          icon: "success",
          // dangerMode: true,
        }).catch((err) => console.log(err));
        window.location.reload();
      })
      .catch((error) => {
        swal({
          title: error.response.data.message,
          icon: "error",
          // dangerMode: true,
        }).catch((err) => console.log(err));
        console.error("There was an error!", error);
      });
    axios
      .post(
        `${config.base_api_url}/api/AddAcceptRequestNoteNotification`,
        {
          access: data?.access,
          shareWith: data?.shareWith,
          User_main_id: data?.User_main_id,
          id_note: data?.id_note?._id,
          current_userId: data?.current_userId?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
          },
        }
      )
      .then((res) => {
        // setDataReqList(response.data?.find_shareWith);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  const handleRedirectUrl = (data) => {
    // id_note;
    console.log(data, "hjftydrtsrtdgh");
    if (data?.id_note?.note_reference?._id != undefined) {
      navigate(`/admin/editnotes/${data?.id_note?.note_reference?._id}`);
    } else {
      navigate(`/admin/editnotes/${data?.id_note?._id}`);
    }
  };
  const handleDeclineNote = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to decline this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your audio has been deleted.", "success");
        axios
          .post(
            `${config.base_api_url}/api/DeclineReqShell/${data?._id}`,
            {
              shareWith: data?.shareWith,
              id_note: data?.id_note?._id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
        if (data.type == "requestTemplateShell") {
          axios
            .post(
              `${config.base_api_url}/api/AddDeclineTempRequestNotification`,
              {
                access: data?.access,
                shareWith: data?.shareWith,
                User_main_id: data?.User_main_id,
                id_note: data?.id_note?._id,
                current_userId: data?.current_userId?._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((res) => {
              // setDataReqList(response.data?.find_shareWith);
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        } else {
          axios
            .post(
              `${config.base_api_url}/api/AddDeclineRequestNoteNotification`,
              {
                access: data?.access,
                shareWith: data?.shareWith,
                User_main_id: data?.User_main_id,
                id_note: data?.id_note?._id,
                current_userId: data?.current_userId?._id,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "firebasetoken"
                  )}`,
                },
              }
            )
            .then((res) => {
              // setDataReqList(response.data?.find_shareWith);
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        }
      }
    });
  };
  const handleDeleteNotification = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this notification!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your notification has been deleted.", "success");
        axios
          .get(`${config.base_api_url}/api/DeleteNotification/${data?._id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          })
          .then((response) => {
            window.location.reload();
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }
    });
  };
  const handleDeclineTag = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to decline this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your tag has been deleted.", "success");
        axios
          .post(
            `${config.base_api_url}/api/deleteReqTagShellArr`,
            {
              shareWith: data?.shareWith,
              tag: data?.tag,
              ControlBy: data?.controlBy,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
        axios
          .post(
            `${config.base_api_url}/api/AddDeclineRequestNoteNotification`,
            {
              access: data?.access,
              shareWith: data?.shareWith,
              User_main_id: data?.User_main_id,
              id_note: data?.id_note?._id,
              current_userId: data?.current_userId?._id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((res) => {
            // setDataReqList(response.data?.find_shareWith);
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }
    });
  };
  return (
    <div>
      {/* <h4>Notification</h4> */}
      <div className="WhiteBoxNotiMain">
        {dataReqList.length != 0 &&
          dataReqList.map((data, i) => {
            return (
              <div className="WhiteBoxNotiSub" key={i}>
                {data.type == "requestShell" && (
                  <p className="darkFont">A Note Shared</p>
                )}
                {data.type == "RequestTagShell" && (
                  <p className="darkFont">A Tag Shared</p>
                )}
                {data.type == "requestTemplateShell" && (
                  <p className="darkFont">A Template Shared</p>
                )}
                {data.type == "AcceptRequestShell" && (
                  <div className="flex" style={{ alignItems: "normal" }}>
                    <p className="darkFont">
                      Your shared note has been accepted
                    </p>
                    {data?.id_note?._id != undefined && (
                      <span className="ViewUrl">
                        {/* View{" "} */}
                        <i
                          class="fa-solid fa-arrow-up-right-from-square"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRedirectUrl(data)}
                        ></i>
                      </span>
                    )}
                  </div>
                )}
                {data.type == "AcceptTempRequestShell" && (
                  <div className="flex" style={{ alignItems: "normal" }}>
                    <p className="darkFont">
                      Your shared template has been accepted
                    </p>
                    {/* {data?.id_note?._id != undefined && (
                      <span className="ViewUrl">
                        <i
                          class="fa-solid fa-arrow-up-right-from-square"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRedirectUrl(data)}
                        ></i>
                      </span>
                    )} */}
                  </div>
                )}
                {data.type == "AcceptTagRequestShell" && (
                  <div className="flex" style={{ alignItems: "normal" }}>
                    <p className="darkFont">
                      Your shared tag has been accepted
                    </p>
                  </div>
                )}
                {data.type == "UpdateNoteShell" && (
                  <div className="flex" style={{ alignItems: "normal" }}>
                    <p className="darkFont">
                      Your shared note has been Updated
                    </p>
                    {data?.id_note?._id != undefined && (
                      <span className="ViewUrl">
                        {/* View{" "} */}
                        <i
                          class="fa-solid fa-arrow-up-right-from-square"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRedirectUrl(data)}
                        ></i>
                      </span>
                    )}
                  </div>
                )}
                {data.type == "DeclineRequestShell" && (
                  <div className="flex" style={{ alignItems: "normal" }}>
                    <p className="darkFont">
                      Your shared note has been declined
                    </p>
                    {data?.id_note?._id != undefined && (
                      <span className="ViewUrl">
                        {/* View{" "} */}
                        <i
                          class="fa-solid fa-arrow-up-right-from-square"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRedirectUrl(data)}
                        ></i>
                      </span>
                    )}
                  </div>
                )}
                {data.type == "DeclineTempRequestShell" && (
                  <div className="flex" style={{ alignItems: "normal" }}>
                    <p className="darkFont">
                      Your shared template has been declined
                    </p>
                    {data?.id_note?._id != undefined && (
                      <span className="ViewUrl">
                        {/* View{" "} */}
                        <i
                          class="fa-solid fa-arrow-up-right-from-square"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRedirectUrl(data)}
                        ></i>
                      </span>
                    )}
                  </div>
                )}
                {data.type == "DeclineTagRequestShell" && (
                  <div className="flex" style={{ alignItems: "normal" }}>
                    <p className="darkFont">
                      Your shared tag has been declined
                    </p>
                  </div>
                )}
                <p>
                  On{" "}
                  <Moment format="D MMM YYYY hh:mm" withTitle toNow diff>
                    {data?.createdAt}
                  </Moment>
                </p>
                {(data.type == "AcceptRequestShell" ||
                  data.type == "DeclineRequestShell" ||
                  data.type == "DeclineTempRequestShell" ||
                  data.type == "AcceptTempRequestShell" ||
                  data.type == "UpdateNoteShell") && (
                  <TruncateMarkup
                    lines={10}
                    ellipsis={
                      <>
                        <br />
                        <br />

                        <button
                          className="ReadMore"
                          onClick={() => handleRedirectUrl(data)}
                        >
                          Read More
                        </button>
                      </>
                    }
                  >
                    <p
                      className="ql-editor"
                      style={{ padding: "0px 0px 0 !important" }}
                    >
                      {" "}
                      {data?.id_note?.description != undefined
                        ? Parser(data?.id_note?.description)
                        : data?.id_note?.note_reference != undefined
                        ? Parser(data?.id_note?.note_reference?.description)
                        : "No longer this note exist"}
                      {/* {Parser(data?.id_note?.description)} */}
                    </p>
                  </TruncateMarkup>
                )}
                {(data.type == "AcceptRequestShell" ||
                  data.type == "DeclineTempRequestShell" ||
                  data.type == "AcceptTempRequestShell" ||
                  data.type == "DeclineRequestShell") && (
                  <p>
                    By {data?.shareWith?.fname} {data?.shareWith?.lname}
                  </p>
                )}
                {(data.type == "requestShell" ||
                  data.type == "requestTemplateShell") && (
                  <p>
                    By {data?.current_userId?.fname}{" "}
                    {data?.current_userId?.lname}
                  </p>
                )}

                {data.type == "RequestTagShell" && (
                  <p>
                    By {data?.current_userId?.fname}{" "}
                    {data?.current_userId?.lname}
                  </p>
                )}
                {data.type == "UpdateNoteShell" && (
                  <p>
                    By {data?.current_userId?.fname}{" "}
                    {data?.current_userId?.lname}
                  </p>
                )}
                {data.type == "RequestTagShell" && (
                  <div className="WhiteBoxAD">
                    <button onClick={() => handleAcceptTag(data)}>
                      Accept
                    </button>
                    <button onClick={() => handleDeclineNote(data)}>
                      Decline
                    </button>
                  </div>
                )}
                {(data.type == "requestShell" ||
                  data.type == "requestTemplateShell") && (
                  <div className="WhiteBoxAD">
                    <button onClick={() => handleAcceptNote(data)}>
                      Accept
                    </button>
                    <button onClick={() => handleDeclineNote(data)}>
                      Decline
                    </button>
                  </div>
                )}
                {(data.type == "AcceptRequestShell" ||
                  data.type == "DeclineRequestShell" ||
                  data.type == "UpdateNoteShell") && (
                  <p
                    className="CancelNotifi"
                    onClick={() => handleDeleteNotification(data)}
                  >
                    <i
                      class="fa-solid fa-rectangle-xmark"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </p>
                )}
              </div>
            );
          })}
        {dataReqList.length == 0 && (
          <div>You currently have no notifications.</div>
        )}
      </div>
    </div>
  );
};

export default Notification;
