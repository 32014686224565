import axios from "axios";
import React, { useEffect, useState } from "react";
import TruncateMarkup from "react-truncate-markup";
import Parser from "html-react-parser";
import Menu from "@material-ui/core/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import Dropdown from "react-dropdown";
import Modal from "@material-ui/core/Modal";
import swal from "sweetalert";

import MenuItem from "@material-ui/core/MenuItem";
import Swal from "sweetalert2";
import { fetchExtreamApi } from "../../utils/fetchExtreamApi";
import config from "../../config";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "16px",
  transform: "translate(-50%, -50%)",
  border: "none",
  // width: 468, // remove this workkkkkk
  bgcolor: "background.paper",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: "0",
};
const Templates = () => {
  const navigate = useNavigate();

  const [templateList, setTemplateList] = useState([]);
  const [templateListNew, setTemplateListNew] = useState([]);
  const [shareWith, setShareWith] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [open2, setOpen2] = React.useState(false);
  const [typedValue, setTypedValue] = useState("");
  const [userEmail, setUserEmail] = useState([]);
  const [accessValue, setAccessValue] = useState("View");
  const [sharedNoteUser, setSharedNoteUser] = useState([]);
  const [accessValueEdit, setAccessValueEdit] = useState("");
  const [accessValueEdit1, setAccessValueEdit1] = useState("");
  const [accessValueEdit2, setAccessValueEdit2] = useState("");

  const [editId, setEditId] = useState("");
  const [editId1, setEditId1] = useState("");
  const [editId2, setEditId2] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tempItem, setTempItem] = React.useState({});
  const [searchValue, setSearchValue] = React.useState("");
  const options1 = ["View", "Edit", "Admin"];
  const defaultOption = options1[0];

  const open = Boolean(anchorEl);
  useEffect(() => {
    const fetchNote = async () => {
      try {
        let url1 = `${config.base_api_url}/api/getAllTemplate`;
        let method1 = "GET";
        let response = await fetchExtreamApi(url1, method1);
        setTemplateList(response);
        setTemplateListNew(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchNote();
  }, []);

  const handleClick = (event, item, type) => {
    if (type == "templateShell") {
      let items = item.note_reference;
      items.type = "templateShell";
      items.accessRight = item.permission;
      setTempItem(items);
      setAnchorEl(event.currentTarget);
    } else {
      setTempItem(item);
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose2 = () => setOpen2(false);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleTempExecute = (e, tempItem) => {
    setAnchorEl(null);
    if (tempItem.type == "templateShell") {
      navigate("/admin", {
        state: {
          templateName: tempItem?.tempName,
          edit: false,
          tempType: "templateShell",
        },
      });
    } else {
      navigate("/admin", {
        state: { templateName: tempItem?.tempName, edit: false },
      });
    }
  };
  const handleTempEdit = (e, tempItem) => {
    setAnchorEl(null);

    if (tempItem.type == "templateShell") {
      navigate("/admin", {
        state: {
          templateName: tempItem?.tempName,
          edit: true,
          tempType: "templateShell",
        },
      });
    } else {
      navigate("/admin", {
        state: { templateName: tempItem?.tempName, edit: true },
      });
    }
  };
  const handleTempDelete = (e, tempItem) => {
    setAnchorEl(null);
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this template!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your template has been deleted.", "success");
        axios
          .post(
            `${config.base_api_url}/api/deleteTemplate`,
            { temp_id: tempItem._id },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchValue(e.target.value);
    let value = e.target.value;
    const newListTemp = templateListNew;
    if (value != "") {
      let filteredData = newListTemp?.filter(
        (items) => {
          if (items?.note_reference != undefined) {
            return items.note_reference.tempName
              .toLowerCase()
              .includes(value.toLowerCase());
          } else {
            return items.tempName.toLowerCase().includes(value.toLowerCase());
          }
        }
        // items.tempName.toLowerCase().includes(value.toLowerCase())
      );
      setTemplateList(filteredData);
    } else {
      setTemplateList(newListTemp);
    }
  };
  const handleOnChange = (selected, { inputValue }) => {
    setSelectedOption(selected.label);
    setShareWith(selected);
    // setTypedValue(inputValue);
  };
  const handleOpen = () => {
    const fetchNote = async () => {
      try {
        let url1 = `${config.base_api_url}/api/sharedNoteUsers`;
        let method1 = "GET";
        let response = await fetchExtreamApi(url1, method1);
        if (response?.sharedNote) {
          const uniqueObjects = [];
          const idSet = new Set();
          let arr = response?.sharedNote;
          for (const obj of arr) {
            if (!idSet.has(obj._id)) {
              uniqueObjects.push(obj);
              idSet.add(obj._id);
            }
          }

          setSharedNoteUser(uniqueObjects);
        }
      } catch (error) {
        // console.error(error.message);
      }
    };
    fetchNote();
    setOpen2(true);
  };
  const customFilter = (option, searchText) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,}$/;
    if (option.data.label == searchText) {
      return true;
    } else {
      if (
        option.data.label.toLowerCase().startsWith(searchText.toLowerCase())
      ) {
        return true;
      } else {
        if (re.test(searchText.toLowerCase())) {
          let bodyValue = { email: searchText };
          let url1 = `${config.base_api_url}/auth/userDetailBySearch`;
          let method1 = "POST";
          (async () => {
            let response = await fetchExtreamApi(url1, method1, bodyValue);
            let len = sharedNoteUser.length - 1;
            if (
              response?.length == 0 &&
              sharedNoteUser[len]?.email.toLowerCase() !=
                searchText.toLowerCase()
            ) {
              let arr = sharedNoteUser;
              arr.push({
                email: searchText,
                _id: 0,
              });
              for (let data1 of arr) {
                if (data1?.email.toLowerCase() == searchText.toLowerCase()) {
                  option.data.label = searchText;
                  option.data.value = 0;
                  setShareWith(option.data);
                  return true;
                }
              }
            } else if (
              response?.length != 0 &&
              sharedNoteUser[len]?.email.toLowerCase() !=
                searchText.toLowerCase()
            ) {
              let arr = sharedNoteUser;
              arr.push({
                email: response[0]?.email,
                _id: response[0]?._id,
              });
              for (let data1 of arr) {
                if (data1?.email.toLowerCase() == searchText.toLowerCase()) {
                  option.data.label = response[0]?.email;
                  option.data.value = response[0]?._id;
                  setShareWith(response[0]);
                  return true;
                }
              }
            } else {
              return false;
            }
          })();
        } else {
          return false;
        }
      }
    }
  };
  const handleSubmitShare = (e) => {
    e.preventDefault();
    // === here
    if (shareWith?.value == 0 && accessValue != "") {
      axios
        .post(
          `${config.base_api_url}/api/SendMailToSignup`,
          {
            email: shareWith?.label,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          swal({
            title: response.data.message,
            icon: "success",
          });

          window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error!", error);
          swal({
            title: error?.response?.data?.message,
            icon: "error",
          });
        });
      axios
        .post(
          `${config.base_api_url}/api/AddPreRequestTemplate/${tempItem._id}`,
          {
            access: accessValue,
            User_main_id: tempItem?.user,
            shareWith_email: shareWith?.label,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          swal({
            title: response.data.message,
            icon: "success",
          });
          setOpen2(false);
          setShareWith("");
          window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error!", error);
          swal({
            title: error?.response?.data?.message,
            icon: "error",
          });
          setOpen2(false);
          setShareWith("");
          // window.location.reload();
        });
    } else {
      if (accessValue != "" && shareWith != "") {
        axios
          .post(
            `${config.base_api_url}/api/AddRequestTemplate/${tempItem._id}`,
            {
              shareWith: shareWith.value,
              access: accessValue,
              User_main_id: tempItem?.user,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            swal({
              title: response.data.message,
              icon: "success",
            });
            setOpen2(false);
            setShareWith("");
            window.location.reload();
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: error?.response?.data?.message,
              icon: "error",
            });
            setOpen2(false);
            setShareWith("");
            // window.location.reload();
          });
      } else {
        swal({
          title: "Please first fill all the fields",
          icon: "error",
        });
      }
    }
  };
  const handleDelete_shareWith = (data) => {
    // herrere
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "User removed from share list.", "success");
        axios
          .post(
            `${config.base_api_url}/api/DeleteNoteShell/${data?.note_id_user}`,
            {
              shareWith_userId: data?._id,
              main_id: tempItem._id,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const handleSubmitSaveUpdate = (data) => {
    if (accessValueEdit != "") {
      axios
        .post(
          `${config.base_api_url}/api/UpdateNoteShell/${data?.note_id_user}`,
          {
            shareWith_userId: data?._id,
            main_id: tempItem._id,
            accessRight: accessValueEdit,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          window.location.reload();
          swal({
            title: "Successfully Updated",
            icon: "success",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          // navigate("/admin");
        });
    } else {
      swal({
        title: "Please first change access",
        icon: "error",
        // dangerMode: true,
      });
    }
  };
  const handleDelete_ReqWith = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this user from request list!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "User removed from request list.", "success");
        axios
          .post(
            `${config.base_api_url}/api/DeleteReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: tempItem._id,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const handleSubmitSaveUpdateRequestUser = (data) => {
    if (accessValueEdit1 != "") {
      axios
        .post(
          `${config.base_api_url}/api/UpdateReqShell/${data?.req_id}`,
          {
            shareWith_userId: data?._id,
            main_id: tempItem._id,
            accessRight: accessValueEdit1,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          window.location.reload();
          swal({
            title: "Successfully Updated",
            icon: "success",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          // navigate("/admin");
        });
    } else {
      swal({
        title: "Please first change access",
        icon: "error",
        // dangerMode: true,
      });
    }
  };
  const handleDelete_NonExistReqWith = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove thus user from non-exist request list!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Deleted!",
          "This user removed from non-exist request list.",
          "success"
        );
        axios
          .post(
            `${config.base_api_url}/api/DeleteNonExistReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: tempItem._id,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const handleSubmitSaveUpdateNonExistRequestUser = (data) => {
    if (accessValueEdit2 != "") {
      axios
        .post(
          `${config.base_api_url}/api/UpdateNonExistReqShell/${data?.req_id}`,
          {
            shareWith_userId: data?._id,
            main_id: tempItem._id,
            accessRight: accessValueEdit2,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          window.location.reload();
          swal({
            title: "Successfully Updated",
            icon: "success",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          // navigate("/admin");
        });
    } else {
      swal({
        title: "Please first change access",
        icon: "error",
        // dangerMode: true,
      });
    }
  };
  return (
    <div className="container templStyle">
      <div className="mb-3">
        <h2>Template</h2>
      </div>
      <div className="search_box">
        <div className="search_box_div">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            defaultValue=""
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleChangeSearch(e)}
          />
        </div>
      </div>
      <div className="card-deck mb-3 text-center">
        {templateList?.map((item) => (
          <>
            {item?.note_reference?._id != undefined ? (
              <div className="card mb-4 box-shadow">
                <div className="card-header">
                  <div
                    className="settingTempl"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(event) => handleClick(event, item, item.type)}
                  >
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <h4 className="my-0 font-weight-normal">
                    {item?.note_reference?.tempName}
                  </h4>
                </div>
                <div className="card-body">
                  <div>
                    {" "}
                    &nbsp;&nbsp;&nbsp;
                    <i
                      class="fa-solid fa-users"
                      style={{ color: "#7CBB4B" }}
                    ></i>
                    &nbsp;Shared by : @{item?.sharedBy?.fname}
                  </div>
                  <TruncateMarkup
                    lines={15}
                    ellipsis={
                      <>
                        <br />
                        <br />

                        <button className="ReadMore">...Read More</button>
                      </>
                    }
                  >
                    <p className="ql-editor">
                      {" "}
                      {Parser(item?.note_reference?.description)}
                    </p>
                  </TruncateMarkup>
                </div>
              </div>
            ) : (
              <div className="card mb-4 box-shadow">
                <div className="card-header">
                  <div
                    className="settingTempl"
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(event) => handleClick(event, item, item.type)}
                  >
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <h4 className="my-0 font-weight-normal">{item?.tempName}</h4>
                </div>
                <div className="card-body">
                  <TruncateMarkup
                    lines={15}
                    ellipsis={
                      <>
                        <br />
                        <br />

                        <button className="ReadMore">...Read More</button>
                      </>
                    }
                  >
                    <p className="ql-editor"> {Parser(item?.description)}</p>
                  </TruncateMarkup>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {(tempItem?.accessRight != "View" || tempItem?.type == "template") && (
          <MenuItem onClick={(e) => handleTempEdit(e, tempItem)}>Edit</MenuItem>
        )}

        <MenuItem onClick={(e) => handleTempExecute(e, tempItem)}>
          Use Template
        </MenuItem>
        {(tempItem?.accessRight == "Admin" || tempItem?.type == "template") && (
          <MenuItem onClick={handleOpen}>Share</MenuItem>
        )}
        {(tempItem?.accessRight == "Admin" || tempItem?.type == "template") && (
          <MenuItem onClick={(e) => handleTempDelete(e, tempItem)}>
            Delete
          </MenuItem>
        )}
      </Menu>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="share_modal">
          <div style={{ marginLeft: "94%" }}>
            <CloseIcon
              style={{ color: "#6F6F6F", cursor: "pointer", fontSize: "3rem" }}
              onClick={handleClose2}
            />
          </div>
          <div className="share_div">SHARE NOTE</div>
          {shareWith?.value == 0 && (
            <div style={{ color: "red" }}>
              This email doesn't exist(Invite user with a mail).
            </div>
          )}
          {/* {shareWith?.value == undefined &&
                shareWith?.label != undefined && (
                  <div>This user doesn't exist</div>
                )} */}
          <div className="share_two_dd">
            <Select
              // defaultValue={selectedOption}
              // onChange={setSelectedOption}
              inputValue={selectedOption != null ? null : typedValue}
              onInputChange={(inputValue, { action }) => {
                if (inputValue?.length != 0) {
                  setTypedValue(inputValue);
                  setSelectedOption(null);
                  setShareWith("");
                } else if (action === "input-change") {
                  setTypedValue(inputValue);
                  setSelectedOption(null);
                  setShareWith("");
                }
              }}
              placeholder="Search user"
              options={sharedNoteUser.map(({ _id, email }) => ({
                value: _id,
                label: email.toLowerCase(),
              }))}
              onChange={handleOnChange}
              isSearchable={true}
              filterOption={customFilter}
              // getOptionLabel={getOptionLabel}
            />
            {/* {shareWith?.value != 0 && ( */}
            <Dropdown
              options={options1}
              className="ddShareNote"
              // onChange={_onSelect}
              value={defaultOption}
              onChange={(value) => setAccessValue(value.value)}
              // placeholder=""
            />
            {/* )} */}

            {/* )} */}
          </div>
          <div className="top_input_box_green">
            <button onClick={(e) => handleSubmitShare(e)}>SHARE</button>
            <div className="mainDivScrollShare">
              {tempItem?.sharedWith?.length != 0 && (
                <div className="share_div2">SHARED WITH</div>
              )}
              {tempItem?.sharedWith != undefined &&
                tempItem?.sharedWith.length != 0 &&
                tempItem?.sharedWith.map((data) => {
                  return (
                    <>
                      <div className="share_two_dd">
                        <input
                          type="text"
                          value={data?.user_id?.email}
                          className="input_SharedEmail"
                        />

                        <Dropdown
                          options={options1}
                          // onChange={_onSelect}
                          value={
                            accessValueEdit != "" && editId == data?._id
                              ? accessValueEdit
                              : data?.accessRight
                          }
                          onChange={(value) => {
                            setAccessValueEdit(value.value);
                            setEditId(data?._id);
                          }}
                          // placeholder=""
                        />
                        <div
                          style={{
                            cursor: "pointer",
                            border: "2px solid #dce0e8",
                            height: "46px",
                            width: "50px",
                            display: "grid",
                            borderRadius: "5px",
                            placeItems: "center",
                          }}
                        >
                          <CloseIcon
                            style={{
                              color: "#6F6F6F",

                              height: "70%",
                              width: "70%",
                            }}
                            onClick={() => handleDelete_shareWith(data)}
                          />
                        </div>
                      </div>

                      {editId == data?._id && (
                        <div style={{ display: "flex", gap: "2%" }}>
                          <button
                            onClick={(e) => handleSubmitSaveUpdate(data, e)}
                          >
                            SAVE
                          </button>
                          <button onClick={(e) => setEditId("")}>cancel</button>
                        </div>
                      )}
                    </>
                  );
                })}
              {tempItem?.Request_sharedWith?.length != 0 && (
                <div className="share_div2">REQUESTED WITH</div>
              )}

              {tempItem?.Request_sharedWith?.length != 0 &&
                tempItem?.Request_sharedWith != undefined &&
                tempItem?.Request_sharedWith.map((data) => {
                  return (
                    <>
                      <div className="share_two_dd">
                        <input
                          type="text"
                          value={data?.user_id?.email}
                          className="input_SharedEmail"
                        />

                        <Dropdown
                          options={options1}
                          value={
                            accessValueEdit1 != "" && editId1 == data?._id
                              ? accessValueEdit1
                              : data?.accessRight
                          }
                          onChange={(value) => {
                            setAccessValueEdit1(value.value);
                            setEditId1(data?._id);
                          }}
                        />
                        <div
                          style={{
                            cursor: "pointer",
                            border: "2px solid #dce0e8",
                            height: "46px",
                            width: "50px",
                            display: "grid",
                            borderRadius: "5px",
                            placeItems: "center",
                          }}
                        >
                          <CloseIcon
                            style={{
                              color: "#6F6F6F",

                              height: "70%",
                              width: "70%",
                            }}
                            onClick={() => handleDelete_ReqWith(data)}
                          />
                        </div>
                      </div>

                      {editId1 == data?._id && (
                        <div style={{ display: "flex", gap: "2%" }}>
                          <button
                            style={
                              editId1 == data?._id
                                ? { diplay: "block" }
                                : { diplay: "none" }
                            }
                            onClick={(e) =>
                              handleSubmitSaveUpdateRequestUser(data, e)
                            }
                          >
                            SAVE
                          </button>
                          <button
                            style={
                              editId1 == data?._id
                                ? { diplay: "block" }
                                : { diplay: "none" }
                            }
                            onClick={(e) => setEditId1("")}
                          >
                            cancel
                          </button>
                        </div>
                      )}
                    </>
                  );
                })}
              {tempItem?.Request_sharedWithUserNotExist?.length != 0 && (
                <div className="share_div2">REQUESTED WITH NON EXIST USER</div>
              )}

              {tempItem?.Request_sharedWithUserNotExist?.length != 0 &&
                tempItem?.Request_sharedWithUserNotExist != undefined &&
                tempItem?.Request_sharedWithUserNotExist.map((data) => {
                  return (
                    <>
                      <div className="share_two_dd">
                        <input
                          type="text"
                          value={data?.user_Email}
                          className="input_SharedEmail"
                        />

                        <Dropdown
                          options={options1}
                          value={
                            accessValueEdit2 != "" && editId2 == data?._id
                              ? accessValueEdit2
                              : data?.accessRight
                          }
                          onChange={(value) => {
                            setAccessValueEdit2(value.value);
                            setEditId2(data?._id);
                          }}
                        />
                        <div
                          style={{
                            cursor: "pointer",
                            border: "2px solid #dce0e8",
                            height: "46px",
                            width: "50px",
                            display: "grid",
                            borderRadius: "5px",
                            placeItems: "center",
                          }}
                        >
                          <CloseIcon
                            style={{
                              color: "#6F6F6F",

                              height: "70%",
                              width: "70%",
                            }}
                            onClick={() => handleDelete_NonExistReqWith(data)}
                          />
                        </div>
                      </div>

                      {editId2 == data?._id && (
                        <div style={{ display: "flex", gap: "2%" }}>
                          <button
                            style={
                              editId2 == data?._id
                                ? { diplay: "block" }
                                : { diplay: "none" }
                            }
                            onClick={(e) =>
                              handleSubmitSaveUpdateNonExistRequestUser(data, e)
                            }
                          >
                            SAVE
                          </button>
                          <button
                            style={
                              editId2 == data?._id
                                ? { diplay: "block" }
                                : { diplay: "none" }
                            }
                            onClick={(e) => setEditId2("")}
                          >
                            cancel
                          </button>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Templates;
