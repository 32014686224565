import React, { useEffect, useState } from "react";
import { Async } from "../Async";
import { get } from "../getItemLength";
import { useNavigate } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { Link } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import "react-select-search/style.css";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { ShareWithPerD } from "./ShareWithPerD";
import { fetchExtreamApi } from "../fetchExtreamApi";
import config from "../../config";
const options1 = ["View", "Edit", "Admin"];
const defaultOption = options1[0];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "16px",
  transform: "translate(-50%, -50%)",
  // width: 468,
  bgcolor: "background.paper",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: "0",
};
const DynamicTagSub = ({ menuData, title, d, t }) => {
  const [tagShareWith, setTagShareWith] = useState([]);
  const [tagArr, setTagArr] = React.useState(false);
  const [tagTitle, setTagTitle] = useState([]);
  const [tagControl_id, setTagControl_id] = useState(undefined);
  const [open2, setOpen2] = React.useState(false);
  const [editId, setEditId] = useState("");
  const [accessValueEdit1, setAccessValueEdit1] = useState("");
  const [editId1, setEditId1] = useState("");
  const [editId2, setEditId2] = useState("");
  const [accessValueEdit2, setAccessValueEdit2] = useState("");
  const [tagShareWith1, setTagShareWith1] = useState([]);
  const [userEmail, setUserEmail] = useState([]);
  const [shareWith, setShareWith] = useState("");
  const [accessValue, setAccessValue] = useState("View");
  const [accessValueEdit, setAccessValueEdit] = useState("");
  const [typedValue, setTypedValue] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);
  const handleClose = () => setOpen2(false);
  const customFilter = (option, searchText) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,}$/;
    if (option.data.label == searchText) {
      return true;
    } else {
      if (
        option.data.label.toLowerCase().startsWith(searchText.toLowerCase())
      ) {
        return true;
      } else {
        if (re.test(searchText.toLowerCase())) {
          let bodyValue = { email: searchText };
          let url1 = `${config.base_api_url}/auth/userDetailBySearch`;
          let method1 = "POST";
          (async () => {
            let response = await fetchExtreamApi(url1, method1, bodyValue);
            let len = tagShareWith1.length - 1;
            if (
              response?.length == 0 &&
              tagShareWith1[len]?.email.toLowerCase() !=
                searchText.toLowerCase()
            ) {
              let arr = tagShareWith1;
              arr.push({
                email: searchText,
                _id: 0,
              });
              for (let data1 of arr) {
                if (data1?.email.toLowerCase() == searchText.toLowerCase()) {
                  option.data.label = searchText;
                  option.data.value = 0;
                  setShareWith(option.data);
                  return true;
                }
              }
            } else if (
              response?.length != 0 &&
              tagShareWith1[len]?.email.toLowerCase() !=
                searchText.toLowerCase()
            ) {
              let arr = tagShareWith1;
              arr.push({
                email: response[0]?.email,
                _id: response[0]?._id,
              });
              for (let data1 of arr) {
                if (data1?.email.toLowerCase() == searchText.toLowerCase()) {
                  option.data.label = response[0]?.email;
                  option.data.value = response[0]?._id;
                  setShareWith(response[0]);
                  return true;
                }
              }
            } else {
              return false;
            }
          })();
        } else {
          return false;
        }
      }
    }
  };
  const handleOnChange = (selected, { inputValue }) => {
    setSelectedOption(selected.label);
    setShareWith(selected);
    // setTypedValue(inputValue);
  };
  const handleSubmitSaveUpdate = (data) => {
    axios
      .post(
        `${config.base_api_url}/api/UpdateTagShell`,
        {
          shareWith_userId: data?._id,
          note_id_user: data?.note_id_user,
          accessRight: accessValueEdit,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
          },
        }
      )
      .then((response) => {
        window.location.reload();
        swal({
          title: "Successfully Updated",
          icon: "success",
          // dangerMode: true,
        }).catch((err) => console.log(err));
        // navigate("/admin");
      });
  };
  const handleDelete_shareWith = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");
        axios
          .post(
            `${config.base_api_url}/api/deleteTagShell`,
            {
              shareWith_userId: data?._id,
              note_id_user: data?.note_id_user,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const handleDelete_ReqWith = (data, tagShareWith) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");

        axios
          .post(
            `${config.base_api_url}/api/DeleteReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: tagShareWith._id,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const handleSubmitShare = ({ e, d }) => {
    e.preventDefault();

    let atAdd = tagTitle.indexOf("#");
    var msg = tagTitle.slice(atAdd).replace(/ *\([^)]*\) */g, "");
    // tagTitle = msg;
    // .replace(/ *\([^)]*\) */g, "");

    if (
      msg != undefined &&
      accessValue != "" &&
      shareWith?.value != undefined
    ) {
      if (tagControl_id != undefined) {
        axios
          .post(
            `${config.base_api_url}/api/addTagRequestShellArr`,
            {
              shareWith: shareWith.value,
              access: accessValue,
              tag: msg,
              ControlBy: tagControl_id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            swal({
              title: response.data.message,
              icon: "success",
            });
            setOpen2(false);
            window.location.reload();
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: "This note is already shared with this user",
              icon: "error",
            });
            setOpen2(false);
            setShareWith("");
            // window.location.reload();
          });
      } else {
        axios
          .post(
            `${config.base_api_url}/api/addTagRequestShellArr`,
            {
              shareWith: shareWith.value,
              access: accessValue,
              tag: msg,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            swal({
              title: response.data.message,
              icon: "success",
            });
            setOpen2(false);
            window.location.reload();
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: "This note is already shared with this user",
              icon: "error",
            });
            setOpen2(false);
            setShareWith("");
            // window.location.reload();
          });
      }
    } else {
      swal({
        title: "Please first fill all the fields",
        icon: "error",
      });
    }
  };
  const handleSubmitSaveUpdateRequestUser = (data, tagShareWith) => {
    if (accessValueEdit1 != "") {
      axios
        .post(
          `${config.base_api_url}/api/UpdateReqShell/${data?.req_id}`,
          {
            shareWith_userId: data?._id,
            main_id: tagShareWith._id,
            accessRight: accessValueEdit1,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          window.location.reload();
          swal({
            title: "Successfully Updated",
            icon: "success",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          // navigate("/admin");
        });
    } else {
      swal({
        title: "Please first change access",
        icon: "error",
        // dangerMode: true,
      });
    }
  };
  const handleSubmitSaveUpdateNonExistRequestUser = (data, tagShareWith) => {
    if (accessValueEdit2 != "") {
      axios
        .post(
          `${config.base_api_url}/api/UpdateTagNonExistUserReqShell/${data?.req_id}`,
          {
            shareWith_userId: data?._id,
            main_id: tagShareWith._id,
            accessRight: accessValueEdit2,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
            },
          }
        )
        .then((response) => {
          window.location.reload();
          swal({
            title: "Successfully Updated",
            icon: "success",
            // dangerMode: true,
          }).catch((err) => console.log(err));
          // navigate("/admin");
        });
    } else {
      swal({
        title: "Please first change access",
        icon: "error",
        // dangerMode: true,
      });
    }
  };
  const handleDelete_NonExistReqWith = (data, tagShareWith) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your note has been deleted.", "success");

        axios
          .post(
            `${config.base_api_url}/api/DeleteNonExistUserReqShell/${data?.req_id}`,
            {
              shareWith_userId: data?._id,
              main_id: tagShareWith._id,
            },

            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
      }
    });
  };
  const handleOpen = () => setOpen2(true);

  const navigate = useNavigate();

  // Collapse all sibling panels
  function collapseSiblings(element) {
    let sibling = element.parentElement.firstElementChild;

    while (sibling) {
      if (sibling !== element && sibling.classList.contains("panel")) {
        sibling.style.display = "none";
        if (sibling.previousElementSibling.querySelector(".sign") != null) {
          sibling.previousElementSibling.querySelector(".sign").textContent =
            "+";
        }
      }
      sibling = sibling.nextElementSibling;
    }
  }

  // Create accordion menu for each group

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url1 = `${config.base_api_url}/api/sharedNoteUsers`;
        let method1 = "GET";
        let res = await fetchExtreamApi(url1, method1);
        const uniqueObjects = [];
        const idSet = new Set();
        let arr = res?.sharedNote;
        for (const obj of arr) {
          if (!idSet.has(obj._id)) {
            uniqueObjects.push(obj);
            idSet.add(obj._id);
          }
        }

        setTagShareWith1(uniqueObjects);
      } catch (error) {
        // console.error(error.message);
      }
    };
    fetchData();

    const groupedMenuData = menuData.reduce((groups, item) => {
      const levels = item.split("#").filter((level) => level !== "");

      let currentGroup = groups;

      levels.forEach((level, index) => {
        let key = levels.slice(0, index + 1).join("#");

        if (item.startsWith("@")) {
          if (key.includes("#") == false) {
            key = levels.slice(0, index + 2).join("#");
          }
          if (!currentGroup[`${key}`]) {
            currentGroup[`${key}`] = {};
          }
          currentGroup = currentGroup[`${key}`];
        } else {
          if (!currentGroup[`#${key}`]) {
            currentGroup[`#${key}`] = {};
          }
          currentGroup = currentGroup[`#${key}`];
        }
      });

      return groups;
    }, {});

    const accordionMenu = document.getElementById(`accordion-menu-${title}`);

    const createAccordionMenu = (menuData, parentElement) => {
      Object.keys(menuData).forEach((key) => {
        const item = menuData[key];

        if (key.match(/#/g).length > 1) {
          const button = document.createElement("button");

          button.className =
            "accordion m-1 p-2 mb-2 bg-light bg-gradient text-dark border border-secondary rounded myDIV2 flex";
          // button.textContent = `${key}`;
          const text = document.createElement("a");
          text.className = "TitleTextSpan";
          let titleKey = key.replace(/ *\([^)]*\) */g, "");
          if (titleKey.includes("@")) {
            let atAdd = titleKey.indexOf("#");
            var msg = titleKey.slice(atAdd);
            titleKey = msg;
          }
          //  navigate("/admin", { state: { dataTitle: titleKey } });
          text.href = `/admin?data-custom-title=${encodeURIComponent(
            titleKey
          )}`;
          text.textContent = `${key}`;
          button.appendChild(text);
          // Add sign and count if item has children

          const sign = document.createElement("span");
          sign.className = "sign";
          sign.textContent = "+";

          const count = document.createElement("span");
          count.className = "count text-dark";
          let arr = [];
          get(key.replace(/ *\([^)]*\) */g, ""), d, t).then((result) => {
            arr.push(result);
            count.textContent = `(${result})`;
            // `(${Object.keys(item).length})`;
            button.appendChild(count);
            if (Object.keys(item).length > 0) {
              button.appendChild(sign);
            }
          });

          function objectKeysToArray(obj, prefix = "") {
            let keys = [];
            for (let key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const fullKey = prefix ? `${key}` : `${key}`;
                keys.push(fullKey);
                if (
                  typeof obj[key] === "object" &&
                  Object.keys(obj[key]).length > 0
                ) {
                  keys = keys.concat(objectKeysToArray(obj[key], fullKey));
                }
              }
            }
            return keys;
          }

          const keysArray = objectKeysToArray(groupedMenuData);

          const value = key.replace(/ *\([^)]*\) */g, "");
          let result = [];

          for (let i = 0; i < keysArray.length; i++) {
            result.push(keysArray[i]);
            if (keysArray[i] === value) {
              break;
            }
          }

          let arr1 = [];
          let title1 = result.reverse();

          for (let checkAllKey of title1) {
            const check = d.find((data) => {
              if (key.replace(/ *\([^)]*\) */g, "").includes("@")) {
                if (
                  key.replace(/ *\([^)]*\) */g, "") ==
                  `@${
                    data?.sharedBy?.fname +
                    data?.sharedBy?.lname +
                    data?.title[0]
                  }`
                ) {
                  return (
                    `@${
                      data?.sharedBy?.fname +
                      data?.sharedBy?.lname +
                      data?.title[0]
                    }`.startsWith(checkAllKey) &&
                    key.replace(/ *\([^)]*\) */g, "").startsWith(checkAllKey) &&
                    data?.tagArr == true &&
                    data?.controlBy?._id == t &&
                    data?.type == "TagShell"
                  );
                }

                // else {
                //   return data?.title.some((titled) => {

                //     return (
                //       `@${
                //         data?.sharedBy?.fname + data?.sharedBy?.lname + titled
                //       }`.startsWith(checkAllKey) &&
                //       key
                //         .replace(/ *\([^)]*\) */g, "")
                //         .startsWith(checkAllKey) &&
                //       data?.tagArr == true &&
                //       data?.controlBy?._id == t &&
                //       data?.type == "TagShell"
                //     );
                //   });
                // }
              }
            });
            arr1.push(check);
          }
          for (let checkAllKey of title1) {
            const check = d.find((data) => {
              if (key.replace(/ *\([^)]*\) */g, "").includes("@")) {
                return data?.title.some((titled) => {
                  return (
                    `@${
                      data?.sharedBy?.fname + data?.sharedBy?.lname + titled
                    }`.startsWith(checkAllKey) &&
                    key.replace(/ *\([^)]*\) */g, "").startsWith(checkAllKey) &&
                    data?.tagArr == true &&
                    data?.controlBy?._id == t &&
                    data?.type == "TagShell"
                  );
                });
              }
            });
            arr1.push(check);
          }

          let arr3 = undefined;
          const totalSharePer = document.createElement("span");
          ShareWithPerD(key.replace(/ *\([^)]*\) */g, ""), d).then((result) => {
            arr3 = result;
            if (result != 0) {
              totalSharePer.className = "sharedPer";

              const iconElement = document.createElement("i");
              iconElement.className = "fa-solid fa-users";
              iconElement.style.color = "#49b3cb";

              const textElement = document.createElement("span");
              textElement.textContent = `Shared with: ${result}`;

              totalSharePer.innerHTML = ""; // Clear existing content
              totalSharePer.appendChild(iconElement);
              totalSharePer.appendChild(textElement);

              button.appendChild(totalSharePer);
            }

            // button.appendChild(sign);
          });

          // const sharePerson =
          const shareLink = document.createElement("p");
          if (key.replace(/ *\([^)]*\) */g, "").includes("@")) {
            const filteredArr = arr1.filter(
              (item) => typeof item !== "undefined"
            );

            if (
              filteredArr[0] != undefined &&
              key
                .replace(/ *\([^)]*\) */g, "")
                .startsWith(
                  `@${
                    filteredArr[0]?.sharedBy?.fname +
                    filteredArr[0]?.sharedBy?.lname +
                    filteredArr[0]?.title[0]
                  }`
                ) &&
              filteredArr[0]?.permission == "Admin"
            ) {
              // arr3.push(arr1?.flat()[0]);
              shareLink.className = "share-link";
              shareLink.textContent = "Share";
              button.appendChild(shareLink);
            }
          } else {
            shareLink.className = "share-link";
            shareLink.textContent = "Share";
            button.appendChild(shareLink);
          }

          parentElement.appendChild(button);
          // Create accordion panel
          const panel = document.createElement("div");
          panel.className = "panel";
          parentElement.appendChild(panel);

          // Add click event listener to accordion button
          button.addEventListener("click", () => {
            const isOpen = panel.style.display === "block";
            collapseSiblings(button);
            panel.style.display = isOpen ? "none" : "block";
            button.querySelector(".sign").textContent = isOpen ? "+" : "-";
          });
          // text content onclick
          // text.addEventListener("click", function (event) {
          //   event.stopPropagation(); // Prevent the event from propagating up to the body element
          //   // alert("Button clicked!");
          //   let titleKey = key.replace(/ *\([^)]*\) */g, "");
          //   if (titleKey.includes("@")) {
          //     let atAdd = titleKey.indexOf("#");
          //     var msg = titleKey.slice(atAdd);
          //     titleKey = msg;
          //   }
          //   navigate("/admin", { state: { dataTitle: titleKey } });
          // });
          shareLink.addEventListener("click", function (event) {
            event.stopPropagation(); // Prevent the event from propagating up to the body element
            // alert("Button clicked!");
            let data = d;
            let titleKey = key.replace(/ *\([^)]*\) */g, "");
            setTagShareWith([]);
            setTagArr(false);
            // let atAdd = title.flat()[0].indexOf("#");\
            var msg = titleKey;
            let index = msg.indexOf("#"); // find the index of the first '#' character
            let result = msg.substring(index); // extract the substring starting from that index
            setTagTitle(msg);
            setTagControl_id(arr1[0]?.controlBy?._id);

            if (arr1[0]?.controlBy?._id != undefined) {
              const fetchData = async () => {
                try {
                  let bodyValue = {
                    tag: result,
                    controlBy: arr1[0]?.controlBy?._id,
                  };
                  let url1 = `${config.base_api_url}/api/GetMasterTagShell`;
                  let method1 = "POST";
                  let response = await fetchExtreamApi(
                    url1,
                    method1,
                    bodyValue
                  );

                  setTagShareWith(response);
                  handleOpen();
                } catch (error) {
                  console.error(error.message);
                }
              };
              fetchData();
            } else {
              const fetchData = async () => {
                try {
                  let bodyValue = {
                    tag: titleKey,
                    controlBy: undefined,
                  };
                  let url1 = `${config.base_api_url}/api/GetMasterTagShell`;
                  let method1 = "POST";
                  let response = await fetchExtreamApi(
                    url1,
                    method1,
                    bodyValue
                  );

                  setTagShareWith(response);

                  handleOpen();
                  //  let arr = [];
                  //  response.map(
                  //    (data) => !arr.includes(data.title) && arr.push(data.title)
                  //  );
                  //  setAllTitle(arr);
                  //  setAllTitle1(arr);
                } catch (error) {
                  console.error(error.message);
                }
              };
              fetchData();
            }
          });
          // Create child accordion menu
          if (Object.keys(item).length > 0) {
            createAccordionMenu(item, panel);
          }
        } else {
          if (Object.keys(item).length != 0) {
            createAccordionMenu(item, parentElement);
          }
        }
        // Create accordion button
      });
    };
    if (accordionMenu?.children?.length != 0) {
      const div = accordionMenu;
      const parentChildren = Array.from(div.children); // Convert HTMLCollection to array
      const uniqueParents = new Map();

      parentChildren.forEach((child) => {
        const parent = child.parentNode;
        const key = parent.getAttribute("data-key") || parent.outerHTML;
        if (!uniqueParents.has(key)) {
          uniqueParents.set(key, parent);
        }
      });

      // Remove all existing child elements from the div
      while (div.firstChild) {
        div.removeChild(div.firstChild);
      }

      // Append the unique parent elements to the div
      uniqueParents.forEach((parent) =>
        div.appendChild(parent.cloneNode(true))
      );
    }
    {
      menuData.length != 0 &&
        Object.keys(groupedMenuData)[0].startsWith(title) &&
        createAccordionMenu(groupedMenuData, accordionMenu);
    }
  }, []);

  return (
    <>
      <div id={`accordion-menu-${title}`}></div>
      <Modal
        open={open2}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className="share_modal">
          <div style={{ marginLeft: "94%" }}>
            <CloseIcon
              style={{ color: "#6F6F6F", cursor: "pointer", fontSize: "3rem" }}
              onClick={handleClose}
            />
          </div>
          <div className="share_div">SHARE TAG</div>
          {shareWith?.value == 0 && (
            <div style={{ color: "red" }}>
              This email doesn't exist(Invite user with a mail).
            </div>
          )}
          <div className="share_tag_cont">
            All notes tagged with this tag will be shared
          </div>
          <div className="share_two_dd">
            <Select
              // defaultValue={selectedOption}
              // onChange={setSelectedOption}
              inputValue={selectedOption != null ? null : typedValue}
              onInputChange={(inputValue, { action }) => {
                if (inputValue.length != 0) {
                  setTypedValue(inputValue);
                  setSelectedOption(null);
                  setShareWith("");
                } else if (action === "input-change") {
                  setTypedValue(inputValue);
                  setSelectedOption(null);
                  setShareWith("");
                }
              }}
              placeholder="Search user"
              options={tagShareWith1.map(({ _id, email }) => ({
                value: _id,
                label: email.toLowerCase(),
              }))}
              onChange={handleOnChange}
              isSearchable={true}
              filterOption={customFilter}
            />

            <Dropdown
              options={options1}
              // onChange={_onSelect}
              value={defaultOption}
              onChange={(value) => setAccessValue(value.value)}
              // placeholder=""
            />
          </div>
          <div className="top_input_box_green">
            <button onClick={(e) => handleSubmitShare({ e, d })}>SHARE</button>

            {tagShareWith[0]?.sharedWith != undefined &&
              tagShareWith[0]?.sharedWith.length != 0 && (
                <div className="share_div2">SHARED WITH</div>
              )}

            {tagShareWith[0]?.sharedWith.map((data) => {
              return (
                <>
                  <div className="share_two_dd">
                    <input
                      type="text"
                      value={data?.user_id?.email}
                      className="input_SharedEmail"
                    />

                    <Dropdown
                      options={options1}
                      // onChange={_onSelect}
                      value={
                        accessValueEdit != "" && editId == data?._id
                          ? accessValueEdit
                          : data?.accessRight
                      }
                      onChange={(value) => {
                        setAccessValueEdit(value.value);
                        setEditId(data?._id);
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        border: "2px solid #dce0e8",
                        height: "46px",
                        width: "50px",
                        display: "grid",
                        borderRadius: "5px",
                        placeItems: "center",
                      }}
                    >
                      <CloseIcon
                        style={{
                          color: "#6F6F6F",

                          height: "70%",
                          width: "70%",
                        }}
                        onClick={() => handleDelete_shareWith(data)}
                      />
                    </div>
                  </div>

                  {editId == data?._id && (
                    <div style={{ display: "flex", gap: "2%" }}>
                      <button onClick={(e) => handleSubmitSaveUpdate(data, e)}>
                        SAVE
                      </button>
                      <button onClick={(e) => setEditId("")}>cancel</button>
                    </div>
                  )}
                </>
              );
            })}
            {tagShareWith[0]?.Request_sharedWith != undefined &&
              tagShareWith[0]?.Request_sharedWith.length != 0 && (
                <div className="share_div2">REQUESTED WITH</div>
              )}
            {tagShareWith[0]?.Request_sharedWith.map((data) => {
              return (
                <>
                  <div className="share_two_dd">
                    <input
                      type="text"
                      value={data?.user_id?.email}
                      className="input_SharedEmail"
                    />

                    <Dropdown
                      options={options1}
                      // onChange={_onSelect}
                      value={
                        accessValueEdit1 != "" && editId1 == data?._id
                          ? accessValueEdit1
                          : data?.accessRight
                      }
                      onChange={(value) => {
                        setAccessValueEdit1(value.value);
                        setEditId1(data?._id);
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        border: "2px solid #dce0e8",
                        height: "46px",
                        width: "50px",
                        display: "grid",
                        borderRadius: "5px",
                        placeItems: "center",
                      }}
                    >
                      <CloseIcon
                        style={{
                          color: "#6F6F6F",

                          height: "70%",
                          width: "70%",
                        }}
                        onClick={() =>
                          handleDelete_ReqWith(data, tagShareWith[0])
                        }
                      />
                    </div>
                  </div>

                  {editId1 == data?._id && (
                    <div style={{ display: "flex", gap: "2%" }}>
                      <button
                        onClick={(e) =>
                          handleSubmitSaveUpdateRequestUser(
                            data,

                            tagShareWith[0]
                          )
                        }
                      >
                        SAVE
                      </button>
                      <button onClick={(e) => setEditId1("")}>cancel</button>
                    </div>
                  )}
                </>
              );
            })}
            {tagShareWith[0]?.Request_sharedWithUserNotExist != undefined &&
              tagShareWith[0]?.Request_sharedWithUserNotExist.length != 0 && (
                <div className="share_div2">REQUESTED WITH NON EXIST USER</div>
              )}

            {tagShareWith[0]?.Request_sharedWithUserNotExist.map((data) => {
              return (
                <>
                  <div className="share_two_dd">
                    <input
                      type="text"
                      value={data?.user_Email}
                      className="input_SharedEmail"
                    />

                    <Dropdown
                      options={options1}
                      // onChange={_onSelect}
                      value={
                        accessValueEdit2 != "" && editId2 == data?._id
                          ? accessValueEdit2
                          : data?.accessRight
                      }
                      onChange={(value) => {
                        setAccessValueEdit2(value.value);
                        setEditId2(data?._id);
                      }}
                      // placeholder=""
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        border: "2px solid #dce0e8",
                        height: "46px",
                        width: "50px",
                        display: "grid",
                        borderRadius: "5px",
                        placeItems: "center",
                      }}
                    >
                      <CloseIcon
                        style={{
                          color: "#6F6F6F",

                          height: "70%",
                          width: "70%",
                        }}
                        onClick={() =>
                          handleDelete_NonExistReqWith(data, tagShareWith[0])
                        }
                      />
                    </div>
                  </div>

                  {editId2 == data?._id && (
                    <div style={{ display: "flex", gap: "2%" }}>
                      <button
                        style={
                          editId2 == data?._id
                            ? { diplay: "block" }
                            : { diplay: "none" }
                        }
                        onClick={(e) =>
                          handleSubmitSaveUpdateNonExistRequestUser(
                            data,

                            tagShareWith[0]
                          )
                        }
                      >
                        SAVE
                      </button>
                      <button
                        style={
                          editId2 == data?._id
                            ? { diplay: "block" }
                            : { diplay: "none" }
                        }
                        onClick={(e) => setEditId2("")}
                      >
                        cancel
                      </button>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DynamicTagSub;
