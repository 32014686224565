let environment = process.env.NODE_ENV;
const config = {
  base_api_url:
    environment == "development"
      ? process.env.REACT_APP_BASE_API_LOCAL
      : process.env.REACT_APP_BASE_API_LIVE,
  cloud_functions_url:
    environment == "development"
      ? process.env.REACT_APP_CLOUD_FUNCTIONS_URL_LOCAL
      : process.env.REACT_APP_CLOUD_FUNCTIONS_URL_LIVE,
};

export default config;
