export const filterByKeywords = (keyword, obj) => {
  if (!keyword.length) return obj;

  const res = {};

  if (keyword in obj) {
    res[`${keyword}`] = obj[keyword];
  }

  for (const key in obj) {
    const tags = obj[key];

    const filteredTags = tags.filter((tag) => {
      return tag.startsWith(keyword);
    });

    if (filteredTags.length) {
      res[`${key}`] = filteredTags;
    }
  }

  // res;

  return res;
};

// const obj = {
//   "#kitty": ["#kitty#sub2", "#kitty#sub"],
//   "#testroot": ["#testroot"],
//   "#testroot2": ["#testroot2#sub#sub2"],
//   "#sub2": ["#sub2"],
//   "#hello": ["#hello#sub2", "#hello#sub"],
//   "#hello90": ["#hello90#sub"],
// };

// filterByKeywords("#test", obj);
