import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import General from "./setting/General";
import { useLocation } from "react-router-dom";
import Notification from "./frontend/Notification";
import Plan from "./setting/Plan";
import Templates from "./template/Templates";
import { styled } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { fetchExtreamApi } from "../utils/fetchExtreamApi";
import config from "../config";
import Billing from "./setting/Billing";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
export const SettingMain = () => {
  const notificationValue =
    window.localStorage.getItem("notification") != null
      ? JSON.parse(window.localStorage.getItem("notification"))
      : true;

  const { state } = useLocation();
  const [value, setValue] = React.useState("1");
  const [notiUpdate, setNotiUpdate] = React.useState(notificationValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (state != null) {
      setValue(state?.value);
    }
  }, [state]);
  const handleChangeNotification = async (e) => {
    setNotiUpdate(e.target.checked);
    let url = `${config.base_api_url}/auth/NotiPermission`;
    let method = "POST";
    let bodyValue = {
      notiPermission: e.target.checked,
    };
    let response = await fetchExtreamApi(url, method, bodyValue);
    localStorage.setItem("notification", e.target.checked);
    console.log(e.target.checked, notificationValue, "jjhgfhdfgdg", response);
  };
  return (
    <div>
      {" "}
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="General" value="1" />
              <Tab label="Billing" value="2" />
              <Tab label="Plan" value="3" />
              <Tab label="Notification" value="4" />
              <Tab label="Template" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <General setValue={setValue} valueG={value} />
          </TabPanel>
          <TabPanel value="2">
            <Billing setValue={setValue} />
          </TabPanel>
          <TabPanel value="3">
            <Plan />
          </TabPanel>
          <TabPanel value="4">
            {/* <Notification /> */}
            Notification mail:
            <div>
              <span>Off</span>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    defaultChecked={notiUpdate}
                    onChange={(e) => {
                      handleChangeNotification(e);
                    }}
                  />
                }
              />
              <span>On</span>
            </div>
          </TabPanel>
          <TabPanel value="5">
            {/* <Notification /> */}
            <Templates />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};
