export const createTagwiseArray = (arr) => {
  if (!arr.length) return null;

  const res = {};

  //   Initialize resultant object
  for (const elm of arr) {
    for (const tag of elm.title) {
      // for (const tag1 of tag) {
      // }
      if (elm?.sharedBy != undefined) {
        const parentTitle = tag.replace(/\b\#\w+/g, "");
        if (parentTitle != "null") {
          res[`@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + parentTitle}`] =
            {
              values: [],
              id: "",
              email: "",
              name: "",
            };
        }
      } else {
        const parentTitle = tag.replace(/\b\#\w+/g, "");
        if (parentTitle != "null") {
          res[`${parentTitle}`] = {
            values: [],
            id: "",
            email: "",
            name: "",
          };
        }
      }
    }
  }

  // Push subtitles
  for (const elm of arr) {
    for (const tag of elm.title) {
      if (elm?.sharedBy != undefined) {
        const parentTitle = tag.replace(/\b\#\w+/g, "");

        if (
          `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + tag}`.startsWith(
            `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + parentTitle}`
          ) &&
          tag.match(/#/g).length >= 2
        ) {
          if (
            res[`@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + parentTitle}`]
              .id.length != 0 &&
            res[`@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + parentTitle}`]
              .id != elm?.controlBy?._id
          ) {
            let value3 = [
              `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + tag}`,
            ];
            let value1 = [
              `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + tag}`,
            ];
            let check =
              res[
                `@${
                  elm?.sharedBy?.fname +
                  elm?.sharedBy?.lname +
                  "(" +
                  elm?.controlBy?._id +
                  ")" +
                  parentTitle
                }`
              ]?.values;

            let value = check != undefined && [
              ...res[
                `@${
                  elm?.sharedBy?.fname +
                  elm?.sharedBy?.lname +
                  "(" +
                  elm?.controlBy?._id +
                  ")" +
                  parentTitle
                }`
              ].values,
              `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + tag}`,
            ];

            res[
              `@${
                elm?.sharedBy?.fname +
                elm?.sharedBy?.lname +
                "(" +
                elm?.controlBy?._id +
                ")" +
                parentTitle
              }`
            ] = {
              values: check != undefined ? value : value3,
              id: elm?.controlBy?._id,
              email: elm?.sharedBy?.email,
              name: elm?.sharedBy?.fname + " " + elm?.sharedBy?.lname,
            };
          } else {
            let value = [
              ...res[
                `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + parentTitle}`
              ].values,
              `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + tag}`,
            ];
            let value1 = [
              `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + tag}`,
            ];
            res[
              `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + parentTitle}`
            ] = {
              values: value,
              id: elm?.controlBy?._id,
              email: elm?.sharedBy?.email,
              name: elm?.sharedBy?.fname + " " + elm?.sharedBy?.lname,
            };
          }

          // if()
        } else {
          if (
            res[`@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + parentTitle}`]
              .id.length != 0 &&
            res[`@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + parentTitle}`]
              .id != elm?.controlBy?._id
          ) {
            res[
              `@${
                elm?.sharedBy?.fname +
                elm?.sharedBy?.lname +
                "(" +
                elm?.controlBy?._id +
                ")" +
                parentTitle
              }`
            ] = {
              values: [],
              id: elm?.controlBy?._id,
              email: elm?.sharedBy?.email,
              name: elm?.sharedBy?.fname + " " + elm?.sharedBy?.lname,
            };
          } else {
            res[
              `@${elm?.sharedBy?.fname + elm?.sharedBy?.lname + parentTitle}`
            ] = {
              values: [],
              id: elm?.controlBy?._id,
              email: elm?.sharedBy?.email,
              name: elm?.sharedBy?.fname + " " + elm?.sharedBy?.lname,
            };
          }
        }
      } else {
        const parentTitle = tag.replace(/\b\#\w+/g, "");

        if (tag.startsWith(parentTitle) && tag.match(/#/g).length >= 2) {
          res[`${parentTitle}`] = {
            values: [...res[`${parentTitle}`].values, tag],

            id: "",
            email: "",
            name: "",
          };
        }
      }
    }
  }

  return res;
};
// const arr = {
//   title:[hello],
// };
