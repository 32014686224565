// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCfHLg1BWfsKPVgERmw3Yji8mH0t9fN_qg",
  authDomain: "postaira.firebaseapp.com",
  projectId: "postaira",
  storageBucket: "postaira.appspot.com",
  messagingSenderId: "20356134636",
  appId: "1:20356134636:web:1e5f08a9e14d60252c57c9",
  measurementId: "G-Y2ZHCTZ7FK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);
