import axios from "axios";
import React, { useMemo, useState, useCallback, useRef } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Parser from "html-react-parser";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import Moment from "react-moment";
import moment from "moment";
import swal from "sweetalert";
import BlotFormatter from "quill-blot-formatter";

import { useDropzone } from "react-dropzone";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// import { images } from "./frontend/images";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Select from "react-select";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import "react-select-search/style.css";
import useRecorder from "../useRecorder";
import { fetchExtreamApi } from "../../utils/fetchExtreamApi";
import config from "../../config";
const useKeyPressD = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });
  return keyPressed;
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "16px",
  transform: "translate(-50%, -50%)",
  border: "none",
  // width: 468, // remove this workkkkkk
  bgcolor: "background.paper",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: "0",
};
const CommentQuill = ({ enterPress, allData, params }) => {
  const [notes, setNotes] = useState();
  const [noteSp, setNoteSp] = useState(false);
  const [allTItle, setAllTitle] = useState([]);
  const [atTagB, setAtTagB] = useState(false);
  const [searchValueATR, setsearchValueATR] = useState("");
  const [rangedATR, setRangedATR] = useState();
  const [atTag, setAtTag] = useState([]);
  const [selected, setSelected] = useState(undefined);
  const [openRec, setOpenRec] = React.useState(false);
  const [open1, setOpen1] = useState(false);

  const [searchValueHash, setsearchValueHash] = useState("");
  const [ranged, setRanged] = useState();
  const downPressD = useKeyPressD("ArrowDown");
  const upPressD = useKeyPressD("ArrowUp");
  // const enterPress = useKeyPressD("Enter");
  const [defaultLi, setDefaultLi] = useState(false);
  const [allTItle1, setAllTitle1] = useState([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [preview, setPreview] = useState([]);
  const [timer, setTimer] = useState(0);
  const [start, setStart] = useState(false);

  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  let hashTitle = [];
  const handleOpenRec = () => setOpenRec(true);
  const handleCloseRec = () => setOpenRec(false);
  const firstStart = useRef(true);
  const tick = useRef();
  const dispSecondsAsMins = (seconds) => {
    // 25:00
    const mins = Math.floor(seconds / 60);
    const seconds_ = seconds % 60;
    return (
      mins.toString() +
      ":" +
      (seconds_ == 0
        ? "00"
        : seconds_.toString().length == 2
        ? seconds_.toString()
        : ` 0${seconds_.toString()}`)
    );
  };
  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }

    if (start) {
      tick.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(tick.current);
    }

    return () => clearInterval(tick.current);
  }, [start]);
  const toggleStart = () => {
    setStart(!start);
    let tip;
    let audioContext;
    let context;
    let audioInput;

    var microphone = document.getElementById("microphone");
    function startRecord(e) {
      var record = navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      record.then(function onSuccess(stream) {
        audioContext = window.AudioContext;

        try {
          context = new audioContext();
        } catch (e) {
          console.log("not support AudioContext");
        }

        audioInput = context.createMediaStreamSource(stream);

        var binaryData = [];
        binaryData.push(stream);
        microphone.src = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/zip" })
        );
        microphone.onloadedmetadata = function (e) {};
        var analyser = context.createAnalyser();
        audioInput.connect(analyser);

        drawSpectrum(analyser);
      });
      record.catch(function (e) {
        tip.innerHTML = e.name;
      });
      var drawSpectrum = function (analyser) {
        var canvas = document.getElementById("canvas"),
          cwidth = canvas?.width,
          cheight = canvas?.height,
          meterWidth = 8,
          gap = 2,
          meterNum = cwidth / (meterWidth + gap),
          ctx = canvas.getContext("2d"),
          gradient = ctx.createLinearGradient(0, 0, 0, cheight);
        gradient.addColorStop(1, "#a467af");
        gradient.addColorStop(0.3, "#ff0");
        gradient.addColorStop(0, "#f00");
        ctx.fillStyle = gradient;
        var drawMeter = function () {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);

          var step = Math.round(array.length / meterNum);
          ctx.clearRect(0, 0, cwidth, cheight);
          for (var i = 0; i < meterNum; i++) {
            var value = array[i * step];

            ctx.fillRect(
              i * (meterWidth + gap),
              cheight - value,
              meterWidth,
              cheight
            );
          }
          requestAnimationFrame(drawMeter);
        };
        requestAnimationFrame(drawMeter);
      };
    }

    startRecord();
  };

  let [
    audioURLs,
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    blobData,
    setAudioURLs,
    title,
    setTitle,
    CancelOne,
    Delete,
    Save,
  ] = useRecorder();
  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "size",
    "list",
    "indent",
    "header",
    "color",
    "background",
    "font",
    "align",
    "height",
    "width",
    "image",
    "link",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      ["link"],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [
        // { size: ["small", false, "large", "huge"] },
        { header: [1, 2, 3, 4, 5, 6, false] },
      ],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [
        {
          font: [],
        },
      ],
      [{ align: [] }],
      ["image"],
    ],
    blotFormatter: {},
  };
  const { quill, quillRef, Quill } = useQuill({
    modules,
    formats,
    // readOnly,
  });
  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register("modules/blotFormatter", BlotFormatter);
  }
  var toolbarDiv = document.querySelector(".left_inputF1 .ql-toolbar.ql-snow");

  React.useEffect(() => {
    if (toolbarDiv != null) {
      var spanElement = document.createElement("span");
      var iElement = document.createElement("i");
      iElement.className = "fa-solid fa-print";

      spanElement.appendChild(iElement);

      spanElement.onclick = handlePrint; // Assuming handlePrint is the function to be executed on click
      spanElement.className = "spanPrint";
      // Append the span element to the toolbar div
      toolbarDiv.appendChild(spanElement);
    }
  }, [quill]);
  React.useEffect(() => {
    if (quill) {
      let newRange = [];
      let newRange1 = [];
      quill.on("editor-change", (eventName, ...args) => {
        if (eventName === "selection-change") {
          const range = args[0];
          if (range) {
            newRange.push(range.index);
          }
        }
      });

      quill.on("text-change", (delta, oldDelta, source) => {
        const getUser = (s) =>
          s.includes("#") && s.substr(s.lastIndexOf("#") + 1).split(" ")[0];

        if (quill.getText().match("#")) {
          var range = quill.getSelection();

          const fetchData = async () => {
            try {
              let url1 = `${config.base_api_url}/api/getnoteMain`;
              let method1 = "GET";
              let response = await fetchExtreamApi(url1, method1);
              // setAllNotes(response);

              let arr = [];
              let arr1 = [];
              response.map((data) => {
                if (data.type == "TagShell") {
                  data?.title &&
                    !arr1.includes(data.title) &&
                    arr1.push({
                      title: data.title,
                      shareBy: data?.sharedBy?.fname + data?.sharedBy?.lname,
                    });
                } else {
                  data?.title &&
                    !arr1.includes(data.title) &&
                    arr1.push({ title: data.title, shareBy: "No" });
                }
              });
              const titleMain = arr1.length != 0 && arr1.flat();
              let removesubHash = [];
              hashTitle = [];
              for (let data of titleMain) {
                if (data?.title[0] != undefined) {
                  if (data?.shareBy != "No") {
                    removesubHash.push(
                      "@" +
                        data?.shareBy +
                        data?.title[0].replace(/\b\#\w+/g, "")
                    );
                  } else {
                    removesubHash.push(data?.title[0].replace(/\b\#\w+/g, ""));
                  }
                }
              }
              let uniqueChars = [...new Set(removesubHash)];
              let allHeads = [uniqueChars];

              const subHead = arr1.flat().filter((data) => {
                if (data?.title[0] != undefined) {
                  // return data?.title[0].match(/#/g).length >= 2;
                  if (data?.shareBy != "No") {
                    if (data?.title[0].match(/#/g).length >= 2) {
                      allHeads.push("@" + data?.shareBy + data?.title[0]);
                    }
                  } else {
                    if (data?.title[0].match(/#/g).length >= 2) {
                      allHeads.push(data?.title[0]);
                    }
                  }
                }
              });
              allHeads.push(subHead);

              const data = [];
              //  ==================================================================================
              var str = quill.getText();
              var stringPosWord = [];
              // let postText = text.replace(regexp, "");

              let test = str.substring(str.lastIndexOf("#") + 1, str.length);
              function getWordAt(str, pos) {
                // Perform type conversions.
                str = String(str);
                pos = Number(pos) >>> 0;

                // Search for the word's beginning and end.
                var left = str.slice(0, pos + 1).search(/\S+$/),
                  right = str.slice(pos).search(/\s/);

                // The last word in the string is a special case.
                if (right < 0) {
                  stringPosWord.push(str.slice(left));
                  return str.slice(left);
                }

                // Return the word, using the located bounds to extract it from the string.
                stringPosWord.push(str.slice(left, right + pos));

                return str.slice(left, right + pos);
              }
              let num = quill.getSelection()?.index;
              if (num != undefined) {
                getWordAt(quill.getText().replace(/\n/g, " "), num - 1);
              }
              if (stringPosWord.length != 0) {
                const data1 = allHeads.flat().filter((text) => {
                  if (text.includes("@")) {
                    let atAdd = text.split(/(?=#)/g);

                    return text
                      ?.toLowerCase()

                      .startsWith(
                        `${atAdd[0]}${stringPosWord[0].trim()}`.toLowerCase()
                      );
                  } else {
                    return text
                      ?.toLowerCase()

                      .startsWith(`${stringPosWord[0].trim()}`.toLowerCase());
                  }
                });
                data.push(data1);
              }

              if (data[0] != undefined) {
                if (data[0].length != 0 && stringPosWord[0].includes("#")) {
                  setNoteSp(true);
                  setsearchValueHash(stringPosWord[0]);
                  setTimeout(() => {
                    // const element = document.getElementById("unicorn1");
                    const cursorPosition = 33;
                    const editor1 = document.getElementById("unicorn1");
                    let arry = newRange;
                    let lastElement = arry[arry.length - 1];
                    setRanged(lastElement);
                    let index = lastElement - stringPosWord[0].length;
                    quill.setSelection(lastElement);

                    // Get the bounds of the selection object
                    const bounds = quill.getBounds(index);
                    // Calculate the offsetLeft and offsetTop properties of the cursor position
                    const offsetLeftk =
                      bounds.left + window.pageXOffset - editor1.offsetLeft;
                    const offsetTopk = bounds.top - editor1.offsetTop;
                    const offsetBottom = bounds.top + bounds.height;

                    var float_elm =
                      document.getElementsByClassName("Active_Float1")[0];

                    let x = offsetTopk;
                    let positive_x = x < 0 ? -x : x;
                    if (offsetTopk <= -50) {
                      float_elm.style.top = `${positive_x + 10}px`;
                    } else {
                      float_elm.style.top = `${positive_x + 60}px`;
                    }

                    float_elm.style.left = `${offsetLeftk + 5}px`;
                  }, 1000);
                } else {
                  setNoteSp(false);
                  if (stringPosWord[0].includes("#")) {
                    setTimeout(() => {
                      let arry = newRange;
                      let lastElement = arry[arry.length - 1];

                      quill.setSelection(lastElement);
                    }, 500);
                  }
                }
              }

              hashTitle.push(data[0]);

              return hashTitle;
            } catch (error) {
              console.error(error.message);
            }
          };
          fetchData();
          fetchData().then((data) => {
            if (data == undefined) {
              setAllTitle([]);
            } else {
              let uniqueChars = [...new Set(data.flat())];
              let allHeads = [uniqueChars];
              setAllTitle(allHeads.flat().sort());
            }
          });
        } else {
          setNoteSp(false);
        }
        if (quill.getText().match("@")) {
          const fetchData = async () => {
            try {
              let url = `${config.base_api_url}/auth/Userslist`;
              let method = "GET";
              let response = await fetchExtreamApi(url, method);
              try {
                // setTimeout(async () => {

                let url1 = `${config.base_api_url}/api/sharedNoteUsers`;
                let method1 = "GET";
                let res = await fetchExtreamApi(url1, method1);
                const emailSet = new Set();
                const filteredData = [];
                res?.sharedNote.forEach((obj) => {
                  if (!emailSet.has(obj.email)) {
                    emailSet.add(obj.email);
                    filteredData.push(obj);
                  }
                });

                let sharedNoteUser = filteredData;

                var stringPosWord = [];
                // let postText = text.replace(regexp, "");

                function getWordAt(str, pos) {
                  // Perform type conversions.
                  str = String(str);
                  pos = Number(pos) >>> 0;

                  // Search for the word's beginning and end.
                  var left = str.slice(0, pos + 1).search(/\S+$/),
                    right = str.slice(pos).search(/\s/);

                  // The last word in the string is a special case.
                  if (right < 0) {
                    stringPosWord.push(str.slice(left));
                    return str.slice(left);
                  }

                  // Return the word, using the located bounds to extract it from the string.
                  stringPosWord.push(str.slice(left, right + pos));

                  return str.slice(left, right + pos);
                }
                let num = quill.getSelection()?.index;
                if (num != undefined) {
                  getWordAt(quill.getText().replace(/\n/g, " "), num - 1);
                }
                if (
                  sharedNoteUser &&
                  stringPosWord.length != 0 &&
                  stringPosWord[0].includes("@")
                ) {
                  if (sharedNoteUser.length != 0) {
                    const tagFilter = sharedNoteUser.filter((data) => {
                      return `${data.fname + data.lname}`
                        .toLowerCase()
                        .trim()
                        .startsWith(
                          stringPosWord[0]
                            .replace(/^@/, "")
                            .trim()
                            .toLowerCase()
                        );
                    });

                    if (tagFilter.length == 0) {
                      const tagFilter1 = response?.list.filter((data) => {
                        return `${data.fname + data.lname}`
                          .toLowerCase()
                          .trim()
                          .startsWith(
                            stringPosWord[0]
                              .replace(/^@/, "")
                              .trim()
                              .toLowerCase()
                          );
                      });

                      if (tagFilter1.length == 0) {
                        setAtTagB(false);
                      } else if (
                        stringPosWord[0].includes("@") &&
                        tagFilter1.length != 0
                      ) {
                        setAtTagB(true);
                        setsearchValueATR(stringPosWord[0]);
                        setTimeout(() => {
                          // const element = document.getElementById("unicorn1");
                          const cursorPosition = 33;
                          const editor1 = document.getElementById("unicorn1");
                          let arry = newRange;
                          let lastElement = arry[arry.length - 1];
                          setRangedATR(lastElement);
                          let index = lastElement - stringPosWord[0].length;
                          quill.setSelection(lastElement);

                          // Get the bounds of the selection object
                          const bounds = quill.getBounds(index);
                          // Calculate the offsetLeft and offsetTop properties of the cursor position
                          const offsetLeftk =
                            bounds.left +
                            window.pageXOffset -
                            editor1.offsetLeft;
                          const offsetTopk = bounds.top - editor1.offsetTop;
                          const offsetBottom = bounds.top + bounds.height;

                          var float_elm =
                            document.getElementsByClassName("Active_Float1")[0];

                          let x = offsetTopk;
                          let positive_x = x < 0 ? -x : x;

                          float_elm.style.top = `${positive_x + 60}px`;
                          float_elm.style.left = `${offsetLeftk + 5}px`;
                        }, 1000);
                      }

                      return tagFilter1;
                    } else if (stringPosWord[0].includes("@")) {
                      setAtTagB(true);
                      setsearchValueATR(stringPosWord[0]);

                      setTimeout(() => {
                        // const element = document.getElementById("unicorn1");
                        const cursorPosition = 33;
                        const editor1 = document.getElementById("unicorn1");
                        let arry = newRange;
                        let lastElement = arry[arry.length - 1];
                        setRangedATR(lastElement);
                        let index = lastElement - stringPosWord[0].length;
                        quill.setSelection(lastElement);

                        // Get the bounds of the selection object
                        const bounds = quill.getBounds(index);
                        // Calculate the offsetLeft and offsetTop properties of the cursor position
                        const offsetLeftk =
                          bounds.left + window.pageXOffset - editor1.offsetLeft;
                        const offsetTopk = bounds.top - editor1.offsetTop;
                        const offsetBottom = bounds.top + bounds.height;

                        var float_elm =
                          document.getElementsByClassName("Active_Float1")[0];

                        let x = offsetTopk;
                        let positive_x = x < 0 ? -x : x;

                        float_elm.style.top = `${positive_x + 60}px`;
                        float_elm.style.left = `${offsetLeftk + 5}px`;
                      }, 1000);

                      return tagFilter;
                    }
                  } else {
                    const tagFilter = response?.list.filter((data) => {
                      return `${data.fname + data.lname}`
                        .toLowerCase()
                        .trim()
                        .startsWith(
                          stringPosWord[0]
                            .replace(/^@/, "")
                            .trim()
                            .toLowerCase()
                        );
                    });
                    if (tagFilter.length == 0) {
                      setAtTagB(false);
                    } else if (stringPosWord[0].includes("@")) {
                      setAtTagB(true);
                      setsearchValueATR(stringPosWord[0]);

                      setTimeout(() => {
                        // const element = document.getElementById("unicorn1");
                        const cursorPosition = 33;
                        const editor1 = document.getElementById("unicorn1");
                        let arry = newRange;
                        let lastElement = arry[arry.length - 1];
                        setRangedATR(lastElement);
                        let index = lastElement - stringPosWord[0].length;
                        quill.setSelection(lastElement);

                        // Get the bounds of the selection object
                        const bounds = quill.getBounds(index);
                        // Calculate the offsetLeft and offsetTop properties of the cursor position
                        const offsetLeftk =
                          bounds.left + window.pageXOffset - editor1.offsetLeft;
                        const offsetTopk = bounds.top - editor1.offsetTop;
                        const offsetBottom = bounds.top + bounds.height;

                        var float_elm =
                          document.getElementsByClassName("Active_Float1")[0];

                        let x = offsetTopk;
                        let positive_x = x < 0 ? -x : x;

                        float_elm.style.top = `${positive_x + 60}px`;
                        float_elm.style.left = `${offsetLeftk + 5}px`;
                      }, 1000);
                    }
                    return tagFilter;
                  }
                } else if (stringPosWord[0].includes("@") == false) {
                  setAtTagB(false);
                }
              } catch (error) {
                // console.error(error.message);
              }

              //  let arr = [];
            } catch (error) {
              console.error(error.message);
            }
          };
          fetchData();
          fetchData().then((data) => {
            setAtTag(data);
            if (data == undefined) {
              setAtTag([]);
            }

            //  setAllTitle(data.flat());
          });
        } else {
          setAtTagB(false);
        }

        setNotes(quill.root.innerHTML);
      });
    }
  }, [quill]);
  const handlePrint = () => {
    const content = quill.root.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write("<div>" + content + "</div>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const handleTouchMove = useMemo(() => {
    return (e) => {
      if (e.keyCode >= 37 && e.keyCode <= 40) {
        e.preventDefault();
        return (e.returnValue = "");
      }
    };
  }, []);

  useEffect(() => {
    if (
      (noteSp == true && downPressD) ||
      (noteSp == true && upPressD) ||
      noteSp == true
    ) {
      window.addEventListener("keydown", handleTouchMove, false);
      window.addEventListener("keyup", handleTouchMove, false);
      return () => {
        window.removeEventListener("keyup", handleTouchMove, false);
        window.removeEventListener("keydown", handleTouchMove, false);
      };
      // document.getElementById("mybtn").click();
    }
  }, [noteSp]);
  useEffect(() => {
    if (
      (atTagB == true && downPressD) ||
      (atTagB == true && upPressD) ||
      atTagB == true
    ) {
      window.addEventListener("keydown", handleTouchMove, false);
      window.addEventListener("keyup", handleTouchMove, false);
      return () => {
        window.removeEventListener("keyup", handleTouchMove, false);
        window.removeEventListener("keydown", handleTouchMove, false);
      };
      // document.getElementById("mybtn").click();
    }
  }, [atTagB]);
  useEffect(() => {
    if (allTItle.length && downPressD && noteSp == true) {
      setCursor((prevState) =>
        prevState < allTItle.length - 1 ? prevState + 1 : prevState
      );
    } else {
    }
    if (atTag.length && downPressD && atTagB == true) {
      setCursor((prevState) =>
        prevState < atTag.length - 1 ? prevState + 1 : prevState
      );
    } else {
    }
  }, [downPressD]);
  useEffect(() => {
    if (allTItle.length && upPressD && (noteSp == true || atTagB == true)) {
      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    } else {
      // return () => {
      //   window.removeEventListener("keyup", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
    if (atTag.length && upPressD && atTagB == true) {
      // window.addEventListener("keydown", listener);
      // window.addEventListener("keyup", handleTouchMove, false);

      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    } else {
      // return () => {
      //   window.removeEventListener("keyup", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
  }, [upPressD]);

  useEffect(() => {
    if (allTItle.length && enterPress && noteSp) {
      // setSelected(allTItle[cursor]);
      if (allTItle[cursor]?.includes("@") && allTItle[cursor] != undefined) {
        let atAdd = allTItle[cursor].indexOf("#");
        var msg = allTItle[cursor].slice(atAdd);
        setSelected(msg);
      } else {
        setSelected(allTItle[cursor]);
        setDefaultLi(true);
      }
      // handledropSelect(allTItle[cursor]);
    }
    if (atTag.length && enterPress && atTagB) {
      // item?.fname + item?.lname;
      setSelected("@" + atTag[cursor]?.fname + atTag[cursor]?.lname);
      setDefaultLi(true);
      // handledropSelect(allTItle[cursor]);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (allTItle.length && hovered) {
      setCursor(allTItle.indexOf(hovered));
    }
    if (atTag.length && hovered) {
      setCursor(atTag.indexOf(hovered));
    }
  }, [hovered]);
  useEffect(() => {
    if (
      selected != undefined &&
      searchValueHash.includes("#") &&
      noteSp == true
    ) {
      let index = searchValueHash.length;
      if (defaultLi == true) {
        quill.deleteText(ranged - index, index + 1);
        setDefaultLi(false);
      } else {
        quill.deleteText(ranged - index, index);
      }

      // let index = searchValueHash.length;
      let add = ranged - index;
      quill.insertText(add, `${selected}`);
      // ${searchValueHash}${selected}
      if (quill) {
        quill.focus();
        let index1 = ranged - index + selected.length;
        setTimeout(() => {
          quill.setSelection(index1);
        }, 0);
      }
      setAllTitle(allTItle1);
      setNoteSp(false);

      setSelected(undefined);
    }
    if (
      selected != undefined &&
      searchValueATR.includes("@") &&
      atTagB == true
    ) {
      let index = searchValueATR.length;
      if (defaultLi == true) {
        quill.deleteText(rangedATR - index, index + 1);
        setDefaultLi(false);
      } else {
        quill.deleteText(rangedATR - index, index);
      }

      // let index = searchValueHash.length;
      let add = rangedATR - index;
      quill.insertText(add, `${selected}`, {
        background: "#d3e1eb",
      });
      if (quill) {
        quill.focus();
        let index1 = rangedATR - index + selected.length;
        setTimeout(() => {
          quill.setSelection(index1 + 1);
        }, 0);
      }
      quill.insertText(add + selected.length, ` `, {
        background: "white",
      });
      setAllTitle(allTItle1);

      setSelected(undefined);
      setAtTag([]);

      setAtTagB(false);
    }
  }, [selected && quill]);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        setUploadImage((prev) => (prev = [...prev, file]));
        const objectUrl = URL.createObjectURL(file);
        setPreview((prev) => (prev = [...prev, objectUrl]));
        // return () => URL.revokeObjectURL(objectUrl);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const handleCancelImage = ({ data, index }) => {
    const removePreviewIndex = preview.filter((data, i) => {
      return i != index;
    });
    setPreview(removePreviewIndex);
    const removeuploadImageIndex = uploadImage.filter((data, i) => {
      return i != index;
    });

    setUploadImage(removeuploadImageIndex);
  };
  const addComment = async (e) => {
    e.preventDefault();
    const string = quill.root.innerHTML;

    const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
    const imgSrcMatches = string.matchAll(imgSrcRegex);

    const srcArray = [];
    let imgId = 0; // Counter for assigning image ids

    // Regular expression pattern to match <img> tags
    let imgPattern1 = /<img\b[^>]*>/gi;

    // Removing <img> tags and adding id attribute
    let newString = string.replace(imgPattern1, function (match) {
      let idAttribute = ' src="id:' + imgId++ + '"';
      return match.replace(/src="[^"]*"/, idAttribute);
    });
    for (const match of imgSrcMatches) {
      srcArray.push(match[1]);
    }

    let imagesBase = [];
    if (srcArray.length != 0) {
      for (let imagesCov of srcArray) {
        const file = base64ToFile(imagesCov, "image");
        imagesBase.push(file);
      }
    }
    function base64ToFile(base64, filename) {
      const arr = base64.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const fileExtension = mime.split("/")[1];

      // Append the file extension to the file name
      const fullFileName = `${filename}.${fileExtension}`;
      const file = new File([u8arr], fullFileName, { type: mime });

      return file;
    }

    const data = new FormData();
    const blob = audioURLs;
    if (uploadImage.length != 0) {
      for (let uploadImageArr of uploadImage)
        data.append("fileAttachment", uploadImageArr);
    }
    if (blob.length != 0) {
      for (let blobArr of blob) data.append("audio", blobArr.file);
    }
    audioURLs.forEach((item) => {
      data.append(`audioInfo`, JSON.stringify(item));
    });
    if (imagesBase.length != 0) {
      for (let uploadImageConArr of imagesBase)
        data.append("imagesBase", uploadImageConArr);
    }
    data.append(`parentId`, undefined);
    data.append(`description`, newString);
    if (allData.type == "note") {
      data.append(`noteId`, params.id);

      e.preventDefault();
      let url1 = `${config.base_api_url}/api/addcomment`;
      let method1 = "PUT";
      await fetchExtreamApi(url1, method1, data)
        .then((response) => {
          if (response?.mergedArrayAllInfo.length != 0) {
            for (let imagesInfo of response?.mergedArrayAllInfo) {
              axios
                .post(
                  `${config.base_api_url}/api/AddSizeOfImageInfo`,

                  {
                    id_note: allData?._id,
                    s3location: imagesInfo?.imag_loc,
                    size: imagesInfo?.image_size,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "firebasetoken"
                      )}`,
                      // "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((res) => {
                  console.log("Successfully added", res);
                })
                .catch((error) => {
                  swal({
                    title: error?.response?.data?.Error,
                    icon: "error",
                    dangerMode: true,
                  });
                  // setNotes("");
                  // setUploadImage("");
                  console.error("There was an error!", error);
                });
            }
          }
          if (response?.AudioAllFilesInfo.length != 0) {
            for (let audioInfo of response?.AudioAllFilesInfo) {
              axios
                .post(
                  `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                  {
                    id_note: allData?._id,
                    s3location: audioInfo?.Audio_loc,
                    size: audioInfo?.Audio_size,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "firebasetoken"
                      )}`,
                    },
                  }
                )
                .then((res) => {
                  console.log("Successfully added", res);
                })
                .catch((error) => {
                  swal({
                    title: error?.response?.data?.Error,
                    icon: "error",
                    dangerMode: true,
                  });
                  // setNotes("");
                  // setUploadImage("");
                  console.error("There was an error!", error);
                });
            }
          }
          swal({
            title: "Edit notes sucessfully!!",
            icon: "success",
          });

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      e.preventDefault();
      data.append(`noteId`, allData?.note_reference?._id);

      let url1 = `${config.base_api_url}/api/addcomment`;
      let method1 = "PUT";
      await fetchExtreamApi(url1, method1, data)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div>
        {" "}
        <form method="post">
          {/* {allData?.permission == "View" ||
          (allData?.permission == "Edit" && (
            <div className="space_mobRes"></div>
          ))} */}
          <div className="top_input_box">
            <div className="border_box">
              <div className="left_inputF1">
                <div ref={quillRef} id="unicorn1">
                  {/* {noteSp == true && (
                    <div className="Active_Float1">
                      <div className="MuiAutocomplete-listbox">
                        {allTItle.map((item, i) => {
                          return (
                            <ListItem
                              key={i}
                              active={i === cursor}
                              item={item}
                              setSelected={setSelected}
                              setHovered={setHovered}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )} */}
                  {/* {atTagB == true && (
                    <div className="Active_Float1">
                      <div className="MuiAutocomplete-listbox">
                        {atTag.map((item, i) => {
                          return (
                            <ListItem1
                              key={i}
                              active={i === cursor}
                              item={item}
                              setSelected={setSelected}
                              setHovered={setHovered}
                            />
                          );
                        })}
                      </div>
                    </div>
                  )} */}
                </div>
              </div>

              <div
                className="right_text"

                // onClick={()  }
              >
                <>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />

                    <i className="fa-solid fa-paperclip"></i>
                    <h2>Attach</h2>
                  </div>
                  <div onClick={handleOpenRec}>
                    <i class="fa-solid fa-microphone"></i>
                    <h2>Record</h2>
                  </div>
                </>

                {/* <ul style={{ listStyle: "none" }}>
                <li>
                  {" "}
                  <span>$</span> - Track finance
                </li>
                <li>
                  {" "}
                  <span>*</span> - Track Time
                </li>
                <li>
                  <span>@</span> - Tag user
                </li>
              </ul> */}
              </div>
            </div>
            <div></div>
            {uploadImage.length != 0 && (
              <div className="WhiteBox gridImages">
                {preview.map((data, index) => (
                  <div className="displayImages">
                    <span className="ImageAdjust">
                      <span
                        className="CancelImage"
                        onClick={() => handleCancelImage({ data, index })}
                      >
                        {" "}
                        <i class="fa-solid fa-circle-xmark"></i>
                      </span>
                      <img
                        src={data}
                        style={{ height: 70, width: 150, objectFit: "contain" }}
                      />
                    </span>
                  </div>
                ))}
              </div>
            )}
            {audioURLs.length != "" && (
              <div className="WhiteBox" style={{ borderRadius: "10px" }}>
                {audioURLs &&
                  audioURLs.map((url) => {
                    return (
                      <>
                        <div className="left_align">
                          <span>
                            <span className="font_dark">File Size:</span>
                            {url?.size}
                          </span>
                          <span>
                            <span className="font_dark">Recorded:</span>
                            {moment(url?.createdTime).format("L LT")}
                          </span>
                          <span
                            className="font_Red"
                            onClick={() => {
                              CancelOne(url);
                            }}
                          >
                            Delete file
                          </span>
                        </div>

                        <input
                          type="text"
                          className="input_RecoredVoice"
                          // value={url?.title}
                          value={url?.title}
                          onChange={(e) => {
                            let arr = [];
                            const checkData = audioURLs.filter((data) => {
                              if (data.audio == url?.audio) {
                                if (data.title.length == 1) {
                                  if (e.target.value.length > 0) {
                                    return (data.title = e.target.value);
                                  } else {
                                    return (data.title = " ");
                                  }
                                } else {
                                  if (e.target.value.length > 0) {
                                    return (data.title = e.target.value);
                                  } else {
                                    return (data.title = " ");
                                  }
                                }
                              } else {
                                return data.title;
                              }
                            });

                            arr.push(checkData);

                            setAudioURLs(arr.flat());
                          }}
                        />
                        <audio
                          className="input_AudioVoice"
                          src={url?.audio}
                          controls
                        />
                      </>
                    );
                  })}
              </div>
            )}

            <div>
              <button onClick={(e) => addComment(e)}>Submit</button>
            </div>
          </div>
        </form>
      </div>
      <Modal
        open={openRec}
        onClose={handleCloseRec}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div style={{ marginLeft: "94%" }}>
            <CloseIcon
              style={{ color: "#6F6F6F", cursor: "pointer" }}
              onClick={handleCloseRec}
            />
          </div>
          <div className="share_div">RECORD AUDIO</div>

          <div></div>
          <div className="top_input_box_green">
            <h4>Name</h4>
            {audioURL != "" && (
              <>
                <input
                  type="text"
                  className="input_RecoredVoice"
                  defaultValue={
                    title != "" ? title : `Untitled_${moment().format("L_LTS")}`
                  }
                  onChange={(e) => setTitle(e.target.value)}
                />
                <audio
                  className="input_AudioVoice"
                  src={audioURL?.audio}
                  onChange={(e) => {
                    console.log(e.target.files, "files");
                  }}
                  controls
                />
              </>
            )}

            <br />
            {audioURL == "" && (
              <>
                <input
                  type="text"
                  className="input_RecoredVoice"
                  defaultValue={
                    title != "" ? title : `Untitled_${moment().format("L_LTS")}`
                  }
                  // value={`Untitled_${moment().format("L_LTS")}`}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <h1 style={{ marginTop: "0px", textAlign: "center" }}>
                  {dispSecondsAsMins(timer)}
                </h1>
              </>
            )}
            {!isRecording && audioURL == "" && (
              <>
                {" "}
                <button
                  onClick={() => {
                    startRecording();
                    toggleStart();
                  }}
                  disabled={isRecording}
                >
                  start recording
                </button>
                <audio id="microphone" autoplay></audio>
                {/* <canvas id="canvas" width="300" height="50" ></canvas> */}
              </>
            )}
            {isRecording && audioURL == "" && (
              <>
                <button
                  className="top_input_box_Red"
                  onClick={() => {
                    stopRecording();
                    toggleStart();
                  }}
                  disabled={!isRecording}
                >
                  stop recording
                </button>
                <audio id="microphone" autoplay></audio>
                <canvas id="canvas" width="300" height="50"></canvas>
              </>
            )}
            {audioURL != "" && (
              <div style={{ display: "flex", gap: "2%" }}>
                <button
                  onClick={() => {
                    Save();
                    setTimer(0);
                    setTitle("");
                    handleCloseRec();
                  }}
                >
                  SAVE
                </button>
                <button
                  onClick={() => {
                    Delete();
                    setTimer(0);
                    setTitle("");
                    handleCloseRec();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
      </Modal>
    </>
  );
};

export default CommentQuill;
