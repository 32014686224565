import { async } from "@firebase/util";
import { collection, getDocs } from "firebase/firestore";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../images/logo.png";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import swal from "sweetalert";
import { useEffect } from "react";
import axios from "axios";
import { fetchExtreamApi } from "../utils/fetchExtreamApi";
import config from "../config";

const Signin = () => {
  const token = localStorage.getItem("firebasetoken");
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      navigate("/admin");
    }
  }, []);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      let url1 = `${config.base_api_url}/auth/login`;
      let method1 = "POST";

      const response = await fetchExtreamApi(url1, method1, values);
      localStorage.setItem("firebasetoken", response.token);
      localStorage.setItem("notification", response?.user?.notiPermission);
      localStorage.setItem("email", values.email);
      navigate("/admin");
    } catch (error) {
      console.error("Error: ", error);
      swal({
        title: "Email or password not correct",
        icon: "error",
        dangerMode: true,
      });
    }
  };

  const handleforgotpassword = (e) => {
    e.preventDefault();
    navigate("/forgotpassword");
  };
  return (
    <div className="main_wrap">
      <div className="left_form">
        <div className="inner_form">
          <h1>Sign In</h1>
          <p>Enter your email and password to sign in!</p>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="form_group">
              <label>
                Email<span>*</span>
              </label>
              <input
                type="text"
                defaultValue=""
                name="email"
                value={values.email}
                onChange={(e) => handleChange(e)}
                placeholder="mail@simmmple.com"
              />
            </div>
            <div className="form_group">
              <label>
                Password<span>*</span>
              </label>
              <input
                type="password"
                defaultValue=""
                name="password"
                value={values.password}
                onChange={(e) => handleChange(e)}
                id="password-field"
                placeholder="Enter password"
              />
              <div className="show_password">
                <i
                  toggle="#password-field"
                  className="far fa-eye"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
            <div className="form_group form_check">
              <div className="remember">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Keep me logged in
                </label>
              </div>
              <div className="forgot_pass_link">
                <p
                  href="forgot-password"
                  role="button"
                  onClick={(e) => handleforgotpassword(e)}
                >
                  Forgot Password?
                </p>
              </div>
            </div>
            <div className="form_group">
              <input type="submit" value="Submit" />
            </div>
          </form>
          <div className="sign_text">
            <p>
              Not registered yet? <Link to="/signup">Create an Account</Link>
            </p>
          </div>
        </div>
        <div className="copyright">
          <p>© 2023 Postaira. All Rights Reserved.</p>
        </div>
      </div>

      <div className="right_logo">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="footer_link">
          <ul style={{ listStyle: "none" }}>
            <li>
              <a href="/">Terms of Use</a>
            </li>
            <li>
              <a href="/">Blog</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Signin;
