import React from "react";
import Signin from "./components/Signin";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import Home from "./pages/admin/Home";
import Tags from "./pages/admin/Tags";
import AddTags from "./pages/admin/AddTags";
import FrontendTags from "./pages/frontend/Tags";
import FrontendMessage from "./pages/frontend/Message";
import Signup from "./pages/Signup";
import EditNotes from "./pages/admin/EditNotes";
import PrivateRoutes from "./PrivateRoute";
import ForgotPasswod from "./pages/ForgotPasswod";
import Setting from "./pages/admin/Setting";
import Notification from "./components/frontend/Notification";
import NotificationMain from "./pages/frontend/NotificationMain";
import MainTemplate from "./pages/frontend/MainTemplate";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/admin" element={<Home />} />

            <Route path="/admin/tags" element={<Tags />} />
            <Route path="/admin/addtags" element={<AddTags />} />

            <Route path="/admin/editnotes/:id" element={<EditNotes />} />
            <Route path="/admin/editnotes/" element={<Home />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/tags" element={<FrontendTags />} />
            {/* <Route path="/template" element={<MainTemplate />} /> */}
            <Route path="/notification" element={<NotificationMain />} />

            {/* <Route path="/tags" element={<FrontendTags />} /> */}
          </Route>
          <Route path="/" element={<Signin />} />

          <Route path="/signup" element={<Signup />} />
          <Route path="/forgotpassword" element={<ForgotPasswod />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
