import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

export default function SimpleBackdrop({ size }) {
  return (
    <CircularProgress color="inherit" size={size ? `${size}px` : "20px"} />
  );
}
