import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Swal from "sweetalert2";
import BlotFormatter from "quill-blot-formatter";
import Moment from "react-moment";
import moment from "moment";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "quill/dist/quill.snow.css";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { useDropzone } from "react-dropzone";
import { db } from "../firebase.config";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import Parser from "html-react-parser";
import swal from "sweetalert";
import { Autocomplete } from "@material-ui/lab";
import { TextField, Chip } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import ReactPaginate from "react-paginate";
import TruncateMarkup from "react-truncate-markup";
import useRecorder from "./useRecorder";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { fetchExtreamApi } from "../utils/fetchExtreamApi";
import config from "../config";
const useKeyPress = function (targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "16px",
  transform: "translate(-50%, -50%)",
  width: 468,
  bgcolor: "background.paper",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: "0",
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  padding: "16px",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  backgroundColor: "white",
  boxShadow: 24,
  p: 4,
  zIndex: "0",
};
const NotesBox = ({ dataReqList, setRedirectPage }) => {
  const [timer, setTimer] = useState(0);
  const [start, setStart] = useState(false);
  const [ranged, setRanged] = useState();
  const [rangedATR, setRangedATR] = useState();
  const [rangedReload, setRangedReload] = useState(false);
  const [preview, setPreview] = useState([]);
  const [mentionInfo, setMentionInfo] = useState([]);
  const [planPackages, setPlanPackages] = useState([]);
  const stripe = useStripe();
  const elements = useElements();
  const [checkedList, setcheckedList] = useState([]);
  const [checkedPlan, setcheckedPlan] = useState("");
  const [notes, setNotes] = useState();
  const [notesTitle, setNotesTitle] = useState();
  const [notesTitle1, setNotesTitle1] = useState();
  const [template, setTemplate] = useState(false);
  const [justSetCookie, setJustSetCookie] = useState(false);

  const [editTemplate, setEditTemplate] = useState(false);

  const [templateName, setTemplateName] = useState("");
  const [age, setAge] = React.useState("");
  let inputRef;
  const [open, setOpen] = React.useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [templateSelected, setTemplateSelected] = useState(null);

  const handleOpenCheckout = () => setOpen(true);
  const handleCloseCheckout = () => setOpen(false);

  const [payment, setpayment] = useState({
    fname: "",
    lname: "",
    email: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zip: "",
    cardName: "",
    cardNum: "",
    expireDate: "",
    cvv: "",
  });
  const firstStart = useRef(true);
  const tick = useRef();
  const dispSecondsAsMins = (seconds) => {
    // 25:00
    const mins = Math.floor(seconds / 60);
    const seconds_ = seconds % 60;
    return (
      mins.toString() +
      ":" +
      (seconds_ == 0
        ? "00"
        : seconds_.toString().length == 2
        ? seconds_.toString()
        : ` 0${seconds_.toString()}`)
    );
  };
  useEffect(() => {
    if (firstStart.current) {
      firstStart.current = !firstStart.current;
      return;
    }

    if (start) {
      tick.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else {
      clearInterval(tick.current);
    }

    return () => clearInterval(tick.current);
  }, [start]);

  const toggleStart = () => {
    setStart(!start);
    let tip;
    let audioContext;
    let context;
    let audioInput;

    var microphone = document.getElementById("microphone");
    function startRecord(e) {
      var record = navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      record.then(function onSuccess(stream) {
        audioContext = window.AudioContext;

        try {
          context = new audioContext();
        } catch (e) {}

        audioInput = context.createMediaStreamSource(stream);

        var binaryData = [];
        binaryData.push(stream);
        microphone.src = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/zip" })
        );
        microphone.onloadedmetadata = function (e) {};
        var analyser = context.createAnalyser();
        audioInput.connect(analyser);

        drawSpectrum(analyser);
      });
      record.catch(function (e) {
        tip.innerHTML = e.name;
      });
      var drawSpectrum = function (analyser) {
        var canvas = document.getElementById("canvas"),
          cwidth = canvas.width,
          cheight = canvas.height,
          meterWidth = 8,
          gap = 2,
          meterNum = cwidth / (meterWidth + gap),
          ctx = canvas.getContext("2d"),
          gradient = ctx.createLinearGradient(0, 0, 0, cheight);
        gradient.addColorStop(1, "#a467af");
        gradient.addColorStop(0.3, "#ff0");
        gradient.addColorStop(0, "#f00");
        ctx.fillStyle = gradient;
        var drawMeter = function () {
          var array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);

          var step = Math.round(array.length / meterNum);
          ctx.clearRect(0, 0, cwidth, cheight);
          for (var i = 0; i < meterNum; i++) {
            var value = array[i * step];

            ctx.fillRect(
              i * (meterWidth + gap),
              cheight - value,
              meterWidth,
              cheight
            );
          }
          requestAnimationFrame(drawMeter);
        };
        requestAnimationFrame(drawMeter);
      };
    }

    startRecord();
  };

  let [
    audioURLs,
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    blobData,
    setAudioURLs,
    title,
    setTitle,
    CancelOne,
    Delete,
    Save,
  ] = useRecorder();

  useEffect(() => {
    if (audioURL) {
      fetch(`http://localhost/fileuploader/fileuploader.php`, {
        method: "POST",
        mode: "cors",
        body: blobData,
      })
        .then((response) => {
          if (response.ok) return response;
          else
            throw Error(
              `Server returned ${response.status}: ${response.statusText}`
            );
        })
        .then((response) => console.log(response.text()))
        .catch((err) => {
          // alert(err);
        });
      //let audio = new Audio(audioURL);
      document.querySelector("audio").play();
    }
  }, [audioURL, blobData]);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();

  const formats = [
    "bold",
    "italic",
    "link",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "size",
    "list",
    "indent",
    "header",
    "color",
    "background",
    "font",
    "align",
    "image",
  ];

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        ["link"],
        [
          // { size: ["small", false, "large", "huge"] },
          { header: [1, 2, 3, 4, 5, 6, false] },
        ],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [
          {
            font: [],
          },
        ],
        [{ align: [] }],
        ["image"],
      ],
    },
    blotFormatter: {},
  };
  const { quill, quillRef, Quill } = useQuill({
    theme: "snow",
    modules,
    formats,
  });
  if (Quill && !quill) {
    // const BlotFormatter = require('quill-blot-formatter');
    Quill.register("modules/blotFormatter", BlotFormatter);
  }

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldContents) => {
        let currrentContents = quill.getContents();
      });
    }
  }, [quill]);

  useEffect(() => {
    if (location?.state?.templateName != undefined && age == "" && quill) {
      if (location?.state?.edit == true) {
        setEditTemplate(true);
      }
      let name = location?.state?.templateName;
      setAge(name);

      const fetchNote3 = async () => {
        try {
          let url1 = `${config.base_api_url}/api/getAllTemplate`;
          let method1 = "GET";
          let response = await fetchExtreamApi(url1, method1);
          if (quill) {
            if (name == "addTemplate") {
              setOpen5(true);
            } else {
              if (location?.state?.tempType == "templateShell") {
                const des = response.filter(
                  (items) => items?.note_reference?.tempName == name
                );
                setTemplate(false);

                if (des.length != 0) {
                  quill.clipboard.dangerouslyPasteHTML(
                    des[0]?.note_reference?.description
                  );
                  setTemplateSelected(des[0]?.note_reference);
                } else {
                  quill.clipboard.dangerouslyPasteHTML("");
                }
              } else {
                const des = response.filter((items) => items?.tempName == name);
                setTemplate(false);

                if (des.length != 0) {
                  quill.clipboard.dangerouslyPasteHTML(des[0].description);
                  setTemplateSelected(des[0]);
                } else {
                  quill.clipboard.dangerouslyPasteHTML("");
                }
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchNote3();
    }
  }, [location?.state?.templateName, quill]);
  const handleChangeTemDD = (event) => {
    setAge(event.target.value);
    setEditTemplate(false);

    if (quill) {
      if (event.target.value == "addTemplate") {
        setTemplate(true);
        setOpen5(true);
      } else {
        const des = templateList.filter((item) => {
          if (item?.note_reference != undefined) {
            return item?.note_reference?.tempName == event.target.value;
          } else {
            return item?.tempName == event.target.value;
          }
        });
        setTemplate(false);
        if (des.length != 0) {
          if (des[0]?.note_reference != undefined) {
            quill.clipboard.dangerouslyPasteHTML(
              des[0].note_reference.description
            );
          } else {
            quill.clipboard.dangerouslyPasteHTML(des[0].description);
          }
        } else {
          quill.clipboard.dangerouslyPasteHTML("");
        }
      }
    }
  };
  const handleEditTemplate = (e, item) => {
    e.stopPropagation();
    setEditTemplate(true);
    setTemplate(false);
    setAge(item?.tempName);

    if (quill) {
      if (item?.tempName == "addTemplate") {
        setOpen5(true);
      } else {
        const des = templateList.filter((items) => {
          if (items?.note_reference != undefined) {
            return items?.note_reference?.tempName == item?.tempName;
          } else {
            return items?.tempName == item?.tempName;
          }
        });
        setTemplate(false);
        if (des.length != 0) {
          if (des[0]?.note_reference != undefined) {
            quill.clipboard.dangerouslyPasteHTML(
              des[0].note_reference.description
            );
            setTemplateSelected(des[0].note_reference);
          } else {
            quill.clipboard.dangerouslyPasteHTML(des[0].description);
            setTemplateSelected(des[0]);
          }
          // quill.clipboard.dangerouslyPasteHTML(des[0].description);
        } else {
          quill.clipboard.dangerouslyPasteHTML("");
        }
      }
    }
  };
  const getCookie = (cookieKey) => {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name, value] = cookie.split("=");
      if (name === cookieKey) {
        return value;
      }
    }
    return null; // Cookie not found
  };

  const searchCookie = getCookie("SearchValue");
  useEffect(() => {
    // window.history.replaceState({}, location?.state?.dataTitle);
    if (searchCookie != undefined) {
      setSearchValue(searchCookie);
      setIsLocked(true);
      const fetchNote = async () => {
        try {
          let url1 = `${config.base_api_url}/api/getnoteMain`;
          let method1 = "GET";
          let response = await fetchExtreamApi(url1, method1);
          const changeSearch = response;
          const result = filterByKeywords(changeSearch, searchCookie);
          console.log(result, "resultresultresult");
          setNewDataForPagi(result);
          if (offset * postsPerPage != offsetValue) {
            let offsetValueNew = offset * postsPerPage;

            const slice = result.slice(
              offsetValueNew,
              offsetValueNew + postsPerPage
            );
            setOffsetValue(offsetValueNew + postsPerPage);

            const postData = GetPostData(slice);
            setAllPosts(postData);
            setPageCount(Math.ceil(result?.length / postsPerPage));
          } else {
            const slice = result.slice(offsetValue, offsetValue + postsPerPage);
            setOffsetValue(offsetValue + postsPerPage);
            const postData = GetPostData(slice);
            setAllPosts(postData);
            setPageCount(Math.ceil(result?.length / postsPerPage));
          }
          setIsLocked(false);
        } catch (error) {
          console.error(error);
        }
      };
      fetchNote();
    }
  }, [searchCookie]);
  useEffect(() => {
    // Perform your desired action here, such as displaying an alert
    const handler = (event) => {
      // window.location.replace = "http://localhost:3000/admin";

      event.preventDefault();
      event.returnValue = "";
    };

    if (audioURLs.length != 0 || rangedReload != 0 || preview.length != 0) {
      window.addEventListener("beforeunload", handler);
      // const unblock = () => navigate(location.pathname);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
        // unblock();
      };
    }

    // Check if the page is being refreshed
    if (performance.navigation.type === 1 && justSetCookie != true) {
      if (searchCookie != null) {
        const pastDate = new Date(0); // Set to a date in the past
        document.cookie = `SearchValue=; expires=${pastDate.toUTCString()}; path=/`;
        setSearchValue(undefined);
      }
    }

    // Add a click event listener to all anchor elements

    return () => {};
    // Rest of your logic
  }, [rangedReload, audioURLs, preview, searchCookie]);

  useEffect(() => {
    // Perform your desired action here, such as displaying an alert
    if (audioURLs.length != 0) {
      setRedirectPage(true);
    } else {
      setRedirectPage(false);
    }
    // Rest of your logic
  }, [audioURLs]);

  onAuthStateChanged(auth, (user) => {
    if (user) {
      const uid = user.uid;
      // localStorage.setItem("firebasetoken", uid);
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  // const [changeSearch, setChangeSearch] = useState([]);
  const [addNotes, setAddNotes] = useState([]);
  const [uploadImage, setUploadImage] = useState([]);
  const [allNotes, setAllNotes] = useState([]);
  const [allTItle, setAllTitle] = useState([]);
  // const [dataReqList, setDataReqList] = useState([]);

  const [titleInput, setTitleInput] = useState(false);
  const [file, setFile] = useState();
  const [noteSp, setNoteSp] = useState(false);
  const [allTItle1, setAllTitle1] = useState([]);
  const [saveId, setSaveId] = useState("");
  const [postsPerPage] = useState(20); // made changes 19
  const [offsetValue, setOffsetValue] = useState(0);

  const [searchValue, setSearchValue] = useState(undefined);
  const [searchValueBool, setSearchValueBool] = useState(false);

  const [offset, setOffset] = useState(0);
  const [sortValue, setSortValue] = useState("lastOpened");

  const [posts, setAllPosts] = useState([]);
  const [newDataForPagi, setNewDataForPagi] = useState([]);
  const [atTag, setAtTag] = useState([]);
  const [atTagB, setAtTagB] = useState(false);

  const [pageCount, setPageCount] = useState(1);
  const [valueOp, setValueOp] = useState(false);
  const [pageSearchValue, setPageSearchValue] = useState(false);

  const [isLocked, setIsLocked] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [defaultLi, setDefaultLi] = useState(false);

  const [searchValueHash, setsearchValueHash] = useState("");
  const [searchValueATR, setsearchValueATR] = useState("");

  // const [sharedNoteUser, setSharedNoteUser] = useState([]);

  const downPress = useKeyPress("ArrowDown");
  const upPress = useKeyPress("ArrowUp");
  const enterPress = useKeyPress("Enter");
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [hovered1, setHovered1] = useState(undefined);

  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [hashAdd, setHashAdd] = React.useState(false);

  const [usage, setUsage] = React.useState({});

  // const [records, updateRecords] = useState([]);
  let hashTitle = [];
  const handleOpen = () => setOpen2(true);
  const handleOpenHash = () => {
    setHashAdd(true);
  };
  const handleClose = () => setOpen2(false);
  const handleOpenTem = () => setOpen5(true);
  const handleCloseTem = () => {
    setOpen5(false);
    setTemplate(true);
  };
  const handleOpen1 = (res) => {
    setOpen3(true);
    setUsage({
      used: res.used,
      limit: res.limit,
    });
  };
  const handleClose1 = () => setOpen3(false);
  const handleOpen2 = () => setOpen4(true);
  const handleClose2 = () => setOpen4(false);
  // const { isRecording, stop, start } = useVoiceRecorder((data) => {
  //   updateRecords([...records, window.URL.createObjectURL(data)]);
  // });
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const titleKey = urlParams?.get("data-custom-title");
  useEffect(() => {
    // window.history.replaceState({}, location?.state?.dataTitle);
    if (titleKey != undefined) {
      setSearchValue(titleKey);
      setIsLocked(true);
      const fetchNote = async () => {
        try {
          let url1 = `${config.base_api_url}/api/getnoteMain`;
          let method1 = "GET";
          let response = await fetchExtreamApi(url1, method1);
          const changeSearch = response;
          const result = filterByKeywords(changeSearch, titleKey);
          console.log(result, "resultresultresult");
          setNewDataForPagi(result);
          if (offset * postsPerPage != offsetValue) {
            let offsetValueNew = offset * postsPerPage;

            const slice = result.slice(
              offsetValueNew,
              offsetValueNew + postsPerPage
            );
            setOffsetValue(offsetValueNew + postsPerPage);

            const postData = GetPostData(slice);
            setAllPosts(postData);
            setPageCount(Math.ceil(result?.length / postsPerPage));
          } else {
            const slice = result.slice(offsetValue, offsetValue + postsPerPage);
            setOffsetValue(offsetValue + postsPerPage);
            const postData = GetPostData(slice);
            setAllPosts(postData);
            setPageCount(Math.ceil(result?.length / postsPerPage));
          }
          setIsLocked(false);
        } catch (error) {
          console.error(error);
        }
      };
      fetchNote();
    }
  }, [titleKey]);

  const Item = ({ entity: { text } }) => {
    return titleInput && <div>{`${text}`}</div>;
  };
  const Loading = () => <div>Loading</div>;
  // const addNote = (e) => {
  //   e.preventDefault();
  //   if (template != false && templateName.length != 0) {
  //     alert("yes name");
  //   } else {
  //     handleOpenTem();
  //   }
  // };

  const addNote = async (e) => {
    e.preventDefault();
    const string = quill.root.innerHTML;

    const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
    const imgSrcMatches = string.matchAll(imgSrcRegex);

    const srcArray = [];
    let imgId = 0; // Counter for assigning image ids

    // Regular expression pattern to match <img> tags
    let imgPattern1 = /<img\b[^>]*>/gi;

    // Removing <img> tags and adding id attribute
    let newString = string.replace(imgPattern1, function (match) {
      let idAttribute = ' src="id:' + imgId++ + '"';
      return match.replace(/src="[^"]*"/, idAttribute);
    });
    for (const match of imgSrcMatches) {
      srcArray.push(match[1]);
    }
    let imagesBase = [];
    if (srcArray.length != 0) {
      for (let imagesCov of srcArray) {
        const file = base64ToFile(imagesCov, "image");
        imagesBase.push(file);
      }
    }
    function base64ToFile(base64, filename) {
      const arr = base64.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      const fileExtension = mime.split("/")[1];

      // Append the file extension to the file name
      const fullFileName = `${filename}.${fileExtension}`;
      const file = new File([u8arr], fullFileName, { type: mime });

      return file;
    }

    setNotes(newString);
    setNotesTitle(quill.getText());
    setNotesTitle1(quill.getContents());
    setAddNotes([...addNotes, newString]);
    setTitleInput(false);
    setAllTitle([]);

    const data = new FormData();
    const hashTag = quill
      .getText()
      .split(" ")
      .filter((v) => v.startsWith("#"));
    // const check = hashTag[0].split(" ").filter((v) => v.replace(/\n/g, " "));

    // data.append("title", `${hashTag}`)
    const arr = [];
    if (hashTag[0] != undefined && hashTag.length != 1) {
      for (let tag of hashTag) {
        const checktag = tag.replace(/\<(?!li).*?\>/g, "");
        if (checktag?.includes("\n") == true) {
          const checkhashBracket = (s) =>
            s.includes("#") && s.substr(s.startsWith("#")).split("\n")[0];
          const result = checkhashBracket(checktag);
          arr.push(`#${result.replace(/[^\w\s#]/gi, "")}`);
        } else {
          arr.push(checktag.replace(/[^\w\s#]/gi, ""));
        }
      }
    } else if (hashTag.length == 1) {
      const checktag = quill.getText().match(/[^\s]+/g);
      const hashTag = checktag.filter((data) => {
        return data.startsWith("#");
      });
      for (let tag of hashTag) {
        arr.push(tag);
      }
    } else {
      // const string = quill.getContents().ops[0];
      if (quill.getContents().ops.length == 1) {
        const stage = quill.getContents().ops[0].insert.replace(/\n/g, " ");
        const result = stage.split(" ").filter((v) => v.startsWith("#"));
        for (let data of result) {
          arr.push(data);
        }
      } else if (quill.getContents().ops.length > 1) {
        for (let data of quill.getContents().ops) {
          if (data.insert?.image != undefined || data.insert?.image != null) {
          } else {
            const result = data.insert
              .split(" ")
              .filter((v) => v.startsWith("#"));
            if (result != []) {
              for (let test of result) {
                result != [] && arr.push(test);
              }
            }
            // result != [] && arr.push(result);
          }
        }
      }
    }
    let arrFilter = [];
    for (let check of arr) {
      if (check.length >= 2) {
        arrFilter.push(check);
      }
    }
    arrFilter.length != 0 && data.append("title", arrFilter);
    data.append("description", newString);

    const blob = audioURLs;
    if (blob.length != 0) {
      for (let blobArr of blob) data.append("audio", blobArr.file);
    }
    audioURLs.forEach((item) => {
      data.append(`audioInfo`, JSON.stringify(item));
    });
    if (uploadImage.length != 0) {
      for (let uploadImageArr of uploadImage)
        data.append("fileAttachment", uploadImageArr);
    }
    if (imagesBase.length != 0) {
      for (let uploadImageConArr of imagesBase)
        data.append("imagesBase", uploadImageConArr);
    }
    if (audioURLs.length != 0) {
      for (let audioInfo of audioURLs) {
        data.append("audioInfo", JSON.stringify(audioInfo));
      }
    }
    if (
      imagesBase.length != 0 ||
      uploadImage.length != 0 ||
      audioURLs.length != 0
    ) {
      let url1 = `${config.base_api_url}/api/imagesSizeCount`;
      let method1 = "POST";
      await fetchExtreamApi(url1, method1, data)
        .then((res) => {
          if (res?.message == "No") {
            handleOpen1(res);
          } else {
            if (template != false) {
              if (templateName.length != 0) {
                data.append("tempName", templateName);

                (async () => {
                  let url1 = `${config.base_api_url}/api/addTemplate`;
                  let method1 = "POST";
                  await fetchExtreamApi(url1, method1, data)
                    .then((response) => {
                      if (response?.mergedArrayAllInfo.length != 0) {
                        for (let imagesInfo of response?.mergedArrayAllInfo) {
                          axios
                            .post(
                              `${config.base_api_url}/api/AddSizeOfImageInfo`,

                              {
                                id_note: response?.userDetails?._id,
                                s3location: imagesInfo?.imag_loc,
                                size: imagesInfo?.image_size,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer ${localStorage.getItem(
                                    "firebasetoken"
                                  )}`,
                                  // "Content-Type": "multipart/form-data",
                                },
                              }
                            )
                            .then((res) => {})
                            .catch((error) => {
                              swal({
                                title: error?.response?.data?.Error,
                                icon: "error",
                                dangerMode: true,
                              });
                              // setNotes("");
                              // setUploadImage("");
                            });
                        }
                      }
                      if (response?.AudioAllFilesInfo.length != 0) {
                        for (let audioInfo of response?.AudioAllFilesInfo) {
                          axios
                            .post(
                              `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                              {
                                id_note: response?.userDetails?._id,
                                s3location: audioInfo?.Audio_loc,
                                size: audioInfo?.Audio_size,
                              },
                              {
                                headers: {
                                  Authorization: `Bearer ${localStorage.getItem(
                                    "firebasetoken"
                                  )}`,
                                },
                              }
                            )
                            .then((res) => {
                              console.log("Successfully added", res);
                            })
                            .catch((error) => {
                              swal({
                                title: error?.response?.data?.Error,
                                icon: "error",
                                dangerMode: true,
                              });
                              // setNotes("");
                              // setUploadImage("");
                              console.error("There was an error!", error);
                            });
                        }
                      }

                      setNotes("");
                      setUploadImage("");
                      navigate(`/setting`, { state: { value: "5" } });
                      // window.location.reload();
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      setNotes("");
                      setUploadImage("");
                      console.error("There was an error!", error);
                    });
                  setAudioURLs([]);

                  setNotes("");
                })();
              } else {
                handleOpenTem();
              }
            } else {
              let url1 = `${config.base_api_url}/api/addnote`;
              let method1 = "POST";
              (async () => {
                await fetchExtreamApi(url1, method1, data)
                  .then((response) => {
                    if (response?.mergedArrayAllInfo.length != 0) {
                      for (let imagesInfo of response?.mergedArrayAllInfo) {
                        axios
                          .post(
                            `${config.base_api_url}/api/AddSizeOfImageInfo`,

                            {
                              id_note: response?.userDetails?._id,
                              s3location: imagesInfo?.imag_loc,
                              size: imagesInfo?.image_size,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                  "firebasetoken"
                                )}`,
                                // "Content-Type": "multipart/form-data",
                              },
                            }
                          )
                          .then((res) => {})
                          .catch((error) => {
                            swal({
                              title: error?.response?.data?.Error,
                              icon: "error",
                              dangerMode: true,
                            });
                            // setNotes("");
                            // setUploadImage("");
                          });
                      }
                    }
                    if (response?.AudioAllFilesInfo.length != 0) {
                      for (let audioInfo of response?.AudioAllFilesInfo) {
                        axios
                          .post(
                            `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                            {
                              id_note: response?.userDetails?._id,
                              s3location: audioInfo?.Audio_loc,
                              size: audioInfo?.Audio_size,
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                  "firebasetoken"
                                )}`,
                              },
                            }
                          )
                          .then((res) => {
                            console.log("Successfully added", res);
                          })
                          .catch((error) => {
                            swal({
                              title: error?.response?.data?.Error,
                              icon: "error",
                              dangerMode: true,
                            });
                            // setNotes("");
                            // setUploadImage("");
                            console.error("There was an error!", error);
                          });
                      }
                    }

                    setNotes("");
                    setUploadImage("");
                    navigate(`/admin/editnotes/${response?.userDetails?._id}`);
                    // window.location.reload();
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    setNotes("");
                    setUploadImage("");
                    console.error("There was an error!", error);
                  });
                setAudioURLs([]);

                setNotes("");
              })();
            }
          }

          // setDataReqList(response.data?.find_shareWith);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    } else {
      if (template != false) {
        if (templateName.length != 0) {
          data.append("tempName", templateName);

          let url1 = `${config.base_api_url}/api/addTemplate`;
          let method1 = "POST";
          await fetchExtreamApi(url1, method1, data)
            .then((response) => {
              if (response?.mergedArrayAllInfo.length != 0) {
                for (let imagesInfo of response?.mergedArrayAllInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfImageInfo`,

                      {
                        id_note: response?.userDetails?._id,
                        s3location: imagesInfo?.imag_loc,
                        size: imagesInfo?.image_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                          // "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((res) => {})
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                    });
                }
              }
              if (response?.AudioAllFilesInfo.length != 0) {
                for (let audioInfo of response?.AudioAllFilesInfo) {
                  axios
                    .post(
                      `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                      {
                        id_note: response?.userDetails?._id,
                        s3location: audioInfo?.Audio_loc,
                        size: audioInfo?.Audio_size,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem(
                            "firebasetoken"
                          )}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Successfully added", res);
                    })
                    .catch((error) => {
                      swal({
                        title: error?.response?.data?.Error,
                        icon: "error",
                        dangerMode: true,
                      });
                      // setNotes("");
                      // setUploadImage("");
                      console.error("There was an error!", error);
                    });
                }
              }

              setNotes("");
              setUploadImage("");
              navigate(`/setting`, { state: { value: "5" } });
              // window.location.reload();
            })
            .catch((error) => {
              swal({
                title: error?.response?.data?.Error,
                icon: "error",
                dangerMode: true,
              });
              setNotes("");
              setUploadImage("");
              console.error("There was an error!", error);
            });
          setAudioURLs([]);

          setNotes("");
        } else {
          handleOpenTem();
        }
      } else {
        let url1 = `${config.base_api_url}/api/addnote`;
        let method1 = "POST";
        await fetchExtreamApi(url1, method1, data)
          .then((response) => {
            if (response?.mergedArrayAllInfo.length != 0) {
              for (let imagesInfo of response?.mergedArrayAllInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfImageInfo`,

                    {
                      id_note: response?.userDetails?._id,
                      s3location: imagesInfo?.imag_loc,
                      size: imagesInfo?.image_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                        // "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {})
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                  });
              }
            }
            if (response?.AudioAllFilesInfo.length != 0) {
              for (let audioInfo of response?.AudioAllFilesInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                    {
                      id_note: response?.userDetails?._id,
                      s3location: audioInfo?.Audio_loc,
                      size: audioInfo?.Audio_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }

            setNotes("");
            setUploadImage("");
            navigate(`/admin/editnotes/${response?.userDetails?._id}`);
            // window.location.reload();
          })
          .catch((error) => {
            swal({
              title: error?.response?.Error,
              icon: "error",
              dangerMode: true,
            });
            setNotes("");
            setUploadImage("");
            console.error("There was an error!", error);
          });
        setAudioURLs([]);

        setNotes("");
      }
    }
  };
  const updateTemplate = (e) => {
    e.preventDefault();
    const notesTitle1 = quill.getContents();
    const notesTitle = quill.getText();
    const notes = quill.root.innerHTML;
    const id = templateSelected._id;
    const notesVersion = templateSelected.description;
    if (notesTitle1 != undefined && notesTitle != undefined) {
      // edit note

      const stringText = quill.root.innerHTML;
      const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
      const imgSrcMatches = stringText.matchAll(imgSrcRegex);

      const srcArray = [];

      for (const match of imgSrcMatches) {
        const srcAttribute = match[1];
        if (srcAttribute.startsWith("data:image/")) {
          srcArray.push(srcAttribute);
        }
      }
      let imgId = 0; // Counter for assigning image ids

      // Regular expression pattern to match <img> tags
      let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

      // Removing <img> tags and adding id attribute
      let newString = stringText.replace(imgPattern1, function (match) {
        let idAttribute = ' src="id:' + imgId++ + '"';
        return match.replace(/src="[^"]*"/, idAttribute);
      });
      let imagesBase = [];
      if (srcArray.length != 0) {
        for (let imagesCov of srcArray) {
          const file = base64ToFile(imagesCov, "image");
          imagesBase.push(file);
        }
      }
      function base64ToFile(base64, filename) {
        const arr = base64.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const fileExtension = mime.split("/")[1];

        // Append the file extension to the file name
        const fullFileName = `${filename}.${fileExtension}`;
        const file = new File([u8arr], fullFileName, { type: mime });

        return file;
      }
      // newString;
      const hashTag = notesTitle
        .replace(/\n/g, " ")
        .split(" ")
        .filter((v) => v.startsWith("#"));
      // const check = hashTag[0].split(" ").filter((v) => v.replace(/\n/g, " "));
      const data = new FormData();

      // data.append("title", `${hashTag}`);
      const arr = [];
      if (hashTag[0] != undefined && hashTag.length != 1) {
        for (let tag of hashTag) {
          const checktag = tag.replace(/\<(?!li).*?\>/g, "");
          if (checktag?.includes("\n") == true) {
            const checkhashBracket = (s) =>
              s.includes("#") && s.substr(s.startsWith("#")).split("\n")[0];
            const result = checkhashBracket(checktag);
            arr.push(`#${result.replace(/[^\w\s#]/gi, "")}`);
          } else {
            arr.push(checktag.replace(/[^\w\s#]/gi, ""));
          }
        }
      } else if (hashTag.length == 1) {
        const checktag = notesTitle.match(/[^\s]+/g);
        const hashTag = checktag.filter((data) => {
          return data.startsWith("#");
        });
        for (let tag of hashTag) {
          arr.push(tag);
        }
      } else {
        const string = notesTitle1.ops[0];
        if (notesTitle1.ops.length == 1) {
          const stage = notesTitle1.ops[0].insert.replace(/\n/g, " ");
          const result = stage.split(" ").filter((v) => v.startsWith("#"));
          for (let data of result) {
            arr.push(data);
          }
        } else if (notesTitle1.ops.length > 1) {
          for (let data of notesTitle1.ops) {
            if (data.insert?.image != undefined || data.insert?.image != null) {
            } else {
              const result = data.insert
                .split(" ")
                .filter((v) => v.startsWith("#"));
              if (result != []) {
                for (let test of result) {
                  result != [] && arr.push(test);
                }
              }
              // result != [] && arr.push(result);
            }
          }
        }
      }
      let arrFilter = [];
      for (let check of arr) {
        if (check.length >= 2) {
          arrFilter.push(check);
        }
      }
      arrFilter.length != 0 && data.append("title", arrFilter);
      data.append("description", newString);
      data.append("id", id);
      data.append("notes", notesVersion);
      const blob = audioURLs;
      if (uploadImage.length != 0) {
        for (let uploadImageArr of uploadImage)
          data.append("fileAttachment", uploadImageArr);
      }
      if (blob.length != 0) {
        for (let blobArr of blob) data.append("audio", blobArr.file);
      }
      audioURLs.forEach((item) => {
        data.append(`audioInfo`, JSON.stringify(item));
      });
      if (imagesBase.length != 0) {
        for (let uploadImageConArr of imagesBase)
          data.append("imagesBase", uploadImageConArr);
      }
      let url1 = `${config.base_api_url}/api/editTemplate`;
      let method1 = "PUT";
      (async () => {
        await fetchExtreamApi(url1, method1, data)
          .then((response) => {
            if (response?.mergedArrayAllInfo.length != 0) {
              for (let imagesInfo of response?.mergedArrayAllInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfImageInfo`,

                    {
                      id_note: id,
                      s3location: imagesInfo?.imag_loc,
                      size: imagesInfo?.image_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                        // "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            if (response?.AudioAllFilesInfo.length != 0) {
              for (let audioInfo of response?.AudioAllFilesInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                    {
                      id_note: id,
                      s3location: audioInfo?.Audio_loc,
                      size: audioInfo?.Audio_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            swal({
              title: "Edit Template sucessfully!!",
              icon: "success",
            });
            setAudioURLs([]);
            setPreview([]);
            setRangedReload(0);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: "Something went wrong!",
              icon: "error",
              dangerMode: true,
            });
          });
      })();

      // arr.length != 0 ? data.append("title", arr) : data.append("title", null);
    } else {
      const stringText = quill.root.innerHTML;
      const imgSrcRegex = /<img[^>]+src="([^">]+)"/g;
      const imgSrcMatches = stringText.matchAll(imgSrcRegex);

      const srcArray = [];

      for (const match of imgSrcMatches) {
        const srcAttribute = match[1];
        if (srcAttribute.startsWith("data:image/")) {
          srcArray.push(srcAttribute);
        }
      }
      let imgId = 0; // Counter for assigning image ids

      // Regular expression pattern to match <img> tags
      let imgPattern1 = /<img src="data:image\b[^>]*>/gi;

      // Removing <img> tags and adding id attribute
      let newString = stringText.replace(imgPattern1, function (match) {
        let idAttribute = ' src="id:' + imgId++ + '"';
        return match.replace(/src="[^"]*"/, idAttribute);
      });
      let imagesBase = [];
      if (srcArray.length != 0) {
        for (let imagesCov of srcArray) {
          const file = base64ToFile(imagesCov, "image");
          imagesBase.push(file);
        }
      }
      function base64ToFile(base64, filename) {
        const arr = base64.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        const fileExtension = mime.split("/")[1];

        // Append the file extension to the file name
        const fullFileName = `${filename}.${fileExtension}`;
        const file = new File([u8arr], fullFileName, { type: mime });

        return file;
      }

      const data = new FormData();
      data.append("title", undefined);
      data.append("description", newString);
      data.append("id", id);
      data.append("notes", notesVersion);

      const blob = audioURLs;
      if (blob.length != 0) {
        for (let blobArr of blob) data.append("audio", blobArr.file);
      }
      audioURLs.forEach((item) => {
        data.append(`audioInfo`, JSON.stringify(item));
      });
      if (uploadImage.length != 0) {
        for (let uploadImageArr of uploadImage)
          data.append("fileAttachment", uploadImageArr);
      }
      if (imagesBase.length != 0) {
        for (let uploadImageConArr of imagesBase)
          data.append("imagesBase", uploadImageConArr);
      }
      let url1 = `${config.base_api_url}/api/editTemplate`;
      let method1 = "PUT";
      (async () => {
        await fetchExtreamApi(url1, method1, data)
          .then((response) => {
            if (response?.mergedArrayAllInfo.length != 0) {
              for (let imagesInfo of response?.mergedArrayAllInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfImageInfo`,

                    {
                      id_note: id,
                      s3location: imagesInfo?.imag_loc,
                      size: imagesInfo?.image_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                        // "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            if (response?.AudioAllFilesInfo.length != 0) {
              for (let audioInfo of response?.AudioAllFilesInfo) {
                axios
                  .post(
                    `${config.base_api_url}/api/AddSizeOfAudioInfo`,

                    {
                      id_note: id,
                      s3location: audioInfo?.Audio_loc,
                      size: audioInfo?.Audio_size,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          "firebasetoken"
                        )}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Successfully added", res);
                  })
                  .catch((error) => {
                    swal({
                      title: error?.response?.data?.Error,
                      icon: "error",
                      dangerMode: true,
                    });
                    // setNotes("");
                    // setUploadImage("");
                    console.error("There was an error!", error);
                  });
              }
            }
            swal({
              title: "Edit Template sucessfully!!",
              icon: "success",
            });
            setAudioURLs([]);
            setPreview([]);
            setRangedReload(0);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            console.error("There was an error!", error);
            swal({
              title: "Something went wrong!",
              icon: "error",
              dangerMode: true,
            });
          });
      })();
    }
  };
  const filterByKeywords = (arr, keyword) => {
    let result = [];

    if (keyword === undefined) {
      return [...arr];
    } else {
      for (let i = 0; i < arr.length; i++) {
        const spaceKey = keyword.split(" ");
        let currentObject = arr[i];

        if (currentObject.description != undefined) {
          let allPresent = spaceKey.every((value) =>
            currentObject.description
              .replace(/<\/?[^>]+(>|$)/g, "")
              .toLowerCase()
              .includes(value.toLowerCase())
          );

          if (allPresent) {
            result.push({ ...currentObject });
          }
        } else {
          let allPresent = spaceKey.every((value) =>
            currentObject?.note_reference?.description
              .replace(/<\/?[^>]+(>|$)/g, "")
              .toLowerCase()
              .includes(value.toLowerCase())
          );

          if (allPresent) {
            result.push({ ...currentObject });
          }
        }
      }
      setNewDataForPagi(result);
      return [...result];
    }
  };
  const handlechangeexist = () => {
    const changeSearch = allNotes;
    const result = filterByKeywords(changeSearch, searchValue);
    setNewDataForPagi(result);
    if (offset * postsPerPage != offsetValue) {
      let offsetValueNew = offset * postsPerPage;

      const slice = result.slice(offsetValueNew, offsetValueNew + postsPerPage);
      setOffsetValue(offsetValueNew + postsPerPage);

      const postData = GetPostData(slice);
      setAllPosts(postData);
      setPageCount(Math.ceil(result?.length / postsPerPage));
    } else {
      const slice = result.slice(offsetValue, offsetValue + postsPerPage);
      setOffsetValue(offsetValue + postsPerPage);
      const postData = GetPostData(slice);
      setAllPosts(postData);
      setPageCount(Math.ceil(result?.length / postsPerPage));
    }
  };

  const handleChange = (e) => {
    const changeSearch = allNotes;
    const result = filterByKeywords(changeSearch, e.target.value);
    setSearchValue(e.target.value);
    setSearchValueBool(true);
    setNewDataForPagi(result);
    console.log(result, "searchjiyjgjhgj");
    if (offset * postsPerPage != offsetValue) {
      let offsetValueNew = offset * postsPerPage;
      console.log(
        result,
        "checkThePostNewYeah",
        offsetValueNew,
        offset * postsPerPage
      );
      console.log(offsetValue, "jkhjjhgfghf1");
      const slice = result.slice(offsetValueNew, offsetValueNew + postsPerPage);
      console.log(slice, "jkhjjhgfghf2");
      setOffsetValue(offsetValueNew + postsPerPage);

      const postData = GetPostData(slice);
      console.log(result?.length, "lengthData");
      setAllPosts(postData);
      setPageCount(Math.ceil(result?.length / postsPerPage));
    } else {
      const slice = result.slice(offsetValue, offsetValue + postsPerPage);
      setOffsetValue(offsetValue + postsPerPage);
      const postData = GetPostData(slice);
      setAllPosts(postData);
      setPageCount(Math.ceil(result?.length / postsPerPage));
    }
  };
  const handleEdit = (data, parentid) => {
    navigate(`/admin/editnotes/${data?._id}`);
    // navigate("/admin/editnotes", {
    //   state: {
    //     id: data?._id,
    //     parentid: parentid,
    //     type: data?.type,
    //     permission: data?.permission,
    //   },
    // });
  };
  const handleEditPage = (data) => {
    setJustSetCookie(true);
    setPageSearchValue(true);
    axios.post(
      `${config.base_api_url}/api/SaveLastOpenDate`,
      { open_id: data?._id },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
        },
      }
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        let url1 = `${config.base_api_url}/api/getnoteMain`;
        let method1 = "GET";
        let response = await fetchExtreamApi(url1, method1);

        setAllNotes(response);

        let arr = [];

        response?.map((data) => {
          data?.title && !arr.includes(data?.title) && arr.push(data?.title);
        });

        const title = arr.length != 0 && arr.flat();

        let removesubHash = [];

        for (let data of title) {
          removesubHash.push(data.replace(/\b\#\w+/g, ""));
        }
        let uniqueChars = [...new Set(removesubHash)];

        setAllTitle(uniqueChars);
        setAllTitle1(uniqueChars);
        // setChangeSearch(response);
        // setOpen(false);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();

    (async () => {
      let url1 = `${config.base_api_url}/api/ReqShellListPartUser`;
      let method1 = "GET";
      await fetchExtreamApi(url1, method1)
        .then((response) => {
          // setDataReqList(response.data?.data);
          for (let arr of response?.data) {
            if (arr.count == true && arr.type == "requestShell") {
              axios
                .post(
                  `${config.base_api_url}/api/UpdateCountNotification`,
                  {
                    main_id: arr._id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem(
                        "firebasetoken"
                      )}`,
                    },
                  }
                )
                .then((res) => {})
                .catch((error) => {
                  console.error("There was an error!", error);
                });
            }
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    })();

    const fetchData1 = async () => {
      try {
        const collectionRef = collection(db, "STRIPE_PRODUCTS");
        const querySnapshot = await getDocs(collectionRef);
        const fetchedData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPlanPackages(fetchedData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData1();
    const fetchNote3 = async () => {
      try {
        let url1 = `${config.base_api_url}/api/getAllTemplate`;
        let method1 = "GET";
        let response = await fetchExtreamApi(url1, method1);
        setTemplateList(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchNote3();
  }, []);
  const handleAcceptNote = (data) => {
    axios
      .post(
        `${config.base_api_url}/api/AddNoteShell/${data?._id}`,
        {
          access: data?.access,
          shareWith: data?.shareWith,
          User_main_id: data?.User_main_id,
          id_note: data?.id_note?._id,
          current_userId: data?.current_userId?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
          },
        }
      )
      .then((response) => {
        swal({
          title: response?.data?.message,
          icon: "success",
          // dangerMode: true,
        }).catch((err) => console.log(err));
        window.location.reload();
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    axios
      .post(
        `${config.base_api_url}/api/AddAcceptRequestNoteNotification`,
        {
          access: data?.access,
          shareWith: data?.shareWith,
          User_main_id: data?.User_main_id,
          id_note: data?.id_note?._id,
          current_userId: data?.current_userId?._id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("firebasetoken")}`,
          },
        }
      )
      .then((res) => {
        // setDataReqList(response.data?.find_shareWith);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  // const qlImages = document.querySelectorAll(".ql-image");

  // qlImages.forEach((qlImage) => {
  //   qlImage.addEventListener("click", (event) => {
  //     // Your onclick logic here
  //   });
  // });
  const handleDeclineNote = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to decline this note!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your audio has been deleted.", "success");
        axios
          .post(
            `${config.base_api_url}/api/DeclineReqShell/${data?._id}`,
            {
              shareWith: data?.shareWith,
              id_note: data?.id_note?._id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((response) => {
            window.location.reload();
          });
        axios
          .post(
            `${config.base_api_url}/api/AddDeclineRequestNoteNotification`,
            {
              access: data?.access,
              shareWith: data?.shareWith,
              User_main_id: data?.User_main_id,
              id_note: data?.id_note?._id,
              current_userId: data?.current_userId?._id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  "firebasetoken"
                )}`,
              },
            }
          )
          .then((res) => {
            console.log("AcceptList", res);

            // setDataReqList(response.data?.find_shareWith);
          })
          .catch((error) => {
            console.error("There was an error!", error);
          });
      }
    });
  };
  var toolbarDiv = document.querySelector("div.ql-toolbar.ql-snow");
  React.useEffect(() => {
    if (toolbarDiv != null) {
      var spanElement = document.createElement("span");
      var iElement = document.createElement("i");
      iElement.className = "fa-solid fa-print";

      spanElement.appendChild(iElement);
      spanElement.onclick = handlePrint; // Assuming handlePrint is the function to be executed on click
      spanElement.className = "spanPrint";
      // Append the span element to the toolbar div
      toolbarDiv.appendChild(spanElement);
    }
  }, [quill]);
  React.useEffect(() => {
    if (quill) {
      let newRange = [];
      let newRange1 = [];
      quill.on("editor-change", (eventName, ...args) => {
        if (eventName === "selection-change") {
          const range = args[0];
          if (range) {
            newRange.push(range.index);
            console.log(range.index, "hujgjhgjhg");
            setRangedReload(range.index);

            if (range.index == 0) {
              setRedirectPage(false);
            } else {
              setRedirectPage(true);
            }
          }
        }
      });

      quill.on("text-change", (delta, oldDelta, source) => {
        // quill.trigger("editor-change", "text-change", delta, oldDelta, source);
        const event = new Event("quill-change");
        event.delta = delta;
        event.oldDelta = oldDelta;
        event.source = source;
        quill.root.dispatchEvent(event);

        const getUser = (s) =>
          s.includes("#") && s.substr(s.lastIndexOf("#") + 1).split(" ")[0];

        if (quill.getText().match("#")) {
          var range = quill.getSelection();

          const fetchData = async () => {
            try {
              let url1 = `${config.base_api_url}/api/getnoteMain`;
              let method1 = "GET";
              let response = await fetchExtreamApi(url1, method1);

              let arr = [];
              let arr1 = [];
              response?.map((data) => {
                if (data?.type == "TagShell") {
                  data?.title &&
                    !arr1.includes(data.title) &&
                    arr1.push({
                      title: data?.title,
                      shareBy: data?.sharedBy?.fname + data?.sharedBy?.lname,
                    });
                } else {
                  data?.title &&
                    !arr1.includes(data?.title) &&
                    arr1.push({ title: data?.title, shareBy: "No" });
                }
              });
              const titleMain = arr1?.length != 0 && arr1.flat();
              let removesubHash = [];
              hashTitle = [];
              for (let data of titleMain) {
                if (data?.title[0] != undefined) {
                  if (data?.shareBy != "No") {
                    removesubHash.push(
                      "@" +
                        data?.shareBy +
                        data?.title[0].replace(/\b\#\w+/g, "")
                    );
                  } else {
                    removesubHash.push(data?.title[0].replace(/\b\#\w+/g, ""));
                  }
                }
              }
              let uniqueChars = [...new Set(removesubHash)];
              let allHeads = [uniqueChars];

              const subHead = arr1.flat().filter((data) => {
                if (data?.title[0] != undefined) {
                  // return data?.title[0].match(/#/g).length >= 2;
                  if (data?.shareBy != "No") {
                    if (data?.title[0].match(/#/g).length >= 2) {
                      allHeads.push("@" + data?.shareBy + data?.title[0]);
                    }
                  } else {
                    if (data?.title[0].match(/#/g).length >= 2) {
                      allHeads.push(data?.title[0]);
                    }
                  }
                }
              });
              allHeads.push(subHead);

              const data = [];
              //  ==================================================================================
              var str = quill.getText();
              var stringPosWord = [];
              // let postText = text.replace(regexp, "");

              let test = str.substring(str.lastIndexOf("#") + 1, str.length);
              function getWordAt(str, pos) {
                // Perform type conversions.
                str = String(str);
                pos = Number(pos) >>> 0;

                // Search for the word's beginning and end.
                var left = str.slice(0, pos + 1).search(/\S+$/),
                  right = str.slice(pos).search(/\s/);

                // The last word in the string is a special case.
                if (right < 0) {
                  stringPosWord.push(str.slice(left));
                  return str.slice(left);
                }

                // Return the word, using the located bounds to extract it from the string.
                stringPosWord.push(str.slice(left, right + pos));

                return str.slice(left, right + pos);
              }
              let num = quill.getSelection()?.index;
              if (num != undefined) {
                getWordAt(quill.getText().replace(/\n/g, " "), num - 1);
              }
              if (stringPosWord.length != 0) {
                const data1 = allHeads.flat().filter((text) => {
                  if (text.includes("@")) {
                    let atAdd = text.split(/(?=#)/g);

                    return text
                      ?.toLowerCase()

                      .startsWith(
                        `${atAdd[0]}${stringPosWord[0].trim()}`.toLowerCase()
                      );
                  } else {
                    return text
                      ?.toLowerCase()

                      .startsWith(`${stringPosWord[0].trim()}`.toLowerCase());
                  }
                });
                data.push(data1);
              }

              if (data[0] != undefined) {
                if (data[0].length != 0 && stringPosWord[0].includes("#")) {
                  setNoteSp(true);
                  setsearchValueHash(stringPosWord[0]);
                  setTimeout(() => {
                    // const element = document.getElementById("unicorn");
                    const cursorPosition = 33;
                    const editor1 = document.getElementById("unicorn");
                    let arry = newRange;
                    let lastElement = arry[arry.length - 1];
                    setRanged(lastElement);
                    let index = lastElement - stringPosWord[0].length;
                    quill.setSelection(lastElement);

                    // Get the bounds of the selection object
                    const bounds = quill.getBounds(index);
                    // Calculate the offsetLeft and offsetTop properties of the cursor position
                    const offsetLeftk =
                      bounds.left + window.pageXOffset - editor1.offsetLeft;
                    const offsetTopk =
                      bounds.top + window.pageYOffset - editor1.offsetTop;
                    const offsetBottom = bounds.top + bounds.height;
                    //  bounds.top + window.pageYOffset - editor1.offsetHeight;

                    // var elmnt = document.getElementsByClassName("activeHas")[0];

                    var float_elm =
                      document.getElementsByClassName("Active_Float")[0];

                    let x = offsetTopk;
                    let positive_x = x < 0 ? -x : x;
                    if (offsetTopk <= -50) {
                      float_elm.style.top = `${positive_x + 10}px`;
                    } else {
                      float_elm.style.top = `${positive_x + 60}px`;
                    }

                    float_elm.style.left = `${offsetLeftk + 5}px`;
                  }, 1000);
                } else {
                  setNoteSp(false);
                  if (stringPosWord[0].includes("#")) {
                    setTimeout(() => {
                      let arry = newRange;
                      let lastElement = arry[arry.length - 1];

                      quill.setSelection(lastElement);
                    }, 500);
                  }
                }
              }

              hashTitle.push(data[0]);

              return hashTitle;
            } catch (error) {
              console.error(error.message);
            }
          };
          fetchData();
          fetchData().then((data) => {
            if (data == undefined) {
              setAllTitle([]);
            } else {
              let uniqueChars = [...new Set(data.flat())];
              let allHeads = [uniqueChars];
              setAllTitle(allHeads.flat().sort());
            }
          });
        } else {
          setNoteSp(false);
        }
        const getUser1 = (s) =>
          s.includes("@") && s.substr(s.lastIndexOf("@") + 1).split(" ")[0];

        if (quill.getText().match("@")) {
          const fetchData = async () => {
            try {
              let url = `${config.base_api_url}/auth/Userslist`;
              let method = "GET";
              let response = await fetchExtreamApi(url, method);
              try {
                // setTimeout(async () => {

                let url1 = `${config.base_api_url}/api/sharedNoteUsers`;
                let method1 = "GET";
                let res = await fetchExtreamApi(url1, method1);

                const emailSet = new Set();
                const filteredData = [];

                res?.sharedNote.forEach((obj) => {
                  if (!emailSet.has(obj.email)) {
                    emailSet.add(obj.email);
                    filteredData.push(obj);
                  }
                });

                let sharedNoteUser = filteredData;

                var stringPosWord = [];
                // let postText = text.replace(regexp, "");

                function getWordAt(str, pos) {
                  // Perform type conversions.
                  str = String(str);
                  pos = Number(pos) >>> 0;

                  // Search for the word's beginning and end.
                  var left = str.slice(0, pos + 1).search(/\S+$/),
                    right = str.slice(pos).search(/\s/);

                  // The last word in the string is a special case.
                  if (right < 0) {
                    stringPosWord.push(str.slice(left));
                    return str.slice(left);
                  }

                  // Return the word, using the located bounds to extract it from the string.
                  stringPosWord.push(str.slice(left, right + pos));

                  return str.slice(left, right + pos);
                }
                let num = quill.getSelection()?.index;
                if (num != undefined) {
                  getWordAt(quill.getText().replace(/\n/g, " "), num - 1);
                }
                if (
                  sharedNoteUser &&
                  stringPosWord.length != 0 &&
                  stringPosWord[0].includes("@")
                ) {
                  if (sharedNoteUser.length != 0) {
                    const tagFilter = sharedNoteUser.filter((data) => {
                      return `${data.fname + data.lname}`
                        .toLowerCase()
                        .trim()
                        .startsWith(
                          stringPosWord[0]
                            .replace(/^@/, "")
                            .trim()
                            .toLowerCase()
                        );
                    });

                    if (tagFilter.length == 0) {
                      const tagFilter1 = response?.list.filter((data) => {
                        return `${data.fname + data.lname}`
                          .toLowerCase()
                          .trim()
                          .startsWith(
                            stringPosWord[0]
                              .replace(/^@/, "")
                              .trim()
                              .toLowerCase()
                          );
                      });

                      if (tagFilter1.length == 0) {
                        setAtTagB(false);
                      } else if (
                        stringPosWord[0].includes("@") &&
                        tagFilter1.length != 0
                      ) {
                        setAtTagB(true);
                        setsearchValueATR(stringPosWord[0]);
                        setTimeout(() => {
                          // const element = document.getElementById("unicorn");
                          const cursorPosition = 33;
                          const editor1 = document.getElementById("unicorn");
                          let arry = newRange;
                          let lastElement = arry[arry.length - 1];
                          setRangedATR(lastElement);
                          let index = lastElement - stringPosWord[0].length;
                          quill.setSelection(lastElement);

                          // Get the bounds of the selection object
                          const bounds = quill.getBounds(index);
                          // Calculate the offsetLeft and offsetTop properties of the cursor position
                          const offsetLeftk =
                            bounds.left +
                            window.pageXOffset -
                            editor1.offsetLeft;
                          const offsetTopk =
                            bounds.top + window.pageYOffset - editor1.offsetTop;
                          const offsetBottom = bounds.top + bounds.height;
                          //  bounds.top + window.pageYOffset - editor1.offsetHeight;

                          // var elmnt = document.getElementsByClassName("activeHas")[0];

                          var float_elm =
                            document.getElementsByClassName("Active_Float")[0];

                          let x = offsetTopk;
                          let positive_x = x < 0 ? -x : x;
                          // if (offsetTopk <= -50) {

                          //   float_elm.style.top = `${positive_x + 10}px`;
                          // } else {

                          //   float_elm.style.top = `${positive_x + 60}px`;
                          // }
                          float_elm.style.top = `${positive_x + 60}px`;
                          float_elm.style.left = `${offsetLeftk + 5}px`;
                        }, 1000);
                      }

                      return tagFilter1;
                    } else if (stringPosWord[0].includes("@")) {
                      setAtTagB(true);
                      setsearchValueATR(stringPosWord[0]);

                      setTimeout(() => {
                        // const element = document.getElementById("unicorn");
                        const cursorPosition = 33;
                        const editor1 = document.getElementById("unicorn");
                        let arry = newRange;
                        let lastElement = arry[arry.length - 1];
                        setRangedATR(lastElement);
                        let index = lastElement - stringPosWord[0].length;
                        quill.setSelection(lastElement);

                        // Get the bounds of the selection object
                        const bounds = quill.getBounds(index);
                        // Calculate the offsetLeft and offsetTop properties of the cursor position
                        const offsetLeftk =
                          bounds.left + window.pageXOffset - editor1.offsetLeft;
                        const offsetTopk =
                          bounds.top + window.pageYOffset - editor1.offsetTop;
                        const offsetBottom = bounds.top + bounds.height;

                        var float_elm =
                          document.getElementsByClassName("Active_Float")[0];

                        let x = offsetTopk;
                        let positive_x = x < 0 ? -x : x;

                        float_elm.style.top = `${positive_x + 60}px`;
                        float_elm.style.left = `${offsetLeftk + 5}px`;
                      }, 1000);

                      return tagFilter;
                    }
                  } else {
                    const tagFilter = response?.list.filter((data) => {
                      return `${data.fname + data.lname}`
                        .toLowerCase()
                        .trim()
                        .startsWith(
                          stringPosWord[0]
                            .replace(/^@/, "")
                            .trim()
                            .toLowerCase()
                        );
                    });
                    if (tagFilter.length == 0) {
                      setAtTagB(false);
                    } else if (stringPosWord[0].includes("@")) {
                      setAtTagB(true);
                      setsearchValueATR(stringPosWord[0]);

                      setTimeout(() => {
                        // const element = document.getElementById("unicorn");
                        const cursorPosition = 33;
                        const editor1 = document.getElementById("unicorn");
                        let arry = newRange;
                        let lastElement = arry[arry.length - 1];
                        setRangedATR(lastElement);
                        let index = lastElement - stringPosWord[0].length;
                        quill.setSelection(lastElement);

                        // Get the bounds of the selection object
                        const bounds = quill.getBounds(index);
                        // Calculate the offsetLeft and offsetTop properties of the cursor position
                        const offsetLeftk =
                          bounds.left + window.pageXOffset - editor1.offsetLeft;
                        const offsetTopk =
                          bounds.top + window.pageYOffset - editor1.offsetTop;
                        const offsetBottom = bounds.top + bounds.height;
                        //  bounds.top + window.pageYOffset - editor1.offsetHeight;

                        // var elmnt = document.getElementsByClassName("activeHas")[0];

                        var float_elm =
                          document.getElementsByClassName("Active_Float")[0];

                        let x = offsetTopk;
                        let positive_x = x < 0 ? -x : x;
                        // if (offsetTopk <= -50) {

                        //   float_elm.style.top = `${positive_x + 10}px`;
                        // } else {

                        //   float_elm.style.top = `${positive_x + 60}px`;
                        // }

                        float_elm.style.top = `${positive_x + 60}px`;
                        float_elm.style.left = `${offsetLeftk + 5}px`;
                      }, 1000);
                    }
                    return tagFilter;
                  }
                } else if (stringPosWord[0].includes("@") == false) {
                  setAtTagB(false);
                }
              } catch (error) {
                // console.error(error.message);
              }

              //  let arr = [];
            } catch (error) {
              console.error(error.message);
            }
          };
          fetchData();
          fetchData().then((data) => {
            setAtTag(data);
            if (data == undefined) {
              setAtTag([]);
            }

            //  setAllTitle(data.flat());
          });
        } else {
          setAtTagB(false);
        }
        // quill.insertText(0, "Hello", "bold", true);

        // setNotes(quill.root.innerHTML);
        // setNotesTitle(quill.getText());
        // setNotesTitle1(quill.getContents());
      });
      quill.root.addEventListener("quill-change", (event) => {
        quill?.trigger(
          "editor-change",
          "text-change",
          event.delta,
          event.oldDelta,
          event.source
        );
      });
    }
  }, [quill]);

  const handlePrint = () => {
    const content = quill.root.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write("<html><head><title>Print</title></head><body>");
    printWindow.document.write("<div>" + content + "</div>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        setUploadImage((prev) => (prev = [...prev, file]));
        const objectUrl = URL.createObjectURL(file);
        setPreview((prev) => (prev = [...prev, objectUrl]));
        setRedirectPage(true);
        // return () => URL.revokeObjectURL(objectUrl);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);
  useEffect(() => {
    window.addEventListener(
      "reload",
      function (e) {
        e.preventDefault();
      },
      false
    );
  }, []);

  useEffect(() => {
    if (titleKey == undefined && searchCookie == null) {
      if (searchValue == undefined && searchValueBool == false) {
        console.log("helloHereImfirst");
        getAllPosts();
      } else {
        // if (searchValue.length < 1) {
        //   getAllPosts();
        // } else {
        handlechangeexist();
        // }
      }
    } else {
      handlechangeexist();
    }
  }, [offset, searchValueBool]);

  useEffect(() => {
    console.log(searchValue, "ikhjkjhgyjhfhg");
    if (pageSearchValue == true) {
      if (searchValue != undefined || titleKey != undefined) {
        const setCookie = (cookieKey, cookieValue) => {
          document.cookie = `${cookieKey}=${cookieValue || ""}; path=/`;
        };
        console.log(searchValue, "hjkhjkhjgjjgjh");
        searchValue != undefined
          ? setCookie("SearchValue", searchValue)
          : setCookie("SearchValue", titleKey);
      }
      setPageSearchValue(false);
    }
  }, [pageSearchValue]);
  useEffect(() => {
    if (sortOpen == true) {
      getAllPosts();
    }
  }, [sortOpen]);

  const GetPostData = (dataU) => {
    return dataU.map((data, index) => {
      var isDuplicate = dataU.filter((data1) => {
        return (
          data1.title[0] == data.title[0] &&
          data1.type == "TagShell" &&
          data.type == "TagShell"
          // data._id == data1._id
        );
      });
      const NoteShellPrior = dataU.filter((data1) => {
        return (
          data1?.note_reference?.title[0] == data?.title[0] &&
          data1?.type == "noteShell" &&
          data?.type == "TagShell" &&
          data._id == data1.note_reference._id
        );
      });

      let main = [];
      if (NoteShellPrior.length != 0) {
        for (let stage of NoteShellPrior) {
          const check = dataU.filter((item) => {
            return (
              stage.note_reference?.title[0] == item.title[0] &&
              item?.user == stage?.note_reference?.user
            );
          });
          if (check.length != 0) {
            main.push(stage);
          }
        }
      }

      let arr = [];
      if (isDuplicate.length > 1) {
        for (let check of isDuplicate) {
          const findPrior = check.tagArr == false;
          if (findPrior == true) {
            arr.push(check);
          }
        }
      }
      return (
        <>
          {main.length != 0 ? (
            data.tagArr == false || true ? null : (
              <div>
                {data.description != undefined && data.description != "" && (
                  <a
                    href={
                      data?.note_reference != undefined
                        ? `/admin/editnotes/${data?.note_reference?._id}`
                        : `/admin/editnotes/${data?._id}`
                    }
                    className="showIndivitualNotes noteshowStyle"
                    key={index}
                    onClick={() => handleEditPage(data)}
                    // style={{
                    //   display: "flex",
                    //   flexDirection: "row",
                    //   justifyContent: "space-between",
                    //   alignItems: "center",
                    // }}
                  >
                    <div className="grpbox">
                      &nbsp;&nbsp;
                      <Moment
                        format="dddd D MMM YYYY hh:mm"
                        withTitle
                        toNow
                        diff
                      >
                        {data.createdAt}
                      </Moment>
                      {/* <strong style={{ color: "#525252" }}> */} by:
                      {data?.type == "note" && (
                        <>
                          @{data?.user?.fname}
                          {data?.sharedWith.length +
                            data?.Request_sharedWith.length +
                            data?.Request_sharedWithUserNotExist.length >
                            0 && (
                            <div>
                              &nbsp;&nbsp;&nbsp;
                              <i
                                class="fa-solid fa-users"
                                style={{ color: "#49b3cb" }}
                              ></i>
                              &nbsp;Shared with:{" "}
                              {data?.sharedWith.length +
                                data?.Request_sharedWith.length +
                                data?.Request_sharedWithUserNotExist
                                  .length}{" "}
                              {data?.sharedWith.length > 1
                                ? "persons"
                                : "person"}
                            </div>
                          )}
                        </>
                      )}
                      {data?.type == "TagShell" && (
                        <>
                          @{data?.controlBy?.fname}
                          <div>
                            {" "}
                            &nbsp;&nbsp;&nbsp;
                            <i
                              class="fa-solid fa-users"
                              style={{ color: "#7CBB4B" }}
                            ></i>
                            &nbsp;Shared by : @{data?.sharedBy?.fname}
                          </div>
                        </>
                      )}
                      {/* </strong>{" "} */}
                      <TruncateMarkup
                        lines={10}
                        ellipsis={
                          <>
                            <br />
                            <br />

                            <button className="ReadMore">Read More</button>
                          </>
                        }
                      >
                        <p className="ql-editor"> {Parser(data.description)}</p>
                      </TruncateMarkup>
                      {data?.audioUrl.length != 0 && (
                        <>
                          <br />
                          <audio
                            className="input_AudioVoice"
                            src={data?.audioUrl[0]?.url}
                            controls
                          />
                        </>
                      )}
                    </div>
                    {data.noteImages.length != 0 && (
                      <img
                        src={data.noteImages[0]?.imageThumb}
                        // style={{ width: "auto", height: 60 }}
                        alt="image"
                      />
                    )}
                  </a>
                )}
                {data?.description == undefined && (
                  <a
                    className="showIndivitualNotes noteshowStyle"
                    href={
                      data?.note_reference != undefined
                        ? `/admin/editnotes/${data?.note_reference?._id}`
                        : `/admin/editnotes/${data?._id}`
                    }
                    key={index}
                    onClick={() => handleEditPage(data)}

                    // onClick={() => handleEdit(data)}
                    // style={{
                    //   display: "flex",
                    //   flexDirection: "row",
                    //   justifyContent: "space-between",
                    //   alignItems: "center",
                    // }}
                  >
                    <div className="grpbox">
                      &nbsp;&nbsp;
                      <Moment
                        format="dddd D MMM YYYY hh:mm"
                        withTitle
                        toNow
                        diff
                      >
                        {data.note_reference.createdAt}
                      </Moment>
                      {/* <strong style={{ color: "#525252" }}> */}{" "}
                      {/* {data?.type == "noteShell" && "Note Shell"} */}
                      by:
                      {data?.type == "note" && (
                        <>
                          @{data?.user?.fname}
                          {data?.sharedWith.length +
                            data?.Request_sharedWith.length +
                            data?.Request_sharedWithUserNotExist.length >
                            0 && (
                            <div>
                              &nbsp;&nbsp;&nbsp;
                              <i
                                class="fa-solid fa-users"
                                style={{ color: "#49b3cb" }}
                              ></i>
                              &nbsp;Shared with:{" "}
                              {data?.sharedWith.length +
                                data?.Request_sharedWith.length +
                                data?.Request_sharedWithUserNotExist
                                  .length}{" "}
                              {data?.sharedWith.length > 1
                                ? "persons"
                                : "person"}
                            </div>
                          )}
                        </>
                      )}
                      {data?.type == "noteShell" && (
                        <>
                          @{data?.controlBy?.fname}
                          <div>
                            {" "}
                            &nbsp;&nbsp;&nbsp;
                            <i
                              class="fa-solid fa-users"
                              style={{ color: "#7CBB4B" }}
                            ></i>
                            &nbsp;Shared by : @{data?.sharedBy?.fname}
                          </div>
                        </>
                      )}
                      {/* </strong> */}
                      <br /> &nbsp;&nbsp;
                      <TruncateMarkup
                        lines={10}
                        ellipsis={
                          <>
                            <br />
                            <br />

                            <button className="ReadMore">...Read More</button>
                          </>
                        }
                      >
                        <p className="ql-editor">
                          {Parser(data.note_reference.description)}
                        </p>
                      </TruncateMarkup>
                      {data?.audioUrl.length != 0 && (
                        <>
                          <br />

                          <audio
                            className="input_AudioVoice"
                            src={data?.audioUrl[0]?.url}
                            controls
                          />
                        </>
                      )}
                    </div>
                    {data.noteImages.length != 0 && (
                      <img
                        src={data.noteImages[0]?.imageThumb}
                        // style={{ width: "auto", height: 60 }}
                        alt="image"
                      />
                    )}
                  </a>
                )}
              </div>
            )
          ) : arr.length == 0 ? (
            data.type == "requestShell" ? (
              <div className="showIndivitualNotes">
                <p className="darkFont">A Note Shared</p>

                <p>
                  On{" "}
                  <Moment format="D MMM YYYY hh:mm" withTitle toNow diff>
                    {data?.createdAt}
                  </Moment>
                </p>

                <p>
                  By {data?.current_userId?.fname} {data?.current_userId?.lname}
                </p>

                <div className="WhiteBoxAD">
                  <button onClick={() => handleAcceptNote(data)}>Accept</button>
                  <button onClick={() => handleDeclineNote(data)}>
                    Decline
                  </button>
                </div>
              </div>
            ) : (
              <div>
                {data.description != undefined && data.description != "" && (
                  <a
                    className="showIndivitualNotes noteshowStyle"
                    href={
                      data?.note_reference != undefined
                        ? `/admin/editnotes/${data?.note_reference?._id}`
                        : `/admin/editnotes/${data?._id}`
                    }
                    onClick={() => handleEditPage(data)}
                    key={index}
                    // onClick={() => handleEdit(data)} // this one here
                    // style={{
                    //   display: "flex",
                    //   flexDirection: "row",
                    //   justifyContent: "space-between",
                    //   alignItems: "center",
                    // }}
                  >
                    <div className="grpbox">
                      &nbsp;&nbsp;
                      <Moment
                        format="dddd D MMM YYYY hh:mm"
                        withTitle
                        toNow
                        diff
                      >
                        {data.createdAt}
                      </Moment>
                      {/* <strong style={{ color: "#525252" }}> */} by:
                      {data?.type == "note" && (
                        <>
                          @{data?.user?.fname}
                          {data?.sharedWith.length +
                            data?.Request_sharedWith.length +
                            data?.Request_sharedWithUserNotExist.length >
                            0 && (
                            <div>
                              &nbsp;&nbsp;&nbsp;
                              <i
                                class="fa-solid fa-users"
                                style={{ color: "#49b3cb" }}
                              ></i>
                              &nbsp;Shared with:{" "}
                              {data?.sharedWith.length +
                                data?.Request_sharedWith.length +
                                data?.Request_sharedWithUserNotExist
                                  .length}{" "}
                              {data?.sharedWith.length > 1
                                ? "persons"
                                : "person"}
                            </div>
                          )}
                        </>
                      )}
                      {data?.type == "TagShell" && (
                        <>
                          @{data?.controlBy?.fname}
                          <div>
                            {" "}
                            &nbsp;&nbsp;&nbsp;
                            <i
                              class="fa-solid fa-users"
                              style={{ color: "#7CBB4B" }}
                            ></i>
                            &nbsp;Shared by : @{data?.sharedBy?.fname}
                          </div>
                        </>
                      )}
                      {}
                      {/* </strong>{" "} */}
                      <TruncateMarkup
                        lines={10}
                        ellipsis={
                          <>
                            <br />
                            <br />

                            <button className="ReadMore">Read More</button>
                          </>
                        }
                      >
                        <p className="ql-editor"> {Parser(data.description)}</p>
                      </TruncateMarkup>
                      {data?.audioUrl.length != 0 && (
                        <>
                          <br />

                          <audio
                            className="input_AudioVoice"
                            src={data?.audioUrl[0]?.url}
                            controls
                          />
                        </>
                      )}
                    </div>
                    {data.noteImages.length != 0 && (
                      <img
                        src={data.noteImages[0]?.imageThumb}
                        // style={{ width: "auto", height: 60 }}
                        alt="image"
                      />
                    )}
                  </a>
                )}
                {data?.description == undefined && (
                  <a
                    className="showIndivitualNotes noteshowStyle"
                    href={
                      data?.note_reference != undefined
                        ? `/admin/editnotes/${data?.note_reference?._id}`
                        : `/admin/editnotes/${data?._id}`
                    }
                    key={index}
                    onClick={() => handleEditPage(data)}

                    // onClick={() => handleEdit(data)}
                    // style={{
                    //   display: "flex",
                    //   flexDirection: "row",
                    //   justifyContent: "space-between",
                    //   alignItems: "center",
                    // }}
                  >
                    <div className="grpbox">
                      &nbsp;&nbsp;{" "}
                      <Moment
                        format="dddd D MMM YYYY hh:mm"
                        withTitle
                        toNow
                        diff
                      >
                        {data?.note_reference?.createdAt}
                      </Moment>
                      {/* <strong style={{ color: "#525252" }}> */} by:
                      {data?.type == "note" && (
                        <>
                          @{data?.user?.fname}
                          {data?.sharedWith.length +
                            data?.Request_sharedWith.length +
                            data?.Request_sharedWithUserNotExist.length >
                            0 && (
                            <div>
                              &nbsp;&nbsp;&nbsp;
                              <i
                                class="fa-solid fa-users"
                                style={{ color: "#49b3cb" }}
                              ></i>
                              &nbsp;Shared with:{" "}
                              {data?.sharedWith.length +
                                data?.Request_sharedWith.length +
                                data?.Request_sharedWithUserNotExist
                                  .length}{" "}
                              {data?.sharedWith.length > 1
                                ? "persons"
                                : "person"}
                            </div>
                          )}
                        </>
                      )}
                      {data?.type == "noteShell" && (
                        <>
                          @{data?.controlBy?.fname}
                          <div>
                            {" "}
                            &nbsp;&nbsp;&nbsp;
                            <i
                              class="fa-solid fa-users"
                              style={{ color: "#7CBB4B" }}
                            ></i>
                            &nbsp;Shared by : @{data?.sharedBy?.fname}
                          </div>
                        </>
                      )}
                      {/* {data?.type == "noteShell" && "Note Shell"} */}
                      {/* </strong> */}
                      <br /> &nbsp;&nbsp;
                      <TruncateMarkup
                        lines={10}
                        ellipsis={
                          <>
                            <br />
                            <br />

                            <button className="ReadMore">...Read More</button>
                          </>
                        }
                      >
                        <p className="ql-editor">
                          {Parser(data?.note_reference?.description)}
                        </p>
                      </TruncateMarkup>
                      {/* <button>hello</button> */}
                      {/* <button id="read-more">Read more</button> */}
                      {data?.audioUrl.length != 0 && (
                        <>
                          <br />

                          <audio
                            className="input_AudioVoice"
                            src={data?.audioUrl[0]?.url}
                            controls
                          />
                        </>
                      )}
                    </div>
                    {data.noteImages.length != 0 && (
                      <img
                        src={data.noteImages[0]?.imageThumb}
                        // style={{ width: "auto", height: 60 }}
                        alt="image"
                      />
                    )}
                  </a>
                )}
              </div>
            )
          ) : data.tagArr == false ? (
            <div>
              {data.description != undefined && data.description != "" && (
                <a
                  className="showIndivitualNotes noteshowStyle"
                  href={
                    data?.note_reference != undefined
                      ? `/admin/editnotes/${data?.note_reference?._id}`
                      : `/admin/editnotes/${data?._id}`
                  }
                  key={index}
                  onClick={() => handleEditPage(data)}

                  // onClick={() => handleEdit(data)}
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "row",
                  //   justifyContent: "space-between",
                  //   alignItems: "center",
                  // }}
                >
                  <div className="grpbox">
                    &nbsp;&nbsp;
                    <Moment format="dddd D MMM YYYY hh:mm" withTitle toNow diff>
                      {data.createdAt}
                    </Moment>
                    by:
                    {data?.type == "note" && (
                      <>
                        @{data?.user?.fname}
                        {data?.sharedWith.length +
                          data?.Request_sharedWith.length +
                          data?.Request_sharedWithUserNotExist.length >
                          0 && (
                          <div>
                            &nbsp;&nbsp;&nbsp;
                            <i
                              class="fa-solid fa-users"
                              style={{ color: "#49b3cb" }}
                            ></i>
                            &nbsp;Shared with:{" "}
                            {data?.sharedWith.length +
                              data?.Request_sharedWith.length +
                              data?.Request_sharedWithUserNotExist.length}{" "}
                            {data?.sharedWith.length > 1 ? "persons" : "person"}
                          </div>
                        )}
                      </>
                    )}
                    {data?.type == "noteShell" && (
                      <>
                        @{data?.controlBy?.fname}
                        <div>
                          {" "}
                          &nbsp;&nbsp;&nbsp;
                          <i
                            class="fa-solid fa-users"
                            style={{ color: "#7CBB4B" }}
                          ></i>
                          &nbsp;Shared by : @{data?.sharedBy?.fname}
                        </div>
                      </>
                    )}
                    <TruncateMarkup
                      lines={10}
                      ellipsis={
                        <>
                          <br />
                          <br />

                          <button className="ReadMore">Read More</button>
                        </>
                      }
                    >
                      <p className="ql-editor"> {Parser(data.description)}</p>
                    </TruncateMarkup>
                  </div>
                  {data.noteImages.length != 0 && (
                    <img
                      src={data.noteImages[0]?.imageThumb}
                      // style={{ width: "auto", height: 60 }}
                      alt="image"
                    />
                  )}
                </a>
              )}
              {data?.description == undefined && (
                <a
                  className="showIndivitualNotes noteshowStyle"
                  href={
                    data?.note_reference != undefined
                      ? `/admin/editnotes/${data?.note_reference?._id}`
                      : `/admin/editnotes/${data?._id}`
                  }
                  key={index}
                  onClick={() => handleEditPage(data)}

                  // onClick={() => handleEdit(data)}
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "row",
                  //   justifyContent: "space-between",
                  //   alignItems: "center",
                  // }}
                >
                  <div className="grpbox">
                    &nbsp;&nbsp;{" "}
                    <Moment format="dddd D MMM YYYY hh:mm" withTitle toNow diff>
                      {data.note_reference.createdAt}
                    </Moment>{" "}
                    by:
                    {data?.type == "note" && (
                      <>
                        @{data?.user?.fname}
                        {data?.sharedWith.length +
                          data?.Request_sharedWith.length +
                          data?.Request_sharedWithUserNotExist.length >
                          0 && (
                          <div>
                            &nbsp;&nbsp;&nbsp;
                            <i
                              class="fa-solid fa-users"
                              style={{ color: "#49b3cb" }}
                            ></i>
                            &nbsp;Shared with:{" "}
                            {data?.sharedWith.length +
                              data?.Request_sharedWith.length +
                              data?.Request_sharedWithUserNotExist.length}{" "}
                            {data?.sharedWith.length > 1 ? "persons" : "person"}
                          </div>
                        )}
                      </>
                    )}
                    {data?.type == "noteShell" && (
                      <>
                        @{data?.controlBy?.fname}
                        <div>
                          {" "}
                          &nbsp;&nbsp;&nbsp;
                          <i
                            class="fa-solid fa-users"
                            style={{ color: "#7CBB4B" }}
                          ></i>
                          &nbsp;Shared by : @{data?.sharedBy?.fname}
                        </div>
                      </>
                    )}
                    <br /> &nbsp;&nbsp;
                    <TruncateMarkup
                      lines={10}
                      ellipsis={
                        <>
                          <br />
                          <br />

                          <button className="ReadMore">...Read More</button>
                        </>
                      }
                    >
                      <p className="ql-editor">
                        {Parser(data.note_reference.description)}
                      </p>
                    </TruncateMarkup>
                  </div>
                  {data.noteImages.length != 0 && (
                    <img
                      src={data.noteImages[0]?.imageThumb}
                      // style={{ width: "auto", height: 60 }}
                      alt="image"
                    />
                  )}
                </a>
              )}
            </div>
          ) : null}
        </>
      );
    });
  };
  const getAllPosts = async () => {
    console.log(sortValue, "AfterSelectOption", offset);
    let url1 = `${config.base_api_url}/api/getnoteMain`;
    let method1 = "GET";
    let res = await fetchExtreamApi(url1, method1);
    const data = res;

    if (sortValue == "newest") {
      const mergedArray = data.concat(dataReqList);

      const check = mergedArray.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setNewDataForPagi(check);
      console.log(check, "checkThePostNew", offsetValue, offset * postsPerPage);
      if (offset * postsPerPage != offsetValue) {
        let offsetValueNew = offset * postsPerPage;
        console.log(
          check,
          "checkThePostNewYeah",
          offsetValueNew,
          offset * postsPerPage
        );
        console.log(offsetValue, "jkhjjhgfghf1");
        const slice = check.slice(
          offsetValueNew,
          offsetValueNew + postsPerPage
        );
        console.log(slice, "jkhjjhgfghf2");
        setOffsetValue(offsetValueNew + postsPerPage);

        const postData = GetPostData(slice);
        console.log(data?.length, "lengthData");
        setAllPosts(postData);
        setPageCount(Math.ceil(data?.length / postsPerPage));
      } else {
        const slice = check.slice(offsetValue, offsetValue + postsPerPage);
        setOffsetValue(offsetValue + postsPerPage);
        const postData = GetPostData(slice);
        console.log(data?.length, "lengthData");
        setAllPosts(postData);
        setPageCount(Math.ceil(data?.length / postsPerPage));
      }
    } else if (sortValue == "lastEdited") {
      const mergedArray = data.concat(dataReqList);

      const check = mergedArray.sort(function (a, b) {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
      setNewDataForPagi(check);
      console.log(check, "checkThePostNew", offsetValue, offset * postsPerPage);
      if (offset * postsPerPage != offsetValue) {
        let offsetValueNew = offset * postsPerPage;
        console.log(
          check,
          "checkThePostNewYeah",
          offsetValueNew,
          offset * postsPerPage
        );
        console.log(offsetValue, "jkhjjhgfghf1");
        const slice = check.slice(
          offsetValueNew,
          offsetValueNew + postsPerPage
        );
        console.log(slice, "jkhjjhgfghf2");
        setOffsetValue(offsetValueNew + postsPerPage);

        const postData = GetPostData(slice);
        console.log(data?.length, "lengthData");
        setAllPosts(postData);
        setPageCount(Math.ceil(data?.length / postsPerPage));
      } else {
        const slice = check.slice(offsetValue, offsetValue + postsPerPage);
        setOffsetValue(offsetValue + postsPerPage);
        const postData = GetPostData(slice);
        console.log(data?.length, "lengthData");
        setAllPosts(postData);
        setPageCount(Math.ceil(data?.length / postsPerPage));
      }
    } else if (sortValue == "lastOpened") {
      const mergedArray = data.concat(dataReqList);

      const check = mergedArray.sort(function (a, b) {
        console.log(b?.lastOpenDate, "checkThePostNew");
        return (
          new Date(b.lastOpenDate != undefined ? b.lastOpenDate : b.createdAt) -
          new Date(a.lastOpenDate != undefined ? a.lastOpenDate : a.createdAt)
        );
      });
      setNewDataForPagi(check);

      if (offset * postsPerPage != offsetValue) {
        let offsetValueNew = offset * postsPerPage;
        console.log(
          check,
          "checkThePostNewYeah",
          offsetValueNew,
          offset * postsPerPage
        );
        console.log(offsetValue, "jkhjjhgfghf1");
        const slice = check.slice(
          offsetValueNew,
          offsetValueNew + postsPerPage
        );
        console.log(slice, "jkhjjhgfghf2");
        setOffsetValue(offsetValueNew + postsPerPage);

        const postData = GetPostData(slice);
        console.log(data?.length, "lengthData");
        setAllPosts(postData);
        setPageCount(Math.ceil(data?.length / postsPerPage));
      } else {
        const slice = check.slice(offsetValue, offsetValue + postsPerPage);
        setOffsetValue(offsetValue + postsPerPage);
        const postData = GetPostData(slice);
        console.log(data?.length, "lengthData");
        setAllPosts(postData);
        setPageCount(Math.ceil(data?.length / postsPerPage));
      }
    } else if (sortValue == "oldest") {
      const mergedArray = data.concat(dataReqList);

      const check = mergedArray.sort(function (a, b) {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setNewDataForPagi(check);
      console.log(check, "checkThePostNew", offsetValue, offset * postsPerPage);
      if (offset * postsPerPage != offsetValue) {
        let offsetValueNew = offset * postsPerPage;
        console.log(
          check,
          "checkThePostNewYeah",
          offsetValueNew,
          offset * postsPerPage
        );
        console.log(offsetValue, "jkhjjhgfghf1");
        const slice = check.slice(
          offsetValueNew,
          offsetValueNew + postsPerPage
        );
        console.log(slice, "jkhjjhgfghf2");
        setOffsetValue(offsetValueNew + postsPerPage);

        const postData = GetPostData(slice);
        console.log(data?.length, "lengthData");
        setAllPosts(postData);
        setPageCount(Math.ceil(data?.length / postsPerPage));
      } else {
        const slice = check.slice(offsetValue, offsetValue + postsPerPage);
        setOffsetValue(offsetValue + postsPerPage);
        const postData = GetPostData(slice);
        console.log(data?.length, "lengthData");
        setAllPosts(postData);
        setPageCount(Math.ceil(data?.length / postsPerPage));
      }
    }
  };
  const handleChangeCheckout = (e) => {
    e.preventDefault();
    setpayment({ ...payment, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name: payment.fname + payment.lname,
        email: payment.email,
        address: payment.address,
      },
    });

    let customerObj = {
      firstName: payment.fname,
      lastName: payment.lname,
      email: payment.email,
      state: payment.state,
      address: payment.address,
      zip: payment.zip,
      city: payment.city,
      planType: checkedList[0],
    };
    let subscriptionObj = {
      customerObj: customerObj,
      paymentMethod: paymentMethod,
    };

    if (!error) {
      try {
        //  const { id } = paymentMethod;
        const response = await axios.post(
          `${config.cloud_functions_url}/createSubscription`,
          subscriptionObj
        );
        if (response) {
          const email = payment.email;
          try {
            // Find the document ID by email
            const q = query(
              collection(db, "users"),
              where("email", "==", email)
            );
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (snapshot) => {
              // Get the document ID
              const documentId = snapshot.id;

              // Update the specific field in the document
              const docRef = doc(db, "users", documentId);
              updateDoc(docRef, { isPaid: false, plan: checkedPlan });
              const subcollectionRef = collection(docRef, "plans");
              const subcollectionRefInvoice = collection(docRef, "invoices");

              const subcollectionRefReceipt = collection(docRef, "receipts");

              const subcollectionSnapshot = await getDocs(subcollectionRef);
              const subcollectionSnapshotInvoice = await getDocs(
                subcollectionRefInvoice
              );

              const subcollectionSnapshotReceipt = await getDocs(
                subcollectionRefReceipt
              );

              if (subcollectionSnapshot.empty) {
                // Subcollection doesn't exist, create it and add fields
                const subcollectionDocRef = doc(
                  collection(db, "users", documentId, "plans")
                );
                await setDoc(subcollectionDocRef, {
                  createdAt: response.data.created,
                  productId: response.data.items.data[0].plan.product,
                  plan: response.data.items.data[0].plan,
                });
                const subcollectionDocRefinvoices = doc(
                  collection(db, "users", documentId, "invoices")
                );
                await setDoc(subcollectionDocRefinvoices, {
                  amount: response.data.items.data[0].plan.amount / 100,
                  invoiceId: response.data.latest_invoice.id,
                  invoice: response.data.latest_invoice,
                });
                const subcollectionDocRefreceipts = doc(
                  collection(db, "users", documentId, "receipts")
                );
                await setDoc(subcollectionDocRefreceipts, {
                  receipt_url:
                    response.data.latest_invoice.payment_intent.charges.data[0]
                      .receipt_url,
                });
              } else {
                const subcollectionFirstDocRef =
                  subcollectionSnapshot.docs[0].ref;
                const subcollectionFirstDocRefInvoice =
                  subcollectionSnapshotInvoice.docs[0].ref;
                const subcollectionFirstDocRefReceipt =
                  subcollectionSnapshotReceipt.docs[0].ref;
                //  Subcollection already exists, update fields or perform other operations
                await updateDoc(subcollectionFirstDocRef, {
                  createdAt: response.data.created,
                  productId: response.data.items.data[0].plan.product,
                  plan: response.data.items.data[0].plan,
                });
                await updateDoc(subcollectionFirstDocRefInvoice, {
                  amount: response.data.items.data[0].plan.amount / 100,
                  invoiceId: response.data.latest_invoice.id,
                  invoice: response.data.latest_invoice,
                });
                await updateDoc(subcollectionFirstDocRefReceipt, {
                  receipt_url:
                    response.data.latest_invoice.payment_intent.charges.data[0]
                      .receipt_url,
                });
              }
            });

            console.log("Document field updated successfully!");
          } catch (error) {
            console.error("Error updating document field:", error);
          }
          console.log("Successful payment", response);
          // setSuccess(true);
        }
      } catch (error) {
        console.log("Error", error);
      }
    } else {
      console.log(error.message);
    }
  };
  const onCheckboxChange = (option, e) => {
    // if (e.target.checked) {
    setcheckedList([option.id + "#" + option.price.id]);
    setcheckedPlan(option.name);
    handleClose2();
    handleOpenCheckout();
    // } else {
    //   for (var i = 0; i < data.length; i++) {
    //     if (checkedList[i] == option.id + "#" + option.price.id) {
    //       checkedList.splice(i, 1);
    //     }
    //   }
    // }
  };
  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    console.log(pageCount, selectedPage, offset, "offsetCounting");
    setOffset(selectedPage);
  };

  const handleChangesSort = (e) => {
    setSortValue(e.target.value);
  };
  useEffect(() => {
    //  getAllPosts();
    const searchCookie = getCookie("SearchValue");
    console.log(searchCookie, "searchCookie");
    if (titleKey == undefined && searchCookie == null) {
      getAllPosts();
    }
  }, [sortValue]);

  const ListItem = ({ item, active, setSelected, setHovered }) => (
    <div
      style={{ padding: "10px", fontSize: "16px" }}
      className={`item ${active ? "active" : ""}`}
      onClick={() => {
        if (item.includes("@")) {
          let atAdd = item.indexOf("#");
          var msg = item.slice(atAdd);
          setSelected(msg);
        } else {
          setSelected(item);
        }
        // handledropSelect(item);
      }}
      onMouseEnter={() => setHovered(item)}
      onMouseLeave={() => setHovered(undefined)}
    >
      {item}
    </div>
  );
  const ListItem1 = ({ item, active, setSelected, setHovered }) => (
    <div
      style={{ padding: "10px", fontSize: "16px" }}
      className={`item ${active ? "active" : ""}`}
      onClick={() => {
        setSelected("@" + item?.fname + item?.lname);
        setMentionInfo((prev) => [
          ...prev,
          {
            name: item?.fname + item?.lname,
            uni: item?._id,
          },
        ]);
        // handledropSelect(item);
      }}
      onMouseEnter={() => setHovered(item?.email)}
      onMouseLeave={() => setHovered(undefined)}
    >
      {item?.fname} {item?.lname}({item?.email})
    </div>
  );
  const handleTouchMove = useMemo(() => {
    return (e) => {
      if (e.keyCode >= 37 && e.keyCode <= 40) {
        e.preventDefault();
        return (e.returnValue = "");
      }
    };
  }, []);

  useEffect(() => {
    if (
      (noteSp == true && downPress) ||
      (noteSp == true && upPress) ||
      noteSp == true
    ) {
      window.addEventListener("keydown", handleTouchMove, false);
      window.addEventListener("keyup", handleTouchMove, false);
      return () => {
        window.removeEventListener("keyup", handleTouchMove, false);
        window.removeEventListener("keydown", handleTouchMove, false);
      };
      // document.getElementById("mybtn").click();
    }
  }, [noteSp]);
  useEffect(() => {
    if (
      (atTagB == true && downPress) ||
      (atTagB == true && upPress) ||
      atTagB == true
    ) {
      window.addEventListener("keydown", handleTouchMove, false);
      window.addEventListener("keyup", handleTouchMove, false);
      return () => {
        window.removeEventListener("keyup", handleTouchMove, false);
        window.removeEventListener("keydown", handleTouchMove, false);
      };
      // document.getElementById("mybtn").click();
    }
  }, [atTagB]);
  useEffect(() => {
    if (allTItle.length && downPress && noteSp == true) {
      // window.addEventListener("keydown", listener);
      // window.addEventListener("keydown", handleTouchMove, false);
      setCursor((prevState) =>
        prevState < allTItle.length - 1 ? prevState + 1 : prevState
      );
    } else {
      // setTimeout(() => {
      //   window.removeEventListener("keyup", handleTouchMove);
      // }, 1000);
      // return () => {
      //   window.removeEventListener("keydown", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
    if (atTag.length && downPress && atTagB == true) {
      // window.addEventListener("keydown", listener);
      // window.addEventListener("keydown", handleTouchMove, false);
      setCursor((prevState) =>
        prevState < atTag.length - 1 ? prevState + 1 : prevState
      );
    } else {
      // setTimeout(() => {
      //   window.removeEventListener("keyup", handleTouchMove);
      // }, 1000);
      // return () => {
      //   window.removeEventListener("keydown", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
  }, [downPress]);
  useEffect(() => {
    // const handleTouchMove = function (e) {
    //   if ([32, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
    //     e.preventDefault();
    //     e.stopPropagation();
    //   }
    // };

    if (allTItle.length && upPress && (noteSp == true || atTagB == true)) {
      // window.addEventListener("keydown", listener);
      // window.addEventListener("keyup", handleTouchMove, false);

      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    } else {
      // return () => {
      //   window.removeEventListener("keyup", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
    if (atTag.length && upPress && atTagB == true) {
      // window.addEventListener("keydown", listener);
      // window.addEventListener("keyup", handleTouchMove, false);

      setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
    } else {
      // return () => {
      //   window.removeEventListener("keyup", handleTouchMove, false);
      // };
      // window.removeEventListener("keydown", listener);
    }
  }, [upPress]);

  useEffect(() => {
    if (allTItle.length && enterPress && noteSp) {
      // setSelected(allTItle[cursor]);
      if (allTItle[cursor]?.includes("@") && allTItle[cursor] != undefined) {
        let atAdd = allTItle[cursor].indexOf("#");
        var msg = allTItle[cursor].slice(atAdd);
        setSelected(msg);
      } else {
        setSelected(allTItle[cursor]);
        setDefaultLi(true);
      }
      // handledropSelect(allTItle[cursor]);
    }
    if (atTag.length && enterPress && atTagB) {
      // item?.fname + item?.lname;
      setSelected("@" + atTag[cursor]?.fname + atTag[cursor]?.lname);
      setMentionInfo((prev) => [
        ...prev,
        {
          name: atTag[cursor]?.fname + atTag[cursor]?.lname,
          uni: atTag[cursor]?._id,
        },
      ]);

      setDefaultLi(true);
      // handledropSelect(allTItle[cursor]);
    }
  }, [cursor, enterPress]);
  useEffect(() => {
    if (allTItle.length && hovered) {
      setCursor(allTItle.indexOf(hovered));
    }
    if (atTag.length && hovered) {
      setCursor(atTag.indexOf(hovered));
    }
  }, [hovered]);
  useEffect(() => {
    if (
      selected != undefined &&
      searchValueHash.includes("#") &&
      noteSp == true
    ) {
      let index = searchValueHash.length;
      if (defaultLi == true) {
        quill.deleteText(ranged - index, index + 1);
        setDefaultLi(false);
      } else {
        quill.deleteText(ranged - index, index);
      }

      // let index = searchValueHash.length;
      let add = ranged - index;
      quill.insertText(add, `${selected}`);
      // ${searchValueHash}${selected}
      if (quill) {
        quill.focus();
        let index1 = ranged - index + selected.length;
        setTimeout(() => {
          quill.setSelection(index1);
        }, 0);
      }
      setAllTitle(allTItle1);
      setNoteSp(false);

      setSelected(undefined);
    }
    if (
      selected != undefined &&
      searchValueATR.includes("@") &&
      atTagB == true
    ) {
      let index = searchValueATR.length;
      if (defaultLi == true) {
        quill.deleteText(rangedATR - index, index + 1);
        setDefaultLi(false);
      } else {
        quill.deleteText(rangedATR - index, index);
      }

      // let index = searchValueHash.length;
      let add = rangedATR - index;
      quill.insertText(add, `${selected}`, {
        background: "#d3e1eb",
      });
      if (quill) {
        quill.focus();
        let index1 = rangedATR - index + selected.length;
        setTimeout(() => {
          quill.setSelection(index1 + 1);
        }, 0);
      }
      quill.insertText(add + selected.length, ` `, {
        background: "white",
      });
      // var editor = document.getElementsByClassName("ql-editor");
      // const root = quill.root.innerHTML.replace(
      //   `${selected}`,
      //   `<span>${selected}</span>`
      // );
      // editor[0].innerHTML = root;
      setAllTitle(allTItle1);

      setSelected(undefined);
      setAtTag([]);

      setAtTagB(false);
    }
  }, [selected && quill]);
  useEffect(() => {
    if (hashAdd == true) {
      let index = 1;
      if (rangedReload == false) {
        let add = 1;
        quill.insertText(add, "#");
        if (quill) {
          quill.focus();
          let index1 = add + 1;
          setTimeout(() => {
            quill.setSelection(index1);
          }, 0);
        }
      } else {
        let add = rangedReload;
        quill.insertText(add, "#");
        if (quill) {
          quill.focus();
          let index1 = add + 1;
          setTimeout(() => {
            quill.setSelection(index1);
          }, 0);
        }
      }

      setHashAdd(false);
    }
  }, [hashAdd && quill]);
  const handleCancelImage = ({ data, index }) => {
    const removePreviewIndex = preview.filter((data, i) => {
      return i != index;
    });
    setPreview(removePreviewIndex);
    const removeuploadImageIndex = uploadImage.filter((data, i) => {
      return i != index;
    });

    setUploadImage(removeuploadImageIndex);
    if (removePreviewIndex.length == 0) {
      setRedirectPage(false);
    }
  };
  useEffect(() => {
    // Adding an event listener to the document to capture keydown events
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // Do something when Escape key is pressed
        console.log("Escape key was pressed", rangedReload);
        let add = rangedReload;
        quill.insertText(add, " ");
        if (quill) {
          quill.focus();
          let index1 = add + 1;
          setTimeout(() => {
            quill.setSelection(index1);
          }, 0);
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      // Removing the event listener when the component is unmounted
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [quill && rangedReload]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <>
      <form method="post">
        <div className="top_input_box">
          {editTemplate == true && (
            <div className="editTemText mb-3">Edit Template: {age}</div>
          )}

          <div className="border_box">
            <div className="left_inputF">
              <div ref={quillRef} id="unicorn">
                {noteSp == true && (
                  <div className="Active_Float">
                    <div className="MuiAutocomplete-listbox">
                      {allTItle.map((item, i) => {
                        return (
                          <ListItem
                            key={i}
                            active={i === cursor}
                            item={item}
                            setSelected={setSelected}
                            setHovered={setHovered}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                {atTagB == true && (
                  <div className="Active_Float">
                    <div className="MuiAutocomplete-listbox">
                      {atTag.map((item, i) => {
                        return (
                          <ListItem1
                            key={i}
                            active={i === cursor}
                            item={item}
                            setSelected={setSelected}
                            setHovered={setHovered}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="right_text">
              <div className="divTemplDd">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    value={age}
                    onChange={(event) => handleChangeTemDD(event)}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    className="removeEditBtn"
                  >
                    <MenuItem value="">
                      <em>Template</em>
                    </MenuItem>
                    <MenuItem className="tempAddName" value="addTemplate">
                      <em>Add New +</em>
                    </MenuItem>
                    {templateList?.map((item) => {
                      if (item?.note_reference != undefined) {
                        return (
                          <MenuItem value={item?.note_reference?.tempName}>
                            <em
                              className="mainEditTemplate"
                              onClick={() => setEditTemplate(false)}
                            >
                              <h6>{item?.note_reference?.tempName}</h6>
                              <span
                                className="editTemplate"
                                onClick={(e) =>
                                  handleEditTemplate(e, item?.note_reference)
                                }
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </span>
                            </em>
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem value={item?.tempName}>
                            <em
                              className="mainEditTemplate"
                              onClick={() => setEditTemplate(false)}
                            >
                              <h6>{item?.tempName}</h6>
                              <span
                                className="editTemplate"
                                onClick={(e) => handleEditTemplate(e, item)}
                              >
                                <i className="fa-solid fa-pen-to-square"></i>
                              </span>
                            </em>
                          </MenuItem>
                        );
                      }
                    })}
                  </Select>
                </FormControl>
              </div>

              <div {...getRootProps()}>
                <input {...getInputProps()} />

                <i className="fa-solid fa-paperclip"></i>
                <h2>Attach</h2>
              </div>
              <div onClick={handleOpen}>
                <i class="fa-solid fa-microphone"></i>
                <h2>Record</h2>
              </div>
              <div onClick={handleOpenHash}>
                <i class="fa-sharp fa-solid fa-hashtag"></i>
                <h2>Hash</h2>
              </div>
              {/* <ul style={{ listStyle: "none" }}>
                <li>
                  {" "}
                  <span>$</span> - Track finance
                </li>
                <li>
                  {" "}
                  <span>*</span> - Track Time
                </li>
                <li>
                  <span>@</span> - Tag user
                </li>
              </ul> */}
            </div>
          </div>
          {uploadImage.length != 0 && (
            <div className="WhiteBox gridImages">
              {preview.map((data, index) => (
                <div className="displayImages">
                  <span className="ImageAdjust">
                    <span
                      className="CancelImage"
                      onClick={() => handleCancelImage({ data, index })}
                    >
                      {" "}
                      <i class="fa-solid fa-circle-xmark"></i>
                    </span>
                    <img
                      src={data}
                      style={{ height: 70, width: 150, objectFit: "contain" }}
                    />
                  </span>
                </div>
              ))}
            </div>
          )}
          {audioURLs.length != "" && (
            <div className="WhiteBox">
              {audioURLs &&
                audioURLs.map((url) => {
                  return (
                    <>
                      <div className="left_align">
                        <span>
                          <span className="font_dark">File Size:</span>
                          {url?.size}
                        </span>
                        <span>
                          <span className="font_dark">Recorded:</span>
                          {moment(url?.createdTime).format("L LT")}
                        </span>
                        <span
                          className="font_Red"
                          onClick={() => {
                            CancelOne(url);
                          }}
                        >
                          Delete file
                        </span>
                      </div>

                      <input
                        type="text"
                        className="input_RecoredVoice"
                        // value={url?.title}
                        value={url?.title}
                        onChange={(e) => {
                          let arr = [];
                          const checkData = audioURLs.filter((data) => {
                            if (data.audio == url?.audio) {
                              if (data.title.length == 1) {
                                if (e.target.value.length > 0) {
                                  return (data.title = e.target.value);
                                } else {
                                  return (data.title = " ");
                                }
                              } else {
                                if (e.target.value.length > 0) {
                                  return (data.title = e.target.value);
                                } else {
                                  return (data.title = " ");
                                }
                              }
                            } else {
                              return data.title;
                            }
                          });

                          // checkData.title = title;
                          arr.push(checkData);

                          // const checkData1 = audioURLs.filter((data) => {
                          //   return data.audio != url?.audio;
                          // });
                          // arr.push(checkData1);
                          setAudioURLs(arr.flat());

                          // setEditName({
                          //   audio: url?.audio,
                          //   title: e.target.value,
                          // });
                          // setSaveId(url?.audio);
                        }}
                      />
                      <audio
                        className="input_AudioVoice"
                        src={url?.audio}
                        controls
                        muted={open2 == true ? true : false}
                        // autoplay={open2 == true ? false : true}
                      />
                      {/* {saveId != "" && saveId == url?.audio && (
                          <div style={{ display: "flex", gap: "2%" }}>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                EditOne();
                                setSaveId("");
                              }}
                            >
                              SAVE
                            </button>
                            <button onClick={CancelOne}>cancel</button>
                          </div>
                        )} */}
                    </>
                  );
                })}
            </div>
          )}
          {editTemplate == false ? (
            <div>
              <button onClick={(e) => addNote(e)}>Submit</button>
            </div>
          ) : (
            <div>
              <button onClick={(e) => updateTemplate(e)}>
                Update Template
              </button>
            </div>
          )}
        </div>
      </form>

      <div className="search_box">
        <div className="search_box_div">
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="text"
            defaultValue=""
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </div>
      {/* notification */}

      <div>
        <select
          className="showIndivitualNotes"
          onClick={(e) => handleChangesSort(e)}
          defaultValue={sortValue}
        >
          <option value="newest">Newest</option>
          <option value="lastEdited">Last Edited</option>
          <option value="lastOpened">Last Opened</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>
      <div className="showNotes">
        <div className="left_input">
          <div className="main-app">
            {/* Display all the posts  */}
            {posts}

            {/* Using React Paginate */}

            {newDataForPagi.length > 20 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        open={open2}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div style={{ marginLeft: "94%" }}>
            <CloseIcon
              style={{ color: "#6F6F6F", cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>
          <div className="share_div">RECORD AUDIO</div>

          {/* <div className="top_input_box_green"> */}
          <div>
            {/* <h3>On air: {isRecording ? "on" : "off"}</h3>
              <button onClick={start}>Start</button>
              <button onClick={stop}>Stop</button> */}
          </div>
          <div className="top_input_box_green">
            <h4>Name</h4>
            {/* <Helmet></Helmet> */}
            {audioURL != "" && (
              <>
                {/* <h1>{dispSecondsAsMins(timer)}</h1> */}
                <input
                  type="text"
                  className="input_RecoredVoice"
                  defaultValue={
                    title != "" ? title : `Untitled_${moment().format("L_LTS")}`
                  }
                  onChange={(e) => setTitle(e.target.value)}
                />
                <audio
                  className="input_AudioVoice"
                  src={audioURL?.audio}
                  onChange={(e) => {
                    console.log(e.target.files, "files");
                  }}
                  controls
                />
              </>
            )}

            <br />
            {audioURL == "" && (
              <>
                <input
                  type="text"
                  className="input_RecoredVoice"
                  defaultValue={
                    title != "" ? title : `Untitled_${moment().format("L_LTS")}`
                  }
                  // value={`Untitled_${moment().format("L_LTS")}`}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <h1 style={{ marginTop: "0px", textAlign: "center" }}>
                  {dispSecondsAsMins(timer)}
                </h1>
              </>
            )}
            {!isRecording && audioURL == "" && (
              <>
                {" "}
                <button
                  onClick={() => {
                    startRecording();
                    toggleStart();
                  }}
                  disabled={isRecording}
                >
                  start recording
                </button>
                <audio id="microphone" autoplay></audio>
                {/* <canvas id="canvas" width="300" height="50" ></canvas> */}
              </>
            )}
            {isRecording && audioURL == "" && (
              <>
                <button
                  className="top_input_box_Red"
                  onClick={() => {
                    stopRecording();
                    toggleStart();
                  }}
                  disabled={!isRecording}
                >
                  stop recording
                </button>
                <audio id="microphone" autoplay></audio>
                <canvas id="canvas" width="300" height="50"></canvas>
              </>
            )}
            {audioURL != "" && (
              <div style={{ display: "flex", gap: "2%" }}>
                <button
                  onClick={() => {
                    Save();
                    setTimer(0);
                    setTitle("");
                    handleClose();
                  }}
                >
                  SAVE
                </button>
                <button
                  onClick={() => {
                    Delete();
                    setTimer(0);
                    setTitle("");
                    handleClose();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          {/* </div> */}
        </div>
      </Modal>
      <Modal
        open={open3}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div style={style}>
            {" "}
            <p className="textPlan">
              You currently using {usage.used} MB of data. Your data limit is
              {usage.limit} MB, so Would you like to upgrade your data plan?
            </p>
            {/* <p>Do you want to upgrade your data limit?</p> */}
            <div className="WhiteBoxAD">
              <button
                onClick={() => {
                  handleOpen2();
                  handleClose1();
                }}
              >
                Yes
              </button>
              <button
                onClick={() => {
                  handleClose1();
                }}
              >
                No
              </button>
            </div>
          </div>
        </>
        {/* <div></div> */}
      </Modal>

      <Modal
        open={open4}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div style={style1}>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-3">
              {planPackages.map((item) => {
                return (
                  <div className="card mb-4 box-shadow">
                    <div className="card-header">
                      <h4 className="my-0 font-weight-normal">
                        {item.description}
                      </h4>
                    </div>
                    <div className="card-body">
                      <h1 className="card-title pricing-card-title">
                        ${item?.price?.unit_amount / 100}
                        <small className="text-muted">/ month</small>
                      </h1>
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>included</li>
                        <li>{item.name} of storage</li>
                        {/* <li>Email support</li>
                  <li>Help center access</li> */}
                      </ul>
                      <button
                        type="button"
                        className={
                          item?.price?.unit_amount / 100 == 0
                            ? "btn btn-lg btn-block btn-outline-primary"
                            : "btn btn-lg btn-block btn-primary"
                        }
                        onClick={
                          item?.price?.unit_amount / 100 == 0
                            ? null
                            : (e) => onCheckboxChange(item, e)
                        }
                      >
                        {item?.price?.unit_amount / 100 == 0 ? (
                          <>Sign up for free</>
                        ) : (
                          <>Get started</>
                        )}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      </Modal>
      <Modal
        open={open}
        onClose={handleCloseCheckout}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div style={style}>
            {" "}
            <div className="card mb-4">
              <div className="card-header py-3">
                <h5 className="mb-0">Payment details</h5>
              </div>
              <div className="card-body">
                <form id="payment-form">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label for="firstName">First name</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChangeCheckout(e)}
                        id="firstName"
                        placeholder=""
                        name="fname"
                        // value=""
                        required=""
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <label for="lastName">Last name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="lname"
                        placeholder=""
                        onChange={(e) => handleChangeCheckout(e)}
                        // value=""
                        required=""
                      />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="email">Email </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={(e) => handleChangeCheckout(e)}
                      id="email"
                      placeholder="you@example.com"
                    />
                  </div>
                  <div className="mb-3">
                    <label for="address">Address</label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      name="address"
                      onChange={(e) => handleChangeCheckout(e)}
                      placeholder="1234 Main St"
                      required=""
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-5 mb-3">
                      <label for="country">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        id="country"
                        name="country"
                        onChange={(e) => handleChangeCheckout(e)}
                        placeholder=""
                        required=""
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label for="state">State</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChangeCheckout(e)}
                        id="state"
                        name="state"
                        placeholder=""
                        required=""
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <label for="city">City</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChangeCheckout(e)}
                        id="city"
                        name="city"
                        placeholder=""
                        required=""
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label for="zip">Zip</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => handleChangeCheckout(e)}
                        id="zip"
                        name="zip"
                        placeholder=""
                        required=""
                      />
                    </div>
                  </div>{" "}
                  <CardElement />
                  <button
                    type="submit"
                    className="btn btn-lg btn-block btn-primary  mt-4"
                    onClick={handleSubmit}
                  >
                    Subscribe Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        open={open5}
        onClose={handleCloseTem}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
          <div style={{ marginLeft: "94%" }}>
            <CloseIcon
              style={{ color: "#6F6F6F", cursor: "pointer" }}
              onClick={handleCloseTem}
            />
          </div>
          <div className="share_div">Template:</div>

          <div className="top_input_box_green">
            <h4>Name</h4>
            <input
              type="text"
              className="input_RecoredVoice"
              // defaultValue={}
              onChange={(e) => setTemplateName(e.target.value)}
            />

            <br />
            <button
              onClick={(e) => {
                addNote(e);
              }}
              disabled={isRecording}
            >
              Save
            </button>
          </div>
          {/* </div> */}
        </div>
      </Modal>
    </>
  );
};

export default NotesBox;
