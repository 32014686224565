import axios from "axios";
import React from "react";
import { fetchExtreamApi } from "../fetchExtreamApi";
import config from "../../config";

const ShareWithPer = ({ title, data }) => {
  const [childData, setChildData] = React.useState(undefined);

  const handleTotalShared = async (title, data) => {
    let atAdd = title.indexOf("#");
    var msg = title.slice(atAdd);
    if (data[0]?.controlBy?._id != undefined) {
      try {
        let bodyValue = {
          tag: msg,
          controlBy: data[0]?.controlBy?._id,
        };
        let url1 = `${config.base_api_url}/api/GetMasterTagShell`;
        let method1 = "POST";
        let response = await fetchExtreamApi(url1, method1, bodyValue);

        if (response.length != 0 && response.length != undefined) {
          setChildData(response[0]?.sharedWith.length);
          return response[0]?.sharedWith.length;
        } else {
          setChildData(0);
          return 0;
        }
      } catch (error) {
        console.error(error.message);
      }
    } else {
      try {
        let bodyValue = {
          tag: title,
        };
        let url1 = `${config.base_api_url}/api/GetMasterTagShell`;
        let method1 = "POST";
        let response = await fetchExtreamApi(url1, method1, bodyValue);

        if (response.length != 0 && response.length != undefined) {
          setChildData(response[0]?.sharedWith.length);
          return response[0]?.sharedWith.length;
        } else {
          setChildData(0);
          return 0;
        }
      } catch (error) {
        console.error(error.message);
      }
    }
  };
  return (
    <>
      {childData != 0 && (
        <div className="sharedPer">
          &nbsp;&nbsp;&nbsp;
          <i class="fa-solid fa-users" style={{ color: "#49b3cb" }}></i>
          <span>&nbsp;Shared with: {childData != undefined && childData}</span>
        </div>
      )}
    </>
  );
};

export default ShareWithPer;
