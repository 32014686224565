import React from "react";
import Logo from "../images/logo.png";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { useState } from "react";

const ForgotPasswod = () => {
  const [email, setEmail] = useState("");
  const handleforgotpassword = (e) => {
    e.preventDefault();

    var auth = getAuth();

    return sendPasswordResetEmail(auth, email).then((a) => {
      alert("Password reset email sent");
    });
  };
  return (
    <>
      <div className="main_wrap">
        <div className="left_form">
          <div className="inner_form">
            <h1>Sign In</h1>
            <p>Enter your email and password to sign in!</p>
            <form onSubmit={(e) => handleforgotpassword(e)}>
              <div className="form_group">
                <label>
                  Email<span>*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="mail@simmmple.com"
                />
              </div>

              <div className="form_group">
                <input type="submit" value="Submit" />
              </div>
            </form>
          </div>
          <div className="copyright">
            <p>
              © 2022 Horizon UI. All Rights Reserved. Made with love by
              Simmmple!
            </p>
          </div>
        </div>

        <div className="right_logo">
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <div className="footer_link">
            <ul style={{ listStyle: "none" }}>
              <li>
                <a href="/">Terms of Use</a>
              </li>
              <li>
                <a href="/">Blog</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswod;
