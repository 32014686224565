import axios from "axios";
import { fetchExtreamApi } from "./fetchExtreamApi";
import config from "../config";

export const get = (e, d, t) => {
  return new Promise((resolve, reject) => {
    let arr = [];
    let name = [];
    let datat = false;
    let data = [];

    for (let resData of d) {
      for (let resData1 of resData.title) {
        if (resData?.sharedBy != undefined) {
          if (e.includes("@")) {
            let atAdd = e.indexOf("#");
            var msg = e.slice(atAdd);
            const check =
              resData1.startsWith(msg) && resData.type == "TagShell";
            if (check == true) {
              arr.push({ id: resData?.controlBy, shared: resData?.sharedBy });
            }
          }
        } else {
          name.push(resData1);
          // setDatat(false);
          datat = false;
        }
      }
    }
    if (arr.length != 0) {
      let arr1 = [];
      let arr2 = [];

      if (arr.length > 1) {
        for (let getValue of arr) {
          let atAdd = e.indexOf("#");
          var msg = e.slice(atAdd);

          if (
            e == `@${getValue?.shared?.fname + getValue?.shared?.lname + msg}`
          ) {
            arr1.push(getValue);
            if (arr1.length > 1 && t == getValue?.id._id) {
              arr2.push(getValue);
            }
          }
        }
      }
      if (arr2.length != 0) {
        let atAdd = e.indexOf("#");
        var msg = e.slice(atAdd);

        async function op() {
          let bodyValue = { tag: msg, id: arr2[0]?.id?._id };
          let url1 = `${config.base_api_url}/api/tagsCount`;
          let method1 = "POST";
          let res = await fetchExtreamApi(url1, method1, bodyValue);

          // setData(res.data.arr);
          data = res.arr;

          resolve(data);
        }
        op();
      } else {
        if (arr1.length != 0) {
          let atAdd = e.indexOf("#");
          var msg = e.slice(atAdd);

          async function op() {
            let bodyValue = { tag: msg, id: arr1[0]?.id?._id };
            let url1 = `${config.base_api_url}/api/tagsCount`;
            let method1 = "POST";
            let res = await fetchExtreamApi(url1, method1, bodyValue);
            data = res.arr;

            resolve(data);
          }
          op();
        } else if (arr1.length == 0) {
          async function op() {
            let atAdd = e.indexOf("#");
            var msg = e.slice(atAdd);
            let bodyValue = { tag: msg, id: arr[0]?.id._id };
            let url1 = `${config.base_api_url}/api/tagsCount`;
            let method1 = "POST";
            let res = await fetchExtreamApi(url1, method1, bodyValue);

            data = res.arr;
            resolve(data);
          }
          op();
        }
      }
    } else if (arr.length == 0) {
      async function op() {
        let bodyValue = { tag: e };
        let url1 = `${config.base_api_url}/api/tagsCount`;
        let method1 = "POST";
        let res = await fetchExtreamApi(url1, method1, bodyValue);

        //   setData(res.data.arr);
        data = res.arr;

        resolve(data);
      }
      op();
    }
  });
};
